/* eslint @typescript-eslint/no-unsafe-function-type: off */
import { ActionIcon, rem, Tooltip } from "@mantine/core";
import { getFeedback } from "@sentry/react";
import type { Integration } from "@sentry/types";
import { IconMessageReport } from "@tabler/icons-react";
import { useEffect, useRef, useState } from "react";
import { Link } from "wouter";
import * as classes from "./AppNavLinks.module.css";

interface Props {
  textButton?: boolean;
}

export const BugReportButton = ({ textButton = false }: Props) => {
  const [feedback, setFeedback] = useState<Integration | undefined>();
  // Read `getFeedback` on the client only, to avoid hydration errors when server rendering
  useEffect(() => {
    setFeedback(getFeedback() as Integration | undefined);
  }, []);

  const buttonRef = useRef<HTMLAnchorElement>(null);
  useEffect(() => {
    if (feedback) {
      const unsubscribe = (
        feedback as Integration & { attachTo: Function }
      ).attachTo(buttonRef.current);
      return unsubscribe;
    }
    return () => {};
  }, [feedback]);

  const labelText = "Report an Issue";

  return textButton ? (
    <Link
      className={classes.menuButton}
      ref={buttonRef}
      href=""
      style={{ display: "flex", gap: rem(8) }}
    >
      <IconMessageReport size={20} />
      <span>{labelText}</span>
    </Link>
  ) : (
    <Tooltip label={labelText}>
      <ActionIcon component="a" ref={buttonRef} variant="default" size="lg">
        <IconMessageReport size={22} />
      </ActionIcon>
    </Tooltip>
  );
};
