.outerWrapper {
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}

.title {
  padding: var(--mantine-spacing-sm) var(--mantine-spacing-md);
  background: white;
  position: relative;
  border-bottom: 1px solid var(--mantine-color-gray-3);
}

.innerTitle {
  padding: var(--mantine-spacing-sm) var(--mantine-spacing-md);
  position: absolute;
  top: 0;
  left: 0;
}

.invisibleTitle {
  opacity: 0;
  user-select: none;
  pointer-events: none;
}

.canvasWrapper {
  position: relative;
  flex-grow: 1;
  overflow: hidden;
}

.canvasExtrasWrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  border-top: 1px solid var(--mantine-color-gray-3);
}

.contentWrapper.withRelevantContent {
  max-height: 18rem;
}

.innerContentWrapper {
  position: relative;
  overflow: scroll;
}

.desktopOuterWrapper {
  flex-direction: row;

  .title {
    --title-fw: var(--mantine-h2-font-weight) !important;
    --title-lh: var(--mantine-h2-line-height) !important;
    --title-fz: var(--mantine-h2-font-size) !important;
  }

  .canvasExtrasWrapper {
    display: none;
  }

  .contentWrapper {
    max-height: none;
    width: 30%;
    min-width: 300px;
    max-width: 500px;
    border-top: none;
    border-left: 1px solid var(--mantine-color-gray-3);
  }
}
