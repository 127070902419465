import { quizConnectQuery } from "@augmedi/proto-gen";
import { useQuery } from "@connectrpc/connect-query";
import { Alert, Container, Loader, Stack, Text, Title } from "@mantine/core";
import { Link } from "wouter";

export const ModelListPage = () => {
  const listModelsQuery = useQuery(quizConnectQuery.listModels);

  return (
    <Container py="md">
      <Stack>
        <Title order={1}>Models</Title>

        {listModelsQuery.isLoading && <Loader />}

        {listModelsQuery.isError && (
          <Alert title="Error" color="red">
            {listModelsQuery.error + ""}
          </Alert>
        )}

        {listModelsQuery.data &&
          listModelsQuery.data.models.map((model) => (
            <div key={model.id}>
              <Text>
                <Link to={`/models/${model.id}/draft`}>{model.name}</Link>
              </Text>
            </div>
          ))}
      </Stack>
    </Container>
  );
};
