/* eslint @tanstack/query/exhaustive-deps: off */
import { atlasConnectQuery } from "@augmedi/proto-gen";
import { isTruthy } from "@augmedi/type-utils";
import {
  callUnaryMethod,
  createConnectQueryKey,
  useTransport,
} from "@connectrpc/connect-query";
import { Alert, Button, Group } from "@mantine/core";
import { useQueries } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { Fragment } from "react/jsx-runtime";
import { StructurePopover } from "./StructurePopover";

interface Props {
  feedbackStructureIds?: string[];
  onTryAgain: () => void;
}

export const AnswerFeedbackTryAgain = ({
  feedbackStructureIds: feedbackStructureIds = [],
  onTryAgain,
}: Props) => {
  const structureKeys = feedbackStructureIds.map((structureId) => {
    return {
      id: structureId,
    };
  });
  const transport = useTransport();
  const getStructuresMetadata = useQueries({
    queries: structureKeys.map((structureKey) => ({
      queryFn: () =>
        callUnaryMethod(atlasConnectQuery.getStructureMetadata, structureKey, {
          transport,
        }),
      queryKey: createConnectQueryKey(
        atlasConnectQuery.getStructureMetadata,
        structureKey,
      ),
    })),
  });

  const filteredStructures = getStructuresMetadata
    .map((query) => query.data?.base)
    .filter(isTruthy);

  const popoverStructures = filteredStructures.map((structure, index) => (
    <Fragment key={structure.id}>
      <StructurePopover structureBaseData={structure} />
      {index < filteredStructures.length - 1 ? ", " : ""}
    </Fragment>
  ));

  const [height, setHeight] = useState("2rem");
  useEffect(() => {
    setHeight("10rem");
  }, []);

  return (
    <div
      style={{
        height,
        background: "white", // needed to prevent the light variant alert from being transparent
        transition: "all ease 0.4s",
        overflow: "scroll",
      }}
    >
      <Alert
        h="100%"
        variant="light"
        color="yellow"
        radius="0"
        title="Incorrect"
      >
        <Group
          justify="space-between"
          align="flex-start"
          style={{
            flexDirection: "column",
          }}
        >
          {popoverStructures.length > 0 ? (
            <span>You have clicked on the {popoverStructures}.</span>
          ) : (
            <span>You have clicked incorrectly.</span>
          )}
          <Button
            w="100%"
            variant="filled"
            color="yellow"
            radius="md"
            onClick={() => {
              setHeight("0rem");
              onTryAgain();
            }}
          >
            Try Again
          </Button>
        </Group>
      </Alert>
    </div>
  );
};
