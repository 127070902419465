import { quizConnectQuery } from "@augmedi/proto-gen";
import { useQuery } from "@connectrpc/connect-query";
import { AppShell, Box, Divider, Flex, ScrollArea, Stack } from "@mantine/core";
import { useIsMobile, useIsMobileWithCanvas } from "../logic/app-layout";
import { zIndexConstants } from "../z-index-constants";
import { AppNavLinks } from "./AppNavLinks";
import { AppUserOptions } from "./AppUserOptions";
import { AvatarAndUsername } from "./AvatarAndUsername";

interface Props {
  onNavLinkClick?: () => void;
}

export const AppNavbar = ({ onNavLinkClick }: Props) => {
  const whoAmIQuery = useQuery(quizConnectQuery.whoAmI);
  const isMobile = useIsMobile();
  const isMobileWithCanvas = useIsMobileWithCanvas();

  const smallStackGap = "0.4rem";

  return (
    <AppShell.Navbar
      style={
        isMobileWithCanvas
          ? {
              height: "100%",
              top: 0,
              paddingTop: "1rem",
              zIndex: zIndexConstants.navbar,
            }
          : {}
      }
    >
      <ScrollArea>
        <Stack
          style={{ minHeight: "100%" }}
          px="sm"
          py="md"
          justify="space-between"
        >
          {/* [MOBILE WITH CANVAS ONLY] Menu Divider */}
          {isMobileWithCanvas && (
            <Box>
              <Divider my="sm" label="Menu" labelPosition="center" />
            </Box>
          )}

          {/* Navigation Links */}
          <Stack gap={isMobile ? smallStackGap : "var(--stack-gap)"}>
            <AppNavLinks
              permissions={whoAmIQuery.data?.permissions ?? []}
              onNavLinkClick={onNavLinkClick}
            />
          </Stack>

          {/* [MOBILE ONLY] Settings Divider and Setting Items */}
          {isMobile && (
            <Stack gap={smallStackGap}>
              <Flex gap="0.5rem">
                <Divider my="lg" w="100%" />
                <AvatarAndUsername
                  username={whoAmIQuery.data?.username}
                  isMobileView={isMobile}
                />
                <Divider my="lg" w="100%" />
              </Flex>
              <AppUserOptions />
            </Stack>
          )}
        </Stack>
      </ScrollArea>
    </AppShell.Navbar>
  );
};
