/* eslint @typescript-eslint/no-unused-vars: off */
import type { quizPb } from "@augmedi/proto-gen";
import { durationBetweenDates } from "@augmedi/proto-gen";
import { Duration, type PlainMessage } from "@bufbuild/protobuf";
import { Button } from "@mantine/core";
import { uniq } from "lodash-es";
import { useEffect, useState } from "react";
import { IntroductionGroupViewer } from "./IntroductionGroupViewer";

interface Props {
  introductionGroup: PlainMessage<quizPb.IntroductionGroup>;
  onConsumeAndSwitchToNextQuestion: (timeTaken: Duration) => void;
}

export const StatefulIntroductionGroupViewer = ({
  introductionGroup,
  onConsumeAndSwitchToNextQuestion,
}: Props) => {
  // TODO shownAtTime should only start running once the model is loaded. Not sure if this is the case now.
  const [shownAtTime, setShownAtTime] = useState(new Date());

  const [selectedItemIds, setSelectedItemIds] = useState<string[]>([]);

  const [seenItemIds, setSeenItemIds] = useState<string[]>(
    introductionGroup.items
      .filter((item) => item.preselected)
      .map((item) => item.id),
  );
  useEffect(() => {
    setSeenItemIds((ids) => uniq([...ids, ...selectedItemIds]));
  }, [selectedItemIds]);
  const remainingItemIds = introductionGroup.items
    .filter((item) => !seenItemIds.includes(item.id))
    .map((item) => item.id);

  return (
    <IntroductionGroupViewer
      introductionGroup={introductionGroup}
      selectedItemIds={selectedItemIds}
      seenItemIds={seenItemIds}
      onClick={setSelectedItemIds}
      controlsPanel={
        <Button
          onClick={() => {
            const timeTaken = durationBetweenDates(shownAtTime, new Date());
            onConsumeAndSwitchToNextQuestion(timeTaken);
          }}
          disabled={!!remainingItemIds.length}
          fullWidth
          mt="sm"
        >
          {remainingItemIds.length === 1
            ? "1 structure left"
            : remainingItemIds.length
              ? `${remainingItemIds.length} structures left`
              : "Continue"}
        </Button>
      }
    />
  );
};
