import { quizPb } from "@augmedi/proto-gen";
import type { PlainMessage } from "@bufbuild/protobuf";
import { config } from "../config";

export interface SubscriptionNeed {
  stripeUrl: string;
}

export function getSubscriptionNeed({
  id, // becomes the client_reference_id in Stripe
  permissions,
}: Pick<PlainMessage<quizPb.WhoAmIResponse>, "id" | "permissions">):
  | SubscriptionNeed
  | undefined {
  if (permissions.includes(quizPb.Permission.USE_FULL_ANATOMY_QUIZ)) {
    return undefined;
  }

  const stripeParams = new URLSearchParams({
    client_reference_id: id,
  });

  const stripeUrl = new URL(`?${stripeParams}`, config.stripeURL);

  return {
    stripeUrl: stripeUrl.href,
  };
}
