import * as THREE from "three";

export class ClickDetectionBitSelectMaterial extends THREE.ShaderMaterial {
  constructor() {
    super({
      depthWrite: false,
      depthTest: false,
      blending: THREE.CustomBlending,
      blendEquation: THREE.MaxEquation,
      blendSrc: THREE.OneFactor,
      blendDst: THREE.OneFactor,
      blendSrcAlpha: THREE.OneFactor,
      blendDstAlpha: THREE.OneFactor,
      side: THREE.DoubleSide,
      uniforms: {
        inputTexture: { value: null },
        inputBitMasks: { value: [0, 0, 0, 0] },
        outputChannel: { value: 0 },
      },
      vertexShader: `
        varying vec2 vUv;
        
        void main() {
          vUv = uv;
          gl_Position = vec4(position, 1.0);
        }
      `,
      fragmentShader: `
        uniform highp usampler2D inputTexture;
        uniform uint bitNumber;
        
        varying vec2 vUv;
        
        void main() {
          uvec4 inputSample = texture2D(inputTexture, vUv);
          if (
            (bitNumber & inputSample.r) != 0u ||
            (bitNumber & inputSample.g) != 0u ||
            (bitNumber & inputSample.b) != 0u ||
            (bitNumber & inputSample.a) != 0u
          ) {
            gl_FragColor = vec4(1);
          } else {
            gl_FragColor = vec4(0);
          }
        }
      `,
    });
  }
}

/**
 * Renders the scene to a temporary render target and checks if the pixel at the given coordinates is non-black.
 * @param x X pixel position.
 * @param y Y pixel position.
 * @param gl THREE webgl renderer.
 * @param scene THREE scene.
 * @param camera THREE camera.
 * @returns Returns true if any pixel at the given coordinates or surrounding tolerance pixels is non-black.
 */
export function clickedWithinTolerance(
  x: number,
  y: number,
  gl: THREE.WebGLRenderer,
  scene: THREE.Scene,
  camera: THREE.Camera,
) {
  const rect = gl.domElement.getBoundingClientRect();

  const oldMaterialByChild = new Map<
    THREE.Object3D<THREE.Event>,
    THREE.Material
  >();
  for (const child of scene.children) {
    if (child instanceof THREE.Mesh && child.userData.clickDetectionMaterial) {
      oldMaterialByChild.set(child, child.material);
      child.material = child.userData.clickDetectionMaterial;
    }
  }

  const oldToneMapping = gl.toneMapping;
  gl.toneMapping = THREE.NoToneMapping;
  const oldBackground = scene.background;
  scene.background = null;

  const tmpTarget = new THREE.WebGLRenderTarget(
    Math.round(rect.width),
    Math.round(rect.height),
  );
  const pixelBuffer = new Uint8Array(4 * tmpTarget.width * tmpTarget.height);
  gl.setRenderTarget(tmpTarget);
  gl.render(scene, camera);
  gl.readRenderTargetPixels(
    tmpTarget,
    0,
    0,
    tmpTarget.width,
    tmpTarget.height,
    pixelBuffer,
  );

  gl.setRenderTarget(null);

  scene.background = oldBackground;
  gl.toneMapping = oldToneMapping;
  const isWithinTolerance = isAnyPixelInToleranceNonBlack(
    pixelBuffer,
    x,
    y,
    tmpTarget.width,
    5, // TODO - Make tolerance dynamic based on the size of the canvas.
  );

  for (const child of scene.children) {
    const oldMaterial = oldMaterialByChild.get(child);
    if (child instanceof THREE.Mesh && oldMaterial) {
      child.material = oldMaterial;
    }
  }

  tmpTarget.dispose();
  return isWithinTolerance;
}

function isPixelNonBlack(
  pixelBuffer: Uint8Array,
  x: number,
  y: number,
  width: number,
): boolean {
  const index = (y * width + x) * 4;
  const r = pixelBuffer[index];
  const g = pixelBuffer[index + 1];
  const b = pixelBuffer[index + 2];
  const a = pixelBuffer[index + 3];
  return r > 0 || g > 0 || b > 0 || a > 0;
}

function isAnyPixelInToleranceNonBlack(
  pixelBuffer: Uint8Array,
  x: number,
  y: number,
  width: number,
  tolerance: number = 0,
) {
  for (let dx = -tolerance; dx <= tolerance; dx++) {
    for (let dy = -tolerance; dy <= tolerance; dy++) {
      if (Math.abs(dx) + Math.abs(dy) > tolerance) {
        continue;
      }
      if (isPixelNonBlack(pixelBuffer, x + dx, y + dy, width)) {
        return true;
      }
    }
  }
  return false;
}
