import {
  atlasConnectQuery,
  quizConnectQuery,
  quizPb,
} from "@augmedi/proto-gen";
import { toPlainMessage, type PlainMessage } from "@bufbuild/protobuf";
import {
  createConnectQueryKey,
  useMutation,
  useQuery,
} from "@connectrpc/connect-query";
import {
  Box,
  Button,
  Group,
  Image,
  Modal,
  ScrollArea,
  Stack,
  Text,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { notifications } from "@mantine/notifications";
import { createSpotlight } from "@mantine/spotlight";
import { useQueryClient } from "@tanstack/react-query";
import { produce } from "immer";
import { sortBy } from "lodash-es";
import { useEffect, useMemo, useState } from "react";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import type { ModelLabel } from "../../../proto-gen/src/proto/quiz_pb.js";
import { AppLayout, useAppLayout } from "../logic/app-layout.js";
import { useNavigationLockAndBeforeUnload } from "../logic/navigation-lock.js";
import { ChildLabelList } from "./ChildLabelList.js";
import { CreateNewDirectedEdgeModal } from "./CreateNewDirectedEdgeModal.js";
import { LabelSettings } from "./LabelSettings.js";
import { PageMenu } from "./PageMenu.js";
import { ParentLabelList } from "./ParentLabelList.js";
import { StructureSearch } from "./StructureSearch";

interface SelectedLabel {
  label: PlainMessage<quizPb.ModelLabel>;
  dirtyInModelPainter: boolean;
  maybeDirtyVersusServer: boolean;
}

const [structureSpotlightStore] = createSpotlight();

export const AnnotatorPage = () => {
  const queryClient = useQueryClient();

  const [mediaItemId, setMediaItemId] = useState("");
  const imageQuery = useQuery(quizConnectQuery.getMediaItemAsset, {
    id: mediaItemId,
  });

  const modelId = "83b6c4fa-e602-47b8-a1a0-cc72b4965858";

  const [
    isCreateNewDirectedEdgeModalOpen,
    {
      toggle: toggleCreateNewDirectedEdgeModal,
      close: closeCreateNewDirectedEdgeModal,
    },
  ] = useDisclosure(false);
  const [desiredLabelId, setDesiredLabelId] = useState<string>("");

  // Do not modify this value directly. Use setDesiredLabelId instead.
  //
  // This is a copy of the label that was originally loaded from the server.
  // This copy might be out of sync with either (or both) of:
  //   - the internal texture in ModelPainter
  //   - the copy saved on the server
  // Based on the corresponding flags we can determine if it's safe to overwrite
  // this value. Overwriting the value will cause ModelPainter to lose any
  // unsaved internal state.
  const [selectedLabel, _setSelectedLabel] = useState<SelectedLabel>();

  const [selectedParentLabel, setSelectedParentLabel] =
    useState<PlainMessage<quizPb.ModelLabel>>();
  const [selectedChildLabel, setSelectedChildLabel] =
    useState<PlainMessage<quizPb.ModelLabel>>();
  const [newAddedLabelId, setNewAddedLabelId] = useState<string>();
  const [imageUrl, setImageUrl] = useState<string>("");

  const downstreamLabelsQuery = useQuery(
    quizConnectQuery.listDownstreamStructures,
    {
      upstreamStructureId: selectedParentLabel?.structureId ?? "",
    },
  );

  const [childLabelList, setChildLabelList] = useState<
    PlainMessage<quizPb.ModelLabel>[]
  >([]);
  useEffect(() => {
    const childLabelList: PlainMessage<quizPb.ModelLabel>[] = [];
    if (downstreamLabelsQuery.data) {
      for (const structure of downstreamLabelsQuery.data.downstreamStructures) {
        childLabelList.push({
          id: structure.downstreamStructureId,
          name: structure.notes,
        } as PlainMessage<quizPb.ModelLabel>);
      }
      setChildLabelList(childLabelList);
      setSelectedChildLabel(undefined);
    }
  }, [downstreamLabelsQuery.data]);

  const [isDeleteLabelModalOpen, setDeleteLabelModalOpen] = useState(false);

  const createEdgeMutation = useMutation(quizConnectQuery.addEdge, {
    onSuccess: async () => {
      downstreamLabelsQuery.refetch();
    },
    onError: () =>
      notifications.show({
        color: "red",
        title: "Error",
        message: "Failed to create new edge.",
        withCloseButton: true,
        autoClose: 2500,
        style: {
          position: "fixed",
          top: "5rem",
          left: "1rem",
        },
      }),
  });
  const deleteEdgeMutation = useMutation(quizConnectQuery.deleteEdge, {
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: createConnectQueryKey(quizConnectQuery.listModelLabels, {
          modelId,
        }),
      });
      if (!selectedParentLabel) {
        throw new Error(
          "Failed to set selected label. A parent label should be select even after child label deletion",
        );
      }
      downstreamLabelsQuery.refetch();
      setDesiredLabelId(selectedParentLabel.id);
      setSelectedChildLabel(undefined);
      setDeleteLabelModalOpen(false);
    },
  });

  const modifySelectedLabel = (
    cb: (label: PlainMessage<quizPb.ModelLabel>) => void,
  ) => {
    _setSelectedLabel((oldEntry) => {
      if (!oldEntry) {
        return;
      }
      return {
        ...oldEntry,
        label: produce<PlainMessage<quizPb.ModelLabel>>(
          toPlainMessage(oldEntry.label),
          cb,
        ),
      };
    });
  };

  const listQuery = useQuery(atlasConnectQuery.listStructures);

  const { parentLabels } = useMemo(() => {
    const entries = listQuery?.data?.entries ?? [];
    let parentLabels = entries.map((entry) => ({
      structureId: entry.structureId,
      id: entry.structureId,
      name: entry.displayName,
    }));
    if (!listQuery.data) {
      return { parentLabels };
    }

    // Sort parent and child labels by name and id lastly
    parentLabels = sortBy(parentLabels, (l) => l.name);

    if (newAddedLabelId) {
      if (!selectedParentLabel) {
        throw new Error(
          `New added child label (id: ${newAddedLabelId}) has no selected parent label.`,
        );
      }
      const newChildLabel = undefined;
      setSelectedParentLabel(undefined);
      setSelectedChildLabel(newChildLabel);
      setNewAddedLabelId(undefined);
    }

    return { parentLabels };
  }, [listQuery.data, newAddedLabelId]);

  const [otherVisibleLabelIds, setOtherVisibleLabelIds] = useState<Set<string>>(
    new Set(),
  );

  const pageListQuery = useQuery(quizConnectQuery.listUpstreamStructures, {
    mediaItemId,
  });

  useEffect(() => {
    if (imageQuery.data) {
      setImageUrl(imageQuery.data.downloadUrl);
      pageListQuery.refetch();
    }
  }, [imageQuery.data]);

  useEffect(() => {
    if (pageListQuery.data) {
      setOtherVisibleLabelIds(
        new Set(
          pageListQuery.data.upstreamStructures.map(
            (s) => s.upstreamStructureId,
          ),
        ),
      );
    }
  }, [pageListQuery.data]);

  const anythingSaving =
    createEdgeMutation.isPending || deleteEdgeMutation.isPending;
  const anythingDirty =
    anythingSaving ||
    !!selectedLabel?.dirtyInModelPainter ||
    !!selectedLabel?.maybeDirtyVersusServer;
  useNavigationLockAndBeforeUnload(anythingDirty);

  let userVisibleSaveState: "saving" | "saved" | "error";
  if (!anythingDirty) {
    userVisibleSaveState = "saved";
  } else if (anythingSaving) {
    userVisibleSaveState = "saving";
  } else {
    // We're not saving yet, but we will be soon because of the autosave timer.
    userVisibleSaveState = "saving";
  }
  const userVisibleSaveStateLabels: {
    [K in typeof userVisibleSaveState]: string;
  } = {
    saved: "All changes saved",
    saving: "Saving...",
  };

  useAppLayout(AppLayout.FullscreenWithHeader);

  const onParentLabelSelect = (newLabel: PlainMessage<quizPb.ModelLabel>) => {
    setDesiredLabelId(newLabel.id);
    setSelectedParentLabel(newLabel);
    setSelectedChildLabel(undefined);
  };

  const onChildLabelSelect = (newLabel: PlainMessage<quizPb.ModelLabel>) => {
    setDesiredLabelId(newLabel.id);
    setSelectedChildLabel(newLabel);
  };

  const onAddChildLabel = () => {
    if (!selectedParentLabel) {
      throw new Error("Failed to add new label. No parent label selected.");
    }
    toggleCreateNewDirectedEdgeModal();
  };

  return (
    <>
      <Group
        style={{ height: "100%", overflow: "hidden" }}
        align="stretch"
        wrap="nowrap"
        gap={0}
      >
        <Box
          style={{
            borderRight: "1px solid #dee2e6",
          }}
        >
          <Stack
            p="sm"
            style={{
              height: "100%",
              width: "max-content",
              display: "flex",
            }}
          >
            <Text fs="italic" c="dimmed">
              {userVisibleSaveStateLabels[userVisibleSaveState]}
            </Text>
            <>
              <Group
                gap="lg"
                align="flex-start"
                style={{
                  overflow: "hidden",
                }}
              >
                <ParentLabelList
                  parentLabels={parentLabels as PlainMessage<ModelLabel>[]}
                  selectedParentLabelId={selectedParentLabel?.id}
                  onParentLabelSelect={onParentLabelSelect}
                  otherVisibleLabelIds={otherVisibleLabelIds}
                  toggleLabelVisible={() => {}}
                  useEyes={false}
                  title="Upstream Structure"
                />
                {parentLabels.length !== 0 && (
                  <ChildLabelList
                    childLabelList={childLabelList}
                    selectedChildLabelId={selectedChildLabel?.id}
                    onChildLabelSelect={onChildLabelSelect}
                    otherVisibleLabelIds={new Set()}
                    toggleLabelVisible={() => {}}
                    onAddChildLabel={onAddChildLabel}
                    useEyes={false}
                    useXes={true}
                    title="Downsteam Structures"
                    xFn={(labelId: string) => {
                      setDesiredLabelId(labelId);
                      setTimeout(() => setDeleteLabelModalOpen(true), 1000);
                    }}
                  />
                )}
              </Group>

              <LabelSettings
                selectedLabel={selectedLabel?.label}
                modifySelectedLabel={modifySelectedLabel}
                openDeleteLabelModal={() => setDeleteLabelModalOpen(true)}
              />
            </>
          </Stack>
        </Box>
        <Box
          style={{
            flexGrow: 1,
            overflow: "hidden",
            cursor: "not-allowed",
          }}
        >
          <PageMenu setImageId={(imageId) => setMediaItemId(imageId)} />
          <ScrollArea h={"100%"}>
            <Zoom>
              <Image src={imageUrl} alt="Chose an image above" />
            </Zoom>
          </ScrollArea>
        </Box>
      </Group>
      <Modal
        opened={isDeleteLabelModalOpen}
        onClose={() => setDeleteLabelModalOpen(false)}
        title="Delete downstream structure?"
      >
        <Stack>
          <Text>
            {`Are you sure you want to delete the downstream structure "${desiredLabelId}"?`}
          </Text>
          <Group justify="center">
            <Button
              color="red"
              onClick={() =>
                deleteEdgeMutation.mutate({
                  upstreamStructureId: selectedParentLabel?.id,
                  downstreamStructureId: desiredLabelId,
                })
              }
            >
              Delete
            </Button>
            <Button color="red" onClick={() => setDeleteLabelModalOpen(false)}>
              Cancel
            </Button>
          </Group>
        </Stack>
      </Modal>
      <StructureSearch
        onSelectStructure={(id) => {
          modifySelectedLabel((label) => {
            label.id = id;
          });
        }}
        structureSpotlightStore={structureSpotlightStore}
      />
      {selectedParentLabel && (
        <CreateNewDirectedEdgeModal
          mediaItemId={mediaItemId}
          modelId={modelId}
          upstreamStructure={selectedParentLabel}
          isModalOpen={isCreateNewDirectedEdgeModalOpen}
          createEdgeMutation={createEdgeMutation}
          closeModal={closeCreateNewDirectedEdgeModal}
        />
      )}
    </>
  );
};
