/* eslint react/display-name: off */
import { NavLink, Text } from "@mantine/core";
import { IconFile, IconFileOff } from "@tabler/icons-react";
import { first } from "lodash-es";
import { memo } from "react";
import { Link } from "wouter";
import type { NestedDisplayTreeNode } from "./convert";

interface Props {
  node: NestedDisplayTreeNode;
  openedIdPath?: string[];
  onClick?: (nodeId: string) => void;
}

const hrefRequiredForFocus = "#";

export const DisplayTreeNavLink = memo(
  ({ node, openedIdPath, onClick }: Props) => {
    const isOpened = !!openedIdPath?.length && openedIdPath[0] === node.node.id;
    const infoPageHref = first(
      node.node.structureIds.map((id) => `/atlas/structures/${id}`),
    );
    return (
      <NavLink
        component={Link}
        href={infoPageHref ?? hrefRequiredForFocus}
        label={
          <Text
            fs={infoPageHref ? undefined : "italic"}
            c={infoPageHref ? undefined : "dimmed"}
          >
            {node.node.name}
          </Text>
        }
        childrenOffset="xs"
        onClick={() => onClick?.(node.node.id)}
        opened={isOpened}
        leftSection={
          infoPageHref ? (
            <div title="Click for info page">
              <IconFile size={14} />
            </div>
          ) : (
            <div title="No info page">
              <IconFileOff
                size={14}
                color={infoPageHref ? undefined : "var(--mantine-color-dimmed)"}
              />
            </div>
          )
        }
      >
        {!!node.children.length &&
          (openedIdPath
            ? node.children.map((child) => (
                <DisplayTreeNavLink
                  key={child.node.id}
                  node={child}
                  openedIdPath={isOpened ? openedIdPath?.slice(1) : undefined}
                  onClick={onClick}
                />
              ))
            : [])}
      </NavLink>
    );
  },
);
