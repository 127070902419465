import { quizConnectQuery } from "@augmedi/proto-gen";
import { useQuery } from "@connectrpc/connect-query";
import {
  Container,
  ScrollArea,
  Stack,
  Table,
  Text,
  Title,
} from "@mantine/core";
import cx from "clsx";
import { useState } from "react";
import { Link } from "wouter";
import { DemoResetButton } from "./DemoResetButton";
import * as classes from "./UsersPage.module.css";
import { formatDuration, formatTimestamp } from "./utils";

export const UsersPage = () => {
  const users = useQuery(quizConnectQuery.listUsers);
  const [scrolled, setScrolled] = useState(false);

  return (
    <Container py="md">
      <Stack>
        <Title>Users</Title>
        <ScrollArea
          h={700}
          onScrollPositionChange={({ y }) => setScrolled(y !== 0)}
        >
          <Table miw={700}>
            <Table.Thead
              className={cx(classes.header, { [classes.scrolled]: scrolled })}
            >
              <Table.Tr>
                <Table.Th>Name</Table.Th>
                <Table.Th>Roles</Table.Th>
                <Table.Th>Learning Time</Table.Th>
                <Table.Th>Last Active</Table.Th>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              {users.data?.users.map((user) => (
                <Table.Tr key={user.id}>
                  <Table.Th>
                    <Link to={`/users/${user.id}`}>
                      <Text>
                        {user.name !== ""
                          ? user.name
                          : user.email !== ""
                            ? user.email
                            : user.username !== ""
                              ? user.username
                              : "Anonymous Coward"}
                      </Text>
                    </Link>
                  </Table.Th>
                  <Table.Th>
                    <Text>
                      {user.roles.map(
                        (role, i) =>
                          role + (i !== user.roles.length - 1 ? ", " : ""),
                      )}
                    </Text>
                  </Table.Th>
                  <Table.Th>
                    <Text>{formatDuration(user.learningTime)}</Text>
                  </Table.Th>
                  <Table.Th>
                    <Text>{formatTimestamp(user.lastActive)}</Text>
                  </Table.Th>
                </Table.Tr>
              ))}
            </Table.Tbody>
          </Table>
        </ScrollArea>
      </Stack>
      <DemoResetButton />
    </Container>
  );
};
