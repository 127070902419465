// Based on https://github.com/p3tecracknell/rasterise-triangle/blob/master/rasterise.js

import { assert } from "assert-ts";
import { assertImageValid, type Image } from "./image.js";

interface Point {
  // in pixel coordinates
  x: number;
  y: number;
}

function calcSlope(va: Point, vb: Point) {
  const slope = (vb.x - va.x) / (vb.y - va.y);
  return Number.isFinite(slope) ? slope : 0;
}

function validateAndSortVertices(vertices: Point[]): [Point, Point, Point] {
  assert(vertices.length === 3);
  vertices = [...vertices].sort((a, b) => a.y - b.y);
  return [vertices[0], vertices[1], vertices[2]];
}

function fillBottomFlatTriangle(
  image: Image,
  [v1, v2, v3]: [Point, Point, Point],
) {
  const invSlope1 = calcSlope(v1, v2);
  const invSlope2 = calcSlope(v3, v1);

  let curX1 = v1.x;
  let curX2 = v1.x;

  const yMin = v1.y;
  const yMax = v2.y;

  for (let y = yMin; y <= yMax; y++) {
    const yStep = Math.min(1, yMax - y);
    curX1 += invSlope1 * yStep;
    curX2 += invSlope2 * yStep;
    horizontalLine(image, curX1, curX2, Math.floor(y));
  }
}

function fillTopFlatTriangle(
  image: Image,
  [v1, v2, v3]: [Point, Point, Point],
) {
  const invSlope1 = calcSlope(v3, v1);
  const invSlope2 = calcSlope(v2, v3);

  let curX1 = v3.x;
  let curX2 = v3.x;

  const yMin = v1.y;
  const yMax = v3.y;

  for (let y = yMax; y >= yMin; y--) {
    horizontalLine(image, curX1, curX2, Math.floor(y));
    curX1 -= invSlope1;
    curX2 -= invSlope2;
  }
}

function horizontalLine(image: Image, x0: number, x1: number, y: number) {
  assert(Number.isSafeInteger(y));

  if (y < 0 || y >= image.height) {
    return;
  }

  const xMin = Math.max(0, Math.floor(Math.min(x0, x1)));
  const xMax = Math.min(image.width, Math.floor(Math.max(x0, x1)) + 1);

  image.data.fill(1, xMin + y * image.width, xMax + y * image.width);
}

export function fillTriangle(image: Image, _vertices: Point[]) {
  assertImageValid(image);
  const vertices = validateAndSortVertices(_vertices);

  if (vertices[0].y === vertices[1].y && vertices[1].y === vertices[2].y) {
    horizontalLine(
      image,
      Math.min(vertices[0].x, vertices[1].x, vertices[2].x),
      Math.max(vertices[0].x, vertices[1].x, vertices[2].x),
      Math.floor(vertices[0].y),
    );
  } else if (vertices[1].y === vertices[2].y) {
    fillBottomFlatTriangle(image, vertices);
  } else if (vertices[0].y === vertices[1].y) {
    fillTopFlatTriangle(image, vertices);
  } else {
    const _p =
      (vertices[1].y - vertices[0].y) / (vertices[2].y - vertices[0].y);
    const p = _p >= 0 && _p <= 1 ? _p : 0; // handles the NaN case for (near-)degenerate triangles
    const v4 = {
      x: vertices[0].x * (1 - p) + vertices[2].x * p,
      y: vertices[1].y,
    };
    fillBottomFlatTriangle(image, [vertices[0], vertices[1], v4]);
    fillTopFlatTriangle(image, [vertices[1], v4, vertices[2]]);
  }
}

export function fillTriangles(image: Image, vertices: Float32Array) {
  assert(vertices.length % 6 === 0);
  for (let i = 0; i < vertices.length; i += 6) {
    fillTriangle(image, [
      { x: vertices[i + 0], y: vertices[i + 1] },
      { x: vertices[i + 2], y: vertices[i + 3] },
      { x: vertices[i + 4], y: vertices[i + 5] },
    ]);
  }
}
