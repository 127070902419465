import { assertImageValid, type Image } from "./image.js";

export function dilate(image: Image) {
  assertImageValid(image);
  const { width, height, data } = image;

  for (let i = 0; i < data.length; i++) {
    const v = data[i];
    if (v !== 0 && v !== 1) {
      throw new Error(`Invalid pixel value: ${v} (must be 0 or 1)`);
    }
  }

  // There are ways to do this in-place, but this is simpler and fast enough.
  const oldData = new Uint8Array(data.length);
  oldData.set(data);

  for (let y = 1; y < height - 1; y++) {
    for (let x = 1; x < width - 1; x++) {
      const i = y * width + x;
      if (data[i] === 1) {
        continue;
      }
      if (
        oldData[i - 1] === 1 ||
        oldData[i + 1] === 1 ||
        oldData[i - width] === 1 ||
        oldData[i + width] === 1
      ) {
        data[i] = 1;
      }
    }
  }
}
