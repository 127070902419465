/* eslint @typescript-eslint/no-explicit-any: off */
import { useContext, useState } from "react";
import { Router } from "wouter";
import { useBrowserLocation } from "wouter/use-browser-location";
import { NavigationLockContext } from "../logic/navigation-lock.js";

interface Props {
  children: React.ReactNode;
}

function navigationHook(): [
  location: string,
  setLocation: (location: string, ...args: any[]) => void,
] {
  const [location, setLocation] = useBrowserLocation();

  const lockContext = useContext(NavigationLockContext);
  if (lockContext === undefined) {
    throw new Error("NavigationLockContext is not defined");
  }
  const { locked } = lockContext;

  const setLocationIfUnlocked: typeof setLocation = (...args) => {
    if (locked) {
      const wantsToNavigate = window.confirm(
        "Leave page? Changes that you made may not be saved.",
      );
      if (!wantsToNavigate) {
        return;
      }
    }
    return setLocation(...args);
  };

  return [location, setLocationIfUnlocked];
}

export const RouteProvider = ({ children }: Props) => {
  const [locked, setLocked] = useState(false);

  return (
    <NavigationLockContext.Provider value={{ locked, setLocked }}>
      <Router hook={navigationHook}>{children}</Router>
    </NavigationLockContext.Provider>
  );
};
