import { assert } from "assert-ts";

/*
This code compresses a binary array using run-length encoding. The uncompressed
array contains bytes, but only with the values 0 or 1. The compressed array
contains 16-bit words (unsigned little endian). The value to repeat is stored in
the most significant bit of each word. The remaining 15 bits are
"repetitionCount - 1".  This encoding can store up to 0x7FFF identical bits per
16-bit word. If a run is longer than that, multiple words are used. The total
number of uncompressed bits must be exactly the expected amount, which means
that even trailing zeros have to be encoded.
*/

function packUint16LittleEndian(compressedValues: number[]) {
  const compressedValuesBuffer = new ArrayBuffer(compressedValues.length * 2);
  const compressedValuesView = new DataView(compressedValuesBuffer);
  for (let i = 0; i < compressedValues.length; i++) {
    compressedValuesView.setUint16(i * 2, compressedValues[i], true);
  }

  return new Uint8Array(compressedValuesBuffer);
}

export function compressBinaryMask(uncompressedValues: Uint8Array): Uint8Array {
  for (const value of uncompressedValues) {
    if (value !== 0 && value !== 1) {
      throw new Error(
        `Binary mask contains values other than 0 and 1: got ${value}`,
      );
    }
  }

  const compressedValues: number[] = [];
  let value: number | undefined;
  let count = 0;

  const flush = () => {
    while (count > 0) {
      assert(value !== undefined);
      const partialCount = Math.min(count, 0x7fff);
      count -= partialCount;
      compressedValues.push((value << 15) | (partialCount - 1));
    }
    assert(count === 0);
  };

  for (const nextValue of uncompressedValues) {
    if (nextValue === value) {
      count++;
    } else {
      flush();
      value = nextValue;
      count = 1;
    }
  }
  flush();

  return packUint16LittleEndian(compressedValues);
}

export function decompressBinaryMask(
  compressedValues: Uint8Array,
  uncompressedLength: number,
): Uint8Array {
  if (compressedValues.length % 2 !== 0) {
    throw new Error(
      `compressedValues.length must be a multiple of 2 bytes, got ${compressedValues.length}`,
    );
  }

  const uncompressedValues = new Uint8Array(uncompressedLength);
  const compressedValuesView = new DataView(
    compressedValues.buffer,
    compressedValues.byteOffset,
    compressedValues.byteLength,
  );
  assert(compressedValuesView.byteLength === compressedValues.length);

  let uncompressedIndex = 0;
  let compressedIndex = 0;

  while (compressedIndex < compressedValues.length) {
    const word = compressedValuesView.getUint16(compressedIndex, true);
    compressedIndex += 2;
    const value = (word >> 15) & 1;
    const count = (word & 0x7fff) + 1;

    const newUncompressedIndex = uncompressedIndex + count;
    if (newUncompressedIndex > uncompressedLength) {
      throw new Error(
        `Decompressed length ${newUncompressedIndex} is greater than the expected length ${uncompressedLength}`,
      );
    }

    uncompressedValues.fill(value, uncompressedIndex, newUncompressedIndex);
    uncompressedIndex = newUncompressedIndex;
  }

  if (uncompressedIndex !== uncompressedLength) {
    throw new Error(
      `Decompressed length ${uncompressedIndex} is less than the expected length ${uncompressedLength}`,
    );
  }

  return uncompressedValues;
}

export function compressFilledMask(length: number, value: 0 | 1): Uint8Array {
  const compressedValues: number[] = [];
  let count = length;
  while (count > 0) {
    const partialCount = Math.min(count, 0x7fff);
    count -= partialCount;
    compressedValues.push((value << 15) | (partialCount - 1));
  }
  return packUint16LittleEndian(compressedValues);
}
