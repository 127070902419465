import { quizPb } from "@augmedi/proto-gen";
import { Box, Group, Paper, Title } from "@mantine/core";
import { Link } from "wouter";
import { MiniPie } from "./MiniPie";
import { PercentPill } from "./PercentPill";
import * as classes from "./QuizChapter.module.css";

const miniPieSize = 24;

interface Props {
  chapter: quizPb.FrozenChapter;
  progress: quizPb.GetQuizProgressResponse_ChapterProgress;
}

export const QuizChapter = ({ chapter, progress }: Props) => {
  if (chapter.notReady) {
    return (
      <div className={classes.chapterCard}>
        <Paper p="md" shadow="sm">
          <Group gap="xs" justify="space-between" align="center" wrap="nowrap">
            <Title order={4}>{chapter.name}</Title>
            <Group gap="xs">
              <PercentPill labelSuffix="coming soon" colorName="gray" />
            </Group>
          </Group>
        </Paper>
      </div>
    );
  }
  return (
    <Link
      key={chapter.id}
      to={`/quiz/chapters/${chapter.id}`}
      className={classes.chapterCard}
    >
      <Paper p="md">
        <Group gap="xs" justify="space-between" align="center" wrap="nowrap">
          <Title order={4}>{chapter.name}</Title>
          <Group gap="xs" visibleFrom="sm">
            <PercentPill
              ratio={progress.learnedRatio}
              labelSuffix="seen"
              colorName="blue"
            />
            <PercentPill
              ratio={progress.needsReviewRatio}
              labelSuffix="need review"
              colorName="yellow"
            />
          </Group>
          <Box
            hiddenFrom="sm"
            title={[
              Math.round(100 * progress.learnedRatio),
              "% seen, ",
              Math.round(100 * progress.needsReviewRatio),
              "% need review",
            ]
              .map((s) => "" + s)
              .join("")}
            style={{ width: miniPieSize, height: miniPieSize }}
          >
            <MiniPie
              size={miniPieSize}
              slices={[
                { ratio: 1, colorName: "gray", colorLevel: 2 },
                { ratio: progress.learnedRatio, colorName: "blue" },
                {
                  ratio: progress.needsReviewRatio,
                  colorName: "yellow",
                },
              ]}
            />
          </Box>
        </Group>
      </Paper>
    </Link>
  );
};
