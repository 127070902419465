/* eslint @typescript-eslint/no-unused-vars: off */
import { quizConnectQuery } from "@augmedi/proto-gen";
import { useSuspenseQuery } from "@connectrpc/connect-query";
import { ActionIcon, Select, useMantineTheme } from "@mantine/core";
import { IconEye, IconX } from "@tabler/icons-react";
import { useState } from "react";
import { Link } from "wouter";

interface Props {
  selectedId: string | undefined;
  onChange: (id: string | undefined) => void;
  label?: string;
  placeholder?: string;
  description?: string;
}

export const ModelSelect = ({
  selectedId,
  onChange,
  label = "Model",
  placeholder = "(no model)",
  description,
}: Props) => {
  const theme = useMantineTheme();

  const listModelsQuery = useSuspenseQuery(quizConnectQuery.listModels);
  const models = listModelsQuery.data!.models;

  let modelExists = true;
  const selectData = models.map((model) => ({
    value: model.id,
    label: model.name,
  }));
  if (selectedId && !selectData.some((option) => option.value === selectedId)) {
    modelExists = false;
    selectData.push({
      value: selectedId,
      label: "(unknown or deleted model)",
    });
  }

  const [searchValue, setSearchValue] = useState("");

  return (
    <Select
      data={selectData}
      value={selectedId ?? null}
      onChange={(id) => onChange(id ?? undefined)}
      label={label}
      placeholder={placeholder}
      description={description}
      rightSection={
        <>
          {modelExists && (
            <Link to={`/models/${selectedId}/draft`} asChild>
              <ActionIcon
                component="a"
                title="Show model"
                aria-label="Show model"
                color="gray"
                variant="subtle"
              >
                <IconEye style={{ width: "70%", height: "70%" }} stroke={1.5} />
              </ActionIcon>
            </Link>
          )}
          <ActionIcon
            component="a"
            title="Clear"
            aria-label="Clear"
            color="gray"
            variant="subtle"
            onClick={() => {
              onChange(undefined);
              setSearchValue("");
            }}
            mr="3px"
          >
            <IconX style={{ width: "70%", height: "70%" }} stroke={1.5} />
          </ActionIcon>
        </>
      }
      rightSectionPointerEvents={modelExists ? "all" : "none"}
      rightSectionWidth={`calc(2 * 1.75rem * var(--mantine-scale) + var(--mantine-spacing-sm))`}
      searchable
      searchValue={searchValue}
      onSearchChange={setSearchValue}
    />
  );
};
