/* eslint @tanstack/query/exhaustive-deps: off */
import { quizConnectQuery } from "@augmedi/proto-gen";
import { Code, ConnectError } from "@connectrpc/connect";
import { callUnaryMethod, useTransport } from "@connectrpc/connect-query";
import { Button, Modal, Stack } from "@mantine/core";
import { useQuery as tsUseQuery, useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { Link, useLocation } from "wouter";

enum LoginState {
  NeverLoggedIn,
  LoggedIn,
  PreviouslyLoggedIn,
  Redirected,
}

export const LoginRedirect = () => {
  const [location, setLocation] = useLocation();
  const onLoginPage =
    location === "/login" ||
    location === "/old-login" ||
    location === "/signup";

  const [loginState, setLoginState] = useState(LoginState.NeverLoggedIn);

  const queryClient = useQueryClient();

  const transport = useTransport();
  const { status, error } = tsUseQuery({
    queryFn: () => callUnaryMethod(quizConnectQuery.whoAmI, {}, { transport }),
    // HACK This queryKey avoids sharing this query with other components,
    // otherwise we might inherit their retries, which delays the redirect.
    queryKey: ["LoginRedirect", "QuizService", "whoAmI"],
    retry: false,
  });

  useEffect(() => {
    if (
      status === "error" &&
      error &&
      error instanceof ConnectError &&
      error.code === Code.Unauthenticated
    ) {
      if (!onLoginPage && loginState === LoginState.NeverLoggedIn) {
        queryClient.cancelQueries();
        setLocation("/login");
      } else if (loginState === LoginState.LoggedIn) {
        setLoginState(LoginState.PreviouslyLoggedIn);
      } else if (onLoginPage && loginState === LoginState.PreviouslyLoggedIn) {
        setLoginState(LoginState.Redirected);
      }
    } else if (status === "success") {
      setLoginState(LoginState.LoggedIn);
      if (onLoginPage) {
        setLocation("/");
      }
    }
  }, [status, error, location, loginState, setLocation]);

  return (
    <Modal
      opened={!onLoginPage && loginState === LoginState.PreviouslyLoggedIn}
      onClose={() => {}}
      withCloseButton={false}
      title="Logged out"
    >
      <Stack>
        You have been logged out.
        <Link to="/login" asChild>
          <Button component="a" onClick={window.location.reload}>
            Log in again
          </Button>
        </Link>
      </Stack>
    </Modal>
  );
};
