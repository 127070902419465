export const LogoImage = () => {
  const logoUrl = new URL("../images/augmedi-blueg-text.png", import.meta.url);

  return (
    <img
      src={"" + logoUrl}
      alt="Augmedi"
      height="25px"
      width="109px"
      style={{
        display: "block",
        marginTop: -3,
        marginBottom: -8,
        objectFit: "contain",
      }}
    />
  );
};
