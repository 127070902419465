// @generated by protoc-gen-connect-es v1.5.0 with parameter "target=ts"
// @generated from file proto/atlas.proto (package augmedi.atlas, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { BatchExactNameSearchRequest, BatchExactNameSearchResponse, EnumerateIdSpaceRequest, EnumerateIdSpaceResponse, GetDisplayTreeRequest, GetDisplayTreeResponse, GetRelatedStructuresRequest, GetRelatedStructuresResponse, GetStructureMetadataRequest, GetStructureMetadataResponse, ListStructuresRequest, ListStructuresResponse, NameSearchRequest, NameSearchResponse, ResolveStructureIdRequest, ResolveStructureIdResponse } from "./atlas_pb.js";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service augmedi.atlas.AtlasService
 */
export const AtlasService = {
  typeName: "augmedi.atlas.AtlasService",
  methods: {
    /**
     * Search RPCs 
     *
     * @generated from rpc augmedi.atlas.AtlasService.NameSearch
     */
    nameSearch: {
      name: "NameSearch",
      I: NameSearchRequest,
      O: NameSearchResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc augmedi.atlas.AtlasService.BatchExactNameSearch
     */
    batchExactNameSearch: {
      name: "BatchExactNameSearch",
      I: BatchExactNameSearchRequest,
      O: BatchExactNameSearchResponse,
      kind: MethodKind.Unary,
    },
    /**
     * Display tree RPCs 
     *
     * @generated from rpc augmedi.atlas.AtlasService.GetDisplayTree
     */
    getDisplayTree: {
      name: "GetDisplayTree",
      I: GetDisplayTreeRequest,
      O: GetDisplayTreeResponse,
      kind: MethodKind.Unary,
    },
    /**
     * Structure RPCs 
     *
     * @generated from rpc augmedi.atlas.AtlasService.GetStructureMetadata
     */
    getStructureMetadata: {
      name: "GetStructureMetadata",
      I: GetStructureMetadataRequest,
      O: GetStructureMetadataResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc augmedi.atlas.AtlasService.ResolveStructureId
     */
    resolveStructureId: {
      name: "ResolveStructureId",
      I: ResolveStructureIdRequest,
      O: ResolveStructureIdResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc augmedi.atlas.AtlasService.GetRelatedStructures
     */
    getRelatedStructures: {
      name: "GetRelatedStructures",
      I: GetRelatedStructuresRequest,
      O: GetRelatedStructuresResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc augmedi.atlas.AtlasService.ListStructures
     */
    listStructures: {
      name: "ListStructures",
      I: ListStructuresRequest,
      O: ListStructuresResponse,
      kind: MethodKind.Unary,
    },
    /**
     * ID space RPCs 
     *
     * @generated from rpc augmedi.atlas.AtlasService.EnumerateIdSpace
     */
    enumerateIdSpace: {
      name: "EnumerateIdSpace",
      I: EnumerateIdSpaceRequest,
      O: EnumerateIdSpaceResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

