/* eslint @typescript-eslint/no-explicit-any: off */
import { isEqual } from "lodash-es";
import { FileLoader } from "three";
import { KTX2Loader } from "three-stdlib";

const basisJsUrl = new URL(
  "raw:../third-party/basis/basis_transcoder.js.nobundle",
  import.meta.url,
);
const basisWasmUrl = new URL(
  "raw:../third-party/basis/basis_transcoder.wasm.nobundle",
  import.meta.url,
);

// HACK This version of the KTX2Loader is modified to use separate paths for
// basis_transcoder.js and basis_transcoder.wasm. The original KTX2Loader
// assumed that they would be in the same folder and have exactly those names,
// which is not the case when bundling with Parcel.
class ParcelKTX2Loader extends KTX2Loader {
  init() {
    const _this = this as any;
    const _KTX2Loader = KTX2Loader as any;

    if (!_this.transcoderPending) {
      // Load transcoder wrapper.
      const jsLoader = new FileLoader(_this.manager);
      jsLoader.setPath(_this.transcoderPath);
      jsLoader.setWithCredentials(_this.withCredentials);
      const jsContent = jsLoader.loadAsync(basisJsUrl.href);

      // Load transcoder WASM binary.
      const binaryLoader = new FileLoader(_this.manager);
      binaryLoader.setPath(_this.transcoderPath);
      binaryLoader.setResponseType("arraybuffer");
      binaryLoader.setWithCredentials(_this.withCredentials);
      const binaryContent = binaryLoader.loadAsync(basisWasmUrl.href);

      _this.transcoderPending = Promise.all([jsContent, binaryContent]).then(
        ([jsContent, binaryContent]) => {
          const fn = _KTX2Loader.BasisWorker.toString();

          const body = [
            "/* constants */",
            "let _EngineFormat = " + JSON.stringify(_KTX2Loader.EngineFormat),
            "let _TranscoderFormat = " +
              JSON.stringify(_KTX2Loader.TranscoderFormat),
            "let _BasisFormat = " + JSON.stringify(_KTX2Loader.BasisFormat),
            "/* basis_transcoder.js */",
            jsContent,
            "/* worker */",
            fn.substring(fn.indexOf("{") + 1, fn.lastIndexOf("}")),
          ].join("\n");

          _this.workerSourceURL = URL.createObjectURL(new Blob([body]));
          _this.transcoderBinary = binaryContent;

          _this.workerPool.setWorkerCreator(() => {
            const worker = new Worker(_this.workerSourceURL);
            const transcoderBinary = _this.transcoderBinary.slice(0);

            worker.postMessage(
              { type: "init", config: _this.workerConfig, transcoderBinary },
              [transcoderBinary],
            );

            return worker;
          });
        },
      );
    }

    return _this.transcoderPending;
  }

  dispose() {
    const _this = this as any;

    _this.workerPool.dispose();
    if (_this.workerSourceURL) URL.revokeObjectURL(_this.workerSourceURL);

    return this;
  }
}

function workerConfigFromLoader(loader: KTX2Loader): object {
  return (loader as any).workerConfig;
}

let globalKtxLoader: KTX2Loader | undefined;
export function getKtxLoader(renderer: THREE.WebGLRenderer) {
  const localKtxLoader = new ParcelKTX2Loader();
  localKtxLoader.detectSupport(renderer);
  const localWorkerConfig = workerConfigFromLoader(localKtxLoader);

  if (globalKtxLoader) {
    const configsAreEqual = isEqual(
      localWorkerConfig,
      workerConfigFromLoader(globalKtxLoader),
    );
    localKtxLoader.dispose();
    if (!configsAreEqual) {
      throw new Error(
        "KTX2Loader.workerConfig must be the same for all instances",
      );
    }
  } else {
    globalKtxLoader = localKtxLoader;
  }
  return globalKtxLoader;
}
