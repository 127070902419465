// @generated by protoc-gen-connect-query v1.4.2 with parameter "target=ts"
// @generated from file proto/atlas.proto (package augmedi.atlas, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { MethodKind } from "@bufbuild/protobuf";
import { BatchExactNameSearchRequest, BatchExactNameSearchResponse, EnumerateIdSpaceRequest, EnumerateIdSpaceResponse, GetDisplayTreeRequest, GetDisplayTreeResponse, GetRelatedStructuresRequest, GetRelatedStructuresResponse, GetStructureMetadataRequest, GetStructureMetadataResponse, ListStructuresRequest, ListStructuresResponse, NameSearchRequest, NameSearchResponse, ResolveStructureIdRequest, ResolveStructureIdResponse } from "./atlas_pb.js";

/**
 * Search RPCs 
 *
 * @generated from rpc augmedi.atlas.AtlasService.NameSearch
 */
export const nameSearch = {
  localName: "nameSearch",
  name: "NameSearch",
  kind: MethodKind.Unary,
  I: NameSearchRequest,
  O: NameSearchResponse,
  service: {
    typeName: "augmedi.atlas.AtlasService"
  }
} as const;

/**
 * @generated from rpc augmedi.atlas.AtlasService.BatchExactNameSearch
 */
export const batchExactNameSearch = {
  localName: "batchExactNameSearch",
  name: "BatchExactNameSearch",
  kind: MethodKind.Unary,
  I: BatchExactNameSearchRequest,
  O: BatchExactNameSearchResponse,
  service: {
    typeName: "augmedi.atlas.AtlasService"
  }
} as const;

/**
 * Display tree RPCs 
 *
 * @generated from rpc augmedi.atlas.AtlasService.GetDisplayTree
 */
export const getDisplayTree = {
  localName: "getDisplayTree",
  name: "GetDisplayTree",
  kind: MethodKind.Unary,
  I: GetDisplayTreeRequest,
  O: GetDisplayTreeResponse,
  service: {
    typeName: "augmedi.atlas.AtlasService"
  }
} as const;

/**
 * Structure RPCs 
 *
 * @generated from rpc augmedi.atlas.AtlasService.GetStructureMetadata
 */
export const getStructureMetadata = {
  localName: "getStructureMetadata",
  name: "GetStructureMetadata",
  kind: MethodKind.Unary,
  I: GetStructureMetadataRequest,
  O: GetStructureMetadataResponse,
  service: {
    typeName: "augmedi.atlas.AtlasService"
  }
} as const;

/**
 * @generated from rpc augmedi.atlas.AtlasService.ResolveStructureId
 */
export const resolveStructureId = {
  localName: "resolveStructureId",
  name: "ResolveStructureId",
  kind: MethodKind.Unary,
  I: ResolveStructureIdRequest,
  O: ResolveStructureIdResponse,
  service: {
    typeName: "augmedi.atlas.AtlasService"
  }
} as const;

/**
 * @generated from rpc augmedi.atlas.AtlasService.GetRelatedStructures
 */
export const getRelatedStructures = {
  localName: "getRelatedStructures",
  name: "GetRelatedStructures",
  kind: MethodKind.Unary,
  I: GetRelatedStructuresRequest,
  O: GetRelatedStructuresResponse,
  service: {
    typeName: "augmedi.atlas.AtlasService"
  }
} as const;

/**
 * @generated from rpc augmedi.atlas.AtlasService.ListStructures
 */
export const listStructures = {
  localName: "listStructures",
  name: "ListStructures",
  kind: MethodKind.Unary,
  I: ListStructuresRequest,
  O: ListStructuresResponse,
  service: {
    typeName: "augmedi.atlas.AtlasService"
  }
} as const;

/**
 * ID space RPCs 
 *
 * @generated from rpc augmedi.atlas.AtlasService.EnumerateIdSpace
 */
export const enumerateIdSpace = {
  localName: "enumerateIdSpace",
  name: "EnumerateIdSpace",
  kind: MethodKind.Unary,
  I: EnumerateIdSpaceRequest,
  O: EnumerateIdSpaceResponse,
  service: {
    typeName: "augmedi.atlas.AtlasService"
  }
} as const;
