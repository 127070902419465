import { Avatar, Text } from "@mantine/core";

function initialsFromUsername(username: string): string | undefined {
  const wordFirstLetters = username
    .trim()
    .split(/\s+/)
    .map((word) => word[0]);
  const firstNonWhitespace = [...username.trim().split(/\s+/).join("")];

  let goodEnoughLetters: string[] | undefined;
  if (wordFirstLetters.length >= 2) {
    goodEnoughLetters = wordFirstLetters;
  } else if (firstNonWhitespace.length >= 2) {
    goodEnoughLetters = firstNonWhitespace;
  }

  return goodEnoughLetters?.slice(0, 2).join("").toUpperCase();
}

interface Props {
  username: string | undefined;
  isMobileView?: boolean;
  showTextForSm?: boolean;
}

export const AvatarAndUsername = ({
  username,
  isMobileView,
  showTextForSm = true,
}: Props) => {
  return username ? (
    <>
      <Avatar color="cyan" radius="xl" alt={username}>
        {initialsFromUsername(username)}
      </Avatar>
      <Text
        visibleFrom={showTextForSm ? undefined : "sm"}
        style={
          isMobileView
            ? {
                alignContent: "center",
                color: "var(--mantine-color-gray-6)",
              }
            : {}
        }
      >
        {username}
      </Text>
    </>
  ) : null;
};
