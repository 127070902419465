import * as THREE from "three";

export class ReadTexelMaterial extends THREE.ShaderMaterial {
  constructor() {
    super({
      depthWrite: false,
      depthTest: false,

      glslVersion: THREE.GLSL3,

      uniforms: {
        inTexture: { value: null },
        readUv: { value: new THREE.Vector2() },
      },
      vertexShader: `
        void main() {
          gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
        }
      `,
      fragmentShader: `
        uniform highp usampler2D inTexture;
        uniform vec2 readUv;

        layout(location = 0) out uvec4 outColor;
        
        void main() {
          uvec4 c = texture(inTexture, readUv);
          outColor.x = (c.r << 0) | (c.g << 8) | (c.b << 16) | (c.a << 24);
        }
      `,
    });
  }
}
