import { assert } from "assert-ts";

export interface Image {
  width: number;
  height: number;
  data: Uint8Array;
}

export function assertImageValid(image: Image) {
  assert(Number.isSafeInteger(image.width));
  assert(Number.isSafeInteger(image.height));
  assert(image.width > 0);
  assert(image.height > 0);
  assert(image.data.length === image.width * image.height);
}
