import { quizConnectQuery } from "@augmedi/proto-gen";
import { useQuery } from "@connectrpc/connect-query";
import { Button } from "@mantine/core";
import { useEffect, useState } from "react";

export const DemoResetButton = () => {
  const [isSending, setIsSending] = useState(false);

  const emulateFetch = () => {
    return new Promise((resolve) => {
      resolve([{ data: "ok" }]);
    });
  };

  const handleClick = () => {
    setIsSending(true);
    refetch();
  };

  const { refetch, isSuccess } = useQuery(
    quizConnectQuery.demoReset,
    emulateFetch,
    {
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      enabled: false,
    },
  );

  useEffect(() => {
    if (isSuccess) {
      window.alert(
        "Demo reset started. This will take a minute because the Auth0 API is rate-limited.",
      );
      setIsSending(false);
    }
  }, [isSuccess]);

  return (
    <Button onClick={handleClick} color="red" disabled={isSending}>
      Demo Reset
    </Button>
  );
};
