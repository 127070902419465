import { Code, ConnectError } from "@connectrpc/connect";

export function retryExceptConnectNotFound(retries: number = 3) {
  return (failureCount: number, error: unknown) => {
    if (failureCount >= retries) {
      return false;
    }
    if (error instanceof ConnectError && error.code === Code.NotFound) {
      return false;
    }
    return true;
  };
}
