import { Alert, Button, Group } from "@mantine/core";
import { useState } from "react";

interface Props {
  feedbackStructureNames?: string[];
  onNextQuestion: () => void;
}

export const AnswerFeedbackWrong = ({
  feedbackStructureNames = [],
  onNextQuestion,
}: Props) => {
  const [height, setHeight] = useState("2rem");
  setTimeout(() => {
    setHeight("10rem");
  }, 0);

  return (
    <div
      style={{
        background: "white", // needed to prevent the light variant alert from being transparent
      }}
    >
      <Alert
        h={height}
        style={{ transition: "all ease 0.4s", overflow: "scroll" }}
        variant="light"
        color="red"
        title="Wrong"
      >
        <Group justify="space-between">
          {feedbackStructureNames.length === 0 ? (
            <span>The correct answer is highlighted in green.</span>
          ) : (
            <span>
              The correct answer is{" "}
              {feedbackStructureNames.map((name, idx) => (
                <span key={idx} style={{ fontWeight: "bold" }}>
                  {name}
                  {idx < feedbackStructureNames.length - 1 ? ", " : ""}
                </span>
              ))}
              .
            </span>
          )}
          <Button
            w="100%"
            variant="filled"
            color="red"
            radius="md"
            onClick={() => onNextQuestion()}
          >
            Next question
          </Button>
        </Group>
      </Alert>
    </div>
  );
};
