/* eslint @typescript-eslint/no-unused-expressions: "off" */
import type { quizPb } from "@augmedi/proto-gen";
import type { PlainMessage } from "@bufbuild/protobuf";
import { Flex, Text } from "@mantine/core";
import { IconEye, IconX } from "@tabler/icons-react";

interface Props {
  labelList: PlainMessage<quizPb.ModelLabel>[];
  hoveredLabelId: string | null;
  hoveredEyeLabelId: string | null;
  selectedLabelId: string | undefined;
  otherVisibleLabelIds: Set<string>;
  toggleLabelVisible: (labelId: string) => void;
  isSelected: (label: PlainMessage<quizPb.ModelLabel>) => boolean;
  onLabelSelect: (label: PlainMessage<quizPb.ModelLabel>) => void;
  setHoveredLabelId: (id: string | null) => void;
  setHoveredEyeLabelId: (id: string | null) => void;
  useEyes: boolean;
  useXes?: boolean;
  xFn?: (labelId: string) => void;
}

export const GenericLabelList = ({
  labelList,
  hoveredLabelId,
  hoveredEyeLabelId,
  selectedLabelId,
  otherVisibleLabelIds,
  toggleLabelVisible,
  isSelected,
  onLabelSelect,
  setHoveredLabelId,
  setHoveredEyeLabelId,
  useEyes,
  useXes,
  xFn,
}: Props) => {
  return labelList.map((label) => {
    const isVisible = isSelected(label) || otherVisibleLabelIds.has(label.id);
    return (
      <Flex
        key={label.id}
        wrap="nowrap"
        onClick={() => onLabelSelect(label)}
        onMouseEnter={() => {
          !isSelected(label) && setHoveredLabelId(label.id);
        }}
        onMouseLeave={() => setHoveredLabelId(null)}
        style={{
          padding: "0.2rem 0.5rem",
          cursor: "pointer",
          width: "100%",
          borderRadius: 4,
          transition: "background-color 0.2s",
          backgroundColor: isSelected(label)
            ? "var(--mantine-color-gray-3)"
            : hoveredLabelId === label.id
              ? "var(--mantine-color-gray-2)"
              : "transparent",
          fontWeight: isSelected(label) ? "bold" : "normal",
        }}
      >
        <Text
          style={{
            textOverflow: "ellipsis",
            overflow: "hidden",
            textWrap: "nowrap",
          }}
        >
          {label.name || "(Unnamed label)"}
        </Text>
        {useEyes &&
          (isVisible || hoveredLabelId === label.id) &&
          selectedLabelId && (
            <IconEye
              color={
                isVisible
                  ? "var(--mantine-primary-color-6)"
                  : "var(--mantine-color-dimmed)"
              }
              style={{
                marginLeft: "auto",
                flexShrink: 0,
                borderRadius: 4,
                backgroundColor:
                  hoveredEyeLabelId === label.id
                    ? "var(--mantine-color-gray-3)"
                    : "",
              }}
              onClick={(ev) => {
                toggleLabelVisible(label.id);
                ev.stopPropagation();
              }}
              onMouseEnter={() => setHoveredEyeLabelId(label.id)}
              onMouseLeave={() => setHoveredEyeLabelId(null)}
            />
          )}
        {useXes && xFn && (
          <IconX
            color="var(--mantine-color-error)"
            style={{
              marginLeft: "auto",
              flexShrink: 0,
              borderRadius: 4,
              backgroundColor: "var(--mantine-color-gray-3)",
            }}
            onClick={() => xFn(label.id)}
          />
        )}
      </Flex>
    );
  });
};
