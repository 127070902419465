import { Auth0Provider } from "@auth0/auth0-react";
import * as Sentry from "@sentry/react";
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { App } from "./components/App.js";
import { config } from "./config";

// Please see https://auth0.github.io/auth0-react/interfaces/Auth0ProviderOptions.html
// for a full list of the available properties on the provider
const providerConfig = {
  domain: config.domain,
  clientId: config.clientId,
  authorizationParams: {
    redirect_uri: window.location.origin + "/api/authorize",
    audience: config.audience,
  },
};

Sentry.init({
  dsn: "https://efb2895b60b61359efd65946aa26f9ed@o4507543756013568.ingest.de.sentry.io/4507543778099280",
  tracesSampleRate: 1.0,
  integrations: [Sentry.feedbackIntegration({ autoInject: false })],
  environment: config.sentryEnvironment,
});

const container = document.getElementById("app")!;
const root = createRoot(container);
root.render(
  <Auth0Provider {...providerConfig}>
    <StrictMode>
      <App />
    </StrictMode>
  </Auth0Provider>,
);
