import { Grid, GridCol, Loader } from "@mantine/core";
import { Suspense } from "react";
import { AppLayout, useAppLayout } from "./../logic/app-layout";
import { CameraAngleTestInner } from "./CameraAngleTestInner";
import { OurCanvas } from "./OurCanvas";

export const CameraAngleTest = () => {
  useAppLayout(AppLayout.FullscreenWithHeader);

  return (
    <Grid h="100%" styles={{ inner: { height: "100%" } }} gutter={0}>
      <GridCol span={12}>
        <Suspense fallback={<Loader />}>
          <OurCanvas>
            <CameraAngleTestInner />
          </OurCanvas>
        </Suspense>
      </GridCol>
    </Grid>
  );
};
