/* eslint @typescript-eslint/no-unused-vars: off */
import { quizPb } from "@augmedi/proto-gen";
import { useRef } from "react";
import { v4 as genUuid } from "uuid";
import { useLocation, useSearch } from "wouter";
import { z } from "zod";
import type { UseQuizOptions } from "./quiz";

const quizSearchParamsZod = z.object({
  sessionId: z.string(),
  frozenChapterId: z.string().optional(),
  mode: z.coerce.number(),
  bodyPart: z.coerce.number().optional(),
});

export function useQuizSearchParams() {
  // HACK
  //
  // The useLocation and useSearch hooks seem to sometimes return inconsistent
  // values when used from different components in React's tree. This causes a crash
  // when navigating away from the /quiz/run page. The Route component still sees the
  // /quiz/run location, so the component containing this hook gets rendered, but the
  // useSearch in this hook already sees the new location and search params, which
  // generally aren't valid for the /quiz/run page.
  //
  // This code works around the problem by not parsing search params unless we're on
  // the /quiz/run page. Instead it reuses the last parsed search params. If the search
  // params have never been parsed, it will crash.
  //
  // This code is a complete hack. If we run into this problem more often in the future,
  // we should look into the Wouter code and understand why the hooks are returning
  // inconsistent values.

  const [location, _setLocation] = useLocation();
  const searchString = useSearch();

  const cachedUseQuizOptionsRef = useRef<UseQuizOptions>();

  if (location === "/quiz/run") {
    const { sessionId, frozenChapterId, mode, bodyPart } =
      quizSearchParamsZod.parse(
        Object.fromEntries(new URLSearchParams(searchString).entries()),
      );
    if (
      mode !== quizPb.QuizMode.LEARN_NEW &&
      mode !== quizPb.QuizMode.REPEAT_OLD
    ) {
      throw new Error(`Invalid mode: ${mode}`);
    }
    cachedUseQuizOptionsRef.current = {
      sessionId,
      frozenChapterId,
      mode,
      bodyPart,
    };
  }

  if (!cachedUseQuizOptionsRef.current) {
    throw new Error("Quiz search params were never parsed");
  }

  return cachedUseQuizOptionsRef.current;
}

export function getUrlForNewQuizSession(
  options: Omit<UseQuizOptions, "sessionId">,
): string {
  const sessionId = genUuid();
  const searchParams = new URLSearchParams({
    sessionId,
    mode: options.mode.toString(),
  });

  if (options.frozenChapterId) {
    searchParams.set("frozenChapterId", options.frozenChapterId);
  }
  if (options.bodyPart !== undefined) {
    searchParams.set("bodyPart", options.bodyPart.toString());
  }

  return `/quiz/run?${searchParams.toString()}`;
}
