import { quizConnectQuery } from "@augmedi/proto-gen";
import { useQuery } from "@connectrpc/connect-query";
import { Combobox, TextInput, useCombobox } from "@mantine/core";
import { useEffect, useState } from "react";

interface Page {
  id: string;
  path: string;
}

interface Props {
  setImageId: (imageId: string) => void;
}

export function PageMenu({ setImageId }: Props) {
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
  });

  const [value, setValue] = useState("");

  const pagesQuery = useQuery(quizConnectQuery.listMediaItems);

  const [pages, setPages] = useState<Page[]>([]);

  useEffect(() => {
    if (pagesQuery.data) {
      const pages: Page[] = [];
      for (const page of pagesQuery.data.paths) {
        pages.push(page);
      }
      setPages(pages);
    }
  }, [pagesQuery.data]);

  const options = pages.map((page) => {
    const pieces = page.path.split("/");
    return (
      <Combobox.Option value={page.id} key={page.id}>
        {pieces[pieces.length - 1]}
      </Combobox.Option>
    );
  });

  return (
    <Combobox
      onOptionSubmit={(optionValue, obj) => {
        setValue(obj.children as string);
        combobox.closeDropdown();
        setImageId(optionValue);
      }}
      store={combobox}
      withinPortal={false}
    >
      <Combobox.Target>
        <TextInput
          placeholder="Chose a page"
          value={value}
          onChange={(event) => {
            setValue(event.currentTarget.value);
            combobox.openDropdown();
            combobox.updateSelectedOptionIndex();
          }}
          onClick={() => combobox.openDropdown()}
          onFocus={() => combobox.openDropdown()}
          onBlur={() => combobox.closeDropdown()}
        />
      </Combobox.Target>

      <Combobox.Dropdown>
        <Combobox.Options mah={200} style={{ overflowY: "auto" }}>
          {options.length === 0 ? (
            <Combobox.Empty>Nothing found</Combobox.Empty>
          ) : (
            options
          )}
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  );
}
