/* eslint react/display-name: "off" */
import type { quizPb } from "@augmedi/proto-gen";
import type { PlainMessage } from "@bufbuild/protobuf";
import {
  CloseButton,
  Flex,
  Group,
  Loader,
  Stack,
  Text,
  TextInput,
  Title,
} from "@mantine/core";
import { IconSearch } from "@tabler/icons-react";
import { memo, useMemo, useState } from "react";
import { GenericLabelList } from "./GenericLabelList";

interface Props {
  parentLabels: PlainMessage<quizPb.ModelLabel>[];
  selectedParentLabelId?: string;
  onParentLabelSelect: (label: PlainMessage<quizPb.ModelLabel>) => void;
  otherVisibleLabelIds: Set<string>;
  toggleLabelVisible: (labelId: string) => void;
  useEyes: boolean;
  title?: string;
}

const maximumLabelsDisplay = 20;

export const ParentLabelList = memo(
  ({
    parentLabels,
    selectedParentLabelId,
    otherVisibleLabelIds,
    toggleLabelVisible,
    onParentLabelSelect,
    useEyes,
    title = "Structure Labels",
  }: Props) => {
    const [hoveredLabelId, setHoveredLabelId] = useState<string | null>(null);
    const [hoveredEyeLabelId, setHoveredEyeLabelId] = useState<string | null>(
      null,
    );
    const [parentLabelSearch, setParentLabelSearch] = useState("");

    const filteredLabelList = useMemo(() => {
      const filteredList = parentLabels.filter((label) =>
        parentLabelSearch
          ? label.name.toLowerCase().includes(parentLabelSearch.toLowerCase())
          : true,
      );
      return filteredList;
    }, [parentLabels, parentLabelSearch]);

    const isSelected = (label: PlainMessage<quizPb.ModelLabel>) => {
      return (
        Boolean(selectedParentLabelId) && selectedParentLabelId === label.id
      );
    };

    const selectedParentLabel = parentLabels.find(
      (l) => l.id === selectedParentLabelId,
    );

    const truncatedLabelList = useMemo(() => {
      const truncatedList = filteredLabelList.slice(0, maximumLabelsDisplay);
      for (const id of otherVisibleLabelIds) {
        if (!truncatedList.find((label) => label.id === id)) {
          truncatedList.unshift(parentLabels.find((label) => label.id === id)!);
        }
      }
      if (
        selectedParentLabel &&
        !truncatedList.find((label) => isSelected(label))
      ) {
        truncatedList.unshift(selectedParentLabel);
      }
      return truncatedList;
    }, [filteredLabelList, selectedParentLabelId]);

    return parentLabels.length === 0 ? (
      <Loader m="auto" />
    ) : (
      <>
        <Flex direction="column" h="100%" gap="0.2rem">
          <Group>
            <Title order={5}>{title}</Title>
            <TextInput
              size="xs"
              w={125}
              placeholder="Search..."
              value={parentLabelSearch}
              onChange={(ev) => setParentLabelSearch(ev.currentTarget.value)}
              leftSection={<IconSearch size={14} />}
              rightSection={
                <CloseButton
                  size="xs"
                  aria-label="Clear"
                  onClick={() => setParentLabelSearch("")}
                  c="dimmed"
                  style={{
                    display: parentLabelSearch ? undefined : "none",
                    cursor: "pointer",
                  }}
                />
              }
            />
          </Group>
          <Stack
            gap={0}
            w={250}
            style={{
              overflowY: "scroll",
              overflowX: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            <GenericLabelList
              labelList={truncatedLabelList}
              hoveredLabelId={hoveredLabelId}
              hoveredEyeLabelId={hoveredEyeLabelId}
              selectedLabelId={selectedParentLabelId}
              otherVisibleLabelIds={otherVisibleLabelIds}
              toggleLabelVisible={toggleLabelVisible}
              isSelected={isSelected}
              onLabelSelect={onParentLabelSelect}
              setHoveredLabelId={setHoveredLabelId}
              setHoveredEyeLabelId={setHoveredEyeLabelId}
              useEyes={useEyes}
            />
          </Stack>
          {filteredLabelList.length > maximumLabelsDisplay && (
            <Text fs="italic" c="dimmed" size="sm">
              Hiding {filteredLabelList.length - maximumLabelsDisplay} other
              labels
            </Text>
          )}
        </Flex>
      </>
    );
  },
);
