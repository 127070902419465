/* eslint react/display-name: off */
import { quizConnectQuery } from "@augmedi/proto-gen";
import { useSuspenseQuery } from "@connectrpc/connect-query";
import { forwardRef, useMemo } from "react";
import { useModelAssetUrls } from "../logic/asset.js";
import { RawModelPreview } from "./RawModelPreview.js";
import {
  SharedModelPreviewStuff,
  type Props as SharedModelPreviewStuffProps,
  type SharedModelPreviewStuffRef,
} from "./SharedModelPreviewStuff.js";

interface Props extends Pick<SharedModelPreviewStuffProps, "backgroundColor"> {
  modelId: string;
  visibleMeshIds?: string[];
}

export const NonFrozenModelPreview = forwardRef<
  SharedModelPreviewStuffRef,
  Props
>(({ modelId, visibleMeshIds, backgroundColor }: Props, ref) => {
  const listModelMeshesQuery = useSuspenseQuery(
    quizConnectQuery.listModelMeshes,
    { modelId },
  );

  const meshNamesByWholeMeshLabelId = useMemo(() => new Map(), []);

  const { relevantPartitionIds, visibleGltfMeshNames } = useMemo(() => {
    const visibleMeshIdsSet = visibleMeshIds && new Set(visibleMeshIds);

    const meshes = listModelMeshesQuery.data.meshes ?? [];

    const relevantPartitionIds = [
      ...new Set(
        meshes
          .filter((m) => !visibleMeshIdsSet || visibleMeshIdsSet.has(m.id))
          .map((m) => m.partitionId),
      ),
    ].sort();

    const visibleGltfMeshNames = new Set(
      meshes
        .filter((m) => !visibleMeshIdsSet || visibleMeshIdsSet.has(m.id))
        .map((m) => m.gltfMeshName),
    );

    return { relevantPartitionIds, visibleGltfMeshNames };
  }, [visibleMeshIds, listModelMeshesQuery.data]);

  const modelAssetUrls = useModelAssetUrls(modelId, relevantPartitionIds);

  return (
    <>
      <SharedModelPreviewStuff ref={ref} backgroundColor={backgroundColor} />
      {modelAssetUrls.map((url) => (
        <RawModelPreview
          key={url}
          gltfUrl={url}
          meshNamesByWholeMeshLabelId={meshNamesByWholeMeshLabelId}
          visibleGltfMeshNames={visibleGltfMeshNames}
        />
      ))}
    </>
  );
});
