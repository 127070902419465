import { createContext, useContext, useEffect } from "react";

export interface NavigationLockContextValue {
  locked: boolean;
  setLocked: (locked: boolean) => void;
}

export const NavigationLockContext = createContext<
  NavigationLockContextValue | undefined
>(undefined);

export function useNavigationLock(locked: boolean) {
  const context = useContext(NavigationLockContext);
  if (context === undefined) {
    throw new Error("useNavigationLock must be used within a RouteProvider");
  }

  useEffect(() => {
    context.setLocked(locked);
  }, [locked]);
}

export function useNavigationLockAndBeforeUnload(locked: boolean) {
  useNavigationLock(locked);

  useEffect(() => {
    if (!locked) {
      return;
    }

    const onBeforeUnload = (ev: BeforeUnloadEvent) => {
      ev.preventDefault();
      ev.returnValue = true;
      return "";
    };

    window.addEventListener("beforeunload", onBeforeUnload);
    return () => window.removeEventListener("beforeunload", onBeforeUnload);
  }, [locked]);
}
