/* eslint @typescript-eslint/no-unused-vars: off */
import { quizConnectQuery } from "@augmedi/proto-gen";
import { useMutation } from "@connectrpc/connect-query";
import { Alert, Button, Container, Input, Stack } from "@mantine/core";
import { useQueryClient } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { useLocation } from "wouter";

export const OldLoginPage = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const queryClient = useQueryClient();
  useEffect(() => {
    queryClient.resetQueries();
  }, []);

  const { mutate, isPending, isSuccess, error } = useMutation(
    quizConnectQuery.auth,
    {
      onSuccess: () => {
        queryClient.resetQueries();
      },
    },
  );

  const disabled = isPending || isSuccess;

  const onSubmit = (ev: React.FormEvent<HTMLFormElement>) => {
    ev.preventDefault();
    if (!disabled) {
      mutate({ username, password });
    }
  };

  const [_location, setLocation] = useLocation();

  useEffect(() => {
    if (isSuccess) {
      setLocation("/");
    }
  }, [setLocation, isSuccess]);

  return (
    <Container size="xs" mt="10vh">
      <form onSubmit={onSubmit}>
        <Stack m="md">
          <Input
            type="text"
            placeholder="Username"
            required
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            autoFocus
          />
          <Input
            type="password"
            placeholder="Password"
            required
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button type="submit" loading={isPending} disabled={isSuccess}>
            Login
          </Button>
          {!!error && (
            <Alert title="Error" color="red" mt="md">
              {error + ""}
            </Alert>
          )}
        </Stack>
      </form>
    </Container>
  );
};
