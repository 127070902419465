import { quizConnectQuery, quizPb } from "@augmedi/proto-gen";
import { useSuspenseQuery } from "@connectrpc/connect-query";
import { Container, Stack, Title } from "@mantine/core";
import { getSubscriptionNeed } from "../logic/subscribe";
import { QuizChapter } from "./QuizChapter";
import { TrialInfoBox } from "./TrialInfoBox";

interface Props {
  whoAmIResponse: quizPb.WhoAmIResponse;
}

export const QuizChapterListPage = ({ whoAmIResponse }: Props) => {
  const getFrozenCurriculumQuery = useSuspenseQuery(
    quizConnectQuery.getFrozenCurriculum,
  );
  const getQuizProgressQuery = useSuspenseQuery(
    quizConnectQuery.getQuizProgress,
  );

  const subscriptionNeed = getSubscriptionNeed(whoAmIResponse);

  return (
    <Container py="md">
      <Stack>
        <Title>Chapters</Title>
        {getFrozenCurriculumQuery.data.chapters.map((chapter) => {
          const progress =
            getQuizProgressQuery.data.progressByFrozenChapterId[chapter.id] ??
            new quizPb.GetQuizProgressResponse_ChapterProgress();
          return (
            <QuizChapter
              chapter={chapter}
              progress={progress}
              key={chapter.id}
            />
          );
        })}
        {subscriptionNeed && (
          <TrialInfoBox subscriptionNeed={subscriptionNeed} />
        )}
      </Stack>
    </Container>
  );
};
