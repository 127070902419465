/* eslint @typescript-eslint/no-unused-vars: off */
import { quizConnectQuery } from "@augmedi/proto-gen";
import {
  createConnectQueryKey,
  useMutation,
  useSuspenseQuery,
} from "@connectrpc/connect-query";
import {
  Button,
  Container,
  Group,
  Modal,
  Stack,
  Text,
  TextInput,
  Title,
} from "@mantine/core";
import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { Link, useLocation } from "wouter";

export const ApproachListPage = () => {
  const [_location, navigate] = useLocation();
  const queryClient = useQueryClient();

  const listApproachesQuery = useSuspenseQuery(quizConnectQuery.listApproaches);

  const [isModalOpen, setModalOpen] = useState(false);
  const [newApproachName, setNewApproachName] = useState("");
  const createApproachMutation = useMutation(quizConnectQuery.createApproach, {
    onSuccess: async (res) => {
      await queryClient.invalidateQueries({
        queryKey: createConnectQueryKey(quizConnectQuery.listApproaches),
      });
      setModalOpen(false);
      navigate(`/approaches/${res.id}/draft`);
    },
  });

  return (
    <>
      <Container py="md">
        <Stack>
          <Group justify="space-between">
            <Title order={1}>Approaches</Title>
            <Button onClick={() => setModalOpen(true)}>New approach</Button>
          </Group>

          {listApproachesQuery.data!.approaches.map((approach) => (
            <div key={approach.id}>
              <Text>
                <Link to={`/approaches/${approach.id}/draft`}>
                  {approach.name.trim() || "(untitled approach)"}
                </Link>
              </Text>
            </div>
          ))}
        </Stack>
      </Container>

      <Modal
        opened={isModalOpen}
        onClose={() => setModalOpen(false)}
        title="New approach"
      >
        <Stack>
          <TextInput
            label="Name"
            description="Can be changed later"
            placeholder="Name"
            value={newApproachName}
            onChange={(ev) => setNewApproachName(ev.target.value)}
          />
          <Button
            onClick={() =>
              createApproachMutation.mutate({
                name: newApproachName,
              })
            }
            disabled={createApproachMutation.isPending}
          >
            Create approach
          </Button>
        </Stack>
      </Modal>
    </>
  );
};
