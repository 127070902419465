import { Text } from "@mantine/core";
import { Link } from "wouter";

export const NotFoundPage = () => {
  return (
    <Text style={{ textAlign: "center" }} mt="10vh">
      Page not found. Go <Link to="/">home</Link>.
    </Text>
  );
};
