import { Anchor, Badge, Popover, Text } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { debounce } from "lodash-es";
import React from "react";
import type { StructureMetadataBase } from "../../../proto-gen/src/proto/atlas_pb";
import { config } from "../config";

interface Props {
  structureBaseData: StructureMetadataBase;
}

export const StructurePopover = ({ structureBaseData }: Props) => {
  const [opened, { close, open }] = useDisclosure(false);
  const debounceClose = debounce(() => {
    close();
  }, config.POPOVER_CLOSE_DELAY);
  const openPopover = () => {
    debounceClose.cancel();
    open();
  };
  const closePopover = () => {
    debounceClose();
  };

  return (
    <Popover width={300} position="top" withArrow shadow="md" opened={opened}>
      <Popover.Target>
        <span
          style={{ fontWeight: "bold" }}
          onMouseEnter={openPopover}
          onMouseLeave={closePopover}
        >
          {structureBaseData.name}
        </span>
      </Popover.Target>
      <Popover.Dropdown onMouseEnter={openPopover} onMouseLeave={closePopover}>
        <Text></Text>
        <Anchor
          fw={"bold"}
          td={"underline"}
          size="sm"
          href={`/atlas/structures/${structureBaseData.id}`}
          target="_blank"
          rel="noreferrer noopener"
        >
          {structureBaseData.name}
        </Anchor>
        <Text component="div" fw={"lighter"} size="xs" fs={"italic"}>
          {structureBaseData.alternativeNames.map((structureName, idx) => (
            <React.Fragment key={idx}>
              {structureName.name}{" "}
              {!!structureName.language && (
                <Badge variant="light" color="gray" size="xs">
                  {structureName.language}
                </Badge>
              )}
              {idx < structureBaseData.alternativeNames.length - 1 && " · "}
            </React.Fragment>
          ))}
        </Text>
        {structureBaseData.description && (
          <Text mt={10} size="sm">
            {structureBaseData.description}
          </Text>
        )}
      </Popover.Dropdown>
    </Popover>
  );
};
