import baseRgbaFromString from "color-rgba";

export function shaderRgbaFromString(color: string): number[] | [] {
  const baseRgba = baseRgbaFromString(color);
  if (!baseRgba) {
    throw new Error(`Invalid color string: ${color}`);
  }
  const [r, g, b, a] = baseRgba;
  if (
    typeof r !== "undefined" &&
    typeof g !== "undefined" &&
    typeof b !== "undefined" &&
    typeof a !== "undefined"
  ) {
    return [r / 255, g / 255, b / 255, a];
  } else {
    return [];
  }
}
