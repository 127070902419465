import { quizConnectQuery } from "@augmedi/proto-gen";
import { isTruthy } from "@augmedi/type-utils";
import { useSuspenseQuery } from "@connectrpc/connect-query";
import {
  Button,
  CopyButton,
  Grid,
  GridCol,
  Loader,
  Stack,
  Text,
} from "@mantine/core";
import { IconCopy, IconCopyCheck } from "@tabler/icons-react";
import { sortBy } from "lodash-es";
import { Suspense, useMemo, useRef, useState } from "react";
import { useParams } from "wouter";
import { AppLayout, useAppLayout } from "../../logic/app-layout";
import { FrozenModelPreview } from "../FrozenModelPreview";
import ModelViewerUi from "../ModelViewerUi";
import { OurCanvas } from "../OurCanvas";
import type { SharedModelPreviewStuffRef } from "../SharedModelPreviewStuff";

export const MeshSelectorPage = () => {
  const { modelId } = useParams<{ modelId: string }>();

  useAppLayout(AppLayout.FullscreenWithHeader);

  const getModelQuery = useSuspenseQuery(quizConnectQuery.getModel, {
    id: modelId,
  });
  const frozenModelId = getModelQuery.data?.latestFrozenModelId;
  const getFrozenModelQuery = useSuspenseQuery(
    quizConnectQuery.getFrozenModel,
    { id: frozenModelId },
  );
  const manifest = getFrozenModelQuery.data?.manifest;

  const [selectedMeshIds, setSelectedMeshIds] = useState(new Set<string>());
  const [lastMovedMeshIds, setLastMovedMeshIds] = useState<string[]>([]);
  const selectMesh = (meshIds: string[]) => {
    setSelectedMeshIds((oldSet) => {
      const newSet = new Set(oldSet);
      for (const meshId of meshIds) {
        newSet.add(meshId);
      }
      return newSet;
    });
    setLastMovedMeshIds(meshIds);
  };
  const deselectMesh = (meshIds: string[]) => {
    setSelectedMeshIds((oldSet) => {
      const newSet = new Set(oldSet);
      for (const meshId of meshIds) {
        newSet.delete(meshId);
      }
      return newSet;
    });
    setLastMovedMeshIds(meshIds);
  };

  const meshIdsFromLabelIds = useMemo(() => {
    const meshIdsByLabelId = new Map(
      manifest?.labels
        .filter((label) => label.isWholeMeshLabel)
        .map((label) => [label.id, label.meshId]),
    );
    return (labelIds: string[]) =>
      labelIds.map((labelId) => meshIdsByLabelId.get(labelId)).filter(isTruthy);
  }, [manifest]);

  const [leftMeshIds, rightMeshIds] = useMemo(() => {
    const leftMeshIds: string[] = [];
    const rightMeshIds: string[] = [];
    for (const mesh of manifest?.meshes ?? []) {
      if (selectedMeshIds.has(mesh.id)) {
        rightMeshIds.push(mesh.id);
      } else {
        leftMeshIds.push(mesh.id);
      }
    }
    return [leftMeshIds, rightMeshIds];
  }, [manifest, selectedMeshIds]);

  const meshNamesById = useMemo(
    () => new Map(manifest?.meshes.map((mesh) => [mesh.id, mesh.gltfMeshName])),
    [manifest],
  );

  const sharedStuffRef = useRef<SharedModelPreviewStuffRef>(null);

  return (
    <Grid h="100%" styles={{ inner: { height: "100%" } }} gutter={0}>
      <GridCol span={2} h="100%" p="md" style={{ overflow: "auto" }}>
        <Stack>
          <Text>
            <b>Selected meshes:</b> {selectedMeshIds.size}
          </Text>
          {!!lastMovedMeshIds.length && (
            <div>
              <Text>
                <b>Last moved:</b>
              </Text>
              <ul>
                {lastMovedMeshIds.map((meshId) => (
                  <li key={meshId}>{meshNamesById.get(meshId)}</li>
                ))}
              </ul>
            </div>
          )}
          <CopyButton
            value={JSON.stringify(
              sortBy(
                [...selectedMeshIds].map((meshId) => meshNamesById.get(meshId)),
              ),
            )}
          >
            {({ copied, copy }) => (
              <Button
                color={copied ? "teal" : "blue"}
                onClick={copy}
                leftSection={
                  copied ? <IconCopyCheck size={14} /> : <IconCopy size={14} />
                }
                fullWidth
              >
                {copied ? "Copied" : "Copy selected as JSON"}
              </Button>
            )}
          </CopyButton>
        </Stack>
      </GridCol>
      <GridCol
        span={5}
        style={{
          borderLeft: "1px solid #dee2e6",
          borderRight: "1px solid #dee2e6",
        }}
      >
        <Suspense fallback={<Loader />}>
          <ModelViewerUi
            onResetCamera={() => sharedStuffRef.current?.resetCamera()}
          >
            <OurCanvas>
              <FrozenModelPreview
                ref={sharedStuffRef}
                frozenModelId={frozenModelId}
                backgroundColor={0xffffff}
                visibleMeshIds={leftMeshIds}
                onClick={(labelIds) =>
                  selectMesh(meshIdsFromLabelIds(labelIds))
                }
                // disableCameraFit
              />
            </OurCanvas>
          </ModelViewerUi>
        </Suspense>
      </GridCol>
      <GridCol span={5}>
        <Suspense fallback={<Loader />}>
          <ModelViewerUi
            onResetCamera={() => sharedStuffRef.current?.resetCamera()}
          >
            <OurCanvas>
              <FrozenModelPreview
                ref={sharedStuffRef}
                frozenModelId={frozenModelId}
                backgroundColor={0xffffff}
                visibleMeshIds={rightMeshIds}
                onClick={(labelIds) =>
                  deselectMesh(meshIdsFromLabelIds(labelIds))
                }
                // disableCameraFit
              />
            </OurCanvas>
          </ModelViewerUi>
        </Suspense>
      </GridCol>
    </Grid>
  );
};
