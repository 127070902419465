import { Duration } from "@bufbuild/protobuf";

export function durationFromMillis(millis: number): Duration {
  const timeTakenWholeSeconds = Math.floor(millis / 1000);
  return new Duration({
    seconds: BigInt(timeTakenWholeSeconds),
    nanos: Math.floor((millis - timeTakenWholeSeconds * 1000) * 1e6),
  });
}

export function durationToMillis(duration: Duration): number {
  return Number(duration.seconds) * 1000 + duration.nanos / 1e6;
}

export function durationBetweenDates(start: Date, end: Date): Duration {
  return durationFromMillis(+end - +start);
}
