import { quizConnectQuery } from "@augmedi/proto-gen";
import { useSuspenseQuery } from "@connectrpc/connect-query";
import { AreaChart } from "@mantine/charts";
import { Button, Group, Menu, Paper, Stack, Text } from "@mantine/core";
import { IconChevronDown } from "@tabler/icons-react";
import { last, sortBy } from "lodash-es";
import { useState } from "react";

export const AccuracyChartPanel = () => {
  const res = useSuspenseQuery(quizConnectQuery.getQuizDailyEffortStats, {
    utcOffset: Math.round(new Date().getTimezoneOffset() / 60),
  });

  const chartValues = res.data.dailyStatsByDate.map((stat) => ({
    year: new Date(stat.date).getFullYear(),
    date: new Date(stat.date).toLocaleDateString(),
    accuracy: Math.round(
      (stat.correctAnswers / (stat.correctAnswers + stat.wrongAnswers)) * 100,
    ),
  }));
  const years = sortBy([...new Set(chartValues.map((item) => item.year))]);

  const [selectedYear, setSelectedYear] = useState(last(years));

  return (
    <Paper withBorder p="md" radius="md">
      <Stack>
        <Group justify="space-between">
          <Text fz="xl" fw={700}>
            Accuracy
          </Text>
          <Menu width="target">
            <Menu.Target>
              <Button variant="default">
                <Group gap="xs">
                  <span>{selectedYear}</span>
                </Group>
                <IconChevronDown size="1rem" stroke={1.5} />
              </Button>
            </Menu.Target>
            <Menu.Dropdown>
              {years.map((year) => (
                <Menu.Item onClick={() => setSelectedYear(year)} key={year}>
                  {year}
                </Menu.Item>
              ))}
            </Menu.Dropdown>
          </Menu>
        </Group>
        <AreaChart
          h={300}
          series={[{ name: "accuracy", color: "blue", label: "Accuracy" }]}
          data={chartValues.filter((item) => item.year === selectedYear)}
          dataKey={"date"}
          curveType="linear"
          unit="%"
        />
      </Stack>
    </Paper>
  );
};
