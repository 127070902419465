/* eslint react/display-name: "off" */
import type { quizPb } from "@augmedi/proto-gen";
import type { PlainMessage } from "@bufbuild/protobuf";
import { Button, Flex, Group, Stack, Title } from "@mantine/core";
import { IconPlus } from "@tabler/icons-react";
import { memo, useState } from "react";
import { GenericLabelList } from "./GenericLabelList";

interface Props {
  childLabelList: PlainMessage<quizPb.ModelLabel>[];
  selectedChildLabelId?: string;
  onChildLabelSelect: (label: PlainMessage<quizPb.ModelLabel>) => void;
  otherVisibleLabelIds: Set<string>;
  toggleLabelVisible: (labelId: string) => void;
  onAddChildLabel: () => void;
  useEyes: boolean;
  useXes?: boolean;
  xFn?: (labelId: string) => void;
  title?: string;
}

export const ChildLabelList = memo(
  ({
    childLabelList,
    selectedChildLabelId,
    onChildLabelSelect,
    otherVisibleLabelIds,
    toggleLabelVisible,
    onAddChildLabel,
    useEyes,
    useXes,
    xFn,
    title = "Substructure Labels",
  }: Props) => {
    const [hoveredLabelId, setHoveredLabelId] = useState<string | null>(null);
    const [hoveredEyeLabelId, setHoveredEyeLabelId] = useState<string | null>(
      null,
    );

    const isSelected = (label: PlainMessage<quizPb.ModelLabel>) => {
      return !!selectedChildLabelId && selectedChildLabelId === label.id;
    };

    return (
      <>
        <Flex direction="column" h="100%" gap="0.2rem">
          <Group>
            <Title order={5}>{title}</Title>
            <Button
              size="xs"
              variant="outline"
              style={{ marginLeft: "auto" }}
              onClick={onAddChildLabel}
            >
              <IconPlus size={14} />
            </Button>
          </Group>
          <Stack
            gap={0}
            w={250}
            style={{
              overflowY: "scroll",
              overflowX: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            <GenericLabelList
              labelList={childLabelList}
              hoveredLabelId={hoveredLabelId}
              hoveredEyeLabelId={hoveredEyeLabelId}
              selectedLabelId={selectedChildLabelId}
              otherVisibleLabelIds={otherVisibleLabelIds}
              toggleLabelVisible={toggleLabelVisible}
              isSelected={isSelected}
              onLabelSelect={onChildLabelSelect}
              setHoveredLabelId={setHoveredLabelId}
              setHoveredEyeLabelId={setHoveredEyeLabelId}
              useEyes={useEyes}
              useXes={useXes}
              xFn={xFn}
            />
          </Stack>
        </Flex>
      </>
    );
  },
);
