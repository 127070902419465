import { quizConnectQuery } from "@augmedi/proto-gen";
import { useSuspenseQuery } from "@connectrpc/connect-query";
import { Alert, Code, Container, Stack, Text, Title } from "@mantine/core";
import { useParams } from "wouter";

export const ApproachFrozenPage = () => {
  const { approachId } = useParams<{ approachId: string }>();

  const getApproachQuery = useSuspenseQuery(quizConnectQuery.getApproach, {
    id: approachId,
  });
  if (!getApproachQuery.data?.latestFrozenApproachId) {
    throw new Error("Approach has no frozen version");
  }
  const getFrozenApproachQuery = useSuspenseQuery(
    quizConnectQuery.getFrozenApproach,
    {
      id: getApproachQuery.data.latestFrozenApproachId,
    },
  );
  const frozenApproach = getFrozenApproachQuery.data!;
  if (!frozenApproach.manifest) {
    throw new Error("Frozen approach has no manifest");
  }

  return (
    <Container py="md">
      <Stack>
        <Title
          order={1}
          fs={frozenApproach.manifest.name.trim() ? undefined : "italic"}
          c={frozenApproach.manifest.name.trim() ? undefined : "dimmed"}
        >
          {frozenApproach.manifest.name.trim() || "(untitled approach)"}
        </Title>
        {frozenApproach.createdAt && (
          <Text>
            <Text span fw={700}>
              Created at:{" "}
            </Text>
            {frozenApproach.createdAt.toDate().toLocaleString()}
          </Text>
        )}
        <Alert color="orange">
          Frozen versions of approaches can not be previewed in the UI yet.
        </Alert>
        <Code block>{JSON.stringify(frozenApproach.manifest, null, 2)}</Code>
      </Stack>
    </Container>
  );
};
