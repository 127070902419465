import { quizConnectQuery, quizPb } from "@augmedi/proto-gen";
import { useQuery } from "@connectrpc/connect-query";
import { config } from "../config";
import { retryExceptConnectNotFound } from "../query";
import { OtherMeshInner } from "./OtherMeshInner";

interface Props {
  modelId: string;
  partitionId: string;
  meshName: string;
}

export const OtherMesh = ({ modelId, partitionId, meshName }: Props) => {
  const getModelAssetQuery = useQuery(
    quizConnectQuery.getModelAsset,
    {
      id: modelId,
      type: quizPb.ModelAssetType.GLTF,
      partitionId: partitionId,
    },
    {
      retry: retryExceptConnectNotFound(),
      staleTime: config.ASSET_URL_LIFETIME_MILLIS / 4,
      enabled: true,
    },
  );

  return (
    getModelAssetQuery.data?.downloadUrl && (
      <OtherMeshInner
        partitionUrl={getModelAssetQuery.data.downloadUrl}
        meshName={meshName}
      />
    )
  );
};
