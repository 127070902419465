import { quizConnectQuery } from "@augmedi/proto-gen";
import { useQuery } from "@connectrpc/connect-query";
import {
  Alert,
  AspectRatio,
  Button,
  Container,
  Paper,
  Stack,
  Text,
} from "@mantine/core";
import { useEffect, useState } from "react";

interface LoadedQrCode {
  imageUrl: string;
  expiresAt: Date;
}

export const AuthQrPage = () => {
  const qrQuery = useQuery(quizConnectQuery.getAuthQrCode);

  const [loadedQrCode, setLoadedQrCode] = useState<LoadedQrCode>();
  useEffect(() => {
    if (!qrQuery.data?.qrCodePng) {
      return;
    }
    const imageUrl = URL.createObjectURL(
      new Blob([qrQuery.data.qrCodePng], { type: "image/png" }),
    );
    setLoadedQrCode({
      imageUrl,
      expiresAt: new Date(
        Date.now() + 1000 * Number(qrQuery.data.expiresIn?.seconds ?? 0),
      ),
    });
    const refreshTimeoutHandle = setTimeout(
      () => qrQuery.refetch(),
      1000 * Math.max(1, Number(qrQuery.data.refreshIn?.seconds ?? 0)),
    );
    return () => {
      URL.revokeObjectURL(imageUrl);
      clearTimeout(refreshTimeoutHandle);
    };
  }, [qrQuery.data]);
  useEffect(() => {
    const handleExpired = () => {
      setLoadedQrCode(undefined);
      qrQuery.refetch();
    };

    if (loadedQrCode?.expiresAt) {
      const expiresInMs = loadedQrCode.expiresAt.getTime() - Date.now();
      if (expiresInMs > 0) {
        const timeoutHandle = setTimeout(() => {
          setLoadedQrCode(undefined);
          qrQuery.refetch();
        }, loadedQrCode.expiresAt.getTime() - Date.now());
        return () => clearTimeout(timeoutHandle);
      } else {
        handleExpired();
      }
    }
  }, [loadedQrCode]);

  return (
    <Container size="xs" mt="md">
      <Stack justify="center">
        <AspectRatio>
          {loadedQrCode && (
            <Paper shadow="xs" radius="xs">
              <img
                src={loadedQrCode.imageUrl}
                alt="QR code for logging in on headset"
                style={{
                  width: "100%",
                  height: "100%",
                  imageRendering: "pixelated",
                }}
              />
            </Paper>
          )}
          {!loadedQrCode && !qrQuery.isError && (
            <Paper shadow="xs" radius="xs">
              <Text fs="italic" c="dimmed">
                Loading login QR code...
              </Text>
            </Paper>
          )}
          {!loadedQrCode && qrQuery.isError && (
            <Alert color="red">
              <Stack>
                <Text>{"Couldn't load login QR code"}</Text>
                <Button
                  onClick={() => qrQuery.refetch()}
                  variant="white"
                  color="gray"
                >
                  Retry
                </Button>
              </Stack>
            </Alert>
          )}
        </AspectRatio>
        <Text ta="center">
          {
            "You can use this QR code to log in on your headset without using a password. Open the Augmedi app and look at this code through the headset to log in."
          }
        </Text>
      </Stack>
    </Container>
  );
};
