/* eslint react/display-name: off, react/prop-types: off, react/no-unknown-property: off */
import { useMemo } from "react";
import { useOurGltf } from "../logic/gltf";
import { useMemoDisposable } from "../logic/memo-cleanup";

interface Props {
  partitionUrl: string;
  meshName: string;
}

export const OtherMeshInner = ({ partitionUrl, meshName }: Props) => {
  const { meshes, material } = useOurGltf(partitionUrl);
  const meshData = useMemo(
    () => meshes.find((mesh) => mesh.name === meshName),
    [meshes, meshName],
  );
  const otherMaterial = useMemoDisposable(() => material.clone(), [material]);
  otherMaterial.transparent = true;
  otherMaterial.opacity = 0.3;

  return (
    meshData && (
      <mesh
        key={meshData.id}
        geometry={meshData.geometry}
        material={otherMaterial}
        scale={meshData.scale}
        rotation={meshData.rotation}
      />
    )
  );
};
