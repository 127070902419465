/* eslint @typescript-eslint/no-unused-expressions: off */
// Based on this StackOverflow question and answer:
// https://stackoverflow.com/questions/66446642/react-usememo-memory-clean

import { useMemo, useRef, type DependencyList } from "react";

const registry = new FinalizationRegistry<{ current?: () => void }>(
  (cleanupRef) => {
    cleanupRef.current && cleanupRef.current();
  },
);

// A version of useMemo that allows cleanup.
// Return a tuple from the callback: [returnValue, cleanupFunction]
export function useMemoCleanup<T>(
  callback: () => [returnValue: T, cleanupFunction: () => void],
  deps: DependencyList,
): T {
  const cleanupRef = useRef<() => void>(); // holds a cleanup value
  const unmountRef = useRef(false); // the GC-triggering candidate

  if (!unmountRef.current) {
    unmountRef.current = true;
    // this works since refs are preserved for the component's lifetime
    registry.register(unmountRef, cleanupRef);
  }

  const returned = useMemo(() => {
    cleanupRef.current && cleanupRef.current();
    cleanupRef.current = undefined;

    const [returned, cleanup] = callback();
    cleanupRef.current = cleanup;

    return returned;
  }, deps);

  return returned;
}

export function useMemoDisposable<T extends { dispose(): void }>(
  callback: () => T,
  deps: DependencyList,
): T {
  return useMemoCleanup(() => {
    const value = callback();
    return [value, () => value.dispose()];
  }, deps);
}
