import { quizConnectQuery } from "@augmedi/proto-gen";
import { useAuth0 } from "@auth0/auth0-react";
import { useMutation } from "@connectrpc/connect-query";
import { Menu, rem } from "@mantine/core";
import { IconLogout } from "@tabler/icons-react";
import { Link } from "wouter";
import * as classes from "./AppNavLinks.module.css";

interface Props {
  textButton?: boolean;
}

export const LogoutButton = ({ textButton = false }: Props) => {
  const { logout } = useAuth0();

  const logoutMutation = useMutation(quizConnectQuery.logout, {
    onSuccess: () => {
      logout({ logoutParams: { returnTo: window.location.origin } });
    },
  });
  const labelText = "Logout";
  return textButton ? (
    <Link
      className={classes.menuButton}
      onClick={() => logoutMutation.mutate({})}
      href=""
      style={{ display: "flex", gap: rem(8) }}
    >
      <IconLogout size={20} />
      <span>{labelText}</span>
    </Link>
  ) : (
    <Menu.Item
      leftSection={
        <IconLogout style={{ width: rem(16), height: rem(16) }} stroke={1.5} />
      }
      onClick={() => logoutMutation.mutate({})}
    >
      Logout
    </Menu.Item>
  );
};
