import "@mantine/charts/styles.css";
import { Container, Stack, Title } from "@mantine/core";
import { AccuracyChartPanel } from "./AccuracyChartPanel";
import { HeatMapPanel } from "./HeatMapPanel";
import { ProgressPanel } from "./ProgressPanel";
import { RecentAnswersPanel } from "./RecentAnswersPanel";

export const StatsPage = () => {
  return (
    <Container py="md">
      <Stack gap="md">
        <Title order={1}>Progress</Title>
        <RecentAnswersPanel />
        <HeatMapPanel />
        <AccuracyChartPanel />
        <ProgressPanel />
      </Stack>
    </Container>
  );
};
