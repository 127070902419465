/* eslint @typescript-eslint/no-unused-expressions: off */
import type { quizPb } from "@augmedi/proto-gen";
import { durationBetweenDates } from "@augmedi/proto-gen";
import { isTruthy } from "@augmedi/type-utils";
import { type PlainMessage } from "@bufbuild/protobuf";
import { Suspense, useEffect, useMemo, useRef, useState } from "react";
import * as THREE from "three";
import type { UseQuizConsumeArgs } from "../logic/quiz";
import { AnswerFeedbackTryAgain } from "./AnswerFeedbackTryAgain";
import { AnswerFeedbackWrong } from "./AnswerFeedbackWrong";
import { QuestionAsker, type QuestionAskerAnswer } from "./QuestionAsker";
import type { SharedModelPreviewStuffRef } from "./SharedModelPreviewStuff";

interface Props {
  selectedQuestion: PlainMessage<quizPb.ConcreteQuestion>;
  onAnswerQuestion: (args: UseQuizConsumeArgs) => void;
  onSwitchToNextQuestion: () => void;
  maxAttempts: number;
}

export const StatefulQuestionAsker = ({
  selectedQuestion,
  onAnswerQuestion,
  onSwitchToNextQuestion,
  maxAttempts,
}: Props) => {
  // TODO answerStartTime should only start running once the model is loaded. Not sure if this is the case now.
  const [answerStartTime, setAnswerStartTime] = useState(new Date());
  const [lastAnswer, setLastAnswer] = useState<QuestionAskerAnswer>();
  const [showCorrectAnswer, setShowCorrectAnswer] = useState(false);
  const [answerClickCount, setAnswerClickCount] = useState(0);
  const sharedStuffRef = useRef<SharedModelPreviewStuffRef>(null);
  const [disableAnswering, setDisableAnswering] = useState(false);

  useEffect(() => {
    setLastAnswer(undefined);
    setShowCorrectAnswer(false);
    setAnswerStartTime(new Date());
    setAnswerClickCount(0);
    setDisableAnswering(false);
  }, [selectedQuestion]);

  const hasAttemped = answerClickCount > 0;
  const clickedStructureIds = lastAnswer?.labelsForFeedback
    ?.filter((label) => isTruthy(label.structureId))
    .map((label) => label.structureId)
    .filter((v1, index, self) => index === self.findIndex((v2) => v1 === v2));
  const correctMultiChoiceText =
    selectedQuestion.answerRequest?.content?.case === "multipleChoice"
      ? selectedQuestion.answerRequest.content.value.options
          .filter((option) => option.correct)
          .map((option) => option.text)
      : undefined;

  const answerFeedbackPanel =
    !lastAnswer?.correct && answerClickCount === maxAttempts ? (
      <AnswerFeedbackWrong
        feedbackStructureNames={correctMultiChoiceText}
        onNextQuestion={onSwitchToNextQuestion}
      />
    ) : hasAttemped ? (
      <AnswerFeedbackTryAgain
        feedbackStructureIds={clickedStructureIds}
        onTryAgain={() => setDisableAnswering(false)}
      />
    ) : undefined;

  const audioCorrect = useMemo(async () => {
    const correctSoundUrl = new URL(
      "../sounds/RightAnswer.mp3",
      import.meta.url,
    );
    let audioBlob = "";
    await fetch(correctSoundUrl)
      .then((response) => response.blob())
      .then((blob) => {
        audioBlob = URL.createObjectURL(blob);
        new Audio(audioBlob);
      });
    return audioBlob;
  }, []);

  const handleAudioCorrect = async () => {
    const audio = new Audio(await audioCorrect);
    audio.play();
  };

  const audioWrong = useMemo(async () => {
    const wrongSoundUrl = new URL("../sounds/WrongAnswer.mp3", import.meta.url);
    let audioBlob = "";
    await fetch(wrongSoundUrl)
      .then((response) => response.blob())
      .then((blob) => {
        audioBlob = URL.createObjectURL(blob);
        new Audio(audioBlob);
      });
    return audioBlob;
  }, []);

  const handleAudioWrong = async () => {
    const audio = new Audio(await audioWrong);
    audio.play();
  };

  return (
    <>
      <Suspense fallback={<div>Loading question...</div>}>
        <QuestionAsker
          sharedStuffRef={sharedStuffRef}
          question={selectedQuestion}
          onAnswered={async (answer) => {
            // Ignore if the answer is the same as the last one
            if (
              JSON.stringify(answer.selectedIds) ===
              JSON.stringify(lastAnswer?.selectedIds)
            ) {
              return;
            }

            if (answer.correct && (lastAnswer?.correct || showCorrectAnswer)) {
              onSwitchToNextQuestion();
            } else {
              const timeTaken = durationBetweenDates(
                answerStartTime,
                new Date(),
              );
              onAnswerQuestion({
                answerIds: answer.selectedIds,
                timeTaken,
              });
              setLastAnswer(answer);
              setAnswerStartTime(new Date());

              const isLastAnswer =
                answerClickCount === maxAttempts - 1 || answer.correct;
              if (answer.correct) {
                handleAudioCorrect();
                await sharedStuffRef.current?.playAnswerVisualFeedback(
                  new THREE.Color(0xccffcc), // green,
                );
                onSwitchToNextQuestion();
              } else {
                handleAudioWrong();
                setDisableAnswering(true);
                setAnswerClickCount((count) => count + 1);
                if (isLastAnswer) {
                  setShowCorrectAnswer(true);
                  await sharedStuffRef.current?.playAnswerVisualFeedback(
                    new THREE.Color(0xffcccc), // red,
                  );
                } else {
                  await sharedStuffRef.current?.playAnswerVisualFeedback(
                    new THREE.Color(0xffffcc), // yellow,
                  );
                }
              }
            }
          }}
          disableAnswering={
            disableAnswering || !!lastAnswer?.correct || showCorrectAnswer
          }
          onEditingAnswer={() => setLastAnswer(undefined)}
          showFeedbackForAnswer={lastAnswer}
          showCorrectAnswer={showCorrectAnswer}
          answerFeedbackPanel={answerFeedbackPanel}
        />
      </Suspense>
    </>
  );
};
