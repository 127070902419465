import { Group, Menu, UnstyledButton } from "@mantine/core";
import { IconChevronDown } from "@tabler/icons-react";
import { AvatarAndUsername } from "./AvatarAndUsername";
import { LogoutButton } from "./LogoutButton";

interface Props {
  username: string | undefined;
  showTextForSm?: boolean;
}

export const HeaderUsername = ({ username, showTextForSm }: Props) => {
  return (
    <div>
      {username && (
        <Menu
          shadow="md"
          width={150}
          position="bottom-end"
          offset={5}
          transitionProps={{ transition: "pop-top-right" }}
        >
          <Menu.Target>
            <UnstyledButton>
              <Group gap="xs">
                <AvatarAndUsername
                  username={username}
                  showTextForSm={showTextForSm}
                />
                <IconChevronDown size="1rem" />
              </Group>
            </UnstyledButton>
          </Menu.Target>
          <Menu.Dropdown>
            <Menu.Label>Settings</Menu.Label>
            <LogoutButton />
          </Menu.Dropdown>
        </Menu>
      )}
    </div>
  );
};
