import { quizConnectQuery, quizPb } from "@augmedi/proto-gen";
import { assertDefined } from "@augmedi/type-utils";
import { useSuspenseQuery } from "@connectrpc/connect-query";
import { Container, Stack, Text, Title } from "@mantine/core";
import { Link } from "wouter";
import { AppLayout, useAppLayout } from "../logic/app-layout";
import { useQuiz } from "../logic/quiz";
import {
  getUrlForNewQuizSession,
  useQuizSearchParams,
} from "../logic/quiz-url";
import { getSubscriptionNeed } from "../logic/subscribe";
import { NotFoundPage } from "./NotFoundPage";
import { StatefulIntroductionGroupViewer } from "./StatefulIntroductionGroupViewer";
import { StatefulQuestionAsker } from "./StatefulQuestionAsker";
import { TrialInfoBox } from "./TrialInfoBox";
import { bodyPartToString } from "./utils";

export const QuizRunPage = () => {
  const quizOptions = useQuizSearchParams();
  const quiz = useQuiz(quizOptions);
  const quizItemContent = quiz.item?.content;

  useAppLayout(AppLayout.FullscreenWithHeader);

  const whoAmIQuery = useSuspenseQuery(quizConnectQuery.whoAmI);
  const subscriptionNeed = getSubscriptionNeed(whoAmIQuery.data);

  const getFrozenCurriculumQuery = useSuspenseQuery(
    quizConnectQuery.getFrozenCurriculum,
  );
  const chapter = getFrozenCurriculumQuery.data.chapters.find(
    (c) => c.id === quizOptions.frozenChapterId,
  );

  let chapterName = "";

  if (chapter === undefined && quizOptions.bodyPart !== undefined) {
    chapterName = bodyPartToString[quizOptions.bodyPart];
  } else if (chapter !== undefined) {
    chapterName = chapter.name;
  }

  if (chapter === undefined && quizOptions.bodyPart === undefined) {
    return <NotFoundPage />;
  }

  return (
    <>
      {quizItemContent?.case === "introductionGroup" && (
        <StatefulIntroductionGroupViewer
          introductionGroup={assertDefined(
            quizItemContent.value.introductionGroup,
          )}
          onConsumeAndSwitchToNextQuestion={(timeTaken) => {
            quiz.consume({ answerIds: [], timeTaken });
            quiz.next();
          }}
        />
      )}
      {quizItemContent?.case === "question" && (
        <StatefulQuestionAsker
          selectedQuestion={assertDefined(quizItemContent.value.question)}
          onAnswerQuestion={(args) => quiz.consume(args)}
          onSwitchToNextQuestion={() => quiz.next()}
          maxAttempts={quizItemContent.value.retryPlan?.maxAttempts || 2}
        />
      )}
      {quizItemContent?.case === undefined && (
        <Container>
          <Stack mt="md">
            <Title>
              {chapterName} ·{" "}
              {quizOptions.mode === quizPb.QuizMode.LEARN_NEW
                ? "New questions"
                : "Review"}
            </Title>
            {quizOptions.mode === quizPb.QuizMode.LEARN_NEW ? (
              <>
                <Text>
                  No new questions left. You can{" "}
                  <Link
                    to={getUrlForNewQuizSession({
                      frozenChapterId: quizOptions.frozenChapterId,
                      bodyPart: quizOptions.bodyPart,
                      mode: quizPb.QuizMode.REPEAT_OLD,
                    })}
                  >
                    switch to the review mode
                  </Link>{" "}
                  to review questions that you have already answered, or{" "}
                  <Link to="/quiz/chapters">go to another chapter</Link>.
                </Text>
                {subscriptionNeed && (
                  <TrialInfoBox subscriptionNeed={subscriptionNeed} />
                )}
              </>
            ) : (
              <Text>
                There are no questions that need review at the moment.
              </Text>
            )}
          </Stack>
        </Container>
      )}
    </>
  );
};
