import { Alert, Button } from "@mantine/core";
import { IconInfoCircle } from "@tabler/icons-react";
import type { SubscriptionNeed } from "../logic/subscribe";

interface Props {
  subscriptionNeed: SubscriptionNeed;
}

export const TrialInfoBox = ({ subscriptionNeed }: Props) => {
  return (
    <Alert
      variant="light"
      color="blue"
      title="Unlock more questions"
      icon={<IconInfoCircle />}
    >
      <p style={{ marginTop: 0 }}>
        {
          "You're currently using the trial version of TeachAnatomy. The trial gives you access to all questions about the knee."
        }
      </p>
      <p>
        {
          "To unlock access to all parts of the body, please subscribe for the full version of TeachAnatomy."
        }
      </p>
      <Button component="a" href={subscriptionNeed.stripeUrl} fullWidth>
        Subscribe now
      </Button>
    </Alert>
  );
};
