import { quizPb } from "@augmedi/proto-gen";
import { isTruthy, unreachable } from "@augmedi/type-utils";
import type { PlainMessage } from "@bufbuild/protobuf";
import { Button, Group, Stack } from "@mantine/core";
import { IconPencilQuestion } from "@tabler/icons-react";
import { uniqBy } from "lodash-es";
import { useEffect, useState } from "react";
import { colorConstants } from "../color-constants";
import {
  splitHighlightsIntoChannels,
  type HighlightedId,
} from "../logic/highlights";
import { FrozenModelPreview } from "./FrozenModelPreview";
import { LogoImage } from "./LogoImage";
import ModelViewerUi from "./ModelViewerUi";
import { OurCanvas } from "./OurCanvas";
import { QuizLayout } from "./QuizLayout";
import { type SharedModelPreviewStuffRef } from "./SharedModelPreviewStuff";

interface Props {
  question: PlainMessage<quizPb.ConcreteQuestion>;
  onAnswered?: (answer: QuestionAskerAnswer) => void;
  onEditingAnswer?: () => void;
  disableAnswering?: boolean;
  showFeedbackForAnswer?: QuestionAskerAnswer;
  showCorrectAnswer?: boolean;
  answerFeedbackPanel?: JSX.Element;
  sharedStuffRef?: React.RefObject<SharedModelPreviewStuffRef>;
}

export interface QuestionAskerAnswer {
  correct: boolean;
  selectedIds: string[];
  labelsForFeedback: PlainMessage<quizPb.FrozenModel_Label>[];
}

function highlightedIdsFromQuestion(
  question: PlainMessage<quizPb.ConcreteQuestion>,
  showFeedbackForIds: string[],
  showCorrectAnswer: boolean,
): HighlightedId[] {
  const answerRequestContent = question.answerRequest?.content;
  switch (answerRequestContent?.case) {
    case "pickLocation": {
      const highlightedIds: HighlightedId[] = [];
      if (showCorrectAnswer) {
        for (const id of answerRequestContent.value.desiredLabelIds) {
          highlightedIds.push({
            id,
            color: colorConstants.systemShownRightColor,
            pulse: true,
          });
        }
      }
      for (const id of showFeedbackForIds) {
        highlightedIds.push({
          id,
          color: answerRequestContent.value.desiredLabelIds.includes(id)
            ? colorConstants.selectedRightColor
            : colorConstants.selectedWrongColor,
          pulse: false,
        });
      }
      return highlightedIds;
    }
    case "multipleChoice": {
      return [];
    }
    case undefined: {
      throw new Error("Unsupported answerRequest type");
    }
    default: {
      unreachable(answerRequestContent);
    }
  }
}

export const QuestionAsker = ({
  question,
  onAnswered,
  onEditingAnswer,
  disableAnswering,
  showFeedbackForAnswer,
  showCorrectAnswer = false,
  answerFeedbackPanel,
  sharedStuffRef,
}: Props) => {
  const highlightedIdsWithDuplicates: HighlightedId[] = [
    ...question.highlightedLabelIds.map((id) => ({
      id,
      color: colorConstants.ambientHighlightColor,
      pulse: true,
    })),
    ...highlightedIdsFromQuestion(
      question,
      showFeedbackForAnswer?.labelsForFeedback?.map((label) => label.id) ?? [],
      showCorrectAnswer,
    ),
  ];
  const highlightedIds = uniqBy(
    highlightedIdsWithDuplicates.slice().reverse(),
    ({ id }) => id,
  ).reverse();

  const { visibleLabelIds, settingsByChannel } =
    splitHighlightsIntoChannels(highlightedIds);

  const [selectedMultipleChoiceIds, setSelectedMultipleChoiceIds] = useState(
    new Set<string>(),
  );
  useEffect(() => {
    setSelectedMultipleChoiceIds(new Set());
  }, [question]);
  const expectedMultipleChoiceIds =
    question.answerRequest?.content?.case === "multipleChoice"
      ? question.answerRequest.content.value.options
          .filter((option) => option.correct)
          .map((option) => option.id)
      : [];
  const instantSubmitMultipleChoice = expectedMultipleChoiceIds.length === 1;

  // convert initailCameraPosition to array
  const initialCameraPosition = question.initialCameraPosition && [
    question.initialCameraPosition.x,
    question.initialCameraPosition.y,
    question.initialCameraPosition.z,
  ];

  const canvasContent = !!question.frozenModelId && (
    <ModelViewerUi onResetCamera={() => sharedStuffRef?.current?.resetCamera()}>
      <OurCanvas>
        <FrozenModelPreview
          ref={sharedStuffRef}
          frozenModelId={question.frozenModelId}
          backgroundColor={0xffffff}
          visibleLabelIdsPerChannel={visibleLabelIds}
          visibleMeshIds={question.visibleMeshIds}
          initialCameraPosition={initialCameraPosition}
          settingsByChannel={settingsByChannel}
          desiredLabelIdsPerChannel={
            question.answerRequest?.content?.case === "pickLocation"
              ? {
                  CombinedR:
                    question.answerRequest.content.value.desiredLabelIds,
                }
              : undefined
          }
          onClick={(selectedLabelIds, labelsById) => {
            if (
              question.answerRequest?.content?.case !== "pickLocation" ||
              !selectedLabelIds.length
            ) {
              return;
            }

            const desiredLabelIds =
              question.answerRequest.content.value.desiredLabelIds;
            const selectedAndCorrectLabelIds = selectedLabelIds.filter((id) =>
              desiredLabelIds.includes(id),
            );
            const correct = !!selectedAndCorrectLabelIds.length;
            if (disableAnswering && (!showCorrectAnswer || !correct)) {
              return;
            }

            const isDefinitelyNotWholeMesh = (labelId: string) => {
              const label = labelsById.get(labelId);
              return !!label && !label.isWholeMeshLabel;
            };
            const desiredIsNotWholeMesh = desiredLabelIds.every(
              isDefinitelyNotWholeMesh,
            );
            const selectedNotWholeMeshLabelIds = selectedLabelIds.filter(
              isDefinitelyNotWholeMesh,
            );
            const labelsIdForFeedback = correct
              ? selectedAndCorrectLabelIds
              : desiredIsNotWholeMesh && selectedNotWholeMeshLabelIds.length
                ? selectedNotWholeMeshLabelIds
                : selectedLabelIds;
            const labelsForFeedback = labelsIdForFeedback
              .map((id) => labelsById.get(id))
              .filter(isTruthy);

            onAnswered?.({
              correct,
              selectedIds: selectedLabelIds,
              labelsForFeedback,
            });
          }}
        />
      </OurCanvas>
    </ModelViewerUi>
  );
  const multiChoiceAnswersContent = question.answerRequest?.content?.case ===
    "multipleChoice" && (
    <Stack p="sm">
      {question.answerRequest.content.value.options.map((option) => {
        let state: "right" | "wrong" | "selected" | "unselected" = "unselected";
        if (
          showFeedbackForAnswer?.labelsForFeedback?.some(
            (label) => label.id === option.id,
          )
        ) {
          state = option.correct ? "right" : "wrong";
        } else if (
          !showFeedbackForAnswer &&
          !showCorrectAnswer &&
          selectedMultipleChoiceIds.has(option.id)
        ) {
          state = "selected";
        }
        return (
          <Button
            key={option.id}
            onClick={() => {
              if (instantSubmitMultipleChoice) {
                if (disableAnswering) {
                  return;
                }
                // HACK Still "submit an answer" here, because this is
                // used for quick jumping to the next question by double
                // clicking the right answer.
                onAnswered?.({
                  correct: option.correct,
                  selectedIds: [option.id],
                  labelsForFeedback: [
                    new quizPb.FrozenModel_Label({ id: option.id }),
                  ],
                });
              } else if (!disableAnswering) {
                setSelectedMultipleChoiceIds((prev) => {
                  const newSet = new Set(prev);
                  if (newSet.has(option.id)) {
                    newSet.delete(option.id);
                  } else {
                    newSet.add(option.id);
                  }
                  return newSet;
                });
                onEditingAnswer?.();
              }
            }}
            variant={state === "unselected" ? "outline" : "filled"}
            color={
              {
                right: colorConstants.selectedRightColorMantine,
                wrong: colorConstants.selectedWrongColorMantine,
                selected: "blue",
                unselected: undefined,
              }[state]
            }
            // Style for label text wrap-around
            style={{
              height: "auto",
              padding: "10px",
            }}
            styles={{
              label: {
                whiteSpace: "normal",
              },
            }}
          >
            {option.text}
          </Button>
        );
      })}
      {!instantSubmitMultipleChoice && (
        <Group justify="center">
          <Button
            onClick={() =>
              onAnswered?.({
                correct:
                  selectedMultipleChoiceIds.size ===
                    expectedMultipleChoiceIds.length &&
                  [...selectedMultipleChoiceIds].every((id) =>
                    expectedMultipleChoiceIds.includes(id),
                  ),
                selectedIds: [...selectedMultipleChoiceIds],
                labelsForFeedback: [...selectedMultipleChoiceIds].map(
                  (id) => new quizPb.FrozenModel_Label({ id }),
                ),
              })
            }
            leftSection={<IconPencilQuestion size={14} />}
            disabled={
              selectedMultipleChoiceIds.size !==
                expectedMultipleChoiceIds.length ||
              disableAnswering ||
              !!showFeedbackForAnswer ||
              showCorrectAnswer
            }
          >
            {selectedMultipleChoiceIds.size ===
              expectedMultipleChoiceIds.length || disableAnswering
              ? "Check answer"
              : `${expectedMultipleChoiceIds.length} answers expected`}
          </Button>
        </Group>
      )}
    </Stack>
  );
  return (
    <QuizLayout
      title={question.text}
      questionId={question.id}
      canvasContent={canvasContent || undefined}
      multiChoiceAnswersContent={multiChoiceAnswersContent || undefined}
      sidebarExtrasAnySize={answerFeedbackPanel}
      canvasExtrasSmall={
        <>
          <Group
            style={{
              position: "absolute",
              left: "1rem",
              bottom: "1rem",
              opacity: 0.5,
            }}
          >
            <LogoImage />
          </Group>
        </>
      }
    />
  );
};
