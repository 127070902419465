import { allRoles, quizConnectQuery } from "@augmedi/proto-gen";
import { oneOrUndefined } from "@augmedi/type-utils";
import {
  createConnectQueryKey,
  useMutation,
  useQuery,
} from "@connectrpc/connect-query";
import {
  Anchor,
  Button,
  Checkbox,
  Container,
  Group,
  Stack,
  Table,
  Text,
  Title,
} from "@mantine/core";
import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useParams } from "wouter";
import { config } from "../config";
import { formatDuration, formatTimestamp } from "./utils";

export const UserPage = () => {
  const { userId } = useParams<{ userId: string }>();
  const [checkedRoles, setCheckedRoles] = useState<string[]>([]);
  const [unsavedChanges, setUnsavedChanges] = useState(false);

  const users = useQuery(quizConnectQuery.listUsers);
  const queryClient = useQueryClient();
  // Mutation for resetting user progress
  const resetProgressMutation = useMutation(
    quizConnectQuery.resetUserProgress,
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: createConnectQueryKey(quizConnectQuery.listUsers),
        });
        alert("User progress has been reset.");
      },
      onError: () => {
        alert("Failed to reset user progress.");
      },
    },
  );
  const demoPasswordQuery = useQuery(quizConnectQuery.demoPassword);

  useEffect(() => {
    if (users.data) {
      const user = oneOrUndefined(
        users.data.users.filter((user) => user.id == userId),
      );
      if (user) {
        setCheckedRoles(user.roles);
        setUnsavedChanges(false);
      }
    }
  }, [users.data]);

  const updateUserMutation = useMutation(quizConnectQuery.updateUser, {
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: createConnectQueryKey(quizConnectQuery.listUsers),
      });
    },
  });

  let user;
  if (users.data) {
    user = oneOrUndefined(users.data.users.filter((user) => user.id == userId));
  }

  if (user) {
    const demoAccount = user.username.slice(0, 4) === "demo";
    let password = "";
    if (demoAccount) {
      password = demoPasswordQuery.data?.demoPassword ?? "";
    }

    return (
      <Container py="md">
        <Stack>
          <Text fs="italic" c="dimmed">
            {unsavedChanges ? "New changes unsaved" : "All changes saved"}
          </Text>
          <Title>
            {user.name !== ""
              ? user.name
              : user.email !== ""
                ? user.email
                : user.username !== ""
                  ? user.username
                  : "Anonymous Coward"}
          </Title>
          <Text>
            Email: {user.email !== "" ? user.email : <i>no email recorded</i>}
          </Text>
          <Text>
            Auth0 ID:{" "}
            {user.auth0Id !== "" ? (
              <Anchor
                href={encodeURI(config.auth0UserURL + user.auth0Id)}
                target="_blank"
              >
                {user.auth0Id}
              </Anchor>
            ) : (
              <i>user not created in Auth0</i>
            )}
          </Text>
          <Text>
            Stripe ID: <i>TBD</i>
          </Text>
          <Text>Learning Time: {formatDuration(user.learningTime)}</Text>
          <Text>Last Active: {formatTimestamp(user.lastActive)}</Text>
          {demoAccount && <Text>Password: {password}</Text>}
          <Text>Roles:</Text>
          <Table>
            <Table.Tbody>
              {allRoles.map((role) => (
                <Table.Tr key={role}>
                  <Table.Th>
                    <Checkbox
                      checked={checkedRoles.includes(role)}
                      label={role}
                      onChange={(event) => {
                        if (event.currentTarget.checked) {
                          setCheckedRoles([...checkedRoles, role]);
                          setUnsavedChanges(true);
                        } else {
                          setCheckedRoles(
                            checkedRoles.filter(
                              (checkedRole) => checkedRole !== role,
                            ),
                          );
                          setUnsavedChanges(true);
                        }
                      }}
                    />
                  </Table.Th>
                </Table.Tr>
              ))}
            </Table.Tbody>
          </Table>
          <Group>
            <Button
              size="sm"
              onClick={() => {
                updateUserMutation.mutate({
                  user: {
                    id: userId,
                    username: user.username,
                    auth0Id: user.auth0Id,
                    roles: checkedRoles.map((role) => {
                      return role;
                    }),
                  },
                });
                setUnsavedChanges(false);
              }}
            >
              Save changes
            </Button>
            <Button
              size="sm"
              color="red"
              onClick={() => {
                if (
                  confirm(
                    "Are you sure you want to reset this user's progress?",
                  )
                ) {
                  resetProgressMutation.mutate({ userId });
                }
              }}
            >
              Reset Progress
            </Button>
          </Group>
        </Stack>
      </Container>
    );
  } else {
    return (
      <Container py="md">
        <Stack>
          <Title>User not found</Title>
        </Stack>
      </Container>
    );
  }
};
