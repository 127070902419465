import { quizConnectQuery } from "@augmedi/proto-gen";
import { useQuery } from "@connectrpc/connect-query";
import { BugReportButton } from "./BugReportButton";
import { LogoutButton } from "./LogoutButton";

export const AppUserOptions = () => {
  const whoAmIQuery = useQuery(quizConnectQuery.whoAmI);
  return (
    !whoAmIQuery.isLoading && (
      <>
        <BugReportButton textButton />
        <LogoutButton textButton />
      </>
    )
  );
};
