import { Group, Loader, Paper, Text } from "@mantine/core";
import { View } from "@react-three/drei";
import { Canvas } from "@react-three/fiber";
import { Suspense } from "react";
import { defaultCamera } from "../logic/camera.js";
import * as classes from "./GlobalCanvas.module.css";

export const GlobalCanvas = () => {
  return (
    <Suspense
      fallback={
        <div className={classes.background}>
          <Group justify="center">
            <Paper className={classes.card} m="xl" mt="20vh" p="lg">
              <Group justify="center">
                <Loader />
                <Text>Loading model...</Text>
              </Group>
            </Paper>
          </Group>
        </div>
      }
    >
      <Canvas
        camera={defaultCamera}
        eventSource={document.getElementById("app") ?? undefined}
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          overflow: "hidden",
        }}
      >
        <View.Port />
      </Canvas>
    </Suspense>
  );
};
