import { quizConnectQuery, quizPb } from "@augmedi/proto-gen";
import { useSuspenseQuery } from "@connectrpc/connect-query";
import { Button, Container, Group, Stack, Title } from "@mantine/core";
import { Link, useParams } from "wouter";
import { getUrlForNewQuizSession } from "../logic/quiz-url";
import { NotFoundPage } from "./NotFoundPage";
import { PercentPill } from "./PercentPill";

export const QuizChapterPage = () => {
  const { chapterId } = useParams<{ chapterId: string }>();

  const getFrozenCurriculumQuery = useSuspenseQuery(
    quizConnectQuery.getFrozenCurriculum,
  );
  const chapter = getFrozenCurriculumQuery.data.chapters.find(
    (c) => c.id === chapterId,
  );

  const getQuizProgressQuery = useSuspenseQuery(
    quizConnectQuery.getQuizProgress,
  );

  if (!chapter) {
    console.log("chapter");
    return <NotFoundPage />;
  }

  const progress =
    getQuizProgressQuery.data.progressByFrozenChapterId[chapter.id] ??
    new quizPb.GetQuizProgressResponse_ChapterProgress();

  const newQuestionContent = (
    <Stack gap="xs">
      <PercentPill
        ratio={progress.learnedRatio}
        labelSuffix="seen"
        colorName="blue"
        border
      />
      <Link
        to={getUrlForNewQuizSession({
          frozenChapterId: chapter.id,
          mode: quizPb.QuizMode.LEARN_NEW,
        })}
        asChild
      >
        <Button component="a" fullWidth>
          New questions
        </Button>
      </Link>
    </Stack>
  );

  const reviewContent = (
    <Stack gap="xs">
      <PercentPill
        ratio={progress.needsReviewRatio}
        labelSuffix="need review"
        colorName="yellow"
        border
      />
      <Link
        to={getUrlForNewQuizSession({
          frozenChapterId: chapter.id,
          mode: quizPb.QuizMode.REPEAT_OLD,
        })}
        asChild
      >
        <Button component="a" fullWidth>
          Review
        </Button>
      </Link>
    </Stack>
  );

  return (
    <Container py="md">
      <Stack>
        <Title>{chapter.name}</Title>
        <Group gap={50} visibleFrom="sm">
          {newQuestionContent}
          {reviewContent}
        </Group>
        <Group gap={50} justify="center" hiddenFrom="sm">
          {newQuestionContent}
          {reviewContent}
        </Group>
      </Stack>
    </Container>
  );
};
