import { quizConnectQuery } from "@augmedi/proto-gen";
import { useQuery } from "@connectrpc/connect-query";
import { AppShell, Burger, Flex, Group } from "@mantine/core";
import * as Sentry from "@sentry/browser";
import { useEffect } from "react";
import { Link } from "wouter";
import { useIsMobileWithCanvas } from "../logic/app-layout";
import { zIndexConstants } from "../z-index-constants";
import { AppNavLinks } from "./AppNavLinks";
import { BugReportButton } from "./BugReportButton";
import { HeaderUsername } from "./HeaderUsername";

interface Props {
  burgerOpened: boolean;
  onBurgerClick: () => void;
  onHeaderChange: (isMobileWithCanvas: boolean) => void;
  onNavLinkClick?: () => void;
}

const logoUrl = new URL("../images/augmedi-blueg-text.png", import.meta.url);

export const AppHeader = ({
  burgerOpened,
  onBurgerClick,
  onHeaderChange,
  onNavLinkClick,
}: Props) => {
  const whoAmIQuery = useQuery(quizConnectQuery.whoAmI);
  const isMobileWithCanvas = useIsMobileWithCanvas();
  Sentry.setUser({ email: whoAmIQuery.data?.email });

  useEffect(() => {
    onHeaderChange(isMobileWithCanvas ?? false);
  }, [isMobileWithCanvas]);

  const sideMenu = whoAmIQuery.data && (
    <Burger
      opened={burgerOpened}
      onClick={onBurgerClick}
      hiddenFrom="md"
      size="sm"
    />
  );

  return (
    <>
      {isMobileWithCanvas ? (
        <Flex
          style={{
            background: "var(--mantine-color-white)",
            border: "1px solid var(--mantine-color-gray-3)",
            borderRadius: "0.5rem",
            position: "absolute",
            top: "0.5rem",
            left: "0.5rem",
            zIndex: zIndexConstants.header,
          }}
        >
          {sideMenu}
        </Flex>
      ) : (
        <AppShell.Header>
          <Group
            align="center"
            justify="space-between"
            px={{ base: "md", sm: "lg" }}
            py="sm"
            style={{ height: "100%" }}
          >
            <Group gap="sm">
              {sideMenu}
              <Link
                to="/"
                style={{
                  color: "inherit",
                  textDecoration: "inherit",
                  cursor: "pointer",
                }}
              >
                <img
                  src={"" + logoUrl}
                  alt="Augmedi"
                  height="25px"
                  width="109px"
                  style={{
                    display: "block",
                    marginTop: -3,
                    marginBottom: -8,
                    objectFit: "contain",
                  }}
                />
              </Link>
            </Group>
            {whoAmIQuery.data && (
              <Group align="center" gap={0} pl="md" visibleFrom="md">
                <AppNavLinks
                  permissions={whoAmIQuery.data.permissions}
                  onNavLinkClick={onNavLinkClick}
                />
              </Group>
            )}

            {!whoAmIQuery.isLoading && (
              <Group
                align="center"
                justify="flex-end"
                gap="sm"
                visibleFrom="sm"
              >
                <BugReportButton />
                <HeaderUsername username={whoAmIQuery.data?.username} />
              </Group>
            )}
          </Group>
        </AppShell.Header>
      )}
    </>
  );
};
