import { useCallback, useState } from "react";

export function useToggleSet<T>(
  initialSet: Set<T> = new Set(),
): [Set<T>, (set: Set<T>) => void, (value: T) => void] {
  const [set, setSet] = useState(initialSet);
  const toggle = useCallback((value: T) => {
    setSet((oldSet) => {
      const newSet = new Set(oldSet);
      if (newSet.has(value)) {
        newSet.delete(value);
      } else {
        newSet.add(value);
      }
      return newSet;
    });
  }, []);
  return [set, setSet, toggle];
}
