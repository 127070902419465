.menuButton {
  color: var(--mantine-color-gray-7);
  text-decoration: none;
  border-radius: var(--mantine-radius-sm);
  padding: 6px 12px;
  font-size: var(--mantine-font-size-sm);
  font-weight: 500;
}

.menuButton:hover {
  background-color: var(--mantine-color-gray-0);
}
