import * as THREE from "three";
import { useMemoDisposable } from "./memo-cleanup";

export const clickDetectionVertexShader = `
    varying vec2 vUv;

    void main() {
        vUv = uv;
        gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
    }
`;

export const clickDetectionFragmentShader = `
    uniform sampler2D uTexture;
    varying vec2 vUv;

    void main() {
        vec4 texture = texture2D(uTexture, vUv);
        
        if (texture.rgb == vec3(0)) {
            gl_FragColor = vec4(0);
        } else {
            gl_FragColor = vec4(1);
        }
    }
`;

export function useWhiteMaterial() {
  const whiteMaterial = useMemoDisposable(
    () =>
      new THREE.MeshBasicMaterial({
        color: new THREE.Color("white"),
      }),
    [],
  );
  return whiteMaterial;
}
