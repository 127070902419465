import { ActionIcon } from "@mantine/core";
import { IconRestore } from "@tabler/icons-react";
import { type ReactNode } from "react";

interface Props {
  children: ReactNode;
  onResetCamera: () => void;
  disabled?: boolean;
}

const ModelViewerUi = ({ children, onResetCamera, disabled }: Props) => {
  return (
    <div style={{ width: "100%", height: "100%", position: "relative" }}>
      {children}
      {!disabled && (
        <div
          style={{ top: "0", right: "0", position: "absolute", margin: "10px" }}
        >
          <ActionIcon
            variant="outline"
            color="gray"
            onClick={() => onResetCamera()}
          >
            <IconRestore />
          </ActionIcon>
        </div>
      )}
    </div>
  );
};

export default ModelViewerUi;
