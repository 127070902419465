import { quizConnectQuery } from "@augmedi/proto-gen";
import { useQuery } from "@connectrpc/connect-query";
import { Text } from "@mantine/core";
import { Link } from "wouter";

export const ThankYouPage = () => {
  useQuery(quizConnectQuery.setUserTeachAnatomySubscription);

  return (
    <>
      <Text style={{ textAlign: "center", fontSize: 40 }} mt="10vh">
        Thanks for subscribing
      </Text>
      <Text style={{ textAlign: "center" }} mt="10vh">
        <Link to="/">{"Let's get started with TeachAnatomy!"}</Link>
      </Text>
    </>
  );
};
