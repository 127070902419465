.chapterCard {
  cursor: pointer;
  text-decoration: none;
  color: inherit;
}

.chapterCard > div {
  box-shadow: var(--mantine-shadow-xs);
}

.chapterCard:hover > div {
  box-shadow: var(--mantine-shadow-sm);
}
