import assert from "assert-ts";

export const onBeforeCompileOverlayShader = (
  shader: THREE.Shader,
  materialToMutate:
    | THREE.MeshBasicMaterial
    | THREE.MeshStandardMaterial
    | THREE.MeshPhysicalMaterial,
) => {
  // Mutate material to save shader reference
  materialToMutate.userData.shaderUniforms = shader.uniforms;
  // Add uniforms to shader
  shader.uniforms["combinedHighlightTexture"] = { value: null };
  shader.uniforms["standaloneHighlightTexture"] = { value: null };
  shader.uniforms["colorCombinedR"] = { value: [0, 0, 0, 0] };
  shader.uniforms["colorCombinedG"] = { value: [0, 0, 0, 0] };
  shader.uniforms["colorCombinedB"] = { value: [0, 0, 0, 0] };
  shader.uniforms["colorCombinedA"] = { value: [0, 0, 0, 0] };
  shader.uniforms["colorStandaloneA"] = { value: [0, 0, 0, 0] };
  shader.vertexShader = shader.vertexShader.replace(
    "void main() {",
    `
    attribute vec4 combinedWholeMeshHighlight;
    varying vec4 vCombinedWholeMeshHighlight;

    void main() {
      vCombinedWholeMeshHighlight = combinedWholeMeshHighlight;
    `,
  );
  // Import uniforms into shader before main function
  shader.fragmentShader = shader.fragmentShader.replace(
    "void main() {",
    `
    uniform sampler2D combinedHighlightTexture;
    uniform sampler2D standaloneHighlightTexture;
    uniform vec4 colorCombinedR;
    uniform vec4 colorCombinedG;
    uniform vec4 colorCombinedB;
    uniform vec4 colorCombinedA;
    uniform vec4 colorStandaloneA;
    varying vec4 vCombinedWholeMeshHighlight;

    void main() {
    `,
  );
  // Add code to overlay labels on top of the PBR shader output.
  shader.fragmentShader = shader.fragmentShader.replace(
    "#include <tonemapping_fragment>",
    `
    #if defined( USE_MAP )
      vec4 combinedSample = texture2D(combinedHighlightTexture, vMapUv);
      vec4 standaloneSample = texture2D(standaloneHighlightTexture, vMapUv);
    # else
      vec4 combinedSample = vec4(0);
      vec4 standaloneSample = vec4(0);
    #endif

    combinedSample = max(combinedSample, vCombinedWholeMeshHighlight);

    vec4 mixedColor = vec4(gl_FragColor.rgb, 1);
    mixedColor.rgb = mix(mixedColor.rgb, colorCombinedR.rgb, colorCombinedR.a * combinedSample.r);
    mixedColor.rgb = mix(mixedColor.rgb, colorCombinedG.rgb, colorCombinedG.a * combinedSample.g);
    mixedColor.rgb = mix(mixedColor.rgb, colorCombinedB.rgb, colorCombinedB.a * combinedSample.b);
    mixedColor.rgb = mix(mixedColor.rgb, colorCombinedA.rgb, colorCombinedA.a * combinedSample.a);
    mixedColor.rgb = mix(mixedColor.rgb, colorStandaloneA.rgb, colorStandaloneA.a * standaloneSample.a);
    gl_FragColor = mixedColor;

    #include <tonemapping_fragment>
    `,
  );
};

export type OverlayMaterialCombinedChannelKeys =
  | "CombinedR"
  | "CombinedG"
  | "CombinedB"
  | "CombinedA";

export function isOverlayMaterialCombinedChannelKey(
  key: string,
): key is OverlayMaterialCombinedChannelKeys {
  return (
    key === "CombinedR" ||
    key === "CombinedG" ||
    key === "CombinedB" ||
    key === "CombinedA"
  );
}

export const allOverlayMaterialCombinedChannelKeys: OverlayMaterialCombinedChannelKeys[] =
  ["CombinedR", "CombinedG", "CombinedB", "CombinedA"];

export function overlayMaterialCombinedChannelKeyToIndex(
  key: OverlayMaterialCombinedChannelKeys,
): number {
  const i = allOverlayMaterialCombinedChannelKeys.indexOf(key);
  assert(i >= 0);
  return i;
}

export type OverlayMaterialStandaloneChannelKeys = "StandaloneA";

export function isOverlayMaterialStandaloneChannelKey(
  key: string,
): key is OverlayMaterialStandaloneChannelKeys {
  return key === "StandaloneA";
}

export const allOverlayMaterialStandaloneChannelKeys: OverlayMaterialStandaloneChannelKeys[] =
  ["StandaloneA"];

export type OverlayMaterialChannelKeys =
  | OverlayMaterialCombinedChannelKeys
  | OverlayMaterialStandaloneChannelKeys;

export function isOverlayMaterialChannelKey(
  key: string,
): key is OverlayMaterialChannelKeys {
  return (
    isOverlayMaterialCombinedChannelKey(key as OverlayMaterialChannelKeys) ||
    isOverlayMaterialStandaloneChannelKey(key as OverlayMaterialChannelKeys)
  );
}

export const allOverlayMaterialChannelKeys: OverlayMaterialChannelKeys[] = [
  ...allOverlayMaterialCombinedChannelKeys,
  ...allOverlayMaterialStandaloneChannelKeys,
];

export interface OverlayMaterialChannelSettings {
  color: number[];
  pulseStrength: number; // 0 - no pulsing, 1 - pulse full opacity range
  pulseFrequency: number; // pulses per second
}

export const defaultOverlayMaterialChannelSettings: OverlayMaterialChannelSettings =
  {
    color: [1, 1, 1, 1],
    pulseStrength: 0,
    pulseFrequency: 0,
  };
