/* eslint @typescript-eslint/no-explicit-any: off */
import { useThree } from "@react-three/fiber";
import * as THREE from "three";
import { FullScreenQuad } from "three-stdlib";
import type { LoadedLabelMask } from "./gltf";
import { useMemoDisposable } from "./memo-cleanup";
import { ReadTexelMaterial } from "./read-texel-material";

function pickLabelMasks(
  gl: THREE.WebGLRenderer,
  readTexelMaterial: ReadTexelMaterial,
  labelMasks: {
    [id: string]: LoadedLabelMask;
  },
  uv: THREE.Vector2,
): string[] {
  if (uv.x < 0 || uv.x > 1 || uv.y < 0 || uv.y > 1) {
    return [];
  }

  const labelMaskIdsByTexture: Map<THREE.Texture, string[]> = new Map();
  for (const [id, { texture }] of Object.entries(labelMasks)) {
    if (!labelMaskIdsByTexture.has(texture)) {
      labelMaskIdsByTexture.set(texture, []);
    }
    labelMaskIdsByTexture.get(texture)!.push(id);
  }

  // HACK We only use the red channel of the render target, be we define it as
  // RGBA because otherwise I couldn't get Firefox to read pixels from it.
  const renderTarget = new THREE.WebGLRenderTarget(1, 1, {
    format: THREE.RGBAIntegerFormat,
    type: THREE.UnsignedIntType,
    // HACK This field is missing in the types for some reason, but is required for things to work.
    ["internalFormat" as any]: "RGBA32UI",
  });

  const fullScreenQuad = new FullScreenQuad(readTexelMaterial);

  const pickedLabelMaskIds: string[] = [];
  for (const [texture, labelMaskIdsThisTexture] of labelMaskIdsByTexture) {
    readTexelMaterial.uniforms.inTexture.value = texture;
    readTexelMaterial.uniforms.readUv.value = uv;

    const pixelBuffer = new Uint32Array(4);

    const oldAutoClear = gl.autoClear;
    gl.autoClear = false;
    gl.setRenderTarget(renderTarget);
    fullScreenQuad.render(gl);
    gl.readRenderTargetPixels(renderTarget, 0, 0, 1, 1, pixelBuffer);
    gl.setRenderTarget(null);
    gl.autoClear = oldAutoClear;

    const pixelBits = pixelBuffer[0]; // see comment on renderTarget

    for (const id of labelMaskIdsThisTexture) {
      const { textureBit } = labelMasks[id];
      if (pixelBits & (1 << textureBit)) {
        pickedLabelMaskIds.push(id);
      }
    }
  }

  renderTarget.dispose();
  fullScreenQuad.dispose();

  return pickedLabelMaskIds;
}

// This will _not_ return IDs for whole mesh labels unless they are explicitly
// stored as masks in the GLTF.
export function usePickLabelMasks(labelMasks: {
  [id: string]: LoadedLabelMask;
}): (uv: THREE.Vector2) => string[] {
  const { gl } = useThree();

  const readTexelMaterial = useMemoDisposable(
    () => new ReadTexelMaterial(),
    [],
  );

  return (uv) => pickLabelMasks(gl, readTexelMaterial, labelMasks, uv);
}
