import { Box, Flex, Stack, Title } from "@mantine/core";
import classNames from "classnames";
import { AnimatePresence, motion } from "framer-motion";
import { useIsMobileWithCanvas } from "../logic/app-layout";
import * as classes from "./QuizLayout.module.css";

interface Props {
  title: string;
  questionId?: string;
  canvasContent?: JSX.Element;
  multiChoiceAnswersContent?: JSX.Element;
  structureInfoContent?: JSX.Element;
  sidebarExtrasAnySize?: JSX.Element;
  canvasExtrasSmall?: JSX.Element;
}

export const QuizLayout = ({
  title,
  questionId,
  canvasContent,
  multiChoiceAnswersContent,
  structureInfoContent,
  sidebarExtrasAnySize,
  canvasExtrasSmall,
}: Props) => {
  const isMobileWithCanvas = useIsMobileWithCanvas();

  const hasRelevantContent = Boolean(
    multiChoiceAnswersContent || structureInfoContent,
  );

  const hasAnyContent = Boolean(hasRelevantContent || sidebarExtrasAnySize);

  return (
    <Flex
      className={classNames({
        [classes.outerWrapper]: true,
        [classes.desktopOuterWrapper]: !isMobileWithCanvas,
      })}
    >
      {/* Canvas */}
      <div className={classes.canvasWrapper}>
        {canvasContent}
        <div className={classes.canvasExtrasWrapper}>{canvasExtrasSmall}</div>
      </div>
      {/* Question */}
      <Box
        className={classNames({
          [classes.contentWrapper]: true,
          [classes.withRelevantContent]: hasRelevantContent,
        })}
      >
        {/* Title */}
        <Title className={classes.title} order={4}>
          <AnimatePresence>
            <motion.div
              className={classes.innerTitle}
              key={questionId}
              initial={{ x: 300, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              exit={{ x: -300, opacity: 0 }}
            >
              {title}
            </motion.div>
          </AnimatePresence>
          <div className={classes.invisibleTitle}>{title}</div>
        </Title>

        {/* Inner Content */}
        {(hasAnyContent || !isMobileWithCanvas) && (
          <div className={classes.innerContentWrapper}>
            {/* Multichoices / Structure Info */}
            {hasAnyContent ? (
              <Stack gap="sm">
                {multiChoiceAnswersContent && (
                  <Box>{multiChoiceAnswersContent}</Box>
                )}
                {structureInfoContent && <Box>{structureInfoContent}</Box>}
              </Stack>
            ) : undefined}
            {/* Feedback */}
            <div
              style={{
                width: "100%",
                position: multiChoiceAnswersContent ? "absolute" : "unset",
                top: !isMobileWithCanvas ? 0 : "unset",
                left: !isMobileWithCanvas ? 0 : "unset",
                bottom: isMobileWithCanvas ? 0 : "unset",
              }}
            >
              {sidebarExtrasAnySize}
            </div>
          </div>
        )}
      </Box>
    </Flex>
  );
};
