import { durationToMillis, quizConnectQuery, quizPb } from "@augmedi/proto-gen";
import { Duration } from "@bufbuild/protobuf";
import { useSuspenseQuery } from "@connectrpc/connect-query";
import {
  Box,
  Group,
  Paper,
  Progress,
  SegmentedControl,
  SimpleGrid,
  Text,
} from "@mantine/core";
import { useState } from "react";
import { formatMillis } from "../utils";

export const RecentAnswersPanel = () => {
  const [value, setValue] = useState("24h");

  let range;
  if (value === "24h") {
    range = quizPb.RecentStatsRange.LAST_24_HOURS;
  } else if (value === "7d") {
    range = quizPb.RecentStatsRange.LAST_7_DAYS;
  } else if (value === "30d") {
    range = quizPb.RecentStatsRange.LAST_30_DAYS;
  } else {
    range = quizPb.RecentStatsRange.LAST_24_HOURS;
  }

  const getQuizRecentStats = useSuspenseQuery(
    quizConnectQuery.getQuizRecentStats,
    { range: range },
  );

  const totalCorrectAnswers = getQuizRecentStats.data.correctAnswers;
  const totalWrongAnswers = getQuizRecentStats.data.wrongAnswers;
  const totalAnswers = totalCorrectAnswers + totalWrongAnswers;

  const data = [
    {
      label: "Correct Answers",
      count: totalCorrectAnswers,
      part: Math.round((100 / totalAnswers) * totalCorrectAnswers),
      color: "#47d6ab",
    },
    {
      label: "Wrong Answers",
      count: totalWrongAnswers,
      part: Math.round((100 / totalAnswers) * totalWrongAnswers),
      color: "#f47373",
    },
  ];

  return (
    <Paper withBorder p="md" radius="md">
      <Group justify="space-between">
        <Group align="flex-end" gap="xs">
          <Text fz="xl" fw={700}>
            Recent Answers
          </Text>
          <SegmentedControl
            color="blue"
            size="xs"
            radius="lg"
            value={value}
            onChange={setValue}
            data={[
              { label: "24h", value: "24h" },
              { label: "7d", value: "7d" },
              { label: "30d", value: "30d" },
            ]}
          />
        </Group>
      </Group>
      <Progress.Root size={34} mt={40}>
        {data.map((segment) => (
          <Progress.Section
            value={segment.part}
            color={segment.color}
            key={segment.color}
          >
            {segment.part > 10 && (
              <Progress.Label>{segment.part}%</Progress.Label>
            )}
          </Progress.Section>
        ))}
      </Progress.Root>
      <SimpleGrid cols={{ base: 1, xs: 3 }} mt="xl">
        {data.map((stat) => (
          <Box key={stat.label} style={{ borderBottomColor: stat.color }}>
            <Group gap="md">
              <Text tt="uppercase" fz="xs" fw={700}>
                {stat.label}
              </Text>
              <Text fw={700} c={stat.color}>
                {stat.count}
              </Text>
            </Group>
          </Box>
        ))}
        <Box style={{ borderBottomColor: "#ffffff" }}>
          <Group gap="md">
            <Text tt="uppercase" fz="xs" fw={700}>
              Learning Time
            </Text>
            <Text fw={700} c="#000000">
              {formatMillis(
                durationToMillis(
                  getQuizRecentStats.data.answeringTime || new Duration(),
                ),
              )}
            </Text>
          </Group>
        </Box>
      </SimpleGrid>
    </Paper>
  );
};
