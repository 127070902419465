/* eslint @typescript-eslint/no-explicit-any: off, @typescript-eslint/no-unused-vars: off */
import { atlasConnectQuery, quizPb } from "@augmedi/proto-gen";
import type { PartialMessage } from "@bufbuild/protobuf";
import type { ConnectError } from "@connectrpc/connect";
import { useQuery } from "@connectrpc/connect-query";
import {
  Button,
  Flex,
  Group,
  Input,
  Loader,
  Modal,
  Select,
  Text,
  TextInput,
  type ComboboxItem,
  type OptionsFilter,
  type SelectProps,
} from "@mantine/core";
import { useDebouncedValue } from "@mantine/hooks";
import { IconCheck } from "@tabler/icons-react";
import {
  keepPreviousData,
  type UseMutationResult,
} from "@tanstack/react-query";
import { useState } from "react";

interface Props {
  modelId: string;
  mediaItemId: string;
  isModalOpen: boolean;
  upstreamStructure: any;
  createEdgeMutation: UseMutationResult<
    any,
    ConnectError,
    PartialMessage<quizPb.AddEdgeRequest>,
    unknown
  >;
  closeModal: () => void;
}

interface ComboboxItemWithSubLabel extends ComboboxItem {
  subLabel: string;
}

export const CreateNewDirectedEdgeModal = ({
  modelId,
  mediaItemId,
  isModalOpen,
  upstreamStructure,
  createEdgeMutation,
  closeModal,
}: Props) => {
  const [newLabelStructureId, setNewLabelStructureId] = useState<string>("");
  const [newLabelName, setNewLabelName] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [lastValidSearchInput, setLastValidSearchInput] = useState("");
  const [debouncedSearchInput] = useDebouncedValue(searchInput, 500);
  const nameSearchQuery = useQuery(
    atlasConnectQuery.nameSearch,
    {
      query: debouncedSearchInput,
    },
    {
      placeholderData: keepPreviousData,
    },
  );

  const loading =
    !!searchInput &&
    (nameSearchQuery.isFetching || searchInput !== debouncedSearchInput);

  const structureItems = nameSearchQuery.data?.entries;

  const closeAndClearNewLabelModal = () => {
    closeModal();
    newLabelStructureIdSelected(null);
  };

  const createNewLabel = () => {
    createEdgeMutation.mutate({
      upstreamStructureId: upstreamStructure.structureId,
      downstreamStructureId: newLabelStructureId,
      mediaItemId: mediaItemId,
      notes: newLabelName,
    });

    closeAndClearNewLabelModal();
  };

  const renderSelectOption: SelectProps["renderOption"] = ({
    option,
    checked,
  }) => (
    <Group flex="1" gap="xs">
      {checked && <IconCheck color="var(--mantine-color-dimmed)" />}
      <Flex direction="column">
        <Text>{option.label}</Text>
        <Text size="xs" c="dimmed">
          {(option as ComboboxItemWithSubLabel).subLabel}
        </Text>
      </Flex>
    </Group>
  );

  const optionsFilter: OptionsFilter = ({ options }) => options;

  const newLabelStructureIdSelected = (option: ComboboxItem | null) => {
    const optionId = option?.value ?? "";
    const optionLabel = option?.label ?? "";
    setNewLabelStructureId(optionId);
    setSearchInput(optionLabel);
    setNewLabelName(optionLabel);
    setLastValidSearchInput(optionLabel);
  };

  // Needed to avoid a bug where it looks like the select is empty when it is not
  const validateSearchInputOnBlur = (e: any) => {
    if (newLabelStructureId && lastValidSearchInput !== searchInput) {
      setSearchInput(lastValidSearchInput);
    }
  };

  return (
    <>
      <Modal
        opened={isModalOpen}
        withCloseButton
        onClose={closeAndClearNewLabelModal}
        title={`Create new downstream structure for ${upstreamStructure.name}`}
      >
        <Flex direction="column" gap="md">
          <Select
            label="Structure name:"
            placeholder="Search..."
            data={structureItems?.map((structure) => {
              return {
                value: structure?.structureId ?? "",
                label: structure?.displayName ?? "",
                subLabel: structure?.matchingName ?? "",
              };
            })}
            renderOption={renderSelectOption}
            searchValue={searchInput}
            onSearchChange={setSearchInput}
            value={newLabelStructureId}
            onChange={(_, option) => newLabelStructureIdSelected(option)}
            onBlur={validateSearchInputOnBlur}
            filter={optionsFilter}
            rightSection={loading ? <Loader size="xs" /> : null}
            searchable
            clearable
            limit={20}
          />

          <Input.Wrapper label="Notes:">
            <TextInput
              placeholder="Type..."
              value={newLabelName}
              onChange={(event) => setNewLabelName(event.currentTarget.value)}
            />
          </Input.Wrapper>

          <Group justify="flex-end" gap="md">
            <Button color="red" onClick={closeAndClearNewLabelModal}>
              Cancel
            </Button>
            <Button onClick={createNewLabel}>Create</Button>
          </Group>
        </Flex>
      </Modal>
    </>
  );
};
