@keyframes fadeInBackground {
  0% {
    backdrop-filter: blur(0);
    background: rgba(0, 0, 0, 0);
  }
  100% {
    backdrop-filter: blur(calc(0.4375rem * var(--mantine-scale)));
    background: rgba(0, 0, 0, 0.35);
  }
}

.background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  overflow: hidden;
  background: rgba(0, 0, 0, 0);
  animation: 200ms ease 300ms 1 fadeInBackground forwards;
}

@keyframes scaleFadeIn {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.card {
  opacity: 0;
  animation: 200ms ease 300ms 1 scaleFadeIn forwards;
}
