import * as THREE from "three";

export class BitSelectMaterial extends THREE.ShaderMaterial {
  constructor() {
    super({
      depthWrite: false,
      depthTest: false,
      blending: THREE.CustomBlending,
      blendEquation: THREE.MaxEquation,
      blendSrc: THREE.OneFactor,
      blendDst: THREE.OneFactor,
      blendSrcAlpha: THREE.OneFactor,
      blendDstAlpha: THREE.OneFactor,
      side: THREE.DoubleSide,
      uniforms: {
        inputTexture: { value: null },
        inputBitMasks: { value: [0, 0, 0, 0] },
        outputChannel: { value: 0 },
      },
      vertexShader: `
        varying vec2 vUv;
        
        void main() {
          vUv = uv;
          gl_Position = vec4(position, 1.0);
        }
      `,
      fragmentShader: `
        uniform highp usampler2D inputTexture;
        uniform uvec4 inputBitMasks;
        uniform int outputChannel;
        
        varying vec2 vUv;
        
        void main() {
          uvec4 inputSample = texture2D(inputTexture, vUv);
          if (
            (inputSample.r & inputBitMasks.r) != 0u ||
            (inputSample.g & inputBitMasks.g) != 0u ||
            (inputSample.b & inputBitMasks.b) != 0u ||
            (inputSample.a & inputBitMasks.a) != 0u
          ) {
            gl_FragColor = vec4(
              outputChannel == 0 ? 1.0 : 0.0,
              outputChannel == 1 ? 1.0 : 0.0,
              outputChannel == 2 ? 1.0 : 0.0,
              outputChannel == 3 ? 1.0 : 0.0
            );
          } else {
            gl_FragColor = vec4(0.0, 0.0, 0.0, 0.0);
          }
        }
      `,
    });
  }
}
