/* eslint react/display-name: "off" */
import { atlasConnectQuery, type quizPb } from "@augmedi/proto-gen";
import type { PlainMessage } from "@bufbuild/protobuf";
import { useQuery } from "@connectrpc/connect-query";
import {
  Alert,
  Button,
  CloseButton,
  Flex,
  Group,
  Paper,
  Stack,
  Text,
  TextInput,
  Title,
} from "@mantine/core";
import { createSpotlight } from "@mantine/spotlight";
import { IconSearch } from "@tabler/icons-react";
import { memo } from "react";
import { StructureSearch } from "./StructureSearch";

interface Props {
  selectedLabel?: PlainMessage<quizPb.ModelLabel>;
  modifySelectedLabel: (
    callback: (label: PlainMessage<quizPb.ModelLabel>) => void,
  ) => void;
  openDeleteLabelModal: () => void;
}

export const LabelSettings = memo(
  ({ selectedLabel, modifySelectedLabel, openDeleteLabelModal }: Props) => {
    const [structureSpotlightStore, structureSpotlight] = createSpotlight();
    const getStructureMetadataQuery = useQuery(
      atlasConnectQuery.getStructureMetadata,
      {
        id: selectedLabel?.structureId,
      },
      {
        enabled: !!selectedLabel?.structureId,
      },
    );
    const structureMetadata = getStructureMetadataQuery.data?.base;

    return (
      selectedLabel && (
        <Stack gap="xs">
          <Title order={4}>Selected label</Title>
          <Text size="sm">Structure ID</Text>
          <Flex gap="xs">
            <Paper withBorder p="xs" w="100%">
              <Group justify="space-between">
                <Text size="sm">
                  {structureMetadata
                    ? structureMetadata.name
                    : "No structure assigned"}
                </Text>
                {structureMetadata && (
                  <CloseButton
                    aria-label="Clear structure"
                    onClick={() => {
                      modifySelectedLabel((label) => {
                        label.structureId = "";
                      });
                    }}
                  />
                )}
              </Group>
            </Paper>
            <Button variant="filled" h="auto" onClick={structureSpotlight.open}>
              <IconSearch size={14} />
            </Button>
          </Flex>
          <TextInput
            label="Name"
            disabled={selectedLabel.isWholeMeshLabel}
            value={selectedLabel.name}
            onChange={(ev) =>
              modifySelectedLabel((selectedLabel) => {
                selectedLabel.name = ev.target.value;
              })
            }
          />
          {selectedLabel.mask ? (
            <Text>
              Resolution: {selectedLabel.mask.width}px x{" "}
              {selectedLabel.mask.height}px
            </Text>
          ) : (
            <Alert color="yellow">Label has no mask</Alert>
          )}

          <Button
            color="red"
            style={{ flexShrink: 0 }}
            disabled={selectedLabel.isWholeMeshLabel}
            onClick={openDeleteLabelModal}
          >
            Delete label
          </Button>
          <StructureSearch
            onSelectStructure={(structureId) => {
              modifySelectedLabel((label) => {
                label.structureId = structureId;
              });
            }}
            structureSpotlightStore={structureSpotlightStore}
          />
        </Stack>
      )
    );
  },
);
