import { allValuesFromStringEnum } from "@augmedi/type-utils";
import { proto3 } from "@bufbuild/protobuf";
import { Permission } from "./proto/quiz_pb.js";

export enum Role {
  Admin = "Admin",
  AnatomyUser = "AnatomyUser",
  ApproachUser = "ApproachUser",
  Labeler = "Labeler",
  ModelViewer = "ModelViewer",
  NewUser = "NewUser",
}

export const allRoles = allValuesFromStringEnum<Role>(Role);

export function roleFromString(role: string): Role {
  if (!allRoles.includes(role as Role)) {
    throw new Error(`Invalid role: ${role}`);
  }
  return role as Role;
}

export const allPermissions = proto3
  .getEnumType(Permission)
  .values.map((v) => v.no as Permission);

export const permissionsByRole: { [role in Role]: Permission[] } = {
  [Role.Admin]: [
    Permission.LIST_MODELS,
    Permission.READ_NON_FROZEN_MODELS,
    Permission.READ_FROZEN_MODELS,
    Permission.EDIT_MODELS,
    Permission.READ_MEDIA_ITEMS,
    Permission.EDIT_MEDIA_ITEMS,
    Permission.USE_ANATOMY_QUIZ,
    Permission.USE_FULL_ANATOMY_QUIZ,
    Permission.EDIT_CURRICULUM,
    Permission.IMPORT_QUESTIONS,
    Permission.LIST_APPROACHES,
    Permission.READ_NON_FROZEN_APPROACHES,
    Permission.READ_FROZEN_APPROACHES,
    Permission.EDIT_APPROACHES,
    Permission.READ_USERS,
    Permission.EDIT_USERS,
    Permission.DELETE_USERS,
    Permission.READ_ATLAS_BASIC,
    Permission.READ_ATLAS_AUGMEDI_INTERNAL,
    Permission.QR_CODE,
    Permission.ANNOTATE,
  ],
  [Role.Labeler]: [
    Permission.LIST_MODELS,
    Permission.READ_NON_FROZEN_MODELS,
    Permission.READ_FROZEN_MODELS,
    Permission.EDIT_MODELS,
    Permission.READ_ATLAS_BASIC,
  ],
  [Role.AnatomyUser]: [
    Permission.READ_FROZEN_MODELS,
    Permission.USE_ANATOMY_QUIZ,
    Permission.USE_FULL_ANATOMY_QUIZ,
    Permission.EDIT_CURRICULUM,
    Permission.READ_ATLAS_BASIC,
    Permission.QR_CODE,
  ],
  [Role.ApproachUser]: [
    Permission.READ_FROZEN_MODELS,
    Permission.READ_MEDIA_ITEMS,
    Permission.LIST_APPROACHES,
    Permission.READ_FROZEN_APPROACHES,
    Permission.READ_ATLAS_BASIC,
    Permission.QR_CODE,
  ],
  [Role.ModelViewer]: [
    Permission.LIST_MODELS,
    Permission.READ_FROZEN_MODELS,
    Permission.READ_ATLAS_BASIC,
  ],
  [Role.NewUser]: [
    Permission.READ_FROZEN_MODELS,
    Permission.USE_ANATOMY_QUIZ,
    Permission.EDIT_CURRICULUM,
    Permission.READ_ATLAS_BASIC,
    Permission.QR_CODE,
  ],
};

// When a user logs in with a low-security login methods, not all the
// permissions that they normally have are available to them. This is the list
// of permissions that are _allowed_ with a low-security login. The user still
// has to normally have a permission for it to be allowed with a low-security
// login.
export const lowSecurityPermissions: Permission[] = [
  Permission.LIST_MODELS,
  Permission.READ_FROZEN_MODELS,
  Permission.READ_MEDIA_ITEMS,
  Permission.USE_ANATOMY_QUIZ,
  Permission.USE_FULL_ANATOMY_QUIZ,
  Permission.LIST_APPROACHES,
  Permission.READ_FROZEN_APPROACHES,
  Permission.READ_ATLAS_BASIC,
];
