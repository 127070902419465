/* eslint @tanstack/query/exhaustive-deps: off */
import { quizConnectQuery, quizPb } from "@augmedi/proto-gen";
import { isTruthy, unreachable } from "@augmedi/type-utils";
import {
  Duration,
  Timestamp,
  type PartialMessage,
  type PlainMessage,
} from "@bufbuild/protobuf";
import { callUnaryMethod, useTransport } from "@connectrpc/connect-query";
import { useSuspenseQuery as tsUseSuspenseQuery } from "@tanstack/react-query";
import { first } from "lodash-es";
import { useCallback, useEffect, useMemo, useState } from "react";
import { v4 as genUuid } from "uuid";

export interface UseQuizOptions {
  sessionId: string;
  frozenChapterId?: string;
  bodyPart?: quizPb.BodyPart;
  mode: quizPb.QuizMode;
}

export interface UseQuizConsumeArgs {
  answerIds: string[];
  timeTaken: Duration;
}

export interface UseQuizResult {
  item?: PlainMessage<quizPb.QuizItem>;
  consume: (args: UseQuizConsumeArgs) => boolean; // return value is expectCorrect
  next: () => void;
}

interface State {
  // UUID that is regenerated each time we need to suspend to load more questions.
  triggerId: string;
  seenIntroductionGroupIds: Set<string>;
  // Items may or may not remain in this list once they have been synced.
  // It doesn't matter, since the server prevents double counting.
  unsyncedConsumedQuizItems: PlainMessage<quizPb.ConsumedQuizItem>[];
  quizItems: PlainMessage<quizPb.QuizItem>[];
  item?: PlainMessage<quizPb.QuizItem>;
  seenQuizItemIds: Set<string>;
  attemptIndexes: { [itemId: string]: number };
}

function evaluateAnswer(
  answerRequest: PlainMessage<quizPb.AnswerRequest> | undefined,
  answerIds: string[],
) {
  const answerRequestContent = answerRequest?.content;
  switch (answerRequestContent?.case) {
    case "pickLocation": {
      if (!answerIds.length) {
        throw new Error("Empty answerIds in pickLocation answer");
      }
      const selectedAndCorrectAnswerIds = answerIds.filter((v) =>
        answerRequestContent.value.desiredLabelIds.includes(v),
      );
      return selectedAndCorrectAnswerIds.length
        ? {
            expectCorrect: true,
            // The click handler can return multiple overlapping labels, but the server expects one label for pick location answers.
            answerIdsForServer: [selectedAndCorrectAnswerIds[0]],
          }
        : {
            expectCorrect: false,
            answerIdsForServer: [answerIds[0]],
          };
    }
    case "multipleChoice": {
      const expectCorrect = answerRequestContent.value.options.every(
        (option) => option.correct === answerIds.includes(option.id),
      );
      return {
        expectCorrect,
        answerIdsForServer: answerIds,
      };
    }
    case undefined: {
      throw new Error("Unsupported answerRequest type");
    }
    default: {
      unreachable(answerRequestContent);
    }
  }
}

function prepareForConsume(
  item: PlainMessage<quizPb.QuizItem> | undefined,
  answerIds: string[],
): {
  expectCorrect: boolean;
  newSeenIntroductionGroupId?: string;
  answerIdsForServer: string[];
} {
  if (!item) {
    throw new Error("No item to consume");
  }
  switch (item.content.case) {
    case "introductionGroup": {
      if (answerIds.length) {
        throw new Error("Non-empty answerIds when consuming introductionGroup");
      }
      return {
        expectCorrect: false, // This field is ignored for introduction groups
        newSeenIntroductionGroupId: item.content.value.introductionGroup?.id,
        answerIdsForServer: [],
      };
    }
    case "question": {
      return evaluateAnswer(
        item.content.value.question?.answerRequest,
        answerIds,
      );
    }
    case undefined: {
      throw new Error("Unsupported item type");
    }
    default: {
      unreachable(item.content);
    }
  }
}

function getFirstUnseenQuizItem(
  quizItems: PlainMessage<quizPb.QuizItem>[],
  {
    seenQuizItemIds,
    seenIntroductionGroupIds,
  }: Pick<State, "seenQuizItemIds" | "seenIntroductionGroupIds">,
): PlainMessage<quizPb.QuizItem> | undefined {
  return first(
    quizItems.filter(
      (item) =>
        !seenQuizItemIds.has(item.id) &&
        (item.content.case !== "introductionGroup" ||
          !seenIntroductionGroupIds.has(
            item.content.value.introductionGroup?.id || "",
          )),
    ),
  );
}

export function useQuiz(options: UseQuizOptions): UseQuizResult {
  const [state, setState] = useState<State>(() => ({
    triggerId: options.sessionId,
    seenIntroductionGroupIds: new Set(),
    unsyncedConsumedQuizItems: [],
    quizItems: [],
    item: undefined,
    seenQuizItemIds: new Set(),
    attemptIndexes: {},
  }));

  const transport = useTransport();

  const baseRequest = useMemo(
    (): PartialMessage<quizPb.QuizRequest> => ({
      frozenChapterId: options.frozenChapterId,
      mode: options.mode,
      earlyRepeat: options.mode === quizPb.QuizMode.REPEAT_OLD,
      bodyPart: options.bodyPart,
    }),
    [options.frozenChapterId, options.mode, options.bodyPart],
  );

  useEffect(() => {
    const consumedQuizItems = state.unsyncedConsumedQuizItems;
    if (!consumedQuizItems.length) {
      return;
    }

    async function backgroundSync() {
      const res = await callUnaryMethod(
        quizConnectQuery.quiz,
        {
          ...baseRequest,
          consumedQuizItems,
        },
        { transport },
      );
      const consumedQuizItemIds = new Set(
        consumedQuizItems.map((item) => item.id),
      );
      const serverAwareSyncedQuizItemIds = new Set(
        consumedQuizItems.map((item) => item.quizItem?.id).filter(isTruthy),
      );
      setState((state) => ({
        ...state,
        unsyncedConsumedQuizItems: state.unsyncedConsumedQuizItems.filter(
          (item) => !consumedQuizItemIds.has(item.id),
        ),
        quizItems: res.quizItems,
        // This code below is intentionally removing any items that the server
        // is aware we have answered from seenQuizItemIds, so that the server
        // can make the user repeat them if it wants to.
        //
        // Any questions in seenQuizItemIds will not be shown to the user, even
        // if the server sends them again. If we never remove quiz items from
        // this set, then it's impossible for the server to repeat questions.
        //
        // If we remove items from seenQuizItemIds too early, then the user
        // will be shown exactly the same question multiple times, even if the
        // server didn't intend to repeat that question.
        //
        // Removing these quiz items at this location ensures that we repeat
        // exactly when the server wants us to.
        seenQuizItemIds: new Set(
          [...state.seenQuizItemIds].filter(
            (id) => !serverAwareSyncedQuizItemIds.has(id),
          ),
        ),
      }));
    }

    backgroundSync().catch((err) =>
      console.warn(
        "Failed background sync of consumed quiz items. This is not a problem if it's rare and a later sync succeeds.",
        err,
      ),
    );
  }, [state.unsyncedConsumedQuizItems, baseRequest]);

  const itemQuery = tsUseSuspenseQuery({
    queryFn: () =>
      callUnaryMethod(
        quizConnectQuery.quiz,
        {
          ...baseRequest,
          consumedQuizItems: state.unsyncedConsumedQuizItems,
        },
        { transport },
      ),
    // queryKey avoids sharing this query with other useQuiz instances and
    // makes sure that we refetch when state.triggerId changes.
    queryKey: ["useQuiz", "QuizService", "quiz", state.triggerId, baseRequest],
  });

  const firstUnseenSuspenseQuizItem = getFirstUnseenQuizItem(
    itemQuery.data?.quizItems,
    state,
  );

  useEffect(() => {
    const firstUnseenSuspenseQuizItem = getFirstUnseenQuizItem(
      itemQuery.data?.quizItems,
      state,
    );
    setState((state) => ({
      ...state,
      item: state.item ?? firstUnseenSuspenseQuizItem,
      quizItems: itemQuery.data?.quizItems,
    }));
  }, [itemQuery.data]);

  const consume = useCallback(
    ({ answerIds, timeTaken }: UseQuizConsumeArgs) => {
      if (!state.item) {
        console.warn("consume called when there was no item to consume");
        return false;
      }

      const seenItem = state.item;
      const { expectCorrect, newSeenIntroductionGroupId, answerIdsForServer } =
        prepareForConsume(seenItem, answerIds);

      const currentAttemptIndex = state.attemptIndexes[seenItem.id] || 0;
      const newAttemptIndex = currentAttemptIndex + 1;

      const consumedQuizItem: PlainMessage<quizPb.ConsumedQuizItem> = {
        id: genUuid(),
        quizItem: seenItem,
        answerIds: answerIdsForServer,
        expectCorrect,
        consumedAt: Timestamp.now(),
        timeTaken: timeTaken,
        attemptIndex: newAttemptIndex,
      };

      setState((state) => ({
        ...state,
        seenIntroductionGroupIds: newSeenIntroductionGroupId
          ? new Set([
              ...state.seenIntroductionGroupIds,
              newSeenIntroductionGroupId,
            ])
          : state.seenIntroductionGroupIds,
        seenQuizItemIds: new Set([...state.seenQuizItemIds, seenItem.id]),
        unsyncedConsumedQuizItems: [
          ...state.unsyncedConsumedQuizItems,
          consumedQuizItem,
        ],
        attemptIndexes: {
          ...state.attemptIndexes,
          [seenItem.id]: newAttemptIndex,
        },
      }));
      return expectCorrect;
    },
    [state],
  );

  const next = useCallback(() => {
    // It's important to generate this outside of setState, otherwise we can
    // cause an infinite loop with suspense.
    const triggerId = genUuid();
    setState((state) => {
      const firstUnseenQuizItem = getFirstUnseenQuizItem(
        state.quizItems,
        state,
      );
      return firstUnseenQuizItem
        ? { ...state, item: firstUnseenQuizItem }
        : { ...state, item: undefined, triggerId };
    });
  }, []);

  const item = state.item ?? firstUnseenSuspenseQuizItem;

  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      attemptIndexes: prevState.item
        ? {
            ...prevState.attemptIndexes,
            [prevState.item.id]: 0,
          }
        : prevState.attemptIndexes,
    }));
  }, [item]);

  return {
    item,
    consume,
    next,
  };
}
