import { atlasPb } from "@augmedi/proto-gen";
import { sortBy } from "lodash-es";

export interface NestedDisplayTreeNode {
  node: atlasPb.DisplayTreeNode;
  children: NestedDisplayTreeNode[];
}

export function convertDisplayTreeToNested(
  nodes: atlasPb.DisplayTreeNode[],
): NestedDisplayTreeNode[] {
  const nestedNodesById = new Map<string, NestedDisplayTreeNode>();
  for (const node of nodes) {
    nestedNodesById.set(node.id, { node, children: [] });
  }

  let topLevelNestedNodes: NestedDisplayTreeNode[] = [];
  for (const nestedNode of nestedNodesById.values()) {
    const { parentId } = nestedNode.node;
    if (parentId) {
      const parent = nestedNodesById.get(parentId);
      if (!parent) {
        throw new Error(`Parent node ${parentId} not found`);
      }
      parent.children.push(nestedNode);
    } else {
      topLevelNestedNodes.push(nestedNode);
    }
  }

  topLevelNestedNodes = sortBy(topLevelNestedNodes, (node) => node.node.order);
  for (const node of topLevelNestedNodes) {
    node.children = sortBy(node.children, (node) => node.node.order);
  }

  return topLevelNestedNodes;
}
