/* eslint react/no-unescaped-entities: off, @typescript-eslint/no-unused-vars: off */
import { quizConnectQuery, quizPb } from "@augmedi/proto-gen";
import type { PlainMessage } from "@bufbuild/protobuf";
import {
  createConnectQueryKey,
  useMutation,
  useSuspenseQuery,
} from "@connectrpc/connect-query";
import {
  Alert,
  Button,
  Container,
  Group,
  Modal,
  Stack,
  Text,
  TextInput,
  Textarea,
  Title,
} from "@mantine/core";
import { useInterval } from "@mantine/hooks";
import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useRef, useState } from "react";
import { v4 as genUuid } from "uuid";
import { useLocation, useParams } from "wouter";
import { useNavigationLockAndBeforeUnload } from "../logic/navigation-lock.js";
import { DraggableList } from "./DraggableList.js";
import { ModelSelect } from "./ModelSelect.js";
import { NotFoundPage } from "./NotFoundPage.js";

export const ApproachStepDraftPage = () => {
  const [_location, navigate] = useLocation();
  const { approachId, approachStepId } = useParams<{
    approachId: string;
    approachStepId: string;
  }>();

  const queryClient = useQueryClient();

  const getApproachQuery = useSuspenseQuery(quizConnectQuery.getApproach, {
    id: approachId,
  });
  const approachStepFromServer = getApproachQuery.data!.steps.find(
    (step) => step.id === approachStepId,
  );

  const [localEdits, setLocalEdits] =
    useState<PlainMessage<quizPb.ApproachStep>>();
  const approachStep = localEdits ?? approachStepFromServer;
  useEffect(() => {
    if (
      localEdits &&
      quizPb.ApproachStep.equals(localEdits, approachStepFromServer)
    ) {
      setLocalEdits(undefined);
    }
  }, [approachStepFromServer, localEdits]);

  const updateApproachStepMutation = useMutation(
    quizConnectQuery.updateApproachStep,
    {
      onSuccess: async () => {
        // TODO centralize invalidation
        await Promise.all([
          queryClient.invalidateQueries({
            queryKey: createConnectQueryKey(quizConnectQuery.listApproaches),
          }),
          queryClient.invalidateQueries({
            queryKey: createConnectQueryKey(quizConnectQuery.getApproach, {
              id: approachId,
            }),
          }),
        ]);
      },
    },
  );
  const saveLocalEdits = () => {
    if (!localEdits) {
      return;
    }
    updateApproachStepMutation.mutate({
      step: localEdits,
    });
  };

  const autosaveIntervalHandlerRef = useRef<() => void>();
  useEffect(() => {
    autosaveIntervalHandlerRef.current = () => {
      if (!updateApproachStepMutation.isPending) {
        saveLocalEdits();
      }
    };
  });
  const autosaveInterval = useInterval(
    () => autosaveIntervalHandlerRef.current?.(),
    1000,
  );
  useEffect(() => {
    autosaveInterval.start();
    return () => autosaveInterval.stop();
  }, []);

  const anythingSaving = updateApproachStepMutation.isPending;
  const anythingDirty = anythingSaving || !!localEdits;
  useNavigationLockAndBeforeUnload(anythingDirty);

  let userVisibleSaveState: "saving" | "saved" | "error";
  if (!anythingDirty) {
    userVisibleSaveState = "saved";
  } else if (anythingSaving) {
    userVisibleSaveState = "saving";
  } else if (updateApproachStepMutation.isError) {
    userVisibleSaveState = "error";
  } else {
    // We're not saving yet, but we will be soon because of the autosave timer.
    userVisibleSaveState = "saving";
  }
  const userVisibleSaveStateLabels: {
    [K in typeof userVisibleSaveState]: string;
  } = {
    saved: "All changes saved",
    saving: "Saving...",
    error: "Failed to save changes",
  };

  const [editingSectionId, setEditingSectionId] = useState<string>();
  const editingSection = approachStep?.infoSections.find(
    (s) => s.id === editingSectionId,
  );

  const [aboutToDeleteSectionId, setAboutToDeleteSectionId] =
    useState<string>();
  useEffect(() => {
    if (aboutToDeleteSectionId === undefined) {
      return;
    }
    const timeoutHandle = setTimeout(() => {
      setAboutToDeleteSectionId(undefined);
    }, 2000);
    return () => clearTimeout(timeoutHandle);
  }, [aboutToDeleteSectionId]);
  useEffect(() => {
    setAboutToDeleteSectionId(undefined);
  }, [editingSectionId]);

  const [isStepDeleteModalOpen, setStepDeleteModalOpen] = useState(false);
  const deleteApproachStepMutation = useMutation(
    quizConnectQuery.deleteApproachStep,
    {
      onSuccess: async (_res, req) => {
        setStepDeleteModalOpen(false);
        navigate(`/approaches/${approachId}/draft`);
        await Promise.all([
          queryClient.invalidateQueries({
            queryKey: createConnectQueryKey(quizConnectQuery.listApproaches),
          }),
          queryClient.invalidateQueries({
            queryKey: createConnectQueryKey(quizConnectQuery.getApproach, {
              id: approachId,
            }),
          }),
        ]);
      },
    },
  );

  if (!approachStep) {
    return <NotFoundPage />;
  }

  return (
    <>
      <Container py="md">
        <Stack>
          <Group justify="space-between" align="center">
            <Title
              order={1}
              fs={approachStep.title.trim() ? undefined : "italic"}
              c={approachStep.title.trim() ? undefined : "dimmed"}
            >
              {approachStep.title.trim() || "(untitled approach step)"}
            </Title>
            <Group>
              <Text fs="italic" c="dimmed">
                {userVisibleSaveStateLabels[userVisibleSaveState]}
              </Text>
              <Button color="red" onClick={() => setStepDeleteModalOpen(true)}>
                Delete step
              </Button>
            </Group>
          </Group>
          <TextInput
            label="Title"
            description="The title shown at the top of the description card for this approach step"
            placeholder="Title"
            value={approachStep.title}
            onChange={(ev) =>
              setLocalEdits({
                ...approachStep,
                title: ev.target.value,
              })
            }
            autoFocus
          />
          <ModelSelect
            selectedId={approachStep.modelId || undefined}
            onChange={(id) =>
              setLocalEdits({
                ...approachStep,
                modelId: id ?? "",
              })
            }
            description="This model with be shown next to the card with this step's sections"
          />
          <Title order={2}>Sections</Title>
          <DraggableList
            items={approachStep.infoSections.map((s) => ({
              id: s.id,
              label:
                s.content.case === "text"
                  ? `Text: ${s.content.value}`
                  : "Media Item",
              onClick: () => setEditingSectionId(s.id),
            }))}
            onReorder={(idsInNewOrder) =>
              setLocalEdits({
                ...approachStep,
                infoSections: idsInNewOrder.map(
                  (id) => approachStep.infoSections.find((s) => s.id === id)!,
                ),
              })
            }
            truncate
          />
          <Group>
            <Button
              onClick={() => {
                const id = genUuid();
                setLocalEdits({
                  ...approachStep,
                  infoSections: [
                    ...approachStep.infoSections,
                    new quizPb.ApproachStepInfoSection({
                      id,
                      content: { case: "text", value: "" },
                    }),
                  ],
                });
                setEditingSectionId(id);
              }}
            >
              Add text section
            </Button>
          </Group>
        </Stack>
      </Container>
      {editingSection && (
        <Modal
          opened={!!editingSection}
          onClose={() => setEditingSectionId(undefined)}
          title="Edit section"
          size="lg"
        >
          <Stack>
            {editingSection.content.case === "text" ? (
              <Textarea
                label="Text"
                description="This text is shown directly to the user"
                value={editingSection.content.value}
                onChange={(ev) =>
                  setLocalEdits({
                    ...approachStep,
                    infoSections: approachStep.infoSections.map((s) =>
                      s.id === editingSectionId
                        ? {
                            ...s,
                            content: { case: "text", value: ev.target.value },
                          }
                        : s,
                    ),
                  })
                }
                minRows={4}
                maxRows={8}
                autosize
                autoFocus
              />
            ) : (
              <Alert color="orange">
                This is an image section. You can't edit image sections in the
                UI yet.
              </Alert>
            )}
            <Group justify="flex-end">
              <Text fs="italic" c="dimmed">
                {userVisibleSaveStateLabels[userVisibleSaveState]}
              </Text>
              {aboutToDeleteSectionId === editingSectionId ? (
                <Button
                  color="red"
                  onClick={() =>
                    setLocalEdits({
                      ...approachStep,
                      infoSections: approachStep.infoSections.filter(
                        (s) => s.id !== editingSectionId,
                      ),
                    })
                  }
                >
                  Really delete section?
                </Button>
              ) : (
                <Button
                  color="red"
                  onClick={() => setAboutToDeleteSectionId(editingSectionId)}
                >
                  Delete section
                </Button>
              )}
            </Group>
          </Stack>
        </Modal>
      )}
      <Modal
        opened={isStepDeleteModalOpen}
        onClose={() => setStepDeleteModalOpen(false)}
        title="Delete step?"
      >
        <Stack>
          <Text>
            Are you sure you want to delete the approach step "
            {approachStep.title}"?
          </Text>
          <Button
            color="red"
            onClick={() =>
              deleteApproachStepMutation.mutate({ id: approachStep.id })
            }
          >
            Delete {approachStep.title}
          </Button>
        </Stack>
      </Modal>
    </>
  );
};
