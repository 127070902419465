import { last } from "lodash-es";
import { shaderRgbaFromString } from "./color";
import {
  allOverlayMaterialCombinedChannelKeys,
  type OverlayMaterialChannelSettings,
  type OverlayMaterialCombinedChannelKeys,
} from "./overlay-material";

export interface HighlightedId {
  id: string;
  color: string;
  pulse: boolean;
}

export function splitHighlightsIntoChannels(highlights: HighlightedId[]): {
  visibleLabelIds: {
    [channel in OverlayMaterialCombinedChannelKeys]?: string[];
  };
  settingsByChannel: {
    [channel in OverlayMaterialCombinedChannelKeys]?: OverlayMaterialChannelSettings;
  };
} {
  interface Channel {
    key: OverlayMaterialCombinedChannelKeys;
    color: string;
    pulse: boolean;
    labelIds: string[];
  }

  const channels: Channel[] = [];
  for (const highlight of highlights) {
    let channel = channels.find(
      (channel) =>
        channel.color === highlight.color && channel.pulse === highlight.pulse,
    );
    if (
      !channel &&
      channels.length < allOverlayMaterialCombinedChannelKeys.length
    ) {
      channel = {
        key: allOverlayMaterialCombinedChannelKeys[channels.length],
        color: highlight.color,
        pulse: highlight.pulse,
        labelIds: [],
      };
      channels.push(channel);
    } else if (!channel) {
      console.warn(
        `Too many unique label settings. Using wrong color and/or pulsing for label ${highlight.id}`,
      );
      channel = last(channels)!;
    }
    channel.labelIds.push(highlight.id);
  }

  const visibleLabelIds: {
    [channel in OverlayMaterialCombinedChannelKeys]?: string[];
  } = {};
  const settingsByChannel: {
    [channel in OverlayMaterialCombinedChannelKeys]?: OverlayMaterialChannelSettings;
  } = {};

  for (const channel of channels) {
    visibleLabelIds[channel.key] = channel.labelIds;
    settingsByChannel[channel.key] = {
      color: shaderRgbaFromString(channel.color),
      pulseStrength: channel.pulse ? 0.85 : 0,
      pulseFrequency: 0.6,
    };
  }

  return {
    visibleLabelIds,
    settingsByChannel,
  };
}
