/* eslint @typescript-eslint/no-unused-vars: off */
import { atlasConnectQuery } from "@augmedi/proto-gen";
import { useSuspenseQuery } from "@connectrpc/connect-query";
import assert from "assert-ts";
import { first } from "lodash-es";
import { useCallback, useMemo, useState } from "react";
import { useLocation } from "wouter";
import { DisplayTreeNavLink } from "./DisplayTreeNavLink";
import { convertDisplayTreeToNested } from "./convert";

export const DisplayTree = () => {
  const [_location, navigate] = useLocation();

  const displayTreeQuery = useSuspenseQuery(
    atlasConnectQuery.getDisplayTree,
    {},
  );
  const nestedNodes = useMemo(
    () => convertDisplayTreeToNested(displayTreeQuery.data?.nodes ?? []),
    [displayTreeQuery.data],
  );
  const pathsFromRootById = useMemo((): Map<string, string[]> => {
    if (!displayTreeQuery.data) {
      return new Map();
    }
    const parentIdsById = new Map<string, string>();
    for (const node of displayTreeQuery.data.nodes) {
      assert(!parentIdsById.has(node.id));
      parentIdsById.set(node.id, node.parentId);
    }
    return new Map(
      displayTreeQuery.data.nodes.map((node) => {
        const path = [];
        let currentId: string | undefined = node.id;
        while (currentId) {
          path.push(currentId);
          currentId = parentIdsById.get(currentId);
        }
        path.reverse();
        return [node.id, path];
      }),
    );
  }, [displayTreeQuery.data]);
  const displayTreeNodesById = useMemo(
    () =>
      new Map(
        displayTreeQuery.data?.nodes.map((node) => [node.id, node]) ?? [],
      ),
    [displayTreeQuery.data],
  );

  const [openedIdPath, setOpenedIdPath] = useState<string[]>([]);
  const onNodeClick = useCallback(
    (nodeId: string) => {
      setOpenedIdPath((openedIdPath) => {
        const index = openedIdPath.indexOf(nodeId);
        return index === -1
          ? (pathsFromRootById.get(nodeId) ?? [])
          : openedIdPath.slice(0, index);
      });

      const autoOpenStructureId = first(
        displayTreeNodesById.get(nodeId)?.structureIds ?? [],
      );
      if (autoOpenStructureId) {
        navigate(`/atlas/structures/${autoOpenStructureId}`);
      }
    },
    [pathsFromRootById, setOpenedIdPath, navigate],
  );

  return (
    <div>
      {nestedNodes.map((node) => (
        <DisplayTreeNavLink
          key={node.node.id}
          node={node}
          openedIdPath={openedIdPath}
          onClick={onNodeClick}
        />
      ))}
    </div>
  );
};
