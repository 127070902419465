import { quizPb } from "@augmedi/proto-gen";
import { Button, Container, Group, Stack, Title } from "@mantine/core";
import { Link, useParams } from "wouter";
import { getUrlForNewQuizSession } from "../logic/quiz-url";
import { bodyPartToInt } from "./utils";
export const QuizBodyPartPage = () => {
  const { regionId } = useParams<{ regionId: string }>();

  const newQuestionContent = (
    <Stack gap="xs">
      <Link
        to={getUrlForNewQuizSession({
          bodyPart: bodyPartToInt[regionId],
          // frozenChapterId: chapter.id,
          mode: quizPb.QuizMode.LEARN_NEW,
        })}
        asChild
      >
        <Button component="a" fullWidth>
          New questions
        </Button>
      </Link>
    </Stack>
  );

  const reviewContent = (
    <Stack gap="xs">
      <Link
        to={getUrlForNewQuizSession({
          bodyPart: bodyPartToInt[regionId],
          //   frozenChapterId: chapter.id,
          mode: quizPb.QuizMode.REPEAT_OLD,
        })}
        asChild
      >
        <Button component="a" fullWidth>
          Review
        </Button>
      </Link>
    </Stack>
  );

  return (
    <Container py="md">
      <Stack>
        <Title>{regionId}</Title>
        <Group gap={50} visibleFrom="sm">
          {newQuestionContent}
          {reviewContent}
        </Group>
        <Group gap={50} justify="center" hiddenFrom="sm">
          {newQuestionContent}
          {reviewContent}
        </Group>
      </Stack>
    </Container>
  );
};
