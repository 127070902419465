export function unreachable(value: never): never {
  throw new Error(`Unreachable (value: ${value})`);
}

export function oneOrUndefined<T>(values: T[]): T | undefined {
  if (values.length === 0) {
    return undefined;
  } else if (values.length === 1) {
    return values[0];
  } else {
    throw new Error(`Expected 0 or 1 values, got ${values.length}`);
  }
}

export function exactlyOne<T>(values: T[]): T {
  if (values.length === 1) {
    return values[0];
  } else {
    throw new Error(`Expected 1 value, got ${values.length}`);
  }
}

export function isTruthy<T>(x: T | false | 0 | "" | null | undefined): x is T {
  return !!x;
}

export function assertDefined<T>(x: T | undefined): T {
  if (x === undefined) {
    throw new Error("Unexpected undefined value");
  }
  return x;
}

export function allValuesFromStringEnum<K extends string>(enumDef: {
  [k in K]: string;
}): K[] {
  const values: K[] = [];
  for (const [k, v] of Object.entries(enumDef)) {
    if (k !== v) {
      throw new Error(
        "Only enums with string values are supported. The key and value for each entry must be identical.",
      );
    }
    values.push(v as K);
  }
  return values;
}

export function intoGroupedMap<I, K, V>(
  inputs: I[],
  keyFromInput: (input: I) => K,
  valueFromInput: (input: I) => V,
): Map<K, V[]> {
  const map = new Map<K, V[]>();
  for (const input of inputs) {
    const key = keyFromInput(input);
    if (!map.has(key)) {
      map.set(key, []);
    }
    assertDefined(map.get(key)).push(valueFromInput(input));
  }
  return map;
}
