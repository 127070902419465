{
  "scenes": {
    "bones": {
      "sideMode": "Unmodified",
      "big": true,
      "initialCameraPosition": {
        "x": -0.0338587515431433,
        "y": 1.389928398683365,
        "z": -1.388170520952082
      },
      "visibleMeshNames": [
        "B_Atlas_C1",
        "B_Axus_C2",
        "B_Calcaneus_L",
        "B_Calcaneus_R",
        "B_Capitate_L",
        "B_Capitate_R",
        "B_Cervical_Disc_C2",
        "B_Cervical_Disc_C3",
        "B_Cervical_Disc_C4",
        "B_Cervical_Disc_C5",
        "B_Cervical_Disc_C6",
        "B_Cervical_Disc_C7",
        "B_Cervical_Vertebra_C3",
        "B_Cervical_Vertebra_C4",
        "B_Cervical_Vertebra_C5",
        "B_Cervical_Vertebra_C6",
        "B_Cervical_Vertebra_C7",
        "B_Clavicle_L",
        "B_Clavicle_R",
        "B_Coccyx",
        "B_Costal_Cartilage_1_L",
        "B_Costal_Cartilage_1_R",
        "B_Costal_Cartilage_2_L",
        "B_Costal_Cartilage_2_R",
        "B_Costal_Cartilage_3_L",
        "B_Costal_Cartilage_3_R",
        "B_Costal_Cartilage_4_L",
        "B_Costal_Cartilage_4_R",
        "B_Costal_Cartilage_5_L",
        "B_Costal_Cartilage_5_R",
        "B_Costal_Cartilage_6_L",
        "B_Costal_Cartilage_6_R",
        "B_Costal_Cartilage_7_L",
        "B_Costal_Cartilage_7_R",
        "B_Costal_Cartilage_8_L",
        "B_Costal_Cartilage_8_R",
        "B_Costal_Cartilage_9_L",
        "B_Costal_Cartilage_9_R",
        "B_Cuboid_L",
        "B_Cuboid_R",
        "B_Distal_Phalanx_1_L",
        "B_Distal_Phalanx_1_R",
        "B_Distal_Phalanx_2_L",
        "B_Distal_Phalanx_2_R",
        "B_Distal_Phalanx_3_L",
        "B_Distal_Phalanx_3_R",
        "B_Distal_Phalanx_4_L",
        "B_Distal_Phalanx_4_R",
        "B_Distal_Phalanx_5_L",
        "B_Distal_Phalanx_5_R",
        "B_Distal_Phalanx_Foot_1_L",
        "B_Distal_Phalanx_Foot_1_R",
        "B_Distal_Phalanx_Foot_2_L",
        "B_Distal_Phalanx_Foot_2_R",
        "B_Distal_Phalanx_Foot_3_L",
        "B_Distal_Phalanx_Foot_3_R",
        "B_Distal_Phalanx_Foot_4_L",
        "B_Distal_Phalanx_Foot_4_R",
        "B_Distal_Phalanx_Foot_5_L",
        "B_Distal_Phalanx_Foot_5_R",
        "B_Ethmoid",
        "B_Femur_L",
        "B_Femur_R",
        "B_Fibula_L",
        "B_Fibula_R",
        "B_Frontal_Bone",
        "B_Hamate_L",
        "B_Hamate_R",
        "B_Hipbone_L",
        "B_Hipbone_R",
        "B_Humerus_L",
        "B_Humerus_R",
        "B_Inferior_Nasal_Concha_Bone_L",
        "B_Inferior_Nasal_Concha_Bone_R",
        "B_Intermediate_Cuneiform_L",
        "B_Intermediate_Cuneiform_R",
        "B_Lacrimal_Bone_L",
        "B_Lacrimal_Bone_R",
        "B_Lateral_Cuneiform_L",
        "B_Lateral_Cuneiform_R",
        "B_Lumbar_Disc_L1",
        "B_Lumbar_Disc_L2",
        "B_Lumbar_Disc_L3",
        "B_Lumbar_Disc_L4",
        "B_Lumbar_Disc_L5",
        "B_Lumbar_Vertebra_L1",
        "B_Lumbar_Vertebra_L2",
        "B_Lumbar_Vertebra_L3",
        "B_Lumbar_Vertebra_L4",
        "B_Lumbar_Vertebra_L5",
        "B_Lunate_L",
        "B_Lunate_R",
        "B_Mandible",
        "B_Maxilla_L",
        "B_Maxilla_R",
        "B_Medial_Cuneiform_Bone_L",
        "B_Medial_Cuneiform_Bone_R",
        "B_Metacarpal_1_L",
        "B_Metacarpal_1_R",
        "B_Metacarpal_2_L",
        "B_Metacarpal_2_R",
        "B_Metacarpal_3_L",
        "B_Metacarpal_3_R",
        "B_Metacarpal_4_L",
        "B_Metacarpal_4_R",
        "B_Metacarpal_5_L",
        "B_Metacarpal_5_R",
        "B_Metatarsal_1_L",
        "B_Metatarsal_1_R",
        "B_Metatarsal_2_L",
        "B_Metatarsal_2_R",
        "B_Metatarsal_3_L",
        "B_Metatarsal_3_R",
        "B_Metatarsal_4_L",
        "B_Metatarsal_4_R",
        "B_Metatarsal_5_L",
        "B_Metatarsal_5_R",
        "B_Middle_Phalanx_2_L",
        "B_Middle_Phalanx_2_R",
        "B_Middle_Phalanx_3_L",
        "B_Middle_Phalanx_3_R",
        "B_Middle_Phalanx_4_L",
        "B_Middle_Phalanx_4_R",
        "B_Middle_Phalanx_5_L",
        "B_Middle_Phalanx_5_R",
        "B_Middle_Phalanx_Foot_2_L",
        "B_Middle_Phalanx_Foot_2_R",
        "B_Middle_Phalanx_Foot_3_L",
        "B_Middle_Phalanx_Foot_3_R",
        "B_Middle_Phalanx_Foot_4_L",
        "B_Middle_Phalanx_Foot_4_R",
        "B_Middle_Phalanx_Foot_5_L",
        "B_Middle_Phalanx_Foot_5_R",
        "B_Nasal_Bone_L",
        "B_Nasal_Bone_R",
        "B_Navicular_Bone_L",
        "B_Navicular_Bone_R",
        "B_Occipital_Sphenoid",
        "B_Palatine_Bone_L",
        "B_Palatine_Bone_R",
        "B_Parietal_Bone_L",
        "B_Parietal_Bone_R",
        "B_Patella_L",
        "B_Patella_R",
        "B_Pisiform_L",
        "B_Pisiform_R",
        "B_Proximal_Phalanx_1_L",
        "B_Proximal_Phalanx_1_R",
        "B_Proximal_Phalanx_2_L",
        "B_Proximal_Phalanx_2_R",
        "B_Proximal_Phalanx_3_L",
        "B_Proximal_Phalanx_3_R",
        "B_Proximal_Phalanx_4_L",
        "B_Proximal_Phalanx_4_R",
        "B_Proximal_Phalanx_5_L",
        "B_Proximal_Phalanx_5_R",
        "B_Proximal_Phalanx_Foot_1_L",
        "B_Proximal_Phalanx_Foot_1_R",
        "B_Proximal_Phalanx_Foot_2_L",
        "B_Proximal_Phalanx_Foot_2_R",
        "B_Proximal_Phalanx_Foot_3_L",
        "B_Proximal_Phalanx_Foot_3_R",
        "B_Proximal_Phalanx_Foot_4_L",
        "B_Proximal_Phalanx_Foot_4_R",
        "B_Proximal_Phalanx_Foot_5_L",
        "B_Proximal_Phalanx_Foot_5_R",
        "B_Radius_L",
        "B_Radius_R",
        "B_Rib_10_L",
        "B_Rib_10_R",
        "B_Rib_11_L",
        "B_Rib_11_R",
        "B_Rib_12_L",
        "B_Rib_12_R",
        "B_Rib_1_L",
        "B_Rib_1_R",
        "B_Rib_2_L",
        "B_Rib_2_R",
        "B_Rib_3_L",
        "B_Rib_3_R",
        "B_Rib_4_L",
        "B_Rib_4_R",
        "B_Rib_5_L",
        "B_Rib_5_R",
        "B_Rib_6_L",
        "B_Rib_6_R",
        "B_Rib_7_L",
        "B_Rib_7_R",
        "B_Rib_8_L",
        "B_Rib_8_R",
        "B_Rib_9_L",
        "B_Rib_9_R",
        "B_Sacrum",
        "B_Scapula_L",
        "B_Scapula_R",
        "B_Sesamoid_Foot_L",
        "B_Sesamoid_Foot_R",
        "B_Sesamoid_hand_L",
        "B_Sesamoid_hand_R",
        "B_Sphenoid_Bone",
        "B_Sternum",
        "B_Styloid_L",
        "B_Styloid_R",
        "B_Talus_L",
        "B_Talus_R",
        "B_Temporal_Bone_L",
        "B_Temporal_Bone_R",
        "B_Temporal_Zygomatic_Bone_L",
        "B_Temporal_Zygomatic_Bone_R",
        "B_Thoracic_Disc_T1",
        "B_Thoracic_Disc_T10",
        "B_Thoracic_Disc_T11",
        "B_Thoracic_Disc_T12",
        "B_Thoracic_Disc_T2",
        "B_Thoracic_Disc_T3",
        "B_Thoracic_Disc_T4",
        "B_Thoracic_Disc_T5",
        "B_Thoracic_Disc_T6",
        "B_Thoracic_Disc_T7",
        "B_Thoracic_Disc_T8",
        "B_Thoracic_Disc_T9",
        "B_Thoracic_Vertebra_T1",
        "B_Thoracic_Vertebra_T10",
        "B_Thoracic_Vertebra_T11",
        "B_Thoracic_Vertebra_T12",
        "B_Thoracic_Vertebra_T2",
        "B_Thoracic_Vertebra_T3",
        "B_Thoracic_Vertebra_T4",
        "B_Thoracic_Vertebra_T5",
        "B_Thoracic_Vertebra_T6",
        "B_Thoracic_Vertebra_T7",
        "B_Thoracic_Vertebra_T8",
        "B_Thoracic_Vertebra_T9",
        "B_Tibia_L",
        "B_Tibia_R",
        "B_Trapezium_L",
        "B_Trapezium_R",
        "B_Trapezoid_L",
        "B_Trapezoid_R",
        "B_Triquetrum_L",
        "B_Triquetrum_R",
        "B_Ulna_L",
        "B_Ulna_R",
        "T_10_Lateral_Incisor",
        "T_11_Cuspid",
        "T_12_First_Bicuspid",
        "T_13_Second_Bicuspid",
        "T_14_First_Molar",
        "T_15_Second_Molar",
        "T_16_Third_Molar",
        "T_17_Third_Molar",
        "T_18_Second_Molar",
        "T_19_First_Molar",
        "T_1_Third_Molar",
        "T_20_Second_Bicuspid",
        "T_21_First_Bicuspid",
        "T_22_Cuspid",
        "T_23_Lateral_Incisor",
        "T_24_Central_Incisor",
        "T_25_Central_Incisor",
        "T_26_Lateral_Incisor",
        "T_27_Cuspid",
        "T_28_First_Bicuspid",
        "T_29_Second_Bicuspid",
        "T_2_Second_Molar",
        "T_30_First_Molar",
        "T_31_Second_Molar",
        "T_32_Third_Molar",
        "T_3_First_Molar",
        "T_4_Second_Bicuspid",
        "T_5_First_Bicuspid",
        "T_6_Cuspid",
        "T_7_Lateral_Incisor",
        "T_8_Central_Incisor",
        "T_9_Central_Incisor",
        "hyoid_bone"
      ],
      "easyMeshNames": [
        "B_Atlas_C1",
        "B_Axus_C2",
        "B_Cervical_Disc_C2",
        "B_Cervical_Disc_C3",
        "B_Cervical_Disc_C4",
        "B_Cervical_Disc_C5",
        "B_Cervical_Disc_C6",
        "B_Cervical_Disc_C7",
        "B_Cervical_Vertebra_C3",
        "B_Cervical_Vertebra_C4",
        "B_Cervical_Vertebra_C5",
        "B_Cervical_Vertebra_C6",
        "B_Cervical_Vertebra_C7",
        "B_Clavicle_L",
        "B_Clavicle_R",
        "B_Coccyx",
        "B_Costal_Cartilage_1_L",
        "B_Costal_Cartilage_1_R",
        "B_Costal_Cartilage_2_L",
        "B_Costal_Cartilage_2_R",
        "B_Costal_Cartilage_3_L",
        "B_Costal_Cartilage_3_R",
        "B_Costal_Cartilage_4_L",
        "B_Costal_Cartilage_4_R",
        "B_Costal_Cartilage_5_L",
        "B_Costal_Cartilage_5_R",
        "B_Costal_Cartilage_6_L",
        "B_Costal_Cartilage_6_R",
        "B_Costal_Cartilage_7_L",
        "B_Costal_Cartilage_7_R",
        "B_Costal_Cartilage_8_L",
        "B_Costal_Cartilage_8_R",
        "B_Costal_Cartilage_9_L",
        "B_Costal_Cartilage_9_R",
        "B_Femur_L",
        "B_Femur_R",
        "B_Fibula_L",
        "B_Fibula_R",
        "B_Frontal_Bone",
        "B_Hipbone_L",
        "B_Hipbone_R",
        "B_Humerus_L",
        "B_Humerus_R",
        "B_Lumbar_Disc_L1",
        "B_Lumbar_Disc_L2",
        "B_Lumbar_Disc_L3",
        "B_Lumbar_Disc_L4",
        "B_Lumbar_Disc_L5",
        "B_Lumbar_Vertebra_L1",
        "B_Lumbar_Vertebra_L2",
        "B_Lumbar_Vertebra_L3",
        "B_Lumbar_Vertebra_L4",
        "B_Lumbar_Vertebra_L5",
        "B_Mandible",
        "B_Maxilla_L",
        "B_Maxilla_R",
        "B_Occipital_Sphenoid",
        "B_Parietal_Bone_L",
        "B_Parietal_Bone_R",
        "B_Patella_L",
        "B_Patella_R",
        "B_Radius_L",
        "B_Radius_R",
        "B_Rib_10_L",
        "B_Rib_10_R",
        "B_Rib_11_L",
        "B_Rib_11_R",
        "B_Rib_12_L",
        "B_Rib_12_R",
        "B_Rib_1_L",
        "B_Rib_1_R",
        "B_Rib_2_L",
        "B_Rib_2_R",
        "B_Rib_3_L",
        "B_Rib_3_R",
        "B_Rib_4_L",
        "B_Rib_4_R",
        "B_Rib_5_L",
        "B_Rib_5_R",
        "B_Rib_6_L",
        "B_Rib_6_R",
        "B_Rib_7_L",
        "B_Rib_7_R",
        "B_Rib_8_L",
        "B_Rib_8_R",
        "B_Rib_9_L",
        "B_Rib_9_R",
        "B_Sacrum",
        "B_Scapula_L",
        "B_Scapula_R",
        "B_Sphenoid_Bone",
        "B_Sternum",
        "B_Styloid_L",
        "B_Styloid_R",
        "B_Temporal_Bone_L",
        "B_Temporal_Bone_R",
        "B_Temporal_Zygomatic_Bone_L",
        "B_Temporal_Zygomatic_Bone_R",
        "B_Thoracic_Disc_T1",
        "B_Thoracic_Disc_T10",
        "B_Thoracic_Disc_T11",
        "B_Thoracic_Disc_T12",
        "B_Thoracic_Disc_T2",
        "B_Thoracic_Disc_T3",
        "B_Thoracic_Disc_T4",
        "B_Thoracic_Disc_T5",
        "B_Thoracic_Disc_T6",
        "B_Thoracic_Disc_T7",
        "B_Thoracic_Disc_T8",
        "B_Thoracic_Disc_T9",
        "B_Thoracic_Vertebra_T1",
        "B_Thoracic_Vertebra_T10",
        "B_Thoracic_Vertebra_T11",
        "B_Thoracic_Vertebra_T12",
        "B_Thoracic_Vertebra_T2",
        "B_Thoracic_Vertebra_T3",
        "B_Thoracic_Vertebra_T4",
        "B_Thoracic_Vertebra_T5",
        "B_Thoracic_Vertebra_T6",
        "B_Thoracic_Vertebra_T7",
        "B_Thoracic_Vertebra_T8",
        "B_Thoracic_Vertebra_T9",
        "B_Tibia_L",
        "B_Tibia_R",
        "B_Ulna_L",
        "B_Ulna_R",
        "hyoid_bone"
      ]
    },
    "cranialBones": {
      "sideMode": "Unmodified",
      "initialCameraPosition": {
        "x": -0.0023897030009242264,
        "y": 1.7512198449658514,
        "z": -0.25084143359407957
      },
      "visibleMeshNames": [
        "B_Ethmoid",
        "B_Frontal_Bone",
        "B_Occipital_Sphenoid",
        "B_Palatine_Bone_L",
        "B_Palatine_Bone_R",
        "B_Parietal_Bone_L",
        "B_Parietal_Bone_R",
        "B_Sphenoid_Bone",
        "B_Temporal_Bone_L",
        "B_Temporal_Bone_R",
        "B_Temporal_Zygomatic_Bone_L",
        "B_Temporal_Zygomatic_Bone_R"
      ],
      "easyMeshNames": [
        "B_Ethmoid",
        "B_Frontal_Bone",
        "B_Occipital_Sphenoid",
        "B_Palatine_Bone_L",
        "B_Palatine_Bone_R",
        "B_Parietal_Bone_L",
        "B_Parietal_Bone_R",
        "B_Sphenoid_Bone",
        "B_Temporal_Bone_L",
        "B_Temporal_Bone_R",
        "B_Temporal_Zygomatic_Bone_L",
        "B_Temporal_Zygomatic_Bone_R"
      ]
    },
    "facialBones": {
      "sideMode": "Unmodified",
      "initialCameraPosition": {
        "x": -0.006563254986956181,
        "y": 1.6474225959633606,
        "z": -0.3316509810994797
      },
      "visibleMeshNames": [
        "B_Inferior_Nasal_Concha_Bone_L",
        "B_Inferior_Nasal_Concha_Bone_R",
        "B_Lacrimal_Bone_L",
        "B_Lacrimal_Bone_R",
        "B_Mandible",
        "B_Maxilla_L",
        "B_Maxilla_R",
        "B_Nasal_Bone_L",
        "B_Nasal_Bone_R",
        "B_Palatine_Bone_L",
        "B_Palatine_Bone_R",
        "B_Temporal_Zygomatic_Bone_L",
        "B_Temporal_Zygomatic_Bone_R",
        "T_10_Lateral_Incisor",
        "T_11_Cuspid",
        "T_12_First_Bicuspid",
        "T_13_Second_Bicuspid",
        "T_14_First_Molar",
        "T_15_Second_Molar",
        "T_16_Third_Molar",
        "T_17_Third_Molar",
        "T_18_Second_Molar",
        "T_19_First_Molar",
        "T_1_Third_Molar",
        "T_20_Second_Bicuspid",
        "T_21_First_Bicuspid",
        "T_22_Cuspid",
        "T_23_Lateral_Incisor",
        "T_24_Central_Incisor",
        "T_25_Central_Incisor",
        "T_26_Lateral_Incisor",
        "T_27_Cuspid",
        "T_28_First_Bicuspid",
        "T_29_Second_Bicuspid",
        "T_2_Second_Molar",
        "T_30_First_Molar",
        "T_31_Second_Molar",
        "T_32_Third_Molar",
        "T_3_First_Molar",
        "T_4_Second_Bicuspid",
        "T_5_First_Bicuspid",
        "T_6_Cuspid",
        "T_7_Lateral_Incisor",
        "T_8_Central_Incisor",
        "T_9_Central_Incisor"
      ],
      "easyMeshNames": [
        "B_Inferior_Nasal_Concha_Bone_L",
        "B_Inferior_Nasal_Concha_Bone_R",
        "B_Lacrimal_Bone_L",
        "B_Lacrimal_Bone_R",
        "B_Mandible",
        "B_Maxilla_L",
        "B_Maxilla_R",
        "B_Nasal_Bone_L",
        "B_Nasal_Bone_R",
        "B_Palatine_Bone_L",
        "B_Palatine_Bone_R",
        "B_Temporal_Zygomatic_Bone_L",
        "B_Temporal_Zygomatic_Bone_R"
      ]
    },
    "bonesLeg": {
      "sideMode": "AutoMirrorAndSeparate",
      "initialCameraPosition": {
        "x": 0.03213850107095294,
        "y": 0.7863370400235963,
        "z": -0.832812306343761
      },
      "visibleMeshNames": [
        "B_Calcaneus_R",
        "B_Cuboid_R",
        "B_Distal_Phalanx_Foot_1_R",
        "B_Distal_Phalanx_Foot_2_R",
        "B_Distal_Phalanx_Foot_3_R",
        "B_Distal_Phalanx_Foot_4_R",
        "B_Distal_Phalanx_Foot_5_R",
        "B_Femur_R",
        "B_Fibula_R",
        "B_Hipbone_R",
        "B_Intermediate_Cuneiform_R",
        "B_Lateral_Cuneiform_R",
        "B_Medial_Cuneiform_Bone_R",
        "B_Metatarsal_1_R",
        "B_Metatarsal_2_R",
        "B_Metatarsal_3_R",
        "B_Metatarsal_4_R",
        "B_Metatarsal_5_R",
        "B_Middle_Phalanx_Foot_2_R",
        "B_Middle_Phalanx_Foot_3_R",
        "B_Middle_Phalanx_Foot_4_R",
        "B_Middle_Phalanx_Foot_5_R",
        "B_Navicular_Bone_R",
        "B_Patella_R",
        "B_Proximal_Phalanx_Foot_1_R",
        "B_Proximal_Phalanx_Foot_2_R",
        "B_Proximal_Phalanx_Foot_3_R",
        "B_Proximal_Phalanx_Foot_4_R",
        "B_Proximal_Phalanx_Foot_5_R",
        "B_Sesamoid_Foot_R",
        "B_Talus_R",
        "B_Tibia_R"
      ],
      "easyMeshNames": [
        "B_Femur_R",
        "B_Fibula_R",
        "B_Hipbone_R",
        "B_Patella_R",
        "B_Tibia_R"
      ]
    },
    "bonesFoot": {
      "sideMode": "AutoMirrorAndSeparate",
      "initialCameraPosition": {
        "x": 0.09501327729235018,
        "y": 0.3557782124666297,
        "z": -0.051474499597238425
      },
      "visibleMeshNames": [
        "B_Calcaneus_R",
        "B_Cuboid_R",
        "B_Distal_Phalanx_Foot_1_R",
        "B_Distal_Phalanx_Foot_2_R",
        "B_Distal_Phalanx_Foot_3_R",
        "B_Distal_Phalanx_Foot_4_R",
        "B_Distal_Phalanx_Foot_5_R",
        "B_Intermediate_Cuneiform_R",
        "B_Lateral_Cuneiform_R",
        "B_Medial_Cuneiform_Bone_R",
        "B_Metatarsal_1_R",
        "B_Metatarsal_2_R",
        "B_Metatarsal_3_R",
        "B_Metatarsal_4_R",
        "B_Metatarsal_5_R",
        "B_Middle_Phalanx_Foot_2_R",
        "B_Middle_Phalanx_Foot_3_R",
        "B_Middle_Phalanx_Foot_4_R",
        "B_Middle_Phalanx_Foot_5_R",
        "B_Navicular_Bone_R",
        "B_Proximal_Phalanx_Foot_1_R",
        "B_Proximal_Phalanx_Foot_2_R",
        "B_Proximal_Phalanx_Foot_3_R",
        "B_Proximal_Phalanx_Foot_4_R",
        "B_Proximal_Phalanx_Foot_5_R",
        "B_Sesamoid_Foot_R",
        "B_Talus_R"
      ],
      "easyMeshNames": [
        "B_Calcaneus_R",
        "B_Cuboid_R",
        "B_Distal_Phalanx_Foot_1_R",
        "B_Distal_Phalanx_Foot_2_R",
        "B_Distal_Phalanx_Foot_3_R",
        "B_Distal_Phalanx_Foot_4_R",
        "B_Distal_Phalanx_Foot_5_R",
        "B_Intermediate_Cuneiform_R",
        "B_Lateral_Cuneiform_R",
        "B_Medial_Cuneiform_Bone_R",
        "B_Metatarsal_1_R",
        "B_Metatarsal_2_R",
        "B_Metatarsal_3_R",
        "B_Metatarsal_4_R",
        "B_Metatarsal_5_R",
        "B_Middle_Phalanx_Foot_2_R",
        "B_Middle_Phalanx_Foot_3_R",
        "B_Middle_Phalanx_Foot_4_R",
        "B_Middle_Phalanx_Foot_5_R",
        "B_Navicular_Bone_R",
        "B_Proximal_Phalanx_Foot_1_R",
        "B_Proximal_Phalanx_Foot_2_R",
        "B_Proximal_Phalanx_Foot_3_R",
        "B_Proximal_Phalanx_Foot_4_R",
        "B_Proximal_Phalanx_Foot_5_R",
        "B_Sesamoid_Foot_R",
        "B_Talus_R"
      ]
    },
    "bonesArm": {
      "sideMode": "AutoMirrorAndSeparate",
      "initialCameraPosition": {
        "x": -0.3155669663434312,
        "y": 1.2811797393948752,
        "z": -0.5051501049901178
      },
      "visibleMeshNames": [
        "B_Capitate_R",
        "B_Clavicle_R",
        "B_Distal_Phalanx_1_R",
        "B_Distal_Phalanx_2_R",
        "B_Distal_Phalanx_3_R",
        "B_Distal_Phalanx_4_R",
        "B_Distal_Phalanx_5_R",
        "B_Hamate_R",
        "B_Humerus_R",
        "B_Lunate_R",
        "B_Metacarpal_1_R",
        "B_Metacarpal_2_R",
        "B_Metacarpal_3_R",
        "B_Metacarpal_4_R",
        "B_Metacarpal_5_R",
        "B_Middle_Phalanx_2_R",
        "B_Middle_Phalanx_3_R",
        "B_Middle_Phalanx_4_R",
        "B_Middle_Phalanx_5_R",
        "B_Pisiform_R",
        "B_Proximal_Phalanx_1_R",
        "B_Proximal_Phalanx_2_R",
        "B_Proximal_Phalanx_3_R",
        "B_Proximal_Phalanx_4_R",
        "B_Proximal_Phalanx_5_R",
        "B_Radius_R",
        "B_Scapula_R",
        "B_Sesamoid_hand_R",
        "B_Styloid_R",
        "B_Trapezium_R",
        "B_Trapezoid_R",
        "B_Triquetrum_R",
        "B_Ulna_R"
      ],
      "easyMeshNames": ["B_Clavicle_R", "B_Radius_R", "B_Scapula_R", "B_Ulna_R"]
    },
    "bonesHand": {
      "sideMode": "AutoMirrorAndSeparate",
      "initialCameraPosition": {
        "x": 0.488543152695174,
        "y": 0.8101844067639892,
        "z": 0.2031825197138516
      },
      "visibleMeshNames": [
        "B_Capitate_R",
        "B_Distal_Phalanx_1_R",
        "B_Distal_Phalanx_2_R",
        "B_Distal_Phalanx_3_R",
        "B_Distal_Phalanx_4_R",
        "B_Distal_Phalanx_5_R",
        "B_Hamate_R",
        "B_Lunate_R",
        "B_Metacarpal_1_R",
        "B_Metacarpal_2_R",
        "B_Metacarpal_3_R",
        "B_Metacarpal_4_R",
        "B_Metacarpal_5_R",
        "B_Middle_Phalanx_2_R",
        "B_Middle_Phalanx_3_R",
        "B_Middle_Phalanx_4_R",
        "B_Middle_Phalanx_5_R",
        "B_Pisiform_R",
        "B_Proximal_Phalanx_1_R",
        "B_Proximal_Phalanx_2_R",
        "B_Proximal_Phalanx_3_R",
        "B_Proximal_Phalanx_4_R",
        "B_Proximal_Phalanx_5_R",
        "B_Sesamoid_hand_R",
        "B_Styloid_R",
        "B_Trapezium_R",
        "B_Trapezoid_R",
        "B_Triquetrum_R"
      ],
      "easyMeshNames": [
        "B_Capitate_R",
        "B_Distal_Phalanx_1_R",
        "B_Distal_Phalanx_2_R",
        "B_Distal_Phalanx_3_R",
        "B_Distal_Phalanx_4_R",
        "B_Distal_Phalanx_5_R",
        "B_Hamate_R",
        "B_Lunate_R",
        "B_Metacarpal_1_R",
        "B_Metacarpal_2_R",
        "B_Metacarpal_3_R",
        "B_Metacarpal_4_R",
        "B_Metacarpal_5_R",
        "B_Middle_Phalanx_2_R",
        "B_Middle_Phalanx_3_R",
        "B_Middle_Phalanx_4_R",
        "B_Middle_Phalanx_5_R",
        "B_Pisiform_R",
        "B_Proximal_Phalanx_1_R",
        "B_Proximal_Phalanx_2_R",
        "B_Proximal_Phalanx_3_R",
        "B_Proximal_Phalanx_4_R",
        "B_Proximal_Phalanx_5_R",
        "B_Sesamoid_hand_R",
        "B_Styloid_R",
        "B_Trapezium_R",
        "B_Trapezoid_R",
        "B_Triquetrum_R"
      ]
    },
    "musclesNervesLeg1": {
      "sideMode": "AutoMirrorAndSeparate",
      "initialCameraPosition": {
        "x": 0.03154461742951034,
        "y": 0.7124135489895463,
        "z": -1.110720550019666
      },
      "visibleMeshNames": [
        "B_Calcaneus_R",
        "B_Cuboid_R",
        "B_Distal_Phalanx_Foot_1_R",
        "B_Distal_Phalanx_Foot_2_R",
        "B_Distal_Phalanx_Foot_3_R",
        "B_Distal_Phalanx_Foot_4_R",
        "B_Distal_Phalanx_Foot_5_R",
        "B_Femur_R",
        "B_Fibula_R",
        "B_Hipbone_R",
        "B_Intermediate_Cuneiform_R",
        "B_Lateral_Cuneiform_R",
        "B_Medial_Cuneiform_Bone_R",
        "B_Metatarsal_1_R",
        "B_Metatarsal_2_R",
        "B_Metatarsal_3_R",
        "B_Metatarsal_4_R",
        "B_Metatarsal_5_R",
        "B_Middle_Phalanx_Foot_2_R",
        "B_Middle_Phalanx_Foot_3_R",
        "B_Middle_Phalanx_Foot_4_R",
        "B_Middle_Phalanx_Foot_5_R",
        "B_Navicular_Bone_R",
        "B_Patella_R",
        "B_Proximal_Phalanx_Foot_1_R",
        "B_Proximal_Phalanx_Foot_2_R",
        "B_Proximal_Phalanx_Foot_3_R",
        "B_Proximal_Phalanx_Foot_4_R",
        "B_Proximal_Phalanx_Foot_5_R",
        "B_Sesamoid_Foot_R",
        "B_Talus_R",
        "B_Tibia_R",
        "M_Abductor_Digiti_Minimi_R",
        "M_Abductor_Hallucis_R",
        "M_Achilles_Tendon_R",
        "M_Adductor_Brevis_R",
        "M_Adductor_Hallucis_Oblique_Head_R",
        "M_Adductor_Hallucis_Transverse_Head_R",
        "M_Adductor_Longus_R",
        "M_Adductor_Magnus_R",
        "M_Aductor_Digit_Minimi_R",
        "M_Articularis_Genus_R",
        "M_Biceps_Femoris_Long_Head_R",
        "M_Biceps_Femoris_Short_Head_R",
        "M_Dorsal_Interossei_R",
        "M_Extensor_Digitorum_Brevis_R",
        "M_Extensor_Digitorum_Longus_R",
        "M_Extensor_Hallucis_Brevis_R",
        "M_Extensor_Hallucis_Longus_R",
        "M_External_Anal_Sphincter",
        "M_Fibularis_Brevis_R",
        "M_Fibularis_Longubs_R",
        "M_Fibularis_Tertius_R",
        "M_Flexor_Digiti_Minimi_Brevis_R",
        "M_Flexor_Digitorum_Brevis_R",
        "M_Flexor_Digitorum_Longus_R",
        "M_Flexor_Hallucis_Brevis_R",
        "M_Flexor_Hallucis_Longus_R",
        "M_Gastrocnemius_Lateral_R",
        "M_Gastrocnemius_Medial_R",
        "M_Gemellus_Interior_R",
        "M_Gemellus_Superior_R",
        "M_Gluteus_Maximus_R",
        "M_Gluteus_Medius_R",
        "M_Gluteus_Minimus_R",
        "M_Gracilis_R",
        "M_Iliacus_R",
        "M_Iliococcygeus_R",
        "M_Iliotibial_Tract_R",
        "M_Internal_anal_Sphincter",
        "M_Interossei_Plantar_R",
        "M_Lumbricals_R",
        "M_Obturator_Externus_R",
        "M_Obturator_Internus_R",
        "M_Opponens_Digiti_Minimi_R",
        "M_Pantar_Aponeurosis_R",
        "M_Pectineus_R",
        "M_Perineal_Membrane",
        "M_Piriformis_R",
        "M_Plantar_Aponeurosis_Lateral_Band_R",
        "M_Plantaris_R",
        "M_Popliteus_R",
        "M_Psoas_Major_R",
        "M_Psoas_Minor_R",
        "M_Pubococcygeus",
        "M_Puborectalis",
        "M_Quadratus_Femoris_R",
        "M_Quadratus_Plantae_R",
        "M_Rectus_Femoris_R",
        "M_Sartorius_R",
        "M_Semimembranosus_R",
        "M_Semitendinosus_R",
        "M_Soleus_R",
        "M_Tendinous_Arch_of_Levator_Ani_R",
        "M_Tibialis_Anterior_R",
        "M_Tibialis_Posterior_R",
        "M_Transverse_Perineal",
        "M_Vastus_Intermedius_R",
        "M_Vastus_Lateralis_R",
        "M_Vastus_Medialis_R",
        "Articular_Branch_of_Femoral_Nerve_R",
        "Articular_Branches_to_Superior_Tibiofibular_Joint_R",
        "Common_Peroneal_Nerve_R",
        "Deep_Peroneal_Nerve_R",
        "Femoral_Nerve_R",
        "Genitofemoral_Nerve_R",
        "Iliohypogastric_Nerve_R",
        "Ilioinguinal_Nerve_R",
        "Inferior_Cluneal_Nerve_R",
        "Inferior_Gluteal_Nerve_R",
        "Inferolateral_Genicular_Nerve_R",
        "Inferomedial_Genicular_Nerve_R",
        "Infrapatellar_Branch_of_Saphenous_Nerve_R",
        "L1_Spinal_Nerve_R",
        "Lateral_Cutaneous_Nerve_of_Thigh_R",
        "Lateral_Plantar_Nerve_R",
        "Medial_Plantar_Nerve_R",
        "Middle_Cluneal_Nerves_L",
        "Middle_Cluneal_Nerves_R",
        "Middle_Genicular_Nerve_R",
        "Nerve_to_Levator_Ani_and_Coccygeus_Muscle_R",
        "Nerve_to_Major_and_Minor_Psoas_R",
        "Nerve_to_Obturator_Internus_and_Superior_Gemellus_R",
        "Nerve_to_Piriformis_R",
        "Nerve_to_Quadratus_Femoris_and_Inferior_Gemellus_R",
        "Obturator_Nerve_R",
        "Perforating_Cutaneous_Nerve_R",
        "Posterior_Femoral_Cutaneous_Nerve_R",
        "Pudendal_Nerve_R",
        "Recurrent_Genicular_Nerve_R",
        "Saphenous_Nerve_R",
        "Sciatic_Nerve_R",
        "Subcostal_Nerve_R",
        "Superficial_Peroneal_Nerve_R",
        "Superior_Cluneal_Nerves_L",
        "Superior_Cluneal_Nerves_R",
        "Superior_Gluteal_Nerve_R",
        "Superolateral_Genicular_Nerve_R",
        "Superomedial_Genicular_Nerve_R",
        "Sural_Nerve_R",
        "T12_Spinal_Nerve_R",
        "Tibial_Nerve_R"
      ],
      "easyMeshNames": [
        "B_Hipbone_R",
        "B_Patella_R",
        "B_Tibia_R",
        "M_Abductor_Digiti_Minimi_R",
        "M_Abductor_Hallucis_R",
        "M_Achilles_Tendon_R",
        "M_Adductor_Longus_R",
        "M_Adductor_Magnus_R",
        "M_Aductor_Digit_Minimi_R",
        "M_Biceps_Femoris_Long_Head_R",
        "M_Biceps_Femoris_Short_Head_R",
        "M_Extensor_Digitorum_Longus_R",
        "M_Extensor_Hallucis_Longus_R",
        "M_Fibularis_Brevis_R",
        "M_Fibularis_Longubs_R",
        "M_Gastrocnemius_Lateral_R",
        "M_Gastrocnemius_Medial_R",
        "M_Gluteus_Maximus_R",
        "M_Gluteus_Medius_R",
        "M_Gracilis_R",
        "M_Iliacus_R",
        "M_Iliotibial_Tract_R",
        "M_Pantar_Aponeurosis_R",
        "M_Pectineus_R",
        "M_Plantar_Aponeurosis_Lateral_Band_R",
        "M_Psoas_Major_R",
        "M_Psoas_Minor_R",
        "M_Rectus_Femoris_R",
        "M_Sartorius_R",
        "M_Semimembranosus_R",
        "M_Semitendinosus_R",
        "M_Soleus_R",
        "M_Tibialis_Anterior_R",
        "M_Vastus_Lateralis_R",
        "M_Vastus_Medialis_R",
        "Common_Peroneal_Nerve_R",
        "Deep_Peroneal_Nerve_R",
        "Femoral_Nerve_R",
        "Genitofemoral_Nerve_R",
        "Iliohypogastric_Nerve_R",
        "Ilioinguinal_Nerve_R",
        "Infrapatellar_Branch_of_Saphenous_Nerve_R",
        "L1_Spinal_Nerve_R",
        "Lateral_Cutaneous_Nerve_of_Thigh_R",
        "Medial_Plantar_Nerve_R",
        "Middle_Cluneal_Nerves_L",
        "Middle_Cluneal_Nerves_R",
        "Nerve_to_Levator_Ani_and_Coccygeus_Muscle_R",
        "Nerve_to_Major_and_Minor_Psoas_R",
        "Nerve_to_Obturator_Internus_and_Superior_Gemellus_R",
        "Obturator_Nerve_R",
        "Perforating_Cutaneous_Nerve_R",
        "Posterior_Femoral_Cutaneous_Nerve_R",
        "Recurrent_Genicular_Nerve_R",
        "Saphenous_Nerve_R",
        "Subcostal_Nerve_R",
        "Superficial_Peroneal_Nerve_R",
        "Superior_Cluneal_Nerves_L",
        "Superior_Cluneal_Nerves_R",
        "Sural_Nerve_R",
        "T12_Spinal_Nerve_R",
        "Tibial_Nerve_R"
      ]
    },
    "musclesNervesLeg2": {
      "sideMode": "AutoMirrorAndSeparate",
      "initialCameraPosition": {
        "x": -0.000315079600314655,
        "y": 0.5099220428234629,
        "z": -1.1065168026112713
      },
      "visibleMeshNames": [
        "B_Calcaneus_R",
        "B_Cuboid_R",
        "B_Distal_Phalanx_Foot_1_R",
        "B_Distal_Phalanx_Foot_2_R",
        "B_Distal_Phalanx_Foot_3_R",
        "B_Distal_Phalanx_Foot_4_R",
        "B_Distal_Phalanx_Foot_5_R",
        "B_Femur_R",
        "B_Fibula_R",
        "B_Hipbone_R",
        "B_Intermediate_Cuneiform_R",
        "B_Lateral_Cuneiform_R",
        "B_Medial_Cuneiform_Bone_R",
        "B_Metatarsal_1_R",
        "B_Metatarsal_2_R",
        "B_Metatarsal_3_R",
        "B_Metatarsal_4_R",
        "B_Metatarsal_5_R",
        "B_Middle_Phalanx_Foot_2_R",
        "B_Middle_Phalanx_Foot_3_R",
        "B_Middle_Phalanx_Foot_4_R",
        "B_Middle_Phalanx_Foot_5_R",
        "B_Navicular_Bone_R",
        "B_Patella_R",
        "B_Proximal_Phalanx_Foot_1_R",
        "B_Proximal_Phalanx_Foot_2_R",
        "B_Proximal_Phalanx_Foot_3_R",
        "B_Proximal_Phalanx_Foot_4_R",
        "B_Proximal_Phalanx_Foot_5_R",
        "B_Sesamoid_Foot_R",
        "B_Talus_R",
        "B_Tibia_R",
        "M_Abductor_Digiti_Minimi_R",
        "M_Abductor_Hallucis_R",
        "M_Adductor_Brevis_R",
        "M_Adductor_Hallucis_Oblique_Head_R",
        "M_Adductor_Hallucis_Transverse_Head_R",
        "M_Adductor_Longus_R",
        "M_Adductor_Magnus_R",
        "M_Aductor_Digit_Minimi_R",
        "M_Articularis_Genus_R",
        "M_Biceps_Femoris_Short_Head_R",
        "M_Dorsal_Interossei_R",
        "M_Extensor_Digitorum_Brevis_R",
        "M_Extensor_Digitorum_Longus_R",
        "M_Extensor_Hallucis_Brevis_R",
        "M_Extensor_Hallucis_Longus_R",
        "M_External_Anal_Sphincter",
        "M_Fibularis_Brevis_R",
        "M_Fibularis_Longubs_R",
        "M_Fibularis_Tertius_R",
        "M_Flexor_Digiti_Minimi_Brevis_R",
        "M_Flexor_Digitorum_Brevis_R",
        "M_Flexor_Digitorum_Longus_R",
        "M_Flexor_Hallucis_Brevis_R",
        "M_Flexor_Hallucis_Longus_R",
        "M_Gemellus_Interior_R",
        "M_Gemellus_Superior_R",
        "M_Gluteus_Medius_R",
        "M_Gluteus_Minimus_R",
        "M_Gracilis_R",
        "M_Iliacus_R",
        "M_Iliococcygeus_R",
        "M_Internal_anal_Sphincter",
        "M_Interossei_Plantar_R",
        "M_Lumbricals_R",
        "M_Obturator_Externus_R",
        "M_Obturator_Internus_R",
        "M_Opponens_Digiti_Minimi_R",
        "M_Pantar_Aponeurosis_R",
        "M_Pectineus_R",
        "M_Perineal_Membrane",
        "M_Piriformis_R",
        "M_Plantar_Aponeurosis_Lateral_Band_R",
        "M_Plantaris_R",
        "M_Popliteus_R",
        "M_Pubococcygeus",
        "M_Puborectalis",
        "M_Quadratus_Femoris_R",
        "M_Quadratus_Plantae_R",
        "M_Semimembranosus_R",
        "M_Soleus_R",
        "M_Tendinous_Arch_of_Levator_Ani_R",
        "M_Tibialis_Anterior_R",
        "M_Tibialis_Posterior_R",
        "M_Transverse_Perineal",
        "M_Vastus_Intermedius_R",
        "M_Vastus_Lateralis_R",
        "M_Vastus_Medialis_R",
        "Articular_Branch_of_Femoral_Nerve_R",
        "Articular_Branches_to_Superior_Tibiofibular_Joint_R",
        "Deep_Peroneal_Nerve_R",
        "Femoral_Nerve_R",
        "Genitofemoral_Nerve_R",
        "Iliohypogastric_Nerve_R",
        "Ilioinguinal_Nerve_R",
        "Inferior_Cluneal_Nerve_R",
        "Inferior_Gluteal_Nerve_R",
        "Inferolateral_Genicular_Nerve_R",
        "Inferomedial_Genicular_Nerve_R",
        "Infrapatellar_Branch_of_Saphenous_Nerve_R",
        "L1_Spinal_Nerve_R",
        "Lateral_Cutaneous_Nerve_of_Thigh_R",
        "Lateral_Plantar_Nerve_R",
        "Medial_Plantar_Nerve_R",
        "Middle_Cluneal_Nerves_L",
        "Middle_Cluneal_Nerves_R",
        "Middle_Genicular_Nerve_R",
        "Nerve_to_Levator_Ani_and_Coccygeus_Muscle_R",
        "Nerve_to_Major_and_Minor_Psoas_R",
        "Nerve_to_Obturator_Internus_and_Superior_Gemellus_R",
        "Nerve_to_Piriformis_R",
        "Nerve_to_Quadratus_Femoris_and_Inferior_Gemellus_R",
        "Obturator_Nerve_R",
        "Perforating_Cutaneous_Nerve_R",
        "Pudendal_Nerve_R",
        "Recurrent_Genicular_Nerve_R",
        "Saphenous_Nerve_R",
        "Sciatic_Nerve_R",
        "Superficial_Peroneal_Nerve_R",
        "Superior_Gluteal_Nerve_R",
        "Superolateral_Genicular_Nerve_R",
        "Superomedial_Genicular_Nerve_R",
        "Sural_Nerve_R",
        "T12_Spinal_Nerve_R",
        "Tibial_Nerve_R"
      ],
      "easyMeshNames": [
        "B_Hipbone_R",
        "B_Patella_R",
        "B_Tibia_R",
        "M_Abductor_Digiti_Minimi_R",
        "M_Abductor_Hallucis_R",
        "M_Adductor_Longus_R",
        "M_Adductor_Magnus_R",
        "M_Aductor_Digit_Minimi_R",
        "M_Biceps_Femoris_Short_Head_R",
        "M_Extensor_Digitorum_Longus_R",
        "M_Extensor_Hallucis_Longus_R",
        "M_Fibularis_Brevis_R",
        "M_Fibularis_Longubs_R",
        "M_Gemellus_Interior_R",
        "M_Gemellus_Superior_R",
        "M_Gluteus_Medius_R",
        "M_Gracilis_R",
        "M_Iliacus_R",
        "M_Obturator_Internus_R",
        "M_Pantar_Aponeurosis_R",
        "M_Pectineus_R",
        "M_Piriformis_R",
        "M_Plantar_Aponeurosis_Lateral_Band_R",
        "M_Plantaris_R",
        "M_Popliteus_R",
        "M_Quadratus_Femoris_R",
        "M_Semimembranosus_R",
        "M_Soleus_R",
        "M_Tibialis_Anterior_R",
        "M_Vastus_Intermedius_R",
        "M_Vastus_Lateralis_R",
        "M_Vastus_Medialis_R",
        "Articular_Branch_of_Femoral_Nerve_R",
        "Deep_Peroneal_Nerve_R",
        "Femoral_Nerve_R",
        "Genitofemoral_Nerve_R",
        "Iliohypogastric_Nerve_R",
        "Ilioinguinal_Nerve_R",
        "Inferior_Cluneal_Nerve_R",
        "Inferior_Gluteal_Nerve_R",
        "Infrapatellar_Branch_of_Saphenous_Nerve_R",
        "L1_Spinal_Nerve_R",
        "Lateral_Cutaneous_Nerve_of_Thigh_R",
        "Medial_Plantar_Nerve_R",
        "Middle_Cluneal_Nerves_L",
        "Middle_Cluneal_Nerves_R",
        "Nerve_to_Levator_Ani_and_Coccygeus_Muscle_R",
        "Nerve_to_Major_and_Minor_Psoas_R",
        "Nerve_to_Obturator_Internus_and_Superior_Gemellus_R",
        "Obturator_Nerve_R",
        "Perforating_Cutaneous_Nerve_R",
        "Pudendal_Nerve_R",
        "Recurrent_Genicular_Nerve_R",
        "Saphenous_Nerve_R",
        "Sciatic_Nerve_R",
        "Superficial_Peroneal_Nerve_R",
        "Sural_Nerve_R",
        "T12_Spinal_Nerve_R",
        "Tibial_Nerve_R"
      ]
    },
    "musclesNervesLeg3": {
      "sideMode": "AutoMirrorAndSeparate",
      "initialCameraPosition": {
        "x": 0.1421317746557797,
        "y": 0.17912171946132438,
        "z": -1.0122786777797035
      },
      "visibleMeshNames": [
        "B_Calcaneus_R",
        "B_Cuboid_R",
        "B_Distal_Phalanx_Foot_1_R",
        "B_Distal_Phalanx_Foot_2_R",
        "B_Distal_Phalanx_Foot_3_R",
        "B_Distal_Phalanx_Foot_4_R",
        "B_Distal_Phalanx_Foot_5_R",
        "B_Femur_R",
        "B_Fibula_R",
        "B_Hipbone_R",
        "B_Intermediate_Cuneiform_R",
        "B_Lateral_Cuneiform_R",
        "B_Medial_Cuneiform_Bone_R",
        "B_Metatarsal_1_R",
        "B_Metatarsal_2_R",
        "B_Metatarsal_3_R",
        "B_Metatarsal_4_R",
        "B_Metatarsal_5_R",
        "B_Middle_Phalanx_Foot_2_R",
        "B_Middle_Phalanx_Foot_3_R",
        "B_Middle_Phalanx_Foot_4_R",
        "B_Middle_Phalanx_Foot_5_R",
        "B_Navicular_Bone_R",
        "B_Patella_R",
        "B_Proximal_Phalanx_Foot_1_R",
        "B_Proximal_Phalanx_Foot_2_R",
        "B_Proximal_Phalanx_Foot_3_R",
        "B_Proximal_Phalanx_Foot_4_R",
        "B_Proximal_Phalanx_Foot_5_R",
        "B_Sesamoid_Foot_R",
        "B_Talus_R",
        "B_Tibia_R",
        "M_Abductor_Digiti_Minimi_R",
        "M_Abductor_Hallucis_R",
        "M_Adductor_Brevis_R",
        "M_Adductor_Hallucis_Oblique_Head_R",
        "M_Adductor_Hallucis_Transverse_Head_R",
        "M_Adductor_Longus_R",
        "M_Adductor_Magnus_R",
        "M_Aductor_Digit_Minimi_R",
        "M_Articularis_Genus_R",
        "M_Biceps_Femoris_Short_Head_R",
        "M_Dorsal_Interossei_R",
        "M_Extensor_Digitorum_Brevis_R",
        "M_Extensor_Digitorum_Longus_R",
        "M_Extensor_Hallucis_Brevis_R",
        "M_Extensor_Hallucis_Longus_R",
        "M_External_Anal_Sphincter",
        "M_Fibularis_Brevis_R",
        "M_Fibularis_Longubs_R",
        "M_Fibularis_Tertius_R",
        "M_Flexor_Digiti_Minimi_Brevis_R",
        "M_Flexor_Digitorum_Brevis_R",
        "M_Flexor_Digitorum_Longus_R",
        "M_Flexor_Hallucis_Brevis_R",
        "M_Flexor_Hallucis_Longus_R",
        "M_Gemellus_Interior_R",
        "M_Gemellus_Superior_R",
        "M_Gluteus_Minimus_R",
        "M_Gracilis_R",
        "M_Iliococcygeus_R",
        "M_Internal_anal_Sphincter",
        "M_Interossei_Plantar_R",
        "M_Lumbricals_R",
        "M_Obturator_Externus_R",
        "M_Obturator_Internus_R",
        "M_Opponens_Digiti_Minimi_R",
        "M_Pectineus_R",
        "M_Perineal_Membrane",
        "M_Piriformis_R",
        "M_Plantar_Aponeurosis_Lateral_Band_R",
        "M_Popliteus_R",
        "M_Pubococcygeus",
        "M_Puborectalis",
        "M_Quadratus_Femoris_R",
        "M_Quadratus_Plantae_R",
        "M_Semimembranosus_R",
        "M_Tendinous_Arch_of_Levator_Ani_R",
        "M_Tibialis_Anterior_R",
        "M_Tibialis_Posterior_R",
        "M_Transverse_Perineal",
        "M_Vastus_Intermedius_R",
        "M_Vastus_Medialis_R",
        "Articular_Branch_of_Femoral_Nerve_R",
        "Articular_Branches_to_Superior_Tibiofibular_Joint_R",
        "Deep_Peroneal_Nerve_R",
        "Femoral_Nerve_R",
        "Inferior_Gluteal_Nerve_R",
        "Inferolateral_Genicular_Nerve_R",
        "Inferomedial_Genicular_Nerve_R",
        "Infrapatellar_Branch_of_Saphenous_Nerve_R",
        "L1_Spinal_Nerve_R",
        "Lateral_Plantar_Nerve_R",
        "Medial_Plantar_Nerve_R",
        "Middle_Cluneal_Nerves_L",
        "Middle_Cluneal_Nerves_R",
        "Middle_Genicular_Nerve_R",
        "Nerve_to_Major_and_Minor_Psoas_R",
        "Nerve_to_Obturator_Internus_and_Superior_Gemellus_R",
        "Nerve_to_Piriformis_R",
        "Nerve_to_Quadratus_Femoris_and_Inferior_Gemellus_R",
        "Obturator_Nerve_R",
        "Pudendal_Nerve_R",
        "Recurrent_Genicular_Nerve_R",
        "Saphenous_Nerve_R",
        "Sciatic_Nerve_R",
        "Superficial_Peroneal_Nerve_R",
        "Superior_Gluteal_Nerve_R",
        "Superolateral_Genicular_Nerve_R",
        "Superomedial_Genicular_Nerve_R",
        "T12_Spinal_Nerve_R"
      ],
      "easyMeshNames": [
        "B_Fibula_R",
        "B_Hipbone_R",
        "B_Patella_R",
        "B_Tibia_R",
        "M_Abductor_Digiti_Minimi_R",
        "M_Abductor_Hallucis_R",
        "M_Adductor_Longus_R",
        "M_Adductor_Magnus_R",
        "M_Aductor_Digit_Minimi_R",
        "M_Biceps_Femoris_Short_Head_R",
        "M_Extensor_Digitorum_Longus_R",
        "M_Extensor_Hallucis_Longus_R",
        "M_Fibularis_Brevis_R",
        "M_Fibularis_Longubs_R",
        "M_Flexor_Digitorum_Brevis_R",
        "M_Flexor_Digitorum_Longus_R",
        "M_Flexor_Hallucis_Brevis_R",
        "M_Flexor_Hallucis_Longus_R",
        "M_Gemellus_Interior_R",
        "M_Gemellus_Superior_R",
        "M_Gracilis_R",
        "M_Obturator_Internus_R",
        "M_Pectineus_R",
        "M_Piriformis_R",
        "M_Plantar_Aponeurosis_Lateral_Band_R",
        "M_Popliteus_R",
        "M_Quadratus_Femoris_R",
        "M_Semimembranosus_R",
        "M_Tibialis_Anterior_R",
        "M_Tibialis_Posterior_R",
        "M_Vastus_Intermedius_R",
        "M_Vastus_Medialis_R",
        "Articular_Branch_of_Femoral_Nerve_R",
        "Articular_Branches_to_Superior_Tibiofibular_Joint_R",
        "Deep_Peroneal_Nerve_R",
        "Femoral_Nerve_R",
        "Inferior_Gluteal_Nerve_R",
        "Inferolateral_Genicular_Nerve_R",
        "Inferomedial_Genicular_Nerve_R",
        "Infrapatellar_Branch_of_Saphenous_Nerve_R",
        "L1_Spinal_Nerve_R",
        "Medial_Plantar_Nerve_R",
        "Middle_Cluneal_Nerves_L",
        "Middle_Cluneal_Nerves_R",
        "Middle_Genicular_Nerve_R",
        "Nerve_to_Major_and_Minor_Psoas_R",
        "Nerve_to_Obturator_Internus_and_Superior_Gemellus_R",
        "Nerve_to_Piriformis_R",
        "Obturator_Nerve_R",
        "Pudendal_Nerve_R",
        "Recurrent_Genicular_Nerve_R",
        "Saphenous_Nerve_R",
        "Sciatic_Nerve_R",
        "Superficial_Peroneal_Nerve_R",
        "Superior_Gluteal_Nerve_R",
        "Superolateral_Genicular_Nerve_R",
        "T12_Spinal_Nerve_R"
      ]
    },
    "musclesNervesLeg4": {
      "sideMode": "AutoMirrorAndSeparate",
      "initialCameraPosition": {
        "x": 0.14313842571348612,
        "y": 0.25451962872843914,
        "z": -1.0102909531928583
      },
      "visibleMeshNames": [
        "B_Calcaneus_R",
        "B_Cuboid_R",
        "B_Distal_Phalanx_Foot_1_R",
        "B_Distal_Phalanx_Foot_2_R",
        "B_Distal_Phalanx_Foot_3_R",
        "B_Distal_Phalanx_Foot_4_R",
        "B_Distal_Phalanx_Foot_5_R",
        "B_Femur_R",
        "B_Fibula_R",
        "B_Hipbone_R",
        "B_Intermediate_Cuneiform_R",
        "B_Lateral_Cuneiform_R",
        "B_Medial_Cuneiform_Bone_R",
        "B_Metatarsal_1_R",
        "B_Metatarsal_2_R",
        "B_Metatarsal_3_R",
        "B_Metatarsal_4_R",
        "B_Metatarsal_5_R",
        "B_Middle_Phalanx_Foot_2_R",
        "B_Middle_Phalanx_Foot_3_R",
        "B_Middle_Phalanx_Foot_4_R",
        "B_Middle_Phalanx_Foot_5_R",
        "B_Navicular_Bone_R",
        "B_Patella_R",
        "B_Proximal_Phalanx_Foot_1_R",
        "B_Proximal_Phalanx_Foot_2_R",
        "B_Proximal_Phalanx_Foot_3_R",
        "B_Proximal_Phalanx_Foot_4_R",
        "B_Proximal_Phalanx_Foot_5_R",
        "B_Sesamoid_Foot_R",
        "B_Talus_R",
        "B_Tibia_R",
        "M_Abductor_Digiti_Minimi_R",
        "M_Abductor_Hallucis_R",
        "M_Adductor_Brevis_R",
        "M_Adductor_Hallucis_Oblique_Head_R",
        "M_Adductor_Hallucis_Transverse_Head_R",
        "M_Adductor_Longus_R",
        "M_Adductor_Magnus_R",
        "M_Aductor_Digit_Minimi_R",
        "M_Articularis_Genus_R",
        "M_Biceps_Femoris_Short_Head_R",
        "M_Dorsal_Interossei_R",
        "M_Extensor_Digitorum_Brevis_R",
        "M_Extensor_Digitorum_Longus_R",
        "M_Extensor_Hallucis_Brevis_R",
        "M_Extensor_Hallucis_Longus_R",
        "M_External_Anal_Sphincter",
        "M_Fibularis_Brevis_R",
        "M_Fibularis_Longubs_R",
        "M_Fibularis_Tertius_R",
        "M_Flexor_Digiti_Minimi_Brevis_R",
        "M_Flexor_Digitorum_Brevis_R",
        "M_Flexor_Digitorum_Longus_R",
        "M_Flexor_Hallucis_Brevis_R",
        "M_Flexor_Hallucis_Longus_R",
        "M_Gemellus_Interior_R",
        "M_Gemellus_Superior_R",
        "M_Gluteus_Minimus_R",
        "M_Iliococcygeus_R",
        "M_Internal_anal_Sphincter",
        "M_Interossei_Plantar_R",
        "M_Lumbricals_R",
        "M_Obturator_Externus_R",
        "M_Obturator_Internus_R",
        "M_Opponens_Digiti_Minimi_R",
        "M_Pectineus_R",
        "M_Perineal_Membrane",
        "M_Piriformis_R",
        "M_Plantar_Aponeurosis_Lateral_Band_R",
        "M_Pubococcygeus",
        "M_Puborectalis",
        "M_Quadratus_Femoris_R",
        "M_Quadratus_Plantae_R",
        "M_Tendinous_Arch_of_Levator_Ani_R",
        "M_Tibialis_Posterior_R",
        "M_Transverse_Perineal",
        "M_Vastus_Medialis_R",
        "Articular_Branch_of_Femoral_Nerve_R",
        "Articular_Branches_to_Superior_Tibiofibular_Joint_R",
        "Deep_Peroneal_Nerve_R",
        "Femoral_Nerve_R",
        "Inferior_Gluteal_Nerve_R",
        "Inferolateral_Genicular_Nerve_R",
        "Inferomedial_Genicular_Nerve_R",
        "Infrapatellar_Branch_of_Saphenous_Nerve_R",
        "Lateral_Plantar_Nerve_R",
        "Medial_Plantar_Nerve_R",
        "Middle_Genicular_Nerve_R",
        "Nerve_to_Obturator_Internus_and_Superior_Gemellus_R",
        "Nerve_to_Piriformis_R",
        "Nerve_to_Quadratus_Femoris_and_Inferior_Gemellus_R",
        "Obturator_Nerve_R",
        "Pudendal_Nerve_R",
        "Recurrent_Genicular_Nerve_R",
        "Sciatic_Nerve_R",
        "Superficial_Peroneal_Nerve_R",
        "Superior_Gluteal_Nerve_R",
        "Superolateral_Genicular_Nerve_R",
        "Superomedial_Genicular_Nerve_R"
      ],
      "easyMeshNames": [
        "B_Femur_R",
        "B_Fibula_R",
        "B_Hipbone_R",
        "B_Patella_R",
        "B_Tibia_R",
        "M_Abductor_Digiti_Minimi_R",
        "M_Abductor_Hallucis_R",
        "M_Adductor_Brevis_R",
        "M_Adductor_Hallucis_Oblique_Head_R",
        "M_Adductor_Hallucis_Transverse_Head_R",
        "M_Adductor_Longus_R",
        "M_Adductor_Magnus_R",
        "M_Aductor_Digit_Minimi_R",
        "M_Articularis_Genus_R",
        "M_Biceps_Femoris_Short_Head_R",
        "M_Dorsal_Interossei_R",
        "M_Extensor_Digitorum_Brevis_R",
        "M_Extensor_Digitorum_Longus_R",
        "M_Extensor_Hallucis_Brevis_R",
        "M_Extensor_Hallucis_Longus_R",
        "M_External_Anal_Sphincter",
        "M_Fibularis_Brevis_R",
        "M_Fibularis_Longubs_R",
        "M_Fibularis_Tertius_R",
        "M_Flexor_Digiti_Minimi_Brevis_R",
        "M_Flexor_Digitorum_Brevis_R",
        "M_Flexor_Digitorum_Longus_R",
        "M_Flexor_Hallucis_Brevis_R",
        "M_Flexor_Hallucis_Longus_R",
        "M_Gemellus_Interior_R",
        "M_Gemellus_Superior_R",
        "M_Gluteus_Minimus_R",
        "M_Iliococcygeus_R",
        "M_Internal_anal_Sphincter",
        "M_Interossei_Plantar_R",
        "M_Lumbricals_R",
        "M_Obturator_Externus_R",
        "M_Obturator_Internus_R",
        "M_Opponens_Digiti_Minimi_R",
        "M_Pectineus_R",
        "M_Perineal_Membrane",
        "M_Piriformis_R",
        "M_Plantar_Aponeurosis_Lateral_Band_R",
        "M_Pubococcygeus",
        "M_Puborectalis",
        "M_Quadratus_Femoris_R",
        "M_Quadratus_Plantae_R",
        "M_Tendinous_Arch_of_Levator_Ani_R",
        "M_Tibialis_Posterior_R",
        "M_Transverse_Perineal",
        "M_Vastus_Medialis_R",
        "Articular_Branch_of_Femoral_Nerve_R",
        "Articular_Branches_to_Superior_Tibiofibular_Joint_R",
        "Deep_Peroneal_Nerve_R",
        "Femoral_Nerve_R",
        "Inferior_Gluteal_Nerve_R",
        "Inferolateral_Genicular_Nerve_R",
        "Inferomedial_Genicular_Nerve_R",
        "Infrapatellar_Branch_of_Saphenous_Nerve_R",
        "Lateral_Plantar_Nerve_R",
        "Medial_Plantar_Nerve_R",
        "Middle_Genicular_Nerve_R",
        "Nerve_to_Obturator_Internus_and_Superior_Gemellus_R",
        "Nerve_to_Piriformis_R",
        "Obturator_Nerve_R",
        "Pudendal_Nerve_R",
        "Recurrent_Genicular_Nerve_R",
        "Sciatic_Nerve_R",
        "Superficial_Peroneal_Nerve_R",
        "Superior_Gluteal_Nerve_R",
        "Superolateral_Genicular_Nerve_R"
      ]
    },
    "musclesNervesLeg5": {
      "sideMode": "AutoMirrorAndSeparate",
      "initialCameraPosition": {
        "x": 0.09657905828515771,
        "y": 0.3268934258066063,
        "z": -1.0312859884012813
      },
      "visibleMeshNames": [
        "B_Calcaneus_R",
        "B_Cuboid_R",
        "B_Distal_Phalanx_Foot_1_R",
        "B_Distal_Phalanx_Foot_2_R",
        "B_Distal_Phalanx_Foot_3_R",
        "B_Distal_Phalanx_Foot_4_R",
        "B_Distal_Phalanx_Foot_5_R",
        "B_Femur_R",
        "B_Fibula_R",
        "B_Hipbone_R",
        "B_Intermediate_Cuneiform_R",
        "B_Lateral_Cuneiform_R",
        "B_Medial_Cuneiform_Bone_R",
        "B_Metatarsal_1_R",
        "B_Metatarsal_2_R",
        "B_Metatarsal_3_R",
        "B_Metatarsal_4_R",
        "B_Metatarsal_5_R",
        "B_Middle_Phalanx_Foot_2_R",
        "B_Middle_Phalanx_Foot_3_R",
        "B_Middle_Phalanx_Foot_4_R",
        "B_Middle_Phalanx_Foot_5_R",
        "B_Navicular_Bone_R",
        "B_Patella_R",
        "B_Proximal_Phalanx_Foot_1_R",
        "B_Proximal_Phalanx_Foot_2_R",
        "B_Proximal_Phalanx_Foot_3_R",
        "B_Proximal_Phalanx_Foot_4_R",
        "B_Proximal_Phalanx_Foot_5_R",
        "B_Sesamoid_Foot_R",
        "B_Talus_R",
        "B_Tibia_R",
        "M_Abductor_Digiti_Minimi_R",
        "M_Adductor_Brevis_R",
        "M_Adductor_Hallucis_Oblique_Head_R",
        "M_Adductor_Hallucis_Transverse_Head_R",
        "M_Adductor_Longus_R",
        "M_Aductor_Digit_Minimi_R",
        "M_Articularis_Genus_R",
        "M_Dorsal_Interossei_R",
        "M_Extensor_Digitorum_Brevis_R",
        "M_Extensor_Digitorum_Longus_R",
        "M_Extensor_Hallucis_Brevis_R",
        "M_Extensor_Hallucis_Longus_R",
        "M_Fibularis_Brevis_R",
        "M_Fibularis_Longubs_R",
        "M_Fibularis_Tertius_R",
        "M_Flexor_Digiti_Minimi_Brevis_R",
        "M_Flexor_Digitorum_Longus_R",
        "M_Flexor_Hallucis_Brevis_R",
        "M_Flexor_Hallucis_Longus_R",
        "M_Gemellus_Interior_R",
        "M_Gemellus_Superior_R",
        "M_Gluteus_Minimus_R",
        "M_Interossei_Plantar_R",
        "M_Lumbricals_R",
        "M_Obturator_Externus_R",
        "M_Obturator_Internus_R",
        "M_Opponens_Digiti_Minimi_R",
        "M_Pectineus_R",
        "M_Piriformis_R",
        "M_Plantar_Aponeurosis_Lateral_Band_R",
        "M_Quadratus_Femoris_R",
        "M_Quadratus_Plantae_R",
        "M_Tendinous_Arch_of_Levator_Ani_R",
        "M_Tibialis_Posterior_R",
        "M_Vastus_Medialis_R",
        "Articular_Branch_of_Femoral_Nerve_R",
        "Deep_Peroneal_Nerve_R",
        "Lateral_Plantar_Nerve_R",
        "Medial_Plantar_Nerve_R",
        "Nerve_to_Obturator_Internus_and_Superior_Gemellus_R",
        "Nerve_to_Piriformis_R",
        "Nerve_to_Quadratus_Femoris_and_Inferior_Gemellus_R",
        "Obturator_Nerve_R",
        "Pudendal_Nerve_R",
        "Superficial_Peroneal_Nerve_R",
        "Superior_Gluteal_Nerve_R",
        "Superomedial_Genicular_Nerve_R"
      ],
      "easyMeshNames": [
        "B_Femur_R",
        "B_Fibula_R",
        "B_Hipbone_R",
        "B_Patella_R",
        "B_Tibia_R",
        "M_Abductor_Digiti_Minimi_R",
        "M_Adductor_Brevis_R",
        "M_Adductor_Longus_R",
        "M_Aductor_Digit_Minimi_R",
        "M_Articularis_Genus_R",
        "M_Extensor_Digitorum_Longus_R",
        "M_Extensor_Hallucis_Brevis_R",
        "M_Extensor_Hallucis_Longus_R",
        "M_Fibularis_Brevis_R",
        "M_Fibularis_Longubs_R",
        "M_Fibularis_Tertius_R",
        "M_Flexor_Digiti_Minimi_Brevis_R",
        "M_Flexor_Digitorum_Longus_R",
        "M_Flexor_Hallucis_Brevis_R",
        "M_Flexor_Hallucis_Longus_R",
        "M_Gemellus_Interior_R",
        "M_Gemellus_Superior_R",
        "M_Gluteus_Minimus_R",
        "M_Lumbricals_R",
        "M_Obturator_Internus_R",
        "M_Opponens_Digiti_Minimi_R",
        "M_Pectineus_R",
        "M_Piriformis_R",
        "M_Plantar_Aponeurosis_Lateral_Band_R",
        "M_Quadratus_Femoris_R",
        "M_Quadratus_Plantae_R",
        "M_Tendinous_Arch_of_Levator_Ani_R",
        "M_Tibialis_Posterior_R",
        "M_Vastus_Medialis_R",
        "Articular_Branch_of_Femoral_Nerve_R",
        "Deep_Peroneal_Nerve_R",
        "Lateral_Plantar_Nerve_R",
        "Medial_Plantar_Nerve_R",
        "Nerve_to_Obturator_Internus_and_Superior_Gemellus_R",
        "Nerve_to_Piriformis_R",
        "Nerve_to_Quadratus_Femoris_and_Inferior_Gemellus_R",
        "Obturator_Nerve_R",
        "Pudendal_Nerve_R",
        "Superficial_Peroneal_Nerve_R",
        "Superior_Gluteal_Nerve_R",
        "Superomedial_Genicular_Nerve_R"
      ]
    },
    "musclesNervesLeg6": {
      "sideMode": "AutoMirrorAndSeparate",
      "initialCameraPosition": {
        "x": 0.09452523580296182,
        "y": 0.5355862380937877,
        "z": -1.0261213668558369
      },
      "visibleMeshNames": [
        "B_Calcaneus_R",
        "B_Cuboid_R",
        "B_Distal_Phalanx_Foot_1_R",
        "B_Distal_Phalanx_Foot_2_R",
        "B_Distal_Phalanx_Foot_3_R",
        "B_Distal_Phalanx_Foot_4_R",
        "B_Distal_Phalanx_Foot_5_R",
        "B_Femur_R",
        "B_Fibula_R",
        "B_Hipbone_R",
        "B_Intermediate_Cuneiform_R",
        "B_Lateral_Cuneiform_R",
        "B_Medial_Cuneiform_Bone_R",
        "B_Metatarsal_1_R",
        "B_Metatarsal_2_R",
        "B_Metatarsal_3_R",
        "B_Metatarsal_4_R",
        "B_Metatarsal_5_R",
        "B_Middle_Phalanx_Foot_2_R",
        "B_Middle_Phalanx_Foot_3_R",
        "B_Middle_Phalanx_Foot_4_R",
        "B_Middle_Phalanx_Foot_5_R",
        "B_Navicular_Bone_R",
        "B_Patella_R",
        "B_Proximal_Phalanx_Foot_1_R",
        "B_Proximal_Phalanx_Foot_2_R",
        "B_Proximal_Phalanx_Foot_3_R",
        "B_Proximal_Phalanx_Foot_4_R",
        "B_Proximal_Phalanx_Foot_5_R",
        "B_Sesamoid_Foot_R",
        "B_Talus_R",
        "B_Tibia_R",
        "M_Adductor_Brevis_R",
        "M_Adductor_Hallucis_Oblique_Head_R",
        "M_Adductor_Hallucis_Transverse_Head_R",
        "M_Aductor_Digit_Minimi_R",
        "M_Dorsal_Interossei_R",
        "M_Extensor_Digitorum_Brevis_R",
        "M_Extensor_Hallucis_Brevis_R",
        "M_Extensor_Hallucis_Longus_R",
        "M_Fibularis_Brevis_R",
        "M_Fibularis_Longubs_R",
        "M_Fibularis_Tertius_R",
        "M_Flexor_Digiti_Minimi_Brevis_R",
        "M_Flexor_Hallucis_Brevis_R",
        "M_Flexor_Hallucis_Longus_R",
        "M_Interossei_Plantar_R",
        "M_Lumbricals_R",
        "M_Obturator_Externus_R",
        "M_Obturator_Internus_R",
        "M_Opponens_Digiti_Minimi_R",
        "M_Quadratus_Plantae_R",
        "M_Tibialis_Posterior_R",
        "Deep_Peroneal_Nerve_R",
        "Lateral_Plantar_Nerve_R",
        "Medial_Plantar_Nerve_R",
        "Nerve_to_Piriformis_R",
        "Nerve_to_Quadratus_Femoris_and_Inferior_Gemellus_R",
        "Superficial_Peroneal_Nerve_R",
        "Superior_Gluteal_Nerve_R"
      ],
      "easyMeshNames": [
        "B_Femur_R",
        "B_Fibula_R",
        "B_Hipbone_R",
        "B_Patella_R",
        "B_Tibia_R",
        "M_Adductor_Brevis_R",
        "M_Aductor_Digit_Minimi_R",
        "M_Extensor_Digitorum_Brevis_R",
        "M_Extensor_Hallucis_Brevis_R",
        "M_Extensor_Hallucis_Longus_R",
        "M_Fibularis_Brevis_R",
        "M_Fibularis_Longubs_R",
        "M_Fibularis_Tertius_R",
        "M_Flexor_Digiti_Minimi_Brevis_R",
        "M_Flexor_Hallucis_Brevis_R",
        "M_Flexor_Hallucis_Longus_R",
        "M_Lumbricals_R",
        "M_Obturator_Externus_R",
        "M_Obturator_Internus_R",
        "M_Opponens_Digiti_Minimi_R",
        "M_Quadratus_Plantae_R",
        "M_Tibialis_Posterior_R",
        "Deep_Peroneal_Nerve_R",
        "Lateral_Plantar_Nerve_R",
        "Medial_Plantar_Nerve_R",
        "Nerve_to_Piriformis_R",
        "Nerve_to_Quadratus_Femoris_and_Inferior_Gemellus_R",
        "Superficial_Peroneal_Nerve_R",
        "Superior_Gluteal_Nerve_R"
      ]
    },
    "musclesNervesLeg7": {
      "sideMode": "AutoMirrorAndSeparate",
      "initialCameraPosition": {
        "x": 0.12033117120931003,
        "y": 0.4806143474984245,
        "z": -0.5645006133522099
      },
      "visibleMeshNames": [
        "B_Calcaneus_R",
        "B_Cuboid_R",
        "B_Distal_Phalanx_Foot_1_R",
        "B_Distal_Phalanx_Foot_2_R",
        "B_Distal_Phalanx_Foot_3_R",
        "B_Distal_Phalanx_Foot_4_R",
        "B_Distal_Phalanx_Foot_5_R",
        "B_Fibula_R",
        "B_Intermediate_Cuneiform_R",
        "B_Lateral_Cuneiform_R",
        "B_Medial_Cuneiform_Bone_R",
        "B_Metatarsal_1_R",
        "B_Metatarsal_2_R",
        "B_Metatarsal_3_R",
        "B_Metatarsal_4_R",
        "B_Metatarsal_5_R",
        "B_Middle_Phalanx_Foot_2_R",
        "B_Middle_Phalanx_Foot_3_R",
        "B_Middle_Phalanx_Foot_4_R",
        "B_Middle_Phalanx_Foot_5_R",
        "B_Navicular_Bone_R",
        "B_Proximal_Phalanx_Foot_1_R",
        "B_Proximal_Phalanx_Foot_2_R",
        "B_Proximal_Phalanx_Foot_3_R",
        "B_Proximal_Phalanx_Foot_4_R",
        "B_Proximal_Phalanx_Foot_5_R",
        "B_Sesamoid_Foot_R",
        "B_Talus_R",
        "B_Tibia_R",
        "M_Adductor_Hallucis_Oblique_Head_R",
        "M_Adductor_Hallucis_Transverse_Head_R",
        "M_Dorsal_Interossei_R",
        "M_Extensor_Digitorum_Brevis_R",
        "M_Extensor_Hallucis_Brevis_R",
        "M_Fibularis_Brevis_R",
        "M_Fibularis_Longubs_R",
        "M_Fibularis_Tertius_R",
        "M_Flexor_Digiti_Minimi_Brevis_R",
        "M_Flexor_Hallucis_Longus_R",
        "M_Interossei_Plantar_R",
        "M_Lumbricals_R",
        "M_Opponens_Digiti_Minimi_R",
        "M_Tibialis_Posterior_R",
        "Deep_Peroneal_Nerve_R",
        "Lateral_Plantar_Nerve_R",
        "Medial_Plantar_Nerve_R",
        "Superficial_Peroneal_Nerve_R"
      ],
      "easyMeshNames": [
        "B_Fibula_R",
        "B_Tibia_R",
        "M_Extensor_Digitorum_Brevis_R",
        "M_Extensor_Hallucis_Brevis_R",
        "M_Fibularis_Brevis_R",
        "M_Fibularis_Longubs_R",
        "M_Fibularis_Tertius_R",
        "M_Flexor_Digiti_Minimi_Brevis_R",
        "M_Flexor_Hallucis_Longus_R",
        "M_Lumbricals_R",
        "M_Opponens_Digiti_Minimi_R",
        "M_Tibialis_Posterior_R",
        "Deep_Peroneal_Nerve_R",
        "Lateral_Plantar_Nerve_R",
        "Medial_Plantar_Nerve_R",
        "Superficial_Peroneal_Nerve_R"
      ]
    },
    "musclesNervesLeg8": {
      "sideMode": "AutoMirrorAndSeparate",
      "initialCameraPosition": {
        "x": 0.09094285374577714,
        "y": 0.4222652855461826,
        "z": -0.5845061465108679
      },
      "visibleMeshNames": [
        "B_Calcaneus_R",
        "B_Cuboid_R",
        "B_Distal_Phalanx_Foot_1_R",
        "B_Distal_Phalanx_Foot_2_R",
        "B_Distal_Phalanx_Foot_3_R",
        "B_Distal_Phalanx_Foot_4_R",
        "B_Distal_Phalanx_Foot_5_R",
        "B_Fibula_R",
        "B_Intermediate_Cuneiform_R",
        "B_Lateral_Cuneiform_R",
        "B_Medial_Cuneiform_Bone_R",
        "B_Metatarsal_1_R",
        "B_Metatarsal_2_R",
        "B_Metatarsal_3_R",
        "B_Metatarsal_4_R",
        "B_Metatarsal_5_R",
        "B_Middle_Phalanx_Foot_2_R",
        "B_Middle_Phalanx_Foot_3_R",
        "B_Middle_Phalanx_Foot_4_R",
        "B_Middle_Phalanx_Foot_5_R",
        "B_Navicular_Bone_R",
        "B_Proximal_Phalanx_Foot_1_R",
        "B_Proximal_Phalanx_Foot_2_R",
        "B_Proximal_Phalanx_Foot_3_R",
        "B_Proximal_Phalanx_Foot_4_R",
        "B_Proximal_Phalanx_Foot_5_R",
        "B_Sesamoid_Foot_R",
        "B_Talus_R",
        "B_Tibia_R",
        "M_Adductor_Hallucis_Oblique_Head_R",
        "M_Adductor_Hallucis_Transverse_Head_R",
        "M_Dorsal_Interossei_R",
        "M_Fibularis_Brevis_R",
        "M_Fibularis_Longubs_R",
        "M_Interossei_Plantar_R",
        "Superficial_Peroneal_Nerve_R"
      ],
      "easyMeshNames": [
        "B_Calcaneus_R",
        "B_Fibula_R",
        "B_Tibia_R",
        "M_Adductor_Hallucis_Oblique_Head_R",
        "M_Adductor_Hallucis_Transverse_Head_R",
        "M_Fibularis_Brevis_R",
        "M_Fibularis_Longubs_R",
        "Superficial_Peroneal_Nerve_R"
      ]
    },
    "musclesFoot1": {
      "sideMode": "AutoMirrorAndSeparate",
      "initialCameraPosition": {
        "x": 0.10067834529270023,
        "y": 0.3307370038447192,
        "z": -0.17074837882107236
      },
      "visibleMeshNames": [
        "B_Calcaneus_R",
        "B_Cuboid_R",
        "B_Distal_Phalanx_Foot_1_R",
        "B_Distal_Phalanx_Foot_2_R",
        "B_Distal_Phalanx_Foot_3_R",
        "B_Distal_Phalanx_Foot_4_R",
        "B_Distal_Phalanx_Foot_5_R",
        "B_Intermediate_Cuneiform_R",
        "B_Lateral_Cuneiform_R",
        "B_Medial_Cuneiform_Bone_R",
        "B_Metatarsal_1_R",
        "B_Metatarsal_2_R",
        "B_Metatarsal_3_R",
        "B_Metatarsal_4_R",
        "B_Metatarsal_5_R",
        "B_Middle_Phalanx_Foot_2_R",
        "B_Middle_Phalanx_Foot_3_R",
        "B_Middle_Phalanx_Foot_4_R",
        "B_Middle_Phalanx_Foot_5_R",
        "B_Navicular_Bone_R",
        "B_Proximal_Phalanx_Foot_1_R",
        "B_Proximal_Phalanx_Foot_2_R",
        "B_Proximal_Phalanx_Foot_3_R",
        "B_Proximal_Phalanx_Foot_4_R",
        "B_Proximal_Phalanx_Foot_5_R",
        "B_Sesamoid_Foot_R",
        "B_Talus_R",
        "M_Adductor_Hallucis_Oblique_Head_R",
        "M_Adductor_Hallucis_Transverse_Head_R",
        "M_Dorsal_Interossei_R",
        "M_Interossei_Plantar_R"
      ],
      "easyMeshNames": [
        "B_Calcaneus_R",
        "B_Cuboid_R",
        "B_Distal_Phalanx_Foot_1_R",
        "B_Distal_Phalanx_Foot_2_R",
        "B_Distal_Phalanx_Foot_3_R",
        "B_Distal_Phalanx_Foot_4_R",
        "B_Distal_Phalanx_Foot_5_R",
        "B_Intermediate_Cuneiform_R",
        "B_Lateral_Cuneiform_R",
        "B_Medial_Cuneiform_Bone_R",
        "B_Metatarsal_1_R",
        "B_Metatarsal_2_R",
        "B_Metatarsal_3_R",
        "B_Metatarsal_4_R",
        "B_Metatarsal_5_R",
        "B_Middle_Phalanx_Foot_2_R",
        "B_Middle_Phalanx_Foot_3_R",
        "B_Middle_Phalanx_Foot_4_R",
        "B_Middle_Phalanx_Foot_5_R",
        "B_Navicular_Bone_R",
        "B_Proximal_Phalanx_Foot_1_R",
        "B_Proximal_Phalanx_Foot_2_R",
        "B_Proximal_Phalanx_Foot_3_R",
        "B_Proximal_Phalanx_Foot_4_R",
        "B_Proximal_Phalanx_Foot_5_R",
        "B_Sesamoid_Foot_R",
        "B_Talus_R",
        "M_Adductor_Hallucis_Oblique_Head_R",
        "M_Adductor_Hallucis_Transverse_Head_R",
        "M_Dorsal_Interossei_R",
        "M_Interossei_Plantar_R"
      ]
    },
    "prometheus-107-a": {
      "sideMode": "Unmodified",
      "initialCameraPosition": {
        "x": 0.01834007689067299,
        "y": 1.3277217722081511,
        "z": -1.4538258106907676
      },
      "visibleMeshNames": [
        "B_Coccyx",
        "B_Costal_Cartilage_1_L",
        "B_Costal_Cartilage_1_R",
        "B_Costal_Cartilage_2_L",
        "B_Costal_Cartilage_2_R",
        "B_Costal_Cartilage_3_L",
        "B_Costal_Cartilage_3_R",
        "B_Costal_Cartilage_4_L",
        "B_Costal_Cartilage_4_R",
        "B_Costal_Cartilage_5_L",
        "B_Costal_Cartilage_5_R",
        "B_Costal_Cartilage_6_L",
        "B_Costal_Cartilage_6_R",
        "B_Costal_Cartilage_7_L",
        "B_Costal_Cartilage_7_R",
        "B_Costal_Cartilage_8_L",
        "B_Costal_Cartilage_8_R",
        "B_Costal_Cartilage_9_L",
        "B_Costal_Cartilage_9_R",
        "B_Femur_R",
        "B_Hipbone_L",
        "B_Hipbone_R",
        "B_Lumbar_Disc_L1",
        "B_Lumbar_Disc_L2",
        "B_Lumbar_Disc_L3",
        "B_Lumbar_Disc_L4",
        "B_Lumbar_Disc_L5",
        "B_Lumbar_Vertebra_L1",
        "B_Lumbar_Vertebra_L2",
        "B_Lumbar_Vertebra_L3",
        "B_Lumbar_Vertebra_L4",
        "B_Lumbar_Vertebra_L5",
        "B_Rib_10_L",
        "B_Rib_10_R",
        "B_Rib_11_L",
        "B_Rib_11_R",
        "B_Rib_12_L",
        "B_Rib_12_R",
        "B_Rib_1_L",
        "B_Rib_1_R",
        "B_Rib_2_L",
        "B_Rib_2_R",
        "B_Rib_3_L",
        "B_Rib_3_R",
        "B_Rib_4_L",
        "B_Rib_4_R",
        "B_Rib_5_L",
        "B_Rib_5_R",
        "B_Rib_6_L",
        "B_Rib_6_R",
        "B_Rib_7_L",
        "B_Rib_7_R",
        "B_Rib_8_L",
        "B_Rib_8_R",
        "B_Rib_9_L",
        "B_Rib_9_R",
        "B_Sacrum",
        "B_Sternum",
        "B_Thoracic_Disc_T1",
        "B_Thoracic_Disc_T10",
        "B_Thoracic_Disc_T11",
        "B_Thoracic_Disc_T12",
        "B_Thoracic_Disc_T2",
        "B_Thoracic_Disc_T3",
        "B_Thoracic_Disc_T4",
        "B_Thoracic_Disc_T5",
        "B_Thoracic_Disc_T6",
        "B_Thoracic_Disc_T7",
        "B_Thoracic_Disc_T8",
        "B_Thoracic_Disc_T9",
        "B_Thoracic_Vertebra_T1",
        "B_Thoracic_Vertebra_T10",
        "B_Thoracic_Vertebra_T11",
        "B_Thoracic_Vertebra_T12",
        "B_Thoracic_Vertebra_T2",
        "B_Thoracic_Vertebra_T3",
        "B_Thoracic_Vertebra_T4",
        "B_Thoracic_Vertebra_T5",
        "B_Thoracic_Vertebra_T6",
        "B_Thoracic_Vertebra_T7",
        "B_Thoracic_Vertebra_T8",
        "B_Thoracic_Vertebra_T9",
        "C_Acetabulum_Lunate_Surface_R",
        "C_Articular_Surface_Head_of_Ribs_L",
        "C_Articular_Surface_Head_of_Ribs_R",
        "C_Articular_Surface_Tubercle_of_Ribs_L",
        "C_Articular_Surface_Tubercle_of_Ribs_R",
        "C_Costal_Facets_of_Vertebra_L",
        "C_Costal_Facets_of_Vertebra_R",
        "C_Femur_Head_R",
        "C_Transverse_Costal_Facet_of_Vertebra_L",
        "C_Transverse_Costal_Facet_of_Vertebra_R",
        "L_Iliopectineal_Line_R",
        "L_Inguinal_Ligament_L",
        "L_Inguinal_Ligament_R",
        "L_Lacunar_Ligament_L",
        "L_Lacunar_Ligament_R",
        "L_Linea_Alba_Ligament",
        "L_Pubic_Symphysis",
        "M_Iliacus_R",
        "M_Psoas_Major_R",
        "M_Quadratus_Lumborum_Muscle_R",
        "M_Rectus_Abdominus_Muscle_L"
      ],
      "easyMeshNames": [
        "B_Femur_R",
        "B_Hipbone_R",
        "B_Sternum",
        "L_Inguinal_Ligament_R",
        "L_Linea_Alba_Ligament",
        "L_Pubic_Symphysis",
        "M_Iliacus_R",
        "M_Psoas_Major_R",
        "M_Quadratus_Lumborum_Muscle_R"
      ]
    },
    "prometheus-108-a": {
      "sideMode": "Unmodified",
      "initialCameraPosition": {
        "x": 0.01834007689067299,
        "y": 1.3277217722081511,
        "z": -1.4538258106907676
      },
      "visibleMeshNames": [
        "B_Coccyx",
        "B_Costal_Cartilage_1_L",
        "B_Costal_Cartilage_1_R",
        "B_Costal_Cartilage_2_L",
        "B_Costal_Cartilage_2_R",
        "B_Costal_Cartilage_3_L",
        "B_Costal_Cartilage_3_R",
        "B_Costal_Cartilage_4_L",
        "B_Costal_Cartilage_4_R",
        "B_Costal_Cartilage_5_L",
        "B_Costal_Cartilage_5_R",
        "B_Costal_Cartilage_6_L",
        "B_Costal_Cartilage_6_R",
        "B_Costal_Cartilage_7_L",
        "B_Costal_Cartilage_7_R",
        "B_Costal_Cartilage_8_L",
        "B_Costal_Cartilage_8_R",
        "B_Costal_Cartilage_9_L",
        "B_Costal_Cartilage_9_R",
        "B_Femur_L",
        "B_Femur_R",
        "B_Hipbone_L",
        "B_Hipbone_R",
        "B_Lumbar_Disc_L1",
        "B_Lumbar_Disc_L2",
        "B_Lumbar_Disc_L3",
        "B_Lumbar_Disc_L4",
        "B_Lumbar_Disc_L5",
        "B_Lumbar_Vertebra_L1",
        "B_Lumbar_Vertebra_L2",
        "B_Lumbar_Vertebra_L3",
        "B_Lumbar_Vertebra_L4",
        "B_Lumbar_Vertebra_L5",
        "B_Rib_10_L",
        "B_Rib_10_R",
        "B_Rib_11_L",
        "B_Rib_11_R",
        "B_Rib_12_L",
        "B_Rib_12_R",
        "B_Rib_1_L",
        "B_Rib_1_R",
        "B_Rib_2_L",
        "B_Rib_2_R",
        "B_Rib_3_L",
        "B_Rib_3_R",
        "B_Rib_4_L",
        "B_Rib_4_R",
        "B_Rib_5_L",
        "B_Rib_5_R",
        "B_Rib_6_L",
        "B_Rib_6_R",
        "B_Rib_7_L",
        "B_Rib_7_R",
        "B_Rib_8_L",
        "B_Rib_8_R",
        "B_Rib_9_L",
        "B_Rib_9_R",
        "B_Sacrum",
        "B_Sternum",
        "B_Thoracic_Disc_T1",
        "B_Thoracic_Disc_T10",
        "B_Thoracic_Disc_T11",
        "B_Thoracic_Disc_T12",
        "B_Thoracic_Disc_T2",
        "B_Thoracic_Disc_T3",
        "B_Thoracic_Disc_T5",
        "B_Thoracic_Disc_T6",
        "B_Thoracic_Disc_T7",
        "B_Thoracic_Disc_T8",
        "B_Thoracic_Disc_T9",
        "B_Thoracic_Vertebra_T1",
        "B_Thoracic_Vertebra_T10",
        "B_Thoracic_Vertebra_T11",
        "B_Thoracic_Vertebra_T12",
        "B_Thoracic_Vertebra_T2",
        "B_Thoracic_Vertebra_T3",
        "B_Thoracic_Vertebra_T4",
        "B_Thoracic_Vertebra_T5",
        "B_Thoracic_Vertebra_T6",
        "B_Thoracic_Vertebra_T7",
        "B_Thoracic_Vertebra_T8",
        "B_Thoracic_Vertebra_T9",
        "C_Acetabulum_Lunate_Surface_L",
        "C_Acetabulum_Lunate_Surface_R",
        "C_Articular_Surface_Head_of_Ribs_L",
        "C_Articular_Surface_Head_of_Ribs_R",
        "C_Articular_Surface_Tubercle_of_Ribs_L",
        "C_Articular_Surface_Tubercle_of_Ribs_R",
        "C_Costal_Facets_of_Vertebra_L",
        "C_Costal_Facets_of_Vertebra_R",
        "C_Femur_Head_L",
        "C_Femur_Head_R",
        "C_Transverse_Costal_Facet_of_Vertebra_L",
        "C_Transverse_Costal_Facet_of_Vertebra_R",
        "Epididymis_L",
        "Epididymis_R",
        "External_Iliac_Artery_R",
        "External_Iliac_Vein_R",
        "F_External_Spermatic_Fascia_L",
        "F_External_Spermatic_Fascia_R",
        "F_Internal_Spermatic_Fascia_L",
        "F_Internal_Spermatic_Fascia_R",
        "F_Tunica_Albuginea__Testis__L",
        "F_Tunica_Albuginea__Testis__R",
        "F_Tunica_Vaginalis_L",
        "F_Tunica_Vaginalis_R",
        "F_Tunica_Vasculosa_L",
        "F_Tunica_Vasculosa_R",
        "Femoral_Artery_R",
        "Femoral_Nerve_R",
        "Femoral_Vein_R",
        "L_Inguinal_Ligament_L",
        "L_Inguinal_Ligament_R",
        "L_Inguinal_Ring_L",
        "L_Inguinal_Ring_R",
        "L_Intercrural_Fibers_L",
        "L_Intercrural_Fibers_R",
        "L_Lacunar_Ligament_L",
        "L_Lacunar_Ligament_R",
        "L_Linea_Alba_Ligament",
        "L_Pubic_Symphysis",
        "M_External_Oblique_Muscle_L",
        "M_External_Oblique_Muscle_R",
        "M_Iliacus_L",
        "M_Iliacus_R",
        "M_Internal_Oblique_Muscle_L",
        "M_Internal_Oblique_Muscle_R",
        "M_Pectineus_L",
        "M_Pectineus_R",
        "M_Psoas_Major_L",
        "M_Psoas_Major_R",
        "M_Rectus_Abdominus_Muscle_L",
        "M_Rectus_Abdominus_Muscle_R",
        "M_Testis_Cremaster_L",
        "M_Testis_Cremaster_R",
        "M_Trasnversus_Abdominus_Muscle_L",
        "M_Trasnversus_Abdominus_Muscle_R",
        "Seminiferous_Tubules_L",
        "Seminiferous_Tubules_R"
      ],
      "easyMeshNames": [
        "F_External_Spermatic_Fascia_R",
        "Femoral_Artery_R",
        "Femoral_Nerve_R",
        "Femoral_Vein_R",
        "M_External_Oblique_Muscle_R",
        "M_Iliacus_R",
        "M_Pectineus_R",
        "M_Psoas_Major_R"
      ]
    },
    "prometheus-108-b": {
      "sideMode": "Unmodified",
      "initialCameraPosition": {
        "x": 0.01834007689067299,
        "y": 1.3277217722081511,
        "z": -1.4538258106907676
      },
      "visibleMeshNames": [
        "B_Coccyx",
        "B_Costal_Cartilage_1_L",
        "B_Costal_Cartilage_1_R",
        "B_Costal_Cartilage_2_L",
        "B_Costal_Cartilage_2_R",
        "B_Costal_Cartilage_3_L",
        "B_Costal_Cartilage_3_R",
        "B_Costal_Cartilage_4_L",
        "B_Costal_Cartilage_4_R",
        "B_Costal_Cartilage_5_L",
        "B_Costal_Cartilage_5_R",
        "B_Costal_Cartilage_6_L",
        "B_Costal_Cartilage_6_R",
        "B_Costal_Cartilage_7_L",
        "B_Costal_Cartilage_7_R",
        "B_Costal_Cartilage_8_L",
        "B_Costal_Cartilage_8_R",
        "B_Costal_Cartilage_9_L",
        "B_Costal_Cartilage_9_R",
        "B_Femur_L",
        "B_Femur_R",
        "B_Hipbone_L",
        "B_Hipbone_R",
        "B_Lumbar_Disc_L1",
        "B_Lumbar_Disc_L2",
        "B_Lumbar_Disc_L3",
        "B_Lumbar_Disc_L4",
        "B_Lumbar_Disc_L5",
        "B_Lumbar_Vertebra_L1",
        "B_Lumbar_Vertebra_L2",
        "B_Lumbar_Vertebra_L3",
        "B_Lumbar_Vertebra_L4",
        "B_Lumbar_Vertebra_L5",
        "B_Rib_10_L",
        "B_Rib_10_R",
        "B_Rib_11_L",
        "B_Rib_11_R",
        "B_Rib_12_L",
        "B_Rib_12_R",
        "B_Rib_1_L",
        "B_Rib_1_R",
        "B_Rib_2_L",
        "B_Rib_2_R",
        "B_Rib_3_L",
        "B_Rib_3_R",
        "B_Rib_4_L",
        "B_Rib_4_R",
        "B_Rib_5_L",
        "B_Rib_5_R",
        "B_Rib_6_L",
        "B_Rib_6_R",
        "B_Rib_7_L",
        "B_Rib_7_R",
        "B_Rib_8_L",
        "B_Rib_8_R",
        "B_Rib_9_L",
        "B_Rib_9_R",
        "B_Sacrum",
        "B_Sternum",
        "B_Thoracic_Disc_T1",
        "B_Thoracic_Disc_T10",
        "B_Thoracic_Disc_T11",
        "B_Thoracic_Disc_T12",
        "B_Thoracic_Disc_T2",
        "B_Thoracic_Disc_T3",
        "B_Thoracic_Disc_T5",
        "B_Thoracic_Disc_T6",
        "B_Thoracic_Disc_T7",
        "B_Thoracic_Disc_T8",
        "B_Thoracic_Disc_T9",
        "B_Thoracic_Vertebra_T1",
        "B_Thoracic_Vertebra_T10",
        "B_Thoracic_Vertebra_T11",
        "B_Thoracic_Vertebra_T12",
        "B_Thoracic_Vertebra_T2",
        "B_Thoracic_Vertebra_T3",
        "B_Thoracic_Vertebra_T4",
        "B_Thoracic_Vertebra_T5",
        "B_Thoracic_Vertebra_T6",
        "B_Thoracic_Vertebra_T7",
        "B_Thoracic_Vertebra_T8",
        "B_Thoracic_Vertebra_T9",
        "C_Acetabulum_Lunate_Surface_L",
        "C_Acetabulum_Lunate_Surface_R",
        "C_Articular_Surface_Head_of_Ribs_L",
        "C_Articular_Surface_Head_of_Ribs_R",
        "C_Articular_Surface_Tubercle_of_Ribs_L",
        "C_Articular_Surface_Tubercle_of_Ribs_R",
        "C_Costal_Facets_of_Vertebra_L",
        "C_Costal_Facets_of_Vertebra_R",
        "C_Femur_Head_L",
        "C_Femur_Head_R",
        "C_Transverse_Costal_Facet_of_Vertebra_L",
        "C_Transverse_Costal_Facet_of_Vertebra_R",
        "Epididymis_L",
        "Epididymis_R",
        "External_Iliac_Artery_R",
        "External_Iliac_Vein_R",
        "F_External_Spermatic_Fascia_L",
        "F_External_Spermatic_Fascia_R",
        "F_Internal_Spermatic_Fascia_L",
        "F_Internal_Spermatic_Fascia_R",
        "F_Tunica_Albuginea__Testis__L",
        "F_Tunica_Albuginea__Testis__R",
        "F_Tunica_Vaginalis_L",
        "F_Tunica_Vaginalis_R",
        "F_Tunica_Vasculosa_L",
        "F_Tunica_Vasculosa_R",
        "Femoral_Artery_R",
        "Femoral_Nerve_R",
        "Femoral_Vein_R",
        "L_Inguinal_Ligament_L",
        "L_Inguinal_Ligament_R",
        "L_Inguinal_Ring_L",
        "L_Inguinal_Ring_R",
        "L_Intercrural_Fibers_L",
        "L_Intercrural_Fibers_R",
        "L_Lacunar_Ligament_L",
        "L_Lacunar_Ligament_R",
        "L_Linea_Alba_Ligament",
        "L_Pubic_Symphysis",
        "M_External_Oblique_Muscle_L",
        "M_Iliacus_L",
        "M_Iliacus_R",
        "M_Internal_Oblique_Muscle_L",
        "M_Internal_Oblique_Muscle_R",
        "M_Pectineus_L",
        "M_Pectineus_R",
        "M_Psoas_Major_L",
        "M_Psoas_Major_R",
        "M_Rectus_Abdominus_Muscle_L",
        "M_Rectus_Abdominus_Muscle_R",
        "M_Testis_Cremaster_L",
        "M_Testis_Cremaster_R",
        "M_Trasnversus_Abdominus_Muscle_L",
        "M_Trasnversus_Abdominus_Muscle_R",
        "Seminiferous_Tubules_L",
        "Seminiferous_Tubules_R"
      ],
      "easyMeshNames": ["M_Internal_Oblique_Muscle_R"]
    },
    "prometheus-108-c": {
      "sideMode": "Unmodified",
      "initialCameraPosition": {
        "x": 0.01834007689067299,
        "y": 1.3277217722081511,
        "z": -1.4538258106907676
      },
      "visibleMeshNames": [
        "B_Coccyx",
        "B_Costal_Cartilage_1_L",
        "B_Costal_Cartilage_1_R",
        "B_Costal_Cartilage_2_L",
        "B_Costal_Cartilage_2_R",
        "B_Costal_Cartilage_3_L",
        "B_Costal_Cartilage_3_R",
        "B_Costal_Cartilage_4_L",
        "B_Costal_Cartilage_4_R",
        "B_Costal_Cartilage_5_L",
        "B_Costal_Cartilage_5_R",
        "B_Costal_Cartilage_6_L",
        "B_Costal_Cartilage_6_R",
        "B_Costal_Cartilage_7_L",
        "B_Costal_Cartilage_7_R",
        "B_Costal_Cartilage_8_L",
        "B_Costal_Cartilage_8_R",
        "B_Costal_Cartilage_9_L",
        "B_Costal_Cartilage_9_R",
        "B_Femur_L",
        "B_Femur_R",
        "B_Hipbone_L",
        "B_Hipbone_R",
        "B_Lumbar_Disc_L1",
        "B_Lumbar_Disc_L2",
        "B_Lumbar_Disc_L3",
        "B_Lumbar_Disc_L4",
        "B_Lumbar_Disc_L5",
        "B_Lumbar_Vertebra_L1",
        "B_Lumbar_Vertebra_L2",
        "B_Lumbar_Vertebra_L3",
        "B_Lumbar_Vertebra_L4",
        "B_Lumbar_Vertebra_L5",
        "B_Rib_10_L",
        "B_Rib_10_R",
        "B_Rib_11_L",
        "B_Rib_11_R",
        "B_Rib_12_L",
        "B_Rib_12_R",
        "B_Rib_1_L",
        "B_Rib_1_R",
        "B_Rib_2_L",
        "B_Rib_2_R",
        "B_Rib_3_L",
        "B_Rib_3_R",
        "B_Rib_4_L",
        "B_Rib_4_R",
        "B_Rib_5_L",
        "B_Rib_5_R",
        "B_Rib_6_L",
        "B_Rib_6_R",
        "B_Rib_7_L",
        "B_Rib_7_R",
        "B_Rib_8_L",
        "B_Rib_8_R",
        "B_Rib_9_L",
        "B_Rib_9_R",
        "B_Sacrum",
        "B_Sternum",
        "B_Thoracic_Disc_T1",
        "B_Thoracic_Disc_T10",
        "B_Thoracic_Disc_T11",
        "B_Thoracic_Disc_T12",
        "B_Thoracic_Disc_T2",
        "B_Thoracic_Disc_T3",
        "B_Thoracic_Disc_T5",
        "B_Thoracic_Disc_T6",
        "B_Thoracic_Disc_T7",
        "B_Thoracic_Disc_T8",
        "B_Thoracic_Disc_T9",
        "B_Thoracic_Vertebra_T1",
        "B_Thoracic_Vertebra_T10",
        "B_Thoracic_Vertebra_T11",
        "B_Thoracic_Vertebra_T12",
        "B_Thoracic_Vertebra_T2",
        "B_Thoracic_Vertebra_T3",
        "B_Thoracic_Vertebra_T4",
        "B_Thoracic_Vertebra_T5",
        "B_Thoracic_Vertebra_T6",
        "B_Thoracic_Vertebra_T7",
        "B_Thoracic_Vertebra_T8",
        "B_Thoracic_Vertebra_T9",
        "C_Acetabulum_Lunate_Surface_L",
        "C_Acetabulum_Lunate_Surface_R",
        "C_Articular_Surface_Head_of_Ribs_L",
        "C_Articular_Surface_Head_of_Ribs_R",
        "C_Articular_Surface_Tubercle_of_Ribs_L",
        "C_Articular_Surface_Tubercle_of_Ribs_R",
        "C_Costal_Facets_of_Vertebra_L",
        "C_Costal_Facets_of_Vertebra_R",
        "C_Femur_Head_L",
        "C_Femur_Head_R",
        "C_Transverse_Costal_Facet_of_Vertebra_L",
        "C_Transverse_Costal_Facet_of_Vertebra_R",
        "Epididymis_L",
        "Epididymis_R",
        "External_Iliac_Artery_R",
        "External_Iliac_Vein_R",
        "F_External_Spermatic_Fascia_L",
        "F_External_Spermatic_Fascia_R",
        "F_Internal_Spermatic_Fascia_L",
        "F_Internal_Spermatic_Fascia_R",
        "F_Tunica_Albuginea__Testis__L",
        "F_Tunica_Albuginea__Testis__R",
        "F_Tunica_Vaginalis_L",
        "F_Tunica_Vaginalis_R",
        "F_Tunica_Vasculosa_L",
        "F_Tunica_Vasculosa_R",
        "Femoral_Artery_R",
        "Femoral_Nerve_R",
        "Femoral_Vein_R",
        "L_Inguinal_Ligament_L",
        "L_Inguinal_Ligament_R",
        "L_Inguinal_Ring_L",
        "L_Inguinal_Ring_R",
        "L_Intercrural_Fibers_L",
        "L_Intercrural_Fibers_R",
        "L_Lacunar_Ligament_L",
        "L_Lacunar_Ligament_R",
        "L_Linea_Alba_Ligament",
        "L_Pubic_Symphysis",
        "M_External_Oblique_Muscle_L",
        "M_Iliacus_L",
        "M_Iliacus_R",
        "M_Internal_Oblique_Muscle_L",
        "M_Pectineus_L",
        "M_Pectineus_R",
        "M_Psoas_Major_L",
        "M_Psoas_Major_R",
        "M_Rectus_Abdominus_Muscle_L",
        "M_Rectus_Abdominus_Muscle_R",
        "M_Testis_Cremaster_L",
        "M_Testis_Cremaster_R",
        "M_Trasnversus_Abdominus_Muscle_L",
        "M_Trasnversus_Abdominus_Muscle_R",
        "Seminiferous_Tubules_L",
        "Seminiferous_Tubules_R"
      ],
      "easyMeshNames": [
        "M_Rectus_Abdominus_Muscle_R",
        "M_Trasnversus_Abdominus_Muscle_R"
      ]
    },
    "prometheus-250-a": {
      "sideMode": "Unmodified",
      "initialCameraPosition": {
        "x": 0.01834007689067299,
        "y": 1.3277217722081511,
        "z": -1.4538258106907676
      },
      "visibleMeshNames": ["B_Hipbone_R", "C_Acetabulum_Lunate_Surface_R"],
      "easyMeshNames": ["B_Hipbone_R", "C_Acetabulum_Lunate_Surface_R"]
    },
    "prometheus-252-a": {
      "sideMode": "Unmodified",
      "initialCameraPosition": {
        "x": 0.01834007689067299,
        "y": 1.3277217722081511,
        "z": -1.4538258106907676
      },
      "visibleMeshNames": [
        "B_Femur_L",
        "B_Hipbone_L",
        "C_Acetabulum_Lunate_Surface_L",
        "C_Femur_Head_L"
      ],
      "easyMeshNames": ["B_Femur_L", "B_Hipbone_L"]
    },
    "prometheus-253-a": {
      "sideMode": "Unmodified",
      "initialCameraPosition": {
        "x": 0.01834007689067299,
        "y": 1.3277217722081511,
        "z": -1.4538258106907676
      },
      "visibleMeshNames": [
        "B_Femur_R",
        "B_Hipbone_R",
        "C_Acetabulum_Lunate_Surface_R",
        "C_Femur_Head_R"
      ],
      "easyMeshNames": ["B_Femur_R", "B_Hipbone_R"]
    },
    "prometheus-254-a": {
      "sideMode": "Unmodified",
      "initialCameraPosition": {
        "x": 0.01834007689067299,
        "y": 1.3277217722081511,
        "z": -1.4538258106907676
      },
      "visibleMeshNames": [
        "B_Coccyx",
        "B_Femur_R",
        "B_Hipbone_R",
        "B_Lumbar_Disc_L4",
        "B_Lumbar_Disc_L5",
        "B_Lumbar_Vertebra_L4",
        "B_Lumbar_Vertebra_L5",
        "B_Sacrum",
        "C_Acetabulum_Lunate_Surface_R",
        "C_Femur_Head_R",
        "L_Anterior_Longituinal_Ligament",
        "L_Anterior_Sacroiliac_Ligament_R",
        "L_Iliofemoral_Ligament_R",
        "L_Iliopectineal_Line_R",
        "L_Inguinal_Ligament_R",
        "L_Ischifemoral_Ligament_R",
        "L_Lacunar_Ligament_R",
        "L_Posterior_Sacroiliac_Ligament_R",
        "L_Pubofemoral_Ligament_R",
        "L_Sacrospinous_Ligament_R",
        "L_Sacrotuberous_ligament_R",
        "L_lliolumbar_Ligament_R"
      ],
      "easyMeshNames": [
        "L_Anterior_Longituinal_Ligament",
        "L_Anterior_Sacroiliac_Ligament_R",
        "L_Iliofemoral_Ligament_R",
        "L_Pubofemoral_Ligament_R",
        "L_Sacrospinous_Ligament_R",
        "L_Sacrotuberous_ligament_R",
        "L_lliolumbar_Ligament_R"
      ]
    },
    "prometheus-255-a": {
      "sideMode": "Unmodified",
      "initialCameraPosition": {
        "x": 0.01834007689067299,
        "y": 1.3277217722081511,
        "z": -1.4538258106907676
      },
      "visibleMeshNames": [
        "B_Coccyx",
        "B_Femur_R",
        "B_Hipbone_R",
        "B_Lumbar_Disc_L4",
        "B_Lumbar_Disc_L5",
        "B_Lumbar_Vertebra_L4",
        "B_Lumbar_Vertebra_L5",
        "B_Sacrum",
        "C_Acetabulum_Lunate_Surface_R",
        "C_Femur_Head_R",
        "L_Anterior_Sacroiliac_Ligament_R",
        "L_Deep_Posterior_Sacrococcogeal_Ligament",
        "L_Iliofemoral_Ligament_R",
        "L_Iliopectineal_Line_R",
        "L_Inguinal_Ligament_R",
        "L_Ischifemoral_Ligament_R",
        "L_Lacunar_Ligament_R",
        "L_Posterior_Sacroiliac_Ligament_R",
        "L_Pubofemoral_Ligament_R",
        "L_Sacrospinous_Ligament_R",
        "L_Sacrotuberous_ligament_R",
        "L_lliolumbar_Ligament_R"
      ],
      "easyMeshNames": [
        "B_Hipbone_R",
        "L_Iliofemoral_Ligament_R",
        "L_Ischifemoral_Ligament_R",
        "L_Posterior_Sacroiliac_Ligament_R",
        "L_Sacrospinous_Ligament_R",
        "L_Sacrotuberous_ligament_R",
        "L_lliolumbar_Ligament_R"
      ]
    },
    "prometheus-256-a": {
      "sideMode": "Unmodified",
      "initialCameraPosition": {
        "x": 0.01834007689067299,
        "y": 1.3277217722081511,
        "z": -1.4538258106907676
      },
      "visibleMeshNames": [
        "B_Coccyx",
        "B_Femur_R",
        "B_Fibula_R",
        "B_Hipbone_L",
        "B_Hipbone_R",
        "B_Lumbar_Disc_L4",
        "B_Lumbar_Disc_L5",
        "B_Lumbar_Vertebra_L4",
        "B_Lumbar_Vertebra_L5",
        "B_Patella_R",
        "B_Sacrum",
        "B_Tibia_R",
        "C_Acetabulum_Lunate_Surface_R",
        "C_Articular_Surface_Distal_Part_Femur_R",
        "C_Articular_Surface_Proximal_Tibia_R",
        "C_Femur_Head_R",
        "L_Anterior_Sacroiliac_Ligament_R",
        "L_Articular_Joint_Capsule_R",
        "L_Iliofemoral_Ligament_R",
        "L_Inguinal_Ligament_L",
        "L_Inguinal_Ligament_R",
        "L_Interosseus_Membrane_R",
        "L_Ischifemoral_Ligament_R",
        "L_Lacunar_Ligament_L",
        "L_Lacunar_Ligament_R",
        "L_Lateral_Collateral_Ligament_R",
        "L_Lateral_Patellar_Retinaculum_R",
        "L_Lateral_Patellotibial_Ligament_R",
        "L_Medial_Collateral_Ligament_R",
        "L_Medial_Patellar_Retinaculum_R",
        "L_Medial_Patellotibial_Ligament_R",
        "L_Patellar_Ligament_R",
        "L_Pubic_Symphysis",
        "L_Pubofemoral_Ligament_R",
        "L_Sacrospinous_Ligament_R",
        "L_Sacrotuberous_ligament_R",
        "L_lliolumbar_Ligament_R",
        "M_Adductor_Brevis_R",
        "M_Adductor_Longus_R",
        "M_Fibularis_Brevis_R",
        "M_Fibularis_Longubs_R",
        "M_Gastrocnemius_Lateral_R",
        "M_Gastrocnemius_Medial_R",
        "M_Gracilis_R",
        "M_Iliacus_R",
        "M_Iliotibial_Tract_R",
        "M_Pectineus_R",
        "M_Piriformis_R",
        "M_Psoas_Major_R",
        "M_Rectus_Femoris_R",
        "M_Sartorius_R",
        "M_Semitendinosus_R",
        "M_Soleus_R",
        "M_Tibialis_Anterior_R",
        "M_Vastus_Lateralis_R",
        "M_Vastus_Medialis_R"
      ],
      "easyMeshNames": [
        "M_Iliacus_R",
        "M_Iliotibial_Tract_R",
        "M_Psoas_Major_R",
        "M_Rectus_Femoris_R",
        "M_Sartorius_R",
        "M_Vastus_Lateralis_R",
        "M_Vastus_Medialis_R"
      ]
    },
    "prometheus-257-a": {
      "sideMode": "Unmodified",
      "initialCameraPosition": {
        "x": 0.01834007689067299,
        "y": 1.3277217722081511,
        "z": -1.4538258106907676
      },
      "visibleMeshNames": [
        "B_Coccyx",
        "B_Femur_R",
        "B_Fibula_R",
        "B_Hipbone_L",
        "B_Hipbone_R",
        "B_Lumbar_Disc_L4",
        "B_Lumbar_Disc_L5",
        "B_Lumbar_Vertebra_L4",
        "B_Lumbar_Vertebra_L5",
        "B_Patella_R",
        "B_Sacrum",
        "B_Tibia_R",
        "C_Acetabulum_Lunate_Surface_R",
        "C_Articular_Surface_Distal_Part_Femur_R",
        "C_Articular_Surface_Proximal_Tibia_R",
        "C_Femur_Head_R",
        "L_Anterior_Longituinal_Ligament",
        "L_Anterior_Sacroiliac_Ligament_R",
        "L_Articular_Joint_Capsule_R",
        "L_Iliofemoral_Ligament_R",
        "L_Interosseus_Membrane_R",
        "L_Ischifemoral_Ligament_R",
        "L_Lateral_Collateral_Ligament_R",
        "L_Lateral_Patellar_Retinaculum_R",
        "L_Lateral_Patellotibial_Ligament_R",
        "L_Medial_Collateral_Ligament_R",
        "L_Medial_Patellar_Retinaculum_R",
        "L_Medial_Patellotibial_Ligament_R",
        "L_Patellar_Ligament_R",
        "L_Pubic_Symphysis",
        "L_Pubofemoral_Ligament_R",
        "L_Sacrospinous_Ligament_R",
        "L_Sacrotuberous_ligament_R",
        "L_lliolumbar_Ligament_R",
        "M_Adductor_Brevis_R",
        "M_Adductor_Longus_R",
        "M_Adductor_Magnus_R",
        "M_Fibularis_Brevis_R",
        "M_Fibularis_Longubs_R",
        "M_Gastrocnemius_Lateral_R",
        "M_Gastrocnemius_Medial_R",
        "M_Gluteus_Medius_R",
        "M_Gluteus_Minimus_R",
        "M_Gracilis_R",
        "M_Pectineus_R",
        "M_Piriformis_R",
        "M_Soleus_R",
        "M_Tibialis_Anterior_R",
        "M_Vastus_Intermedius_R"
      ],
      "easyMeshNames": [
        "L_Patellar_Ligament_R",
        "M_Adductor_Brevis_R",
        "M_Adductor_Longus_R",
        "M_Pectineus_R",
        "M_Vastus_Intermedius_R"
      ]
    },
    "prometheus-258-a": {
      "sideMode": "Unmodified",
      "initialCameraPosition": {
        "x": 0.01834007689067299,
        "y": 1.3277217722081511,
        "z": -1.4538258106907676
      },
      "visibleMeshNames": [
        "B_Coccyx",
        "B_Femur_R",
        "B_Fibula_R",
        "B_Hipbone_L",
        "B_Hipbone_R",
        "B_Lumbar_Disc_L4",
        "B_Lumbar_Disc_L5",
        "B_Lumbar_Vertebra_L4",
        "B_Lumbar_Vertebra_L5",
        "B_Patella_R",
        "B_Sacrum",
        "B_Tibia_R",
        "C_Acetabulum_Lunate_Surface_R",
        "C_Articular_Surface_Distal_Part_Femur_R",
        "C_Articular_Surface_Proximal_Tibia_R",
        "C_Femur_Head_R",
        "L_Iliofemoral_Ligament_R",
        "L_Interosseus_Membrane_R",
        "L_Ischifemoral_Ligament_R",
        "L_Pubic_Symphysis",
        "L_Pubofemoral_Ligament_R",
        "L_Sacrospinous_Ligament_R",
        "L_Sacrotuberous_ligament_R",
        "M_Adductor_Brevis_R",
        "M_Adductor_Magnus_R",
        "M_Gluteus_Medius_R",
        "M_Gluteus_Minimus_R",
        "M_Gracilis_R",
        "M_Obturator_Externus_R",
        "M_Piriformis_R"
      ],
      "easyMeshNames": [
        "B_Femur_R",
        "B_Hipbone_R",
        "M_Adductor_Brevis_R",
        "M_Adductor_Magnus_R",
        "M_Gracilis_R",
        "M_Obturator_Externus_R"
      ]
    },
    "prometheus-259-a": {
      "sideMode": "Unmodified",
      "initialCameraPosition": {
        "x": 0.01834007689067299,
        "y": 1.3277217722081511,
        "z": -1.4538258106907676
      },
      "visibleMeshNames": [
        "B_Coccyx",
        "B_Femur_R",
        "B_Fibula_R",
        "B_Hipbone_R",
        "B_Lumbar_Disc_L1",
        "B_Lumbar_Disc_L2",
        "B_Lumbar_Disc_L3",
        "B_Lumbar_Disc_L4",
        "B_Lumbar_Disc_L5",
        "B_Lumbar_Vertebra_L1",
        "B_Lumbar_Vertebra_L2",
        "B_Lumbar_Vertebra_L3",
        "B_Lumbar_Vertebra_L4",
        "B_Lumbar_Vertebra_L5",
        "B_Patella_R",
        "B_Sacrum",
        "B_Thoracic_Disc_T12",
        "B_Thoracic_Vertebra_T12",
        "B_Tibia_R",
        "C_Acetabulum_Lunate_Surface_R",
        "C_Articular_Surface_Distal_Part_Femur_R",
        "C_Articular_Surface_Proximal_Tibia_R",
        "C_Femur_Head_R",
        "L_Anterior_Sacroiliac_Ligament_R",
        "L_Articular_Joint_Capsule_R",
        "L_Iliofemoral_Ligament_R",
        "L_Iliopectineal_Line_R",
        "L_Inguinal_Ligament_R",
        "L_Interosseus_Membrane_R",
        "L_Ischifemoral_Ligament_R",
        "L_Lacunar_Ligament_R",
        "L_Lateral_Collateral_Ligament_R",
        "L_Lateral_Patellar_Retinaculum_R",
        "L_Lateral_Patellotibial_Ligament_R",
        "L_Medial_Collateral_Ligament_R",
        "L_Medial_Patellar_Retinaculum_R",
        "L_Medial_Patellotibial_Ligament_R",
        "L_Patellar_Ligament_R",
        "L_Pubofemoral_Ligament_R",
        "L_Sacrospinous_Ligament_R",
        "L_Sacrotuberous_ligament_R",
        "L_lliolumbar_Ligament_R",
        "M_Adductor_Brevis_R",
        "M_Adductor_Longus_R",
        "M_Adductor_Magnus_R",
        "M_Biceps_Femoris_Long_Head_R",
        "M_Biceps_Femoris_Short_Head_R",
        "M_Gastrocnemius_Lateral_R",
        "M_Gastrocnemius_Medial_R",
        "M_Gluteus_Maximus_R",
        "M_Gluteus_Medius_R",
        "M_Gluteus_Minimus_R",
        "M_Gracilis_R",
        "M_Iliacus_R",
        "M_Iliotibial_Tract_R",
        "M_Obturator_Externus_R",
        "M_Obturator_Internus_R",
        "M_Pectineus_R",
        "M_Piriformis_R",
        "M_Psoas_Major_R",
        "M_Rectus_Femoris_R",
        "M_Sartorius_R",
        "M_Semimembranosus_R",
        "M_Semitendinosus_R",
        "M_Soleus_R",
        "M_Vastus_Lateralis_R",
        "M_Vastus_Medialis_R"
      ],
      "easyMeshNames": [
        "B_Sacrum",
        "M_Adductor_Magnus_R",
        "M_Gluteus_Maximus_R",
        "M_Gracilis_R",
        "M_Obturator_Internus_R",
        "M_Piriformis_R",
        "M_Sartorius_R",
        "M_Vastus_Medialis_R"
      ]
    },
    "prometheus-260-a": {
      "sideMode": "Unmodified",
      "initialCameraPosition": {
        "x": 0.01834007689067299,
        "y": 1.3277217722081511,
        "z": -1.4538258106907676
      },
      "visibleMeshNames": [
        "B_Coccyx",
        "B_Femur_R",
        "B_Fibula_R",
        "B_Hipbone_R",
        "B_Lumbar_Disc_L4",
        "B_Lumbar_Disc_L5",
        "B_Lumbar_Vertebra_L4",
        "B_Lumbar_Vertebra_L5",
        "B_Patella_R",
        "B_Sacrum",
        "B_Tibia_R",
        "C_Acetabulum_Lunate_Surface_R",
        "C_Articular_Surface_Distal_Part_Femur_R",
        "C_Articular_Surface_Proximal_Tibia_R",
        "C_Femur_Head_R",
        "L_Anterior_Sacroiliac_Ligament_R",
        "L_Iliofemoral_Ligament_R",
        "L_Iliopectineal_Line_R",
        "L_Inguinal_Ligament_R",
        "L_Interosseus_Membrane_R",
        "L_Ischifemoral_Ligament_R",
        "L_Lacunar_Ligament_R",
        "L_Lateral_Collateral_Ligament_R",
        "L_Medial_Collateral_Ligament_R",
        "L_Posterior_Sacroiliac_Ligament_R",
        "L_Pubofemoral_Ligament_R",
        "L_Sacrospinous_Ligament_R",
        "L_Sacrotuberous_ligament_R",
        "L_lliolumbar_Ligament_R",
        "M_Adductor_Brevis_R",
        "M_Adductor_Longus_R",
        "M_Adductor_Magnus_R",
        "M_Biceps_Femoris_Long_Head_R",
        "M_Biceps_Femoris_Short_Head_R",
        "M_Extensor_Digitorum_Longus_R",
        "M_Fibularis_Brevis_R",
        "M_Fibularis_Longubs_R",
        "M_Gastrocnemius_Lateral_R",
        "M_Gastrocnemius_Medial_R",
        "M_Gluteus_Maximus_R",
        "M_Gluteus_Medius_R",
        "M_Gluteus_Minimus_R",
        "M_Gracilis_R",
        "M_Iliacus_R",
        "M_Iliotibial_Tract_R",
        "M_Obturator_Externus_R",
        "M_Obturator_Internus_R",
        "M_Pectineus_R",
        "M_Piriformis_R",
        "M_Rectus_Femoris_R",
        "M_Sartorius_R",
        "M_Semimembranosus_R",
        "M_Semitendinosus_R",
        "M_Soleus_R",
        "M_Tibialis_Anterior_R",
        "M_Vastus_Intermedius_R",
        "M_Vastus_Lateralis_R",
        "M_Vastus_Medialis_R"
      ],
      "easyMeshNames": [
        "B_Femur_R",
        "M_Biceps_Femoris_Long_Head_R",
        "M_Gastrocnemius_Lateral_R",
        "M_Gastrocnemius_Medial_R",
        "M_Gluteus_Maximus_R",
        "M_Gluteus_Medius_R",
        "M_Iliotibial_Tract_R",
        "M_Semimembranosus_R",
        "M_Semitendinosus_R"
      ]
    },
    "prometheus-261-a": {
      "sideMode": "Unmodified",
      "initialCameraPosition": {
        "x": 0.01834007689067299,
        "y": 1.3277217722081511,
        "z": -1.4538258106907676
      },
      "visibleMeshNames": [
        "B_Coccyx",
        "B_Femur_R",
        "B_Fibula_R",
        "B_Hipbone_R",
        "B_Lumbar_Disc_L1",
        "B_Lumbar_Disc_L2",
        "B_Lumbar_Disc_L3",
        "B_Lumbar_Disc_L4",
        "B_Lumbar_Disc_L5",
        "B_Lumbar_Vertebra_L1",
        "B_Lumbar_Vertebra_L2",
        "B_Lumbar_Vertebra_L3",
        "B_Lumbar_Vertebra_L4",
        "B_Lumbar_Vertebra_L5",
        "B_Patella_R",
        "B_Sacrum",
        "B_Thoracic_Disc_T12",
        "B_Thoracic_Vertebra_T12",
        "B_Tibia_R",
        "C_Acetabulum_Lunate_Surface_R",
        "C_Articular_Surface_Distal_Part_Femur_R",
        "C_Articular_Surface_Proximal_Tibia_R",
        "C_Femur_Head_R",
        "L_Anterior_Sacroiliac_Ligament_R",
        "L_Iliofemoral_Ligament_R",
        "L_Inguinal_Ligament_R",
        "L_Ischifemoral_Ligament_R",
        "L_Lacunar_Ligament_R",
        "L_Lateral_Collateral_Ligament_R",
        "L_Medial_Collateral_Ligament_R",
        "L_Posterior_Sacroiliac_Ligament_R",
        "L_Pubofemoral_Ligament_R",
        "L_Sacrospinous_Ligament_R",
        "L_Sacrotuberous_ligament_R",
        "L_lliolumbar_Ligament_R",
        "M_Adductor_Brevis_R",
        "M_Adductor_Longus_R",
        "M_Adductor_Magnus_R",
        "M_Biceps_Femoris_Long_Head_R",
        "M_Biceps_Femoris_Short_Head_R",
        "M_Gastrocnemius_Lateral_R",
        "M_Gastrocnemius_Medial_R",
        "M_Gemellus_Interior_R",
        "M_Gemellus_Superior_R",
        "M_Gluteus_Minimus_R",
        "M_Gracilis_R",
        "M_Iliacus_R",
        "M_Iliotibial_Tract_R",
        "M_Obturator_Externus_R",
        "M_Obturator_Internus_R",
        "M_Pectineus_R",
        "M_Piriformis_R",
        "M_Psoas_Major_R",
        "M_Quadratus_Femoris_R",
        "M_Rectus_Femoris_R",
        "M_Sartorius_R",
        "M_Semimembranosus_R",
        "M_Semitendinosus_R",
        "M_Soleus_R",
        "M_Vastus_Intermedius_R",
        "M_Vastus_Lateralis_R",
        "M_Vastus_Medialis_R"
      ],
      "easyMeshNames": [
        "B_Hipbone_R",
        "L_Sacrotuberous_ligament_R",
        "M_Gluteus_Minimus_R",
        "M_Iliotibial_Tract_R",
        "M_Obturator_Internus_R",
        "M_Piriformis_R",
        "M_Quadratus_Femoris_R"
      ]
    },
    "prometheus-262-a": {
      "sideMode": "Unmodified",
      "initialCameraPosition": {
        "x": 0.01834007689067299,
        "y": 1.3277217722081511,
        "z": -1.4538258106907676
      },
      "visibleMeshNames": [
        "B_Coccyx",
        "B_Femur_R",
        "B_Fibula_R",
        "B_Hipbone_R",
        "B_Lumbar_Disc_L4",
        "B_Lumbar_Disc_L5",
        "B_Lumbar_Vertebra_L4",
        "B_Lumbar_Vertebra_L5",
        "B_Patella_R",
        "B_Sacrum",
        "B_Tibia_R",
        "C_Acetabulum_Lunate_Surface_R",
        "C_Articular_Surface_Distal_Part_Femur_R",
        "C_Articular_Surface_Proximal_Tibia_R",
        "C_Femur_Head_R",
        "L_Anterior_Sacroiliac_Ligament_R",
        "L_Iliofemoral_Ligament_R",
        "L_Inguinal_Ligament_R",
        "L_Ischifemoral_Ligament_R",
        "L_Lacunar_Ligament_R",
        "L_Lateral_Collateral_Ligament_R",
        "L_Medial_Collateral_Ligament_R",
        "L_Pubofemoral_Ligament_R",
        "L_Sacrospinous_Ligament_R",
        "L_Sacrotuberous_ligament_R",
        "M_Adductor_Brevis_R",
        "M_Adductor_Longus_R",
        "M_Adductor_Magnus_R",
        "M_Biceps_Femoris_Short_Head_R",
        "M_Gastrocnemius_Lateral_R",
        "M_Gastrocnemius_Medial_R",
        "M_Gemellus_Interior_R",
        "M_Gemellus_Superior_R",
        "M_Gluteus_Minimus_R",
        "M_Gracilis_R",
        "M_Iliacus_R",
        "M_Iliotibial_Tract_R",
        "M_Obturator_Externus_R",
        "M_Obturator_Internus_R",
        "M_Pectineus_R",
        "M_Piriformis_R",
        "M_Quadratus_Femoris_R",
        "M_Rectus_Femoris_R",
        "M_Sartorius_R",
        "M_Semimembranosus_R",
        "M_Soleus_R",
        "M_Vastus_Lateralis_R",
        "M_Vastus_Medialis_R"
      ],
      "easyMeshNames": [
        "B_Femur_R",
        "B_Hipbone_R",
        "B_Sacrum",
        "L_Sacrotuberous_ligament_R",
        "M_Adductor_Magnus_R",
        "M_Biceps_Femoris_Short_Head_R",
        "M_Gracilis_R"
      ]
    },
    "prometheus-263-a": {
      "sideMode": "Unmodified",
      "initialCameraPosition": {
        "x": 0.01834007689067299,
        "y": 1.3277217722081511,
        "z": -1.4538258106907676
      },
      "visibleMeshNames": [
        "B_Coccyx",
        "B_Femur_R",
        "B_Fibula_R",
        "B_Hipbone_R",
        "B_Lumbar_Disc_L4",
        "B_Lumbar_Disc_L5",
        "B_Lumbar_Vertebra_L4",
        "B_Lumbar_Vertebra_L5",
        "B_Patella_R",
        "B_Sacrum",
        "B_Tibia_R",
        "C_Acetabulum_Lunate_Surface_R",
        "C_Articular_Surface_Distal_Part_Femur_R",
        "C_Articular_Surface_Proximal_Tibia_R",
        "C_Femur_Head_R",
        "L_Anterior_Fibular_Head_Ligament_R",
        "L_Anterior_Sacroiliac_Ligament_R",
        "L_Arcuate_Popliteal_Ligament_R",
        "L_Articular_Joint_Capsule_R",
        "L_Iliofemoral_Ligament_R",
        "L_Ischifemoral_Ligament_R",
        "L_Lateral_Collateral_Ligament_R",
        "L_Lateral_Patellar_Retinaculum_R",
        "L_Lateral_Patellofemoral_Ligament_R",
        "L_Lateral_Patellotibial_Ligament_R",
        "L_Medial_Collateral_Ligament_R",
        "L_Medial_Patellar_Retinaculum_R",
        "L_Medial_Patellofemoral_Ligament_R",
        "L_Medial_Patellotibial_Ligament_R",
        "L_Patellar_Ligament_R",
        "L_Posterior_Fibular_Head_Ligament_R",
        "L_Pubofemoral_Ligament_R",
        "L_lliolumbar_Ligament_R",
        "M_Adductor_Brevis_R",
        "M_Adductor_Longus_R",
        "M_Adductor_Magnus_R",
        "M_Biceps_Femoris_Long_Head_R",
        "M_Biceps_Femoris_Short_Head_R",
        "M_Extensor_Digitorum_Longus_R",
        "M_Fibularis_Brevis_R",
        "M_Fibularis_Longubs_R",
        "M_Gastrocnemius_Lateral_R",
        "M_Gastrocnemius_Medial_R",
        "M_Gluteus_Maximus_R",
        "M_Gluteus_Medius_R",
        "M_Gracilis_R",
        "M_Iliacus_R",
        "M_Iliotibial_Tract_R",
        "M_Pectineus_R",
        "M_Rectus_Femoris_R",
        "M_Sartorius_R",
        "M_Semimembranosus_R",
        "M_Soleus_R",
        "M_Tibialis_Anterior_R",
        "M_Vastus_Intermedius_R",
        "M_Vastus_Lateralis_R",
        "M_Vastus_Medialis_R"
      ],
      "easyMeshNames": [
        "B_Fibula_R",
        "B_Hipbone_R",
        "M_Biceps_Femoris_Long_Head_R",
        "M_Gluteus_Maximus_R",
        "M_Gluteus_Medius_R",
        "M_Iliotibial_Tract_R",
        "M_Vastus_Lateralis_R"
      ]
    },
    "prometheus-264-a": {
      "sideMode": "Unmodified",
      "initialCameraPosition": {
        "x": 0.01834007689067299,
        "y": 1.3277217722081511,
        "z": -1.4538258106907676
      },
      "visibleMeshNames": [
        "B_Coccyx",
        "B_Femur_R",
        "B_Hipbone_L",
        "B_Hipbone_R",
        "B_Lumbar_Disc_L1",
        "B_Lumbar_Disc_L2",
        "B_Lumbar_Disc_L3",
        "B_Lumbar_Disc_L4",
        "B_Lumbar_Disc_L5",
        "B_Lumbar_Vertebra_L1",
        "B_Lumbar_Vertebra_L2",
        "B_Lumbar_Vertebra_L3",
        "B_Lumbar_Vertebra_L4",
        "B_Lumbar_Vertebra_L5",
        "B_Rib_12_R",
        "B_Sacrum",
        "B_Thoracic_Disc_T12",
        "B_Thoracic_Vertebra_T12",
        "C_Femur_Head_R",
        "L_Iliopectineal_Line_R",
        "L_Inguinal_Ligament_R",
        "L_Lacunar_Ligament_R",
        "L_Pubic_Symphysis",
        "L_Sacrospinous_Ligament_R",
        "L_Sacrotuberous_ligament_R",
        "M_Iliacus_R",
        "M_Psoas_Major_R"
      ],
      "easyMeshNames": [
        "B_Femur_R",
        "B_Hipbone_R",
        "B_Lumbar_Vertebra_L5",
        "B_Rib_12_R",
        "L_Sacrotuberous_ligament_R",
        "M_Iliacus_R",
        "M_Psoas_Major_R"
      ]
    },
    "prometheus-265-a": {
      "sideMode": "Unmodified",
      "initialCameraPosition": {
        "x": 0.01834007689067299,
        "y": 1.3277217722081511,
        "z": -1.4538258106907676
      },
      "visibleMeshNames": [
        "B_Femur_R",
        "B_Fibula_R",
        "B_Hipbone_L",
        "B_Hipbone_R",
        "B_Lumbar_Disc_L4",
        "B_Lumbar_Disc_L5",
        "B_Lumbar_Vertebra_L4",
        "B_Lumbar_Vertebra_L5",
        "B_Sacrum",
        "B_Tibia_R",
        "C_Articular_Surface_Distal_Part_Femur_R",
        "C_Articular_Surface_Fibular_Tibia_R",
        "C_Articular_Surface_Proximal_Tibia_R",
        "C_Femur_Head_R",
        "F_Thoracolumbar_Fascia",
        "L_Interosseus_Membrane_R",
        "L_Posterior_Sacroiliac_Ligament_R",
        "L_Pubic_Symphysis",
        "L_Sacrospinous_Ligament_R",
        "L_Sacrotuberous_ligament_R",
        "M_Gluteus_Maximus_R",
        "M_Gluteus_Medius_R",
        "M_Iliotibial_Tract_R"
      ],
      "easyMeshNames": [
        "B_Fibula_R",
        "B_Tibia_R",
        "F_Thoracolumbar_Fascia",
        "L_Interosseus_Membrane_R",
        "M_Gluteus_Maximus_R",
        "M_Gluteus_Medius_R",
        "M_Iliotibial_Tract_R"
      ]
    },
    "prometheus-265-b": {
      "sideMode": "Unmodified",
      "initialCameraPosition": {
        "x": 0.01834007689067299,
        "y": 1.3277217722081511,
        "z": -1.4538258106907676
      },
      "visibleMeshNames": [
        "B_Femur_R",
        "B_Fibula_R",
        "B_Hipbone_L",
        "B_Hipbone_R",
        "B_Lumbar_Disc_L4",
        "B_Lumbar_Disc_L5",
        "B_Lumbar_Vertebra_L4",
        "B_Lumbar_Vertebra_L5",
        "B_Sacrum",
        "B_Tibia_R",
        "C_Articular_Surface_Distal_Part_Femur_R",
        "C_Articular_Surface_Fibular_Tibia_R",
        "C_Articular_Surface_Proximal_Tibia_R",
        "C_Femur_Head_R",
        "L_Interosseus_Membrane_R",
        "L_Posterior_Sacroiliac_Ligament_R",
        "L_Pubic_Symphysis",
        "L_Sacrospinous_Ligament_R",
        "L_Sacrotuberous_ligament_R",
        "M_Iliotibial_Tract_R"
      ],
      "easyMeshNames": ["B_Hipbone_R", "B_Sacrum", "M_Iliotibial_Tract_R"]
    },
    "prometheus-266-a": {
      "sideMode": "Unmodified",
      "initialCameraPosition": {
        "x": 0.01834007689067299,
        "y": 1.3277217722081511,
        "z": -1.4538258106907676
      },
      "visibleMeshNames": [
        "B_Coccyx",
        "B_Femur_R",
        "B_Hipbone_R",
        "B_Lumbar_Disc_L4",
        "B_Lumbar_Disc_L5",
        "B_Lumbar_Vertebra_L4",
        "B_Lumbar_Vertebra_L5",
        "B_Sacrum",
        "C_Femur_Head_R",
        "L_Sacrospinous_Ligament_R",
        "L_Sacrotuberous_ligament_R",
        "M_Gemellus_Interior_R",
        "M_Gemellus_Superior_R",
        "M_Gluteus_Medius_R",
        "M_Gluteus_Minimus_R",
        "M_Obturator_Internus_R",
        "M_Piriformis_R",
        "M_Quadratus_Femoris_R"
      ],
      "easyMeshNames": [
        "B_Femur_R",
        "B_Hipbone_R",
        "L_Sacrotuberous_ligament_R",
        "M_Gemellus_Interior_R",
        "M_Gemellus_Superior_R",
        "M_Gluteus_Medius_R",
        "M_Obturator_Internus_R",
        "M_Piriformis_R",
        "M_Quadratus_Femoris_R"
      ]
    },
    "prometheus-267-a": {
      "sideMode": "Unmodified",
      "initialCameraPosition": {
        "x": 0.01834007689067299,
        "y": 1.3277217722081511,
        "z": -1.4538258106907676
      },
      "visibleMeshNames": [
        "B_Coccyx",
        "B_Femur_R",
        "B_Hipbone_R",
        "B_Lumbar_Disc_L4",
        "B_Lumbar_Disc_L5",
        "B_Lumbar_Vertebra_L4",
        "B_Lumbar_Vertebra_L5",
        "B_Sacrum",
        "C_Femur_Head_R",
        "L_Sacrospinous_Ligament_R",
        "L_Sacrotuberous_ligament_R",
        "M_Gemellus_Interior_R",
        "M_Gemellus_Superior_R",
        "M_Gluteus_Minimus_R",
        "M_Obturator_Internus_R",
        "M_Piriformis_R",
        "M_Quadratus_Femoris_R"
      ],
      "easyMeshNames": [
        "B_Femur_R",
        "B_Hipbone_R",
        "B_Sacrum",
        "M_Gemellus_Interior_R",
        "M_Gemellus_Superior_R",
        "M_Gluteus_Minimus_R",
        "M_Obturator_Internus_R",
        "M_Piriformis_R",
        "M_Quadratus_Femoris_R"
      ]
    },
    "prometheus-269-a": {
      "sideMode": "Unmodified",
      "initialCameraPosition": {
        "x": 0.01834007689067299,
        "y": 1.3277217722081511,
        "z": -1.4538258106907676
      },
      "visibleMeshNames": [
        "B_Coccyx",
        "B_Femur_R",
        "B_Fibula_R",
        "B_Hipbone_L",
        "B_Hipbone_R",
        "B_Lumbar_Disc_L4",
        "B_Lumbar_Disc_L5",
        "B_Lumbar_Vertebra_L4",
        "B_Lumbar_Vertebra_L5",
        "B_Patella_R",
        "B_Sacrum",
        "B_Tibia_R",
        "C_Articular_Surface_Fibular_Tibia_R",
        "C_Articular_Surface_Head_Fibula_R",
        "C_Articular_Surface_Patella_R",
        "C_Articular_Surface_Proximal_Tibia_R",
        "C_Femur_Head_R",
        "L_Iliopectineal_Line_L",
        "L_Iliopectineal_Line_R",
        "L_Interosseus_Membrane_R",
        "L_Pubic_Symphysis",
        "L_Sacrospinous_Ligament_L",
        "L_Sacrospinous_Ligament_R",
        "L_Sacrotuberous_ligament_L",
        "L_Sacrotuberous_ligament_R",
        "M_Adductor_Brevis_R",
        "M_Adductor_Longus_R",
        "M_Adductor_Magnus_R",
        "M_Gracilis_R",
        "M_Obturator_Externus_R",
        "M_Pectineus_R"
      ],
      "easyMeshNames": [
        "B_Femur_R",
        "B_Hipbone_R",
        "B_Lumbar_Disc_L5",
        "B_Patella_R",
        "B_Tibia_R",
        "M_Adductor_Magnus_R",
        "M_Pectineus_R"
      ]
    },
    "prometheus-269-b": {
      "sideMode": "Unmodified",
      "initialCameraPosition": {
        "x": 0.01834007689067299,
        "y": 1.3277217722081511,
        "z": -1.4538258106907676
      },
      "visibleMeshNames": [
        "B_Coccyx",
        "B_Femur_R",
        "B_Fibula_R",
        "B_Hipbone_L",
        "B_Hipbone_R",
        "B_Lumbar_Disc_L4",
        "B_Lumbar_Disc_L5",
        "B_Lumbar_Vertebra_L4",
        "B_Lumbar_Vertebra_L5",
        "B_Patella_R",
        "B_Sacrum",
        "B_Tibia_R",
        "C_Articular_Surface_Fibular_Tibia_R",
        "C_Articular_Surface_Head_Fibula_R",
        "C_Articular_Surface_Patella_R",
        "C_Articular_Surface_Proximal_Tibia_R",
        "C_Femur_Head_R",
        "L_Iliopectineal_Line_L",
        "L_Iliopectineal_Line_R",
        "L_Interosseus_Membrane_R",
        "L_Pubic_Symphysis",
        "L_Sacrospinous_Ligament_L",
        "L_Sacrospinous_Ligament_R",
        "L_Sacrotuberous_ligament_L",
        "L_Sacrotuberous_ligament_R",
        "M_Adductor_Magnus_R",
        "M_Gracilis_R",
        "M_Obturator_Externus_R"
      ],
      "easyMeshNames": ["M_Adductor_Magnus_R", "M_Obturator_Externus_R"]
    },
    "prometheus-270-a": {
      "sideMode": "Unmodified",
      "initialCameraPosition": {
        "x": 0.01834007689067299,
        "y": 1.3277217722081511,
        "z": -1.4538258106907676
      },
      "visibleMeshNames": [
        "B_Coccyx",
        "B_Femur_R",
        "B_Fibula_R",
        "B_Hipbone_L",
        "B_Hipbone_R",
        "B_Lumbar_Disc_L4",
        "B_Lumbar_Disc_L5",
        "B_Lumbar_Vertebra_L4",
        "B_Lumbar_Vertebra_L5",
        "B_Patella_R",
        "B_Sacrum",
        "B_Tibia_R",
        "C_Articular_Surface_Fibular_Tibia_R",
        "C_Articular_Surface_Head_Fibula_R",
        "C_Articular_Surface_Patella_R",
        "C_Articular_Surface_Proximal_Tibia_R",
        "C_Femur_Head_R",
        "L_Iliopectineal_Line_L",
        "L_Iliopectineal_Line_R",
        "L_Interosseus_Membrane_R",
        "L_Pubic_Symphysis",
        "L_Sacrospinous_Ligament_L",
        "L_Sacrospinous_Ligament_R",
        "L_Sacrotuberous_ligament_L",
        "L_Sacrotuberous_ligament_R",
        "M_Adductor_Brevis_R",
        "M_Adductor_Longus_R",
        "M_Adductor_Magnus_R",
        "M_Gracilis_R",
        "M_Obturator_Externus_R",
        "M_Pectineus_R"
      ],
      "easyMeshNames": [
        "B_Femur_R",
        "M_Adductor_Brevis_R",
        "M_Adductor_Longus_R",
        "M_Adductor_Magnus_R",
        "M_Gracilis_R",
        "M_Pectineus_R"
      ]
    },
    "prometheus-271-a": {
      "sideMode": "Unmodified",
      "initialCameraPosition": {
        "x": 0.01834007689067299,
        "y": 1.3277217722081511,
        "z": -1.4538258106907676
      },
      "visibleMeshNames": [
        "B_Femur_R",
        "B_Fibula_R",
        "B_Hipbone_L",
        "B_Hipbone_R",
        "B_Patella_R",
        "B_Sacrum",
        "B_Tibia_R",
        "C_Acetabulum_Lunate_Surface_R",
        "C_Articular_Surface_Distal_Part_Femur_R",
        "C_Articular_Surface_Proximal_Tibia_R",
        "C_Femur_Head_R",
        "L_Interosseus_Membrane_R",
        "L_Lateral_Patellar_Retinaculum_R",
        "L_Medial_Patellar_Retinaculum_R",
        "L_Patellar_Ligament_R",
        "L_Pubic_Symphysis",
        "L_Sacrospinous_Ligament_R",
        "L_Sacrotuberous_ligament_R",
        "M_Rectus_Femoris_R",
        "M_Sartorius_R",
        "M_Vastus_Intermedius_R",
        "M_Vastus_Lateralis_R",
        "M_Vastus_Medialis_R"
      ],
      "easyMeshNames": [
        "B_Femur_R",
        "B_Hipbone_R",
        "L_Lateral_Patellar_Retinaculum_R",
        "L_Medial_Patellar_Retinaculum_R",
        "L_Patellar_Ligament_R",
        "M_Rectus_Femoris_R",
        "M_Sartorius_R",
        "M_Vastus_Lateralis_R",
        "M_Vastus_Medialis_R"
      ]
    },
    "prometheus-272-a": {
      "sideMode": "Unmodified",
      "initialCameraPosition": {
        "x": 0.01834007689067299,
        "y": 1.3277217722081511,
        "z": -1.4538258106907676
      },
      "visibleMeshNames": [
        "B_Femur_R",
        "B_Fibula_R",
        "B_Hipbone_L",
        "B_Hipbone_R",
        "B_Patella_R",
        "B_Sacrum",
        "B_Tibia_R",
        "C_Acetabulum_Lunate_Surface_R",
        "C_Articular_Surface_Proximal_Tibia_R",
        "C_Femur_Head_R",
        "L_Interosseus_Membrane_R",
        "L_Lateral_Patellar_Retinaculum_R",
        "L_Medial_Patellar_Retinaculum_R",
        "L_Patellar_Ligament_R",
        "L_Pubic_Symphysis",
        "L_Sacrospinous_Ligament_R",
        "L_Sacrotuberous_ligament_R",
        "M_Vastus_Intermedius_R",
        "M_Vastus_Lateralis_R",
        "M_Vastus_Medialis_R"
      ],
      "easyMeshNames": [
        "B_Femur_R",
        "M_Vastus_Intermedius_R",
        "M_Vastus_Lateralis_R",
        "M_Vastus_Medialis_R"
      ]
    },
    "prometheus-272-b": {
      "sideMode": "Unmodified",
      "initialCameraPosition": {
        "x": 0.01834007689067299,
        "y": 1.3277217722081511,
        "z": -1.4538258106907676
      },
      "visibleMeshNames": [
        "B_Femur_R",
        "B_Fibula_R",
        "B_Hipbone_L",
        "B_Hipbone_R",
        "B_Patella_R",
        "B_Sacrum",
        "B_Tibia_R",
        "C_Acetabulum_Lunate_Surface_R",
        "C_Articular_Surface_Proximal_Tibia_R",
        "C_Femur_Head_R",
        "L_Interosseus_Membrane_R",
        "L_Lateral_Patellar_Retinaculum_R",
        "L_Medial_Patellar_Retinaculum_R",
        "L_Patellar_Ligament_R",
        "L_Pubic_Symphysis",
        "L_Sacrospinous_Ligament_R",
        "L_Sacrotuberous_ligament_R",
        "M_Rectus_Femoris_R",
        "M_Vastus_Intermedius_R",
        "M_Vastus_Lateralis_R",
        "M_Vastus_Medialis_R"
      ],
      "easyMeshNames": ["M_Rectus_Femoris_R"]
    },
    "prometheus-273-a": {
      "sideMode": "Unmodified",
      "initialCameraPosition": {
        "x": 0.01834007689067299,
        "y": 1.3277217722081511,
        "z": -1.4538258106907676
      },
      "visibleMeshNames": [
        "B_Femur_L",
        "B_Fibula_L",
        "B_Hipbone_L",
        "B_Lumbar_Disc_L4",
        "B_Lumbar_Disc_L5",
        "B_Lumbar_Vertebra_L4",
        "B_Lumbar_Vertebra_L5",
        "B_Sacrum",
        "B_Tibia_L",
        "C_Articular_Surface_Distal_Part_Femur_L",
        "C_Articular_Surface_Proximal_Tibia_L",
        "L_Interosseus_Membrane_L",
        "L_Sacrospinous_Ligament_L",
        "L_Sacrotuberous_ligament_L",
        "M_Biceps_Femoris_Short_Head_L",
        "M_Popliteus_L",
        "M_Semimembranosus_L",
        "M_Semitendinosus_L"
      ],
      "easyMeshNames": [
        "B_Femur_L",
        "B_Fibula_L",
        "B_Hipbone_L",
        "B_Sacrum",
        "B_Tibia_L",
        "L_Sacrotuberous_ligament_L",
        "M_Biceps_Femoris_Short_Head_L",
        "M_Popliteus_L",
        "M_Semimembranosus_L",
        "M_Semitendinosus_L"
      ]
    },
    "prometheus-273-b": {
      "sideMode": "Unmodified",
      "initialCameraPosition": {
        "x": 0.01834007689067299,
        "y": 1.3277217722081511,
        "z": -1.4538258106907676
      },
      "visibleMeshNames": [
        "B_Femur_L",
        "B_Fibula_L",
        "B_Hipbone_L",
        "B_Lumbar_Disc_L4",
        "B_Lumbar_Disc_L5",
        "B_Lumbar_Vertebra_L4",
        "B_Lumbar_Vertebra_L5",
        "B_Sacrum",
        "B_Tibia_L",
        "C_Articular_Surface_Distal_Part_Femur_L",
        "C_Articular_Surface_Proximal_Tibia_L",
        "L_Interosseus_Membrane_L",
        "L_Sacrospinous_Ligament_L",
        "L_Sacrotuberous_ligament_L",
        "M_Biceps_Femoris_Long_Head_L",
        "M_Biceps_Femoris_Short_Head_L",
        "M_Popliteus_L",
        "M_Semimembranosus_L",
        "M_Semitendinosus_L"
      ],
      "easyMeshNames": ["M_Biceps_Femoris_Long_Head_L"]
    },
    "prometheus-274-a": {
      "sideMode": "Unmodified",
      "initialCameraPosition": {
        "x": 0.01834007689067299,
        "y": 1.3277217722081511,
        "z": -1.4538258106907676
      },
      "visibleMeshNames": [
        "B_Coccyx",
        "B_Femur_R",
        "B_Fibula_R",
        "B_Hipbone_R",
        "B_Lumbar_Disc_L4",
        "B_Lumbar_Disc_L5",
        "B_Lumbar_Vertebra_L4",
        "B_Lumbar_Vertebra_L5",
        "B_Patella_R",
        "B_Sacrum",
        "B_Tibia_R",
        "C_Articular_Surface_Fibular_Tibia_R",
        "C_Articular_Surface_Head_Fibula_R",
        "C_Articular_Surface_Patella_R",
        "C_Articular_Surface_Proximal_Tibia_R",
        "C_Femur_Head_R",
        "L_Interosseus_Membrane_R",
        "L_Sacrospinous_Ligament_R",
        "L_Sacrotuberous_ligament_R",
        "M_Biceps_Femoris_Long_Head_R",
        "M_Biceps_Femoris_Short_Head_R",
        "M_Popliteus_R",
        "M_Semimembranosus_R",
        "M_Semitendinosus_R"
      ],
      "easyMeshNames": [
        "B_Femur_R",
        "B_Fibula_R",
        "B_Hipbone_R",
        "B_Tibia_R",
        "L_Sacrotuberous_ligament_R",
        "M_Biceps_Femoris_Long_Head_R",
        "M_Biceps_Femoris_Short_Head_R",
        "M_Popliteus_R",
        "M_Semimembranosus_R",
        "M_Semitendinosus_R"
      ]
    },
    "prometheus-312-a": {
      "sideMode": "Unmodified",
      "initialCameraPosition": {
        "x": 0.01834007689067299,
        "y": 1.3277217722081511,
        "z": -1.4538258106907676
      },
      "visibleMeshNames": [
        "B_Calcaneus_R",
        "B_Coccyx",
        "B_Cuboid_R",
        "B_Distal_Phalanx_Foot_1_R",
        "B_Distal_Phalanx_Foot_2_R",
        "B_Distal_Phalanx_Foot_3_R",
        "B_Distal_Phalanx_Foot_4_R",
        "B_Distal_Phalanx_Foot_5_R",
        "B_Femur_R",
        "B_Fibula_R",
        "B_Hipbone_R",
        "B_Intermediate_Cuneiform_R",
        "B_Lateral_Cuneiform_R",
        "B_Lumbar_Disc_L1",
        "B_Lumbar_Disc_L2",
        "B_Lumbar_Disc_L3",
        "B_Lumbar_Disc_L4",
        "B_Lumbar_Disc_L5",
        "B_Lumbar_Vertebra_L1",
        "B_Lumbar_Vertebra_L2",
        "B_Lumbar_Vertebra_L3",
        "B_Lumbar_Vertebra_L4",
        "B_Lumbar_Vertebra_L5",
        "B_Medial_Cuneiform_Bone_R",
        "B_Metatarsal_1_R",
        "B_Metatarsal_2_R",
        "B_Metatarsal_3_R",
        "B_Metatarsal_4_R",
        "B_Metatarsal_5_R",
        "B_Middle_Phalanx_Foot_2_R",
        "B_Middle_Phalanx_Foot_3_R",
        "B_Middle_Phalanx_Foot_4_R",
        "B_Middle_Phalanx_Foot_5_R",
        "B_Navicular_Bone_R",
        "B_Patella_R",
        "B_Proximal_Phalanx_Foot_1_R",
        "B_Proximal_Phalanx_Foot_2_R",
        "B_Proximal_Phalanx_Foot_3_R",
        "B_Proximal_Phalanx_Foot_4_R",
        "B_Proximal_Phalanx_Foot_5_R",
        "B_Sacrum",
        "B_Sesamoid_Foot_R",
        "B_Talus_R",
        "B_Thoracic_Disc_T12",
        "B_Thoracic_Vertebra_T12",
        "B_Tibia_R",
        "C_Articular_Surface_Fibular_Tibia_R",
        "C_Articular_Surface_Head_Fibula_R",
        "C_Articular_Surface_Patella_R",
        "C_Articular_Surface_Proximal_Tibia_R",
        "C_Femur_Head_R",
        "Common_Peroneal_Nerve_R",
        "Deep_Peroneal_Nerve_R",
        "Femoral_Nerve_R",
        "Genitofemoral_Nerve_R",
        "Iliohypogastric_Nerve_R",
        "Ilioinguinal_Nerve_R",
        "L1_Anterior_Root_R",
        "L1_Posterior_Root_Ganglion_R",
        "L1_Posterior_Root_R",
        "L1_Spinal_Nerve_R",
        "L2_Anterior_Root_R",
        "L2_Posterior_Root_Ganglion_R",
        "L2_Posterior_Root_R",
        "L2_Spinal_Nerve_R",
        "L3_Anterior_Root_R",
        "L3_Posterior_Root_Ganglion_R",
        "L3_Posterior_Root_R",
        "L3_Spinal_Nerve_R",
        "L4_Anterior_Root_L",
        "L4_Anterior_Root_R",
        "L4_Posterior_Root_Ganglion_L",
        "L4_Posterior_Root_Ganglion_R",
        "L4_Posterior_Root_L",
        "L4_Posterior_Root_R",
        "L4_Spinal_Nerve_L",
        "L4_Spinal_Nerve_R",
        "L5_Anterior_Root_R",
        "L5_Posterior_Root_Ganglion_R",
        "L5_Posterior_Root_R",
        "L5_Spinal_Nerve_R",
        "Lateral_Cutaneous_Nerve_of_Thigh_R",
        "Lateral_Plantar_Nerve_R",
        "Medial_Plantar_Nerve_R",
        "Posterior_Femoral_Cutaneous_Nerve_R",
        "Pudendal_Nerve_R",
        "S1_Anterior_Root_R",
        "S1_Posterior_Root_Ganglion_R",
        "S1_Posterior_Root_R",
        "S1_Spinal_Nerve_R",
        "S2_Anterior_Root_R",
        "S2_Posterior_Root_Ganglion_R",
        "S2_Posterior_Root_R",
        "S2_Spinal_Nerve_R",
        "S3_Anterior_Root_R",
        "S3_Posterior_Root_Ganglion_R",
        "S3_Posterior_Root_R",
        "S3_Spinal_Nerve_R",
        "S4_Anterior_Root_R",
        "S4_Posterior_Root_Ganglion_R",
        "S4_Posterior_Root_R",
        "S4_Spinal_Nerve_R",
        "S5_Anterior_Root_R",
        "S5_Posterior_Root_Ganglion_R",
        "S5_Posterior_Root_R",
        "S5_Spinal_Nerve_R",
        "S5_Ventral_Ramus_R",
        "Saphenous_Nerve_R",
        "Sciatic_Nerve_R",
        "Superficial_Peroneal_Nerve_R",
        "T12_Anterior_Root_R",
        "T12_Posterior_Root_Ganglion_R",
        "T12_Posterior_Root_R",
        "T12_Spinal_Nerve_R",
        "Tibial_Nerve_R",
        "Ventral_Ramus_of_S5_Nerve_R"
      ],
      "easyMeshNames": [
        "Common_Peroneal_Nerve_R",
        "Femoral_Nerve_R",
        "Ilioinguinal_Nerve_R",
        "Lateral_Plantar_Nerve_R",
        "Medial_Plantar_Nerve_R",
        "Posterior_Femoral_Cutaneous_Nerve_R",
        "Pudendal_Nerve_R",
        "Saphenous_Nerve_R",
        "Sciatic_Nerve_R",
        "Superficial_Peroneal_Nerve_R",
        "Tibial_Nerve_R"
      ]
    },
    "prometheus-313-a": {
      "sideMode": "Unmodified",
      "initialCameraPosition": {
        "x": 0.01834007689067299,
        "y": 1.3277217722081511,
        "z": -1.4538258106907676
      },
      "visibleMeshNames": [
        "B_Coccyx",
        "B_Femur_R",
        "B_Hipbone_R",
        "B_Lumbar_Disc_L1",
        "B_Lumbar_Disc_L2",
        "B_Lumbar_Disc_L3",
        "B_Lumbar_Disc_L4",
        "B_Lumbar_Disc_L5",
        "B_Lumbar_Vertebra_L1",
        "B_Lumbar_Vertebra_L2",
        "B_Lumbar_Vertebra_L3",
        "B_Lumbar_Vertebra_L4",
        "B_Lumbar_Vertebra_L5",
        "B_Rib_12_R",
        "B_Sacrum",
        "B_Thoracic_Disc_T12",
        "B_Thoracic_Vertebra_T12",
        "C_Femur_Head_R",
        "Femoral_Nerve_R",
        "Genitofemoral_Nerve_R",
        "Iliohypogastric_Nerve_R",
        "Ilioinguinal_Nerve_R",
        "L1_Anterior_Root_R",
        "L1_Posterior_Root_Ganglion_R",
        "L1_Posterior_Root_R",
        "L1_Spinal_Nerve_R",
        "L2_Anterior_Root_R",
        "L2_Posterior_Root_Ganglion_R",
        "L2_Posterior_Root_R",
        "L2_Spinal_Nerve_R",
        "L3_Anterior_Root_R",
        "L3_Posterior_Root_Ganglion_R",
        "L3_Posterior_Root_R",
        "L3_Spinal_Nerve_R",
        "L4_Anterior_Root_L",
        "L4_Anterior_Root_R",
        "L4_Posterior_Root_Ganglion_L",
        "L4_Posterior_Root_Ganglion_R",
        "L4_Posterior_Root_L",
        "L4_Posterior_Root_R",
        "L4_Spinal_Nerve_L",
        "L4_Spinal_Nerve_R",
        "L5_Anterior_Root_R",
        "L5_Posterior_Root_Ganglion_R",
        "L5_Posterior_Root_R",
        "L5_Spinal_Nerve_R",
        "L_Inguinal_Ligament_R",
        "L_Sacrospinous_Ligament_R",
        "Lateral_Cutaneous_Nerve_of_Thigh_R",
        "M_Iliacus_R",
        "Obturator_Nerve_R",
        "Posterior_Femoral_Cutaneous_Nerve_R",
        "Pudendal_Nerve_R",
        "S1_Anterior_Root_R",
        "S1_Posterior_Root_Ganglion_R",
        "S1_Posterior_Root_R",
        "S1_Spinal_Nerve_R",
        "S2_Anterior_Root_R",
        "S2_Posterior_Root_Ganglion_R",
        "S2_Posterior_Root_R",
        "S2_Spinal_Nerve_R",
        "S3_Anterior_Root_R",
        "S3_Posterior_Root_Ganglion_R",
        "S3_Posterior_Root_R",
        "S3_Spinal_Nerve_R",
        "S4_Anterior_Root_R",
        "S4_Posterior_Root_Ganglion_R",
        "S4_Posterior_Root_R",
        "S4_Spinal_Nerve_R",
        "S5_Anterior_Root_R",
        "S5_Posterior_Root_Ganglion_R",
        "S5_Posterior_Root_R",
        "S5_Spinal_Nerve_R",
        "S5_Ventral_Ramus_R",
        "Sciatic_Nerve_R",
        "Subcostal_Nerve_R",
        "T12_Anterior_Root_R",
        "T12_Posterior_Root_Ganglion_R",
        "T12_Posterior_Root_R",
        "T12_Spinal_Nerve_R",
        "Ventral_Ramus_of_S5_Nerve_R"
      ],
      "easyMeshNames": [
        "B_Lumbar_Vertebra_L1",
        "Femoral_Nerve_R",
        "Iliohypogastric_Nerve_R",
        "Ilioinguinal_Nerve_R",
        "Lateral_Cutaneous_Nerve_of_Thigh_R",
        "Obturator_Nerve_R",
        "Sciatic_Nerve_R"
      ]
    },
    "prometheus-314-a": {
      "sideMode": "Unmodified",
      "initialCameraPosition": {
        "x": 0.01834007689067299,
        "y": 1.3277217722081511,
        "z": -1.4538258106907676
      },
      "visibleMeshNames": [
        "B_Coccyx",
        "B_Femur_R",
        "B_Fibula_R",
        "B_Hipbone_L",
        "B_Hipbone_R",
        "B_Lumbar_Disc_L1",
        "B_Lumbar_Disc_L2",
        "B_Lumbar_Disc_L3",
        "B_Lumbar_Disc_L4",
        "B_Lumbar_Disc_L5",
        "B_Lumbar_Vertebra_L1",
        "B_Lumbar_Vertebra_L2",
        "B_Lumbar_Vertebra_L3",
        "B_Lumbar_Vertebra_L4",
        "B_Lumbar_Vertebra_L5",
        "B_Patella_R",
        "B_Sacrum",
        "B_Tibia_R",
        "C_Acetabulum_Lunate_Surface_R",
        "C_Articular_Surface_Distal_Part_Femur_R",
        "C_Articular_Surface_Fibular_Tibia_R",
        "C_Articular_Surface_Head_Fibula_R",
        "C_Articular_Surface_Patella_R",
        "C_Articular_Surface_Proximal_Tibia_R",
        "C_Femur_Head_R",
        "L1_Anterior_Root_R",
        "L1_Posterior_Root_Ganglion_R",
        "L1_Posterior_Root_R",
        "L1_Spinal_Nerve_R",
        "L2_Anterior_Root_R",
        "L2_Posterior_Root_Ganglion_R",
        "L2_Posterior_Root_R",
        "L2_Spinal_Nerve_R",
        "L3_Anterior_Root_R",
        "L3_Posterior_Root_Ganglion_R",
        "L3_Posterior_Root_R",
        "L3_Spinal_Nerve_R",
        "L4_Anterior_Root_R",
        "L4_Posterior_Root_Ganglion_R",
        "L4_Posterior_Root_R",
        "L4_Spinal_Nerve_R",
        "L5_Anterior_Root_R",
        "L5_Posterior_Root_Ganglion_R",
        "L5_Posterior_Root_R",
        "L5_Spinal_Nerve_R",
        "L_Inguinal_Ligament_R",
        "L_Lacunar_Ligament_R",
        "L_Pubic_Symphysis",
        "Lateral_Cutaneous_Nerve_of_Thigh_R",
        "M_Adductor_Brevis_R",
        "M_Adductor_Longus_R",
        "M_Adductor_Magnus_R",
        "M_Gracilis_R",
        "M_Obturator_Externus_R",
        "Obturator_Nerve_R"
      ],
      "easyMeshNames": [
        "B_Lumbar_Vertebra_L4",
        "M_Adductor_Brevis_R",
        "M_Adductor_Longus_R",
        "M_Gracilis_R",
        "M_Obturator_Externus_R",
        "Obturator_Nerve_R"
      ]
    },
    "prometheus-314-b": {
      "sideMode": "Unmodified",
      "initialCameraPosition": {
        "x": 0.01834007689067299,
        "y": 1.3277217722081511,
        "z": -1.4538258106907676
      },
      "visibleMeshNames": [
        "B_Coccyx",
        "B_Femur_R",
        "B_Fibula_R",
        "B_Hipbone_L",
        "B_Hipbone_R",
        "B_Lumbar_Disc_L1",
        "B_Lumbar_Disc_L2",
        "B_Lumbar_Disc_L3",
        "B_Lumbar_Disc_L4",
        "B_Lumbar_Disc_L5",
        "B_Lumbar_Vertebra_L1",
        "B_Lumbar_Vertebra_L2",
        "B_Lumbar_Vertebra_L3",
        "B_Lumbar_Vertebra_L4",
        "B_Lumbar_Vertebra_L5",
        "B_Patella_R",
        "B_Sacrum",
        "B_Tibia_R",
        "C_Acetabulum_Lunate_Surface_R",
        "C_Articular_Surface_Distal_Part_Femur_R",
        "C_Articular_Surface_Fibular_Tibia_R",
        "C_Articular_Surface_Head_Fibula_R",
        "C_Articular_Surface_Patella_R",
        "C_Articular_Surface_Proximal_Tibia_R",
        "C_Femur_Head_R",
        "L1_Anterior_Root_R",
        "L1_Posterior_Root_Ganglion_R",
        "L1_Posterior_Root_R",
        "L1_Spinal_Nerve_R",
        "L2_Anterior_Root_R",
        "L2_Posterior_Root_Ganglion_R",
        "L2_Posterior_Root_R",
        "L2_Spinal_Nerve_R",
        "L3_Anterior_Root_R",
        "L3_Posterior_Root_Ganglion_R",
        "L3_Posterior_Root_R",
        "L3_Spinal_Nerve_R",
        "L4_Anterior_Root_R",
        "L4_Posterior_Root_Ganglion_R",
        "L4_Posterior_Root_R",
        "L4_Spinal_Nerve_R",
        "L5_Anterior_Root_R",
        "L5_Posterior_Root_Ganglion_R",
        "L5_Posterior_Root_R",
        "L5_Spinal_Nerve_R",
        "L_Inguinal_Ligament_R",
        "L_Lacunar_Ligament_R",
        "L_Pubic_Symphysis",
        "Lateral_Cutaneous_Nerve_of_Thigh_R",
        "M_Adductor_Brevis_R",
        "M_Adductor_Magnus_R",
        "M_Gracilis_R",
        "M_Obturator_Externus_R",
        "Obturator_Nerve_R"
      ],
      "easyMeshNames": [
        "M_Adductor_Brevis_R",
        "M_Obturator_Externus_R",
        "Obturator_Nerve_R"
      ]
    },
    "prometheus-315-a": {
      "sideMode": "Unmodified",
      "initialCameraPosition": {
        "x": 0.01834007689067299,
        "y": 1.3277217722081511,
        "z": -1.4538258106907676
      },
      "visibleMeshNames": [
        "B_Coccyx",
        "B_Femur_R",
        "B_Fibula_R",
        "B_Hipbone_L",
        "B_Hipbone_R",
        "B_Lumbar_Disc_L1",
        "B_Lumbar_Disc_L2",
        "B_Lumbar_Disc_L3",
        "B_Lumbar_Disc_L4",
        "B_Lumbar_Disc_L5",
        "B_Lumbar_Vertebra_L1",
        "B_Lumbar_Vertebra_L2",
        "B_Lumbar_Vertebra_L3",
        "B_Lumbar_Vertebra_L4",
        "B_Lumbar_Vertebra_L5",
        "B_Patella_R",
        "B_Sacrum",
        "B_Tibia_R",
        "C_Acetabulum_Lunate_Surface_R",
        "C_Articular_Surface_Distal_Part_Femur_R",
        "C_Articular_Surface_Fibular_Tibia_R",
        "C_Articular_Surface_Head_Fibula_R",
        "C_Articular_Surface_Patella_R",
        "C_Articular_Surface_Proximal_Tibia_R",
        "C_Femur_Head_R",
        "Femoral_Nerve_R",
        "Infrapatellar_Branch_of_Saphenous_Nerve_R",
        "L1_Anterior_Root_R",
        "L1_Posterior_Root_Ganglion_R",
        "L1_Posterior_Root_R",
        "L1_Spinal_Nerve_R",
        "L2_Anterior_Root_R",
        "L2_Posterior_Root_Ganglion_R",
        "L2_Posterior_Root_R",
        "L2_Spinal_Nerve_R",
        "L3_Anterior_Root_R",
        "L3_Posterior_Root_Ganglion_R",
        "L3_Posterior_Root_R",
        "L3_Spinal_Nerve_R",
        "L4_Anterior_Root_R",
        "L4_Posterior_Root_Ganglion_R",
        "L4_Posterior_Root_R",
        "L4_Spinal_Nerve_R",
        "L5_Anterior_Root_R",
        "L5_Posterior_Root_Ganglion_R",
        "L5_Posterior_Root_R",
        "L5_Spinal_Nerve_R",
        "L_Inguinal_Ligament_R",
        "L_Lacunar_Ligament_R",
        "L_Pubic_Symphysis",
        "Lateral_Cutaneous_Nerve_of_Thigh_R",
        "M_Iliacus_R",
        "M_Pectineus_R",
        "M_Psoas_Major_R",
        "M_Rectus_Femoris_R",
        "M_Sartorius_R",
        "M_Vastus_Intermedius_R",
        "M_Vastus_Lateralis_R",
        "M_Vastus_Medialis_R",
        "Saphenous_Nerve_R"
      ],
      "easyMeshNames": [
        "Femoral_Nerve_R",
        "Infrapatellar_Branch_of_Saphenous_Nerve_R",
        "M_Iliacus_R",
        "M_Pectineus_R",
        "M_Psoas_Major_R",
        "M_Rectus_Femoris_R",
        "M_Sartorius_R",
        "M_Vastus_Lateralis_R",
        "M_Vastus_Medialis_R",
        "Saphenous_Nerve_R"
      ]
    },
    "prometheus-315-b": {
      "sideMode": "Unmodified",
      "initialCameraPosition": {
        "x": 0.01834007689067299,
        "y": 1.3277217722081511,
        "z": -1.4538258106907676
      },
      "visibleMeshNames": [
        "B_Coccyx",
        "B_Femur_R",
        "B_Fibula_R",
        "B_Hipbone_L",
        "B_Hipbone_R",
        "B_Lumbar_Disc_L1",
        "B_Lumbar_Disc_L2",
        "B_Lumbar_Disc_L3",
        "B_Lumbar_Disc_L4",
        "B_Lumbar_Disc_L5",
        "B_Lumbar_Vertebra_L1",
        "B_Lumbar_Vertebra_L2",
        "B_Lumbar_Vertebra_L3",
        "B_Lumbar_Vertebra_L4",
        "B_Lumbar_Vertebra_L5",
        "B_Patella_R",
        "B_Sacrum",
        "B_Tibia_R",
        "C_Acetabulum_Lunate_Surface_R",
        "C_Articular_Surface_Distal_Part_Femur_R",
        "C_Articular_Surface_Fibular_Tibia_R",
        "C_Articular_Surface_Head_Fibula_R",
        "C_Articular_Surface_Patella_R",
        "C_Articular_Surface_Proximal_Tibia_R",
        "C_Femur_Head_R",
        "Femoral_Nerve_R",
        "Infrapatellar_Branch_of_Saphenous_Nerve_R",
        "L1_Anterior_Root_R",
        "L1_Posterior_Root_Ganglion_R",
        "L1_Posterior_Root_R",
        "L1_Spinal_Nerve_R",
        "L2_Anterior_Root_R",
        "L2_Posterior_Root_Ganglion_R",
        "L2_Posterior_Root_R",
        "L2_Spinal_Nerve_R",
        "L3_Anterior_Root_R",
        "L3_Posterior_Root_Ganglion_R",
        "L3_Posterior_Root_R",
        "L3_Spinal_Nerve_R",
        "L4_Anterior_Root_R",
        "L4_Posterior_Root_Ganglion_R",
        "L4_Posterior_Root_R",
        "L4_Spinal_Nerve_R",
        "L5_Anterior_Root_R",
        "L5_Posterior_Root_Ganglion_R",
        "L5_Posterior_Root_R",
        "L5_Spinal_Nerve_R",
        "L_Inguinal_Ligament_R",
        "L_Lacunar_Ligament_R",
        "L_Pubic_Symphysis",
        "Lateral_Cutaneous_Nerve_of_Thigh_R",
        "M_Iliacus_R",
        "M_Pectineus_R",
        "M_Psoas_Major_R",
        "M_Vastus_Intermedius_R",
        "M_Vastus_Lateralis_R",
        "M_Vastus_Medialis_R",
        "Saphenous_Nerve_R"
      ],
      "easyMeshNames": ["M_Vastus_Intermedius_R", "Saphenous_Nerve_R"]
    },
    "prometheus-316-a": {
      "sideMode": "Unmodified",
      "initialCameraPosition": {
        "x": 0.01834007689067299,
        "y": 1.3277217722081511,
        "z": -1.4538258106907676
      },
      "visibleMeshNames": [
        "B_Calcaneus_R",
        "B_Coccyx",
        "B_Cuboid_R",
        "B_Distal_Phalanx_Foot_1_R",
        "B_Distal_Phalanx_Foot_2_R",
        "B_Distal_Phalanx_Foot_3_R",
        "B_Distal_Phalanx_Foot_4_R",
        "B_Distal_Phalanx_Foot_5_R",
        "B_Femur_R",
        "B_Fibula_R",
        "B_Hipbone_L",
        "B_Hipbone_R",
        "B_Intermediate_Cuneiform_R",
        "B_Lateral_Cuneiform_R",
        "B_Lumbar_Disc_L4",
        "B_Lumbar_Disc_L5",
        "B_Lumbar_Vertebra_L4",
        "B_Lumbar_Vertebra_L5",
        "B_Medial_Cuneiform_Bone_R",
        "B_Metatarsal_1_R",
        "B_Metatarsal_2_R",
        "B_Metatarsal_3_R",
        "B_Metatarsal_4_R",
        "B_Metatarsal_5_R",
        "B_Middle_Phalanx_Foot_2_R",
        "B_Middle_Phalanx_Foot_3_R",
        "B_Middle_Phalanx_Foot_4_R",
        "B_Middle_Phalanx_Foot_5_R",
        "B_Navicular_Bone_R",
        "B_Patella_R",
        "B_Proximal_Phalanx_Foot_1_R",
        "B_Proximal_Phalanx_Foot_2_R",
        "B_Proximal_Phalanx_Foot_3_R",
        "B_Proximal_Phalanx_Foot_4_R",
        "B_Proximal_Phalanx_Foot_5_R",
        "B_Sacrum",
        "B_Sesamoid_Foot_R",
        "B_Talus_R",
        "B_Tibia_R",
        "C_Acetabulum_Lunate_Surface_R",
        "C_Articular_Surface_Distal_Part_Femur_R",
        "C_Articular_Surface_Fibular_Tibia_R",
        "C_Articular_Surface_Head_Fibula_R",
        "C_Articular_Surface_Patella_R",
        "C_Articular_Surface_Proximal_Tibia_R",
        "C_Femur_Head_R",
        "Common_Peroneal_Nerve_R",
        "Deep_Peroneal_Nerve_R",
        "L4_Anterior_Root_R",
        "L4_Posterior_Root_Ganglion_R",
        "L4_Posterior_Root_R",
        "L4_Spinal_Nerve_R",
        "L5_Anterior_Root_R",
        "L5_Posterior_Root_Ganglion_R",
        "L5_Posterior_Root_R",
        "L5_Spinal_Nerve_R",
        "L_Inferior_Extensor_Retinaculum_R",
        "L_Inferior_Fibular_Retinaculumac_R",
        "L_Interosseus_Membrane_R",
        "L_Pubic_Symphysis",
        "L_Sheath_of_Tibialis_Anterior_R",
        "L_Superior_Extensor_Retinaculum_R",
        "L_Superior_Fibular_Retinaculum_R",
        "L_Tendon_sheath_R",
        "M_Biceps_Femoris_Short_Head_R",
        "M_Extensor_Digitorum_Brevis_R",
        "M_Extensor_Digitorum_Longus_R",
        "M_Extensor_Hallucis_Brevis_R",
        "M_Extensor_Hallucis_Longus_R",
        "M_Fibularis_Brevis_R",
        "M_Fibularis_Longubs_R",
        "M_Fibularis_Tertius_R",
        "M_Tibialis_Anterior_R",
        "S1_Anterior_Root_R",
        "S1_Posterior_Root_Ganglion_R",
        "S1_Posterior_Root_R",
        "S1_Spinal_Nerve_R",
        "S2_Anterior_Root_R",
        "S2_Posterior_Root_Ganglion_R",
        "S2_Posterior_Root_R",
        "S2_Spinal_Nerve_R",
        "S3_Anterior_Root_R",
        "S3_Posterior_Root_Ganglion_R",
        "S3_Posterior_Root_R",
        "S3_Spinal_Nerve_R",
        "S4_Anterior_Root_R",
        "S4_Posterior_Root_Ganglion_R",
        "S4_Posterior_Root_R",
        "S4_Spinal_Nerve_R",
        "S5_Anterior_Root_R",
        "S5_Posterior_Root_Ganglion_R",
        "S5_Posterior_Root_R",
        "S5_Spinal_Nerve_R",
        "S5_Ventral_Ramus_R",
        "Sciatic_Nerve_R",
        "Superficial_Peroneal_Nerve_R",
        "Ventral_Ramus_of_S5_Nerve_R"
      ],
      "easyMeshNames": [
        "B_Fibula_R",
        "Common_Peroneal_Nerve_R",
        "M_Biceps_Femoris_Short_Head_R",
        "M_Extensor_Digitorum_Longus_R",
        "M_Fibularis_Longubs_R",
        "M_Tibialis_Anterior_R",
        "Sciatic_Nerve_R"
      ]
    },
    "prometheus-316-b": {
      "sideMode": "Unmodified",
      "initialCameraPosition": {
        "x": 0.01834007689067299,
        "y": 1.3277217722081511,
        "z": -1.4538258106907676
      },
      "visibleMeshNames": [
        "B_Calcaneus_R",
        "B_Coccyx",
        "B_Cuboid_R",
        "B_Distal_Phalanx_Foot_1_R",
        "B_Distal_Phalanx_Foot_2_R",
        "B_Distal_Phalanx_Foot_3_R",
        "B_Distal_Phalanx_Foot_4_R",
        "B_Distal_Phalanx_Foot_5_R",
        "B_Femur_R",
        "B_Fibula_R",
        "B_Hipbone_L",
        "B_Hipbone_R",
        "B_Intermediate_Cuneiform_R",
        "B_Lateral_Cuneiform_R",
        "B_Lumbar_Disc_L4",
        "B_Lumbar_Disc_L5",
        "B_Lumbar_Vertebra_L4",
        "B_Lumbar_Vertebra_L5",
        "B_Medial_Cuneiform_Bone_R",
        "B_Metatarsal_1_R",
        "B_Metatarsal_2_R",
        "B_Metatarsal_3_R",
        "B_Metatarsal_4_R",
        "B_Metatarsal_5_R",
        "B_Middle_Phalanx_Foot_2_R",
        "B_Middle_Phalanx_Foot_3_R",
        "B_Middle_Phalanx_Foot_4_R",
        "B_Middle_Phalanx_Foot_5_R",
        "B_Navicular_Bone_R",
        "B_Patella_R",
        "B_Proximal_Phalanx_Foot_1_R",
        "B_Proximal_Phalanx_Foot_2_R",
        "B_Proximal_Phalanx_Foot_3_R",
        "B_Proximal_Phalanx_Foot_4_R",
        "B_Proximal_Phalanx_Foot_5_R",
        "B_Sacrum",
        "B_Sesamoid_Foot_R",
        "B_Talus_R",
        "B_Tibia_R",
        "C_Acetabulum_Lunate_Surface_R",
        "C_Articular_Surface_Distal_Part_Femur_R",
        "C_Articular_Surface_Fibular_Tibia_R",
        "C_Articular_Surface_Head_Fibula_R",
        "C_Articular_Surface_Patella_R",
        "C_Articular_Surface_Proximal_Tibia_R",
        "C_Femur_Head_R",
        "Common_Peroneal_Nerve_R",
        "Deep_Peroneal_Nerve_R",
        "L4_Anterior_Root_R",
        "L4_Posterior_Root_Ganglion_R",
        "L4_Posterior_Root_R",
        "L4_Spinal_Nerve_R",
        "L5_Anterior_Root_R",
        "L5_Posterior_Root_Ganglion_R",
        "L5_Posterior_Root_R",
        "L5_Spinal_Nerve_R",
        "L_Inferior_Extensor_Retinaculum_R",
        "L_Inferior_Fibular_Retinaculumac_R",
        "L_Interosseus_Membrane_R",
        "L_Pubic_Symphysis",
        "L_Superior_Extensor_Retinaculum_R",
        "L_Superior_Fibular_Retinaculum_R",
        "M_Biceps_Femoris_Short_Head_R",
        "M_Extensor_Digitorum_Brevis_R",
        "M_Extensor_Hallucis_Brevis_R",
        "M_Extensor_Hallucis_Longus_R",
        "M_Fibularis_Brevis_R",
        "M_Fibularis_Tertius_R",
        "S1_Anterior_Root_R",
        "S1_Posterior_Root_Ganglion_R",
        "S1_Posterior_Root_R",
        "S1_Spinal_Nerve_R",
        "S2_Anterior_Root_R",
        "S2_Posterior_Root_Ganglion_R",
        "S2_Posterior_Root_R",
        "S2_Spinal_Nerve_R",
        "S3_Anterior_Root_R",
        "S3_Posterior_Root_Ganglion_R",
        "S3_Posterior_Root_R",
        "S3_Spinal_Nerve_R",
        "S4_Anterior_Root_R",
        "S4_Posterior_Root_Ganglion_R",
        "S4_Posterior_Root_R",
        "S4_Spinal_Nerve_R",
        "S5_Anterior_Root_R",
        "S5_Posterior_Root_Ganglion_R",
        "S5_Posterior_Root_R",
        "S5_Spinal_Nerve_R",
        "S5_Ventral_Ramus_R",
        "Sciatic_Nerve_R",
        "Superficial_Peroneal_Nerve_R",
        "Ventral_Ramus_of_S5_Nerve_R"
      ],
      "easyMeshNames": ["Superficial_Peroneal_Nerve_R"]
    },
    "prometheus-318-a": {
      "sideMode": "Unmodified",
      "initialCameraPosition": {
        "x": 0.01834007689067299,
        "y": 1.3277217722081511,
        "z": -1.4538258106907676
      },
      "visibleMeshNames": [
        "B_Coccyx",
        "B_Hipbone_R",
        "B_Lumbar_Disc_L1",
        "B_Lumbar_Disc_L2",
        "B_Lumbar_Disc_L3",
        "B_Lumbar_Disc_L4",
        "B_Lumbar_Disc_L5",
        "B_Lumbar_Vertebra_L1",
        "B_Lumbar_Vertebra_L2",
        "B_Lumbar_Vertebra_L3",
        "B_Lumbar_Vertebra_L4",
        "B_Lumbar_Vertebra_L5",
        "B_Sacrum",
        "B_Thoracic_Disc_T12",
        "B_Thoracic_Vertebra_T12",
        "Deep_Peroneal_Nerve_R",
        "Dorsal_Metatarsal_Veins_R",
        "Dorsal_Venous_Arch_R",
        "Dorsalis_Metatarsal_Arteries_R",
        "F_Fascia_Lata_L",
        "F_Fascia_Lata_R",
        "F_Membranous_Layer_of_Superficial_Fascia_of_Abdomen",
        "F_Penis_Superficial_Fascia",
        "F_Superficial_Perineal__Colles__Fascia",
        "F_Thoracolumbar_Fascia",
        "Femoral_Nerve_R",
        "Great_Saphenous_Vein_R",
        "Inferior_Cluneal_Nerve_R",
        "Inferior_Clunial_Nerve_R",
        "L1_Anterior_Root_R",
        "L1_Posterior_Root_Ganglion_R",
        "L1_Posterior_Root_R",
        "L1_Spinal_Nerve_R",
        "L2_Anterior_Root_R",
        "L2_Posterior_Root_Ganglion_R",
        "L2_Posterior_Root_R",
        "L2_Spinal_Nerve_R",
        "L3_Anterior_Root_R",
        "L3_Posterior_Root_Ganglion_R",
        "L3_Posterior_Root_R",
        "L3_Spinal_Nerve_R",
        "L4_Anterior_Root_R",
        "L4_Posterior_Root_Ganglion_R",
        "L4_Posterior_Root_R",
        "L4_Spinal_Nerve_R",
        "L_Anococcygeal_Ligament",
        "L_Anococcygeal_Raphe",
        "L_Anterior_Cruciate_Ligament_L",
        "L_Anterior_Cruciate_Ligament_R",
        "L_Anterior_Fibular_Head_Ligament_L",
        "L_Anterior_Fibular_Head_Ligament_R",
        "L_Anterior_Meniscofemoral_Ligament_L",
        "L_Anterior_Meniscofemoral_Ligament_R",
        "L_Anterior_Sacroiliac_Ligament_L",
        "L_Anterior_Sacroiliac_Ligament_R",
        "L_Anterior_Talofibular_Ligament_L",
        "L_Anterior_Talofibular_Ligament_R",
        "L_Anterior_Tibiofibular_Ligament_L",
        "L_Anterior_Tibiofibular_Ligament_R",
        "L_Anterior_Tibiotalar_Part_L",
        "L_Anterior_Tibiotalar_Part_R",
        "L_Arcuate_Popliteal_Ligament_L",
        "L_Arcuate_Popliteal_Ligament_R",
        "L_Articular_Joint_Capsule_L",
        "L_Articular_Joint_Capsule_R",
        "L_Calcaneocuboidea_Plantare_L",
        "L_Calcaneocuboidea_Plantare_R",
        "L_Calcaneofibular_Ligament_L",
        "L_Calcaneofibular_Ligament_R",
        "L_Cervical_Ligament_L",
        "L_Cervical_Ligament_R",
        "L_Collateral_Interphalangial_Ligaments_L",
        "L_Collateral_Interphalangial_Ligaments_R",
        "L_Cruciform_Ligaments_L1_L",
        "L_Cruciform_Ligaments_R2_R",
        "L_Cuneo_Cuboideum_Plantare_Ligament_L",
        "L_Cuneo_Cuboideum_Plantare_Ligament_R",
        "L_Deep_Posterior_Sacrococcogeal_Ligament",
        "L_Deep_Transverse_Metatarsal_Ligaments_L",
        "L_Deep_Transverse_Metatarsal_Ligaments_R",
        "L_Dorsal_Calcaneocuboid_Ligament_L",
        "L_Dorsal_Calcaneocuboid_Ligament_R",
        "L_Dorsal_Cuboideonavicular_Ligament_L",
        "L_Dorsal_Cuboideonavicular_Ligament_R",
        "L_Dorsal_Cuneocuboid_Ligament_L",
        "L_Dorsal_Cuneocuboid_Ligament_R",
        "L_Dorsal_Cuneonavicular_Ligament_L",
        "L_Dorsal_Cuneonavicular_Ligament_R",
        "L_Dorsal_Metatarsal_Ligaments_L",
        "L_Dorsal_Metatarsal_Ligaments_R",
        "L_Dorsal_Talonavicular_Ligament_L",
        "L_Dorsal_Talonavicular_Ligament_R",
        "L_Dorsal_Tarsal_Ligament_R",
        "L_Dorsal_Tarsal_Ligament_L",
        "L_Dorsal_Tarsometatarsal_Ligament_L",
        "L_Dorsal_Tarsometatarsal_Ligament_R",
        "L_Extensor_Hoods_L",
        "L_Extensor_Hoods_R",
        "L_Fibularis_Brevis_L",
        "L_Fibularis_Brevis_R",
        "L_Flexor_Digitorum_Longus_Tendon_L",
        "L_Flexor_Digitorum_Longus_Tendon_R",
        "L_Flexor_Retinaculum_L",
        "L_Flexor_Retinaculum_R",
        "L_Iliofemoral_Ligament_L",
        "L_Iliofemoral_Ligament_R",
        "L_Iliopectineal_Line_L",
        "L_Iliopectineal_Line_R",
        "L_Inferior_Extensor_Retinaculum_L",
        "L_Inferior_Extensor_Retinaculum_R",
        "L_Inferior_Fibular_Retinaculumac_L",
        "L_Inferior_Fibular_Retinaculumac_R",
        "L_Inguinal_Ligament_L",
        "L_Inguinal_Ligament_R",
        "L_Inguinal_Ring_L",
        "L_Inguinal_Ring_R",
        "L_Intercrural_Fibers_L",
        "L_Intercrural_Fibers_R",
        "L_Intermetatarsal_ligament_L",
        "L_Intermetatarsal_ligament_R",
        "L_Interosseous_talocalcaneal_ligament_L",
        "L_Interosseous_talocalcaneal_ligament_R",
        "L_Interosseus_Membrane_L",
        "L_Interosseus_Membrane_R",
        "L_Ischifemoral_Ligament_L",
        "L_Ischifemoral_Ligament_R",
        "L_Joining_Capsules_L",
        "L_Joining_Capsules_R",
        "L_Lacunar_Ligament_L",
        "L_Lacunar_Ligament_R",
        "L_Lateral_Collateral_Ligament_L",
        "L_Lateral_Collateral_Ligament_R",
        "L_Lateral_Patellar_Retinaculum_L",
        "L_Lateral_Patellar_Retinaculum_R",
        "L_Lateral_Patellofemoral_Ligament_L",
        "L_Lateral_Patellofemoral_Ligament_R",
        "L_Lateral_Patellotibial_Ligament_L",
        "L_Lateral_Patellotibial_Ligament_R",
        "L_Lateral_Talocalcaneal_Ligament_L",
        "L_Lateral_Talocalcaneal_Ligament_R",
        "L_Ligament_of_the_Head_of_Femur_L",
        "L_Ligament_of_the_Head_of_Femur_R",
        "L_Lisfranc_Ligament_L",
        "L_Lisfranc_Ligament_R",
        "L_Medial_Collateral_Ligament_L",
        "L_Medial_Collateral_Ligament_R",
        "L_Medial_Patellar_Retinaculum_L",
        "L_Medial_Patellar_Retinaculum_R",
        "L_Medial_Patellofemoral_Ligament_L",
        "L_Medial_Patellofemoral_Ligament_R",
        "L_Medial_Patellotibial_Ligament_L",
        "L_Medial_Patellotibial_Ligament_R",
        "L_Medial_Talocalcaneal_Ligament_L",
        "L_Medial_Talocalcaneal_Ligament_R",
        "L_Oblique_Popliteal_Ligament_L",
        "L_Oblique_Popliteal_Ligament_R",
        "L_Patellar_Ligament_L",
        "L_Patellar_Ligament_R",
        "L_Pedis_Sheaths_L",
        "L_Pedis_Sheaths_R",
        "L_Plantar_Calcaneonavicular_Ligament_L",
        "L_Plantar_Calcaneonavicular_Ligament_R",
        "L_Plantar_Calcaneonavicular_Spring_Ligament_L",
        "L_Plantar_Calcaneonavicular_Spring_Ligament_R",
        "L_Plantar_Cuboideonavicular_Ligament_L",
        "L_Plantar_Cuboideonavicular_Ligament_R",
        "L_Plantar_Intercuneiform_Ligament_L",
        "L_Plantar_Intercuneiform_Ligament_R",
        "L_Plantar_Ligaments_L",
        "L_Plantar_Ligaments_R",
        "L_Plantar_Metatarsal_Ligament_L",
        "L_Plantar_Metatarsal_Ligament_R",
        "L_Plantar_Tarsometatarsal_Ligament_L",
        "L_Plantar_Tarsometatarsal_Ligament_R",
        "L_Posterior_Cruciate_Ligament_L",
        "L_Posterior_Cruciate_Ligament_R",
        "L_Posterior_Fibular_Head_Ligament_L",
        "L_Posterior_Fibular_Head_Ligament_R",
        "L_Posterior_Meniscofemoral_Ligament_L",
        "L_Posterior_Meniscofemoral_Ligament_R",
        "L_Posterior_Sacroiliac_Ligament_L",
        "L_Posterior_Sacroiliac_Ligament_R",
        "L_Posterior_Talocalcaneal_Ligament_L",
        "L_Posterior_Talocalcaneal_Ligament_R",
        "L_Posterior_Talofibular_Ligament_L",
        "L_Posterior_Talofibular_Ligament_R",
        "L_Posterior_Tibiofibular_Ligament_L",
        "L_Posterior_Tibiofibular_Ligament_R",
        "L_Posterior_Tibiotalal_Part_of_Medial_Ligament_L",
        "L_Posterior_Tibiotalal_Part_of_Medial_Ligament_R",
        "L_Pubic_Symphysis",
        "L_Pubofemoral_Ligament_L",
        "L_Pubofemoral_Ligament_R",
        "L_Sacrospinous_Ligament_L",
        "L_Sacrospinous_Ligament_R",
        "L_Sacrotuberous_ligament_L",
        "L_Sacrotuberous_ligament_R",
        "L_Shearh_of_Extensor_Hallucis_Longus_L",
        "L_Shearh_of_Extensor_Hallucis_Longus_R",
        "L_Sheath_of_Flexor_Digitorum_Longus_L",
        "L_Sheath_of_Flexor_Digitorum_Longus_R",
        "L_Sheath_of_Flexor_Hallucis_Longus_L",
        "L_Sheath_of_Flexor_Hallucis_Longus_R",
        "L_Sheath_of_Tibialis_Anterior_L",
        "L_Sheath_of_Tibialis_Anterior_R",
        "L_Sheath_of_Tibialis_Posterior_L",
        "L_Sheath_of_Tibialis_Posterior_R",
        "L_Superficial_Transverse_Metacarpall_L",
        "L_Superficial_Transverse_Metacarpall_R",
        "L_Superior_Extensor_Retinaculum_L",
        "L_Superior_Extensor_Retinaculum_R",
        "L_Superior_Fibular_Retinaculum_L",
        "L_Superior_Fibular_Retinaculum_R",
        "L_Tendon_sheath_L",
        "L_Tendon_sheath_R",
        "L_Tibiocalcaneal_part_of_medial_ligament_L",
        "L_Tibiocalcaneal_part_of_medial_ligament_R",
        "L_Tibionavicular_part_of_medial_ligament_L",
        "L_Tibionavicular_part_of_medial_ligament_R",
        "L_Transverse_Acetabular_Ligament_L",
        "L_Transverse_Acetabular_Ligament_R",
        "L_Transverse_Ligament_of_Knee_L",
        "L_Transverse_Ligament_of_Knee_R",
        "L_lliolumbar_Ligament_L",
        "L_lliolumbar_Ligament_R",
        "Lateral_Cutaneous_Nerve_of_Thigh_R",
        "Lateral_Marginal_Vein_R",
        "Lesser_Saphenous_Vein_R",
        "M_Abductor_Digiti_Minimi_L",
        "M_Abductor_Digiti_Minimi_R",
        "M_Abductor_Hallucis_L",
        "M_Abductor_Hallucis_R",
        "M_Achilles_Tendon_L",
        "M_Achilles_Tendon_R",
        "M_Adductor_Brevis_L",
        "M_Adductor_Brevis_R",
        "M_Adductor_Hallucis_Oblique_Head_L",
        "M_Adductor_Hallucis_Oblique_Head_R",
        "M_Adductor_Hallucis_Transverse_Head_L",
        "M_Adductor_Hallucis_Transverse_Head_R",
        "M_Adductor_Longus_L",
        "M_Adductor_Longus_R",
        "M_Adductor_Magnus_L",
        "M_Adductor_Magnus_R",
        "M_Aductor_Digit_Minimi_L",
        "M_Aductor_Digit_Minimi_R",
        "M_Articularis_Genus_L",
        "M_Articularis_Genus_R",
        "M_Biceps_Femoris_Long_Head_L",
        "M_Biceps_Femoris_Long_Head_R",
        "M_Biceps_Femoris_Short_Head_L",
        "M_Biceps_Femoris_Short_Head_R",
        "M_Dorsal_Interossei_L",
        "M_Dorsal_Interossei_R",
        "M_Extensor_Digitorum_Brevis_L",
        "M_Extensor_Digitorum_Brevis_R",
        "M_Extensor_Digitorum_Longus_L",
        "M_Extensor_Digitorum_Longus_R",
        "M_Extensor_Hallucis_Brevis_L",
        "M_Extensor_Hallucis_Brevis_R",
        "M_Extensor_Hallucis_Longus_L",
        "M_Extensor_Hallucis_Longus_R",
        "M_External_Anal_Sphincter",
        "M_Fibularis_Brevis_L",
        "M_Fibularis_Brevis_R",
        "M_Fibularis_Longubs_L",
        "M_Fibularis_Longubs_R",
        "M_Fibularis_Tertius_L",
        "M_Fibularis_Tertius_R",
        "M_Flexor_Digiti_Minimi_Brevis_L",
        "M_Flexor_Digiti_Minimi_Brevis_R",
        "M_Flexor_Digitorum_Brevis_L",
        "M_Flexor_Digitorum_Brevis_R",
        "M_Flexor_Digitorum_Longus_R",
        "M_Flexor_Digitorum_Longus_L",
        "M_Flexor_Hallucis_Brevis_L",
        "M_Flexor_Hallucis_Brevis_R",
        "M_Flexor_Hallucis_Longus_L",
        "M_Flexor_Hallucis_Longus_R",
        "M_Gastrocnemius_Lateral_L",
        "M_Gastrocnemius_Lateral_R",
        "M_Gastrocnemius_Medial_L",
        "M_Gastrocnemius_Medial_R",
        "M_Gemellus_Interior_L",
        "M_Gemellus_Interior_R",
        "M_Gemellus_Superior_L",
        "M_Gemellus_Superior_R",
        "M_Gluteus_Maximus_L",
        "M_Gluteus_Maximus_R",
        "M_Gluteus_Medius_L",
        "M_Gluteus_Medius_R",
        "M_Gluteus_Minimus_L",
        "M_Gluteus_Minimus_R",
        "M_Gracilis_L",
        "M_Gracilis_R",
        "M_Iliacus_L",
        "M_Iliacus_R",
        "M_Iliococcygeus_L",
        "M_Iliococcygeus_R",
        "M_Iliotibial_Tract_L",
        "M_Iliotibial_Tract_R",
        "M_Internal_anal_Sphincter",
        "M_Interossei_Plantar_L",
        "M_Interossei_Plantar_R",
        "M_Lumbricals_L",
        "M_Lumbricals_R",
        "M_Obturator_Externus_L",
        "M_Obturator_Externus_R",
        "M_Obturator_Internus_L",
        "M_Obturator_Internus_R",
        "M_Opponens_Digiti_Minimi_L",
        "M_Opponens_Digiti_Minimi_R",
        "M_Pantar_Aponeurosis_L",
        "M_Pantar_Aponeurosis_R",
        "M_Pectineus_L",
        "M_Pectineus_R",
        "M_Perineal_Membrane",
        "M_Piriformis_L",
        "M_Piriformis_R",
        "M_Plantar_Aponeurosis_Lateral_Band_L",
        "M_Plantar_Aponeurosis_Lateral_Band_R",
        "M_Plantaris_L",
        "M_Plantaris_R",
        "M_Popliteus_L",
        "M_Popliteus_R",
        "M_Psoas_Major_L",
        "M_Psoas_Major_R",
        "M_Psoas_Minor_L",
        "M_Psoas_Minor_R",
        "M_Pubococcygeus",
        "M_Puborectalis",
        "M_Quadratus_Femoris_L",
        "M_Quadratus_Femoris_R",
        "M_Quadratus_Plantae_L",
        "M_Quadratus_Plantae_R",
        "M_Rectus_Femoris_L",
        "M_Rectus_Femoris_R",
        "M_Sartorius_L",
        "M_Sartorius_R",
        "M_Semimembranosus_L",
        "M_Semimembranosus_R",
        "M_Semitendinosus_L",
        "M_Semitendinosus_R",
        "M_Soleus_L",
        "M_Soleus_R",
        "M_Tendinous_Arch_of_Levator_Ani_L",
        "M_Tendinous_Arch_of_Levator_Ani_R",
        "M_Tibialis_Anterior_L",
        "M_Tibialis_Anterior_R",
        "M_Tibialis_Posterior_L",
        "M_Tibialis_Posterior_R",
        "M_Transverse_Perineal",
        "M_Vastus_Intermedius_L",
        "M_Vastus_Intermedius_R",
        "M_Vastus_Lateralis_L",
        "M_Vastus_Lateralis_R",
        "M_Vastus_Medialis_L",
        "M_Vastus_Medialis_R",
        "Medial_Marginal_Vein_R",
        "Middle_Cluneal_Nerves_R",
        "Plantar_Metatarsal_Arteries_R",
        "Posterior_Femoral_Cutaneous_Nerve_R",
        "M_Plantaris_R",
        "M_Plantaris_L",
        "Saphenous_Nerve_R",
        "Superficial_Epigastric_Artery_R",
        "Superficial_Epigastric_Vein_R",
        "Superficial_Iliac_Circumflex_Artery_R",
        "Superficial_Iliac_Circumflex_Vein_R",
        "Superficial_Peroneal_Nerve_R",
        "Superior_Cluneal_Nerves_R",
        "Sural_Nerve_R",
        "Tibial_Nerve_R"
      ],
      "easyMeshNames": [
        "Great_Saphenous_Vein_R",
        "Lateral_Cutaneous_Nerve_of_Thigh_R",
        "Lesser_Saphenous_Vein_R",
        "Posterior_Femoral_Cutaneous_Nerve_R",
        "Saphenous_Nerve_R",
        "Superficial_Peroneal_Nerve_R",
        "Sural_Nerve_R"
      ]
    },
    "prometheus-318-b": {
      "sideMode": "Unmodified",
      "initialCameraPosition": {
        "x": 0.01834007689067299,
        "y": 1.3277217722081511,
        "z": -1.4538258106907676
      },
      "visibleMeshNames": [
        "B_Coccyx",
        "B_Hipbone_R",
        "B_Lumbar_Disc_L1",
        "B_Lumbar_Disc_L2",
        "B_Lumbar_Disc_L3",
        "B_Lumbar_Disc_L4",
        "B_Lumbar_Disc_L5",
        "B_Lumbar_Vertebra_L1",
        "B_Lumbar_Vertebra_L2",
        "B_Lumbar_Vertebra_L3",
        "B_Lumbar_Vertebra_L4",
        "B_Lumbar_Vertebra_L5",
        "B_Sacrum",
        "B_Thoracic_Disc_T12",
        "B_Thoracic_Vertebra_T12",
        "Deep_Peroneal_Nerve_R",
        "Dorsal_Metatarsal_Veins_R",
        "Dorsal_Venous_Arch_R",
        "Dorsalis_Metatarsal_Arteries_R",
        "F_Fascia_Lata_L",
        "F_Membranous_Layer_of_Superficial_Fascia_of_Abdomen",
        "F_Penis_Superficial_Fascia",
        "F_Superficial_Perineal__Colles__Fascia",
        "F_Thoracolumbar_Fascia",
        "Femoral_Nerve_R",
        "Great_Saphenous_Vein_R",
        "Inferior_Cluneal_Nerve_R",
        "Inferior_Clunial_Nerve_R",
        "L1_Anterior_Root_R",
        "L1_Posterior_Root_Ganglion_R",
        "L1_Posterior_Root_R",
        "L1_Spinal_Nerve_R",
        "L2_Anterior_Root_R",
        "L2_Posterior_Root_Ganglion_R",
        "L2_Posterior_Root_R",
        "L2_Spinal_Nerve_R",
        "L3_Anterior_Root_R",
        "L3_Posterior_Root_Ganglion_R",
        "L3_Posterior_Root_R",
        "L3_Spinal_Nerve_R",
        "L4_Anterior_Root_R",
        "L4_Posterior_Root_Ganglion_R",
        "L4_Posterior_Root_R",
        "L4_Spinal_Nerve_R",
        "L_Anococcygeal_Ligament",
        "L_Anococcygeal_Raphe",
        "L_Anterior_Cruciate_Ligament_L",
        "L_Anterior_Cruciate_Ligament_R",
        "L_Anterior_Fibular_Head_Ligament_L",
        "L_Anterior_Fibular_Head_Ligament_R",
        "L_Anterior_Meniscofemoral_Ligament_L",
        "L_Anterior_Meniscofemoral_Ligament_R",
        "L_Anterior_Sacroiliac_Ligament_L",
        "L_Anterior_Sacroiliac_Ligament_R",
        "L_Anterior_Talofibular_Ligament_L",
        "L_Anterior_Talofibular_Ligament_R",
        "L_Anterior_Tibiofibular_Ligament_L",
        "L_Anterior_Tibiofibular_Ligament_R",
        "L_Anterior_Tibiotalar_Part_L",
        "L_Anterior_Tibiotalar_Part_R",
        "L_Arcuate_Popliteal_Ligament_L",
        "L_Arcuate_Popliteal_Ligament_R",
        "L_Articular_Joint_Capsule_L",
        "L_Articular_Joint_Capsule_R",
        "L_Calcaneocuboidea_Plantare_L",
        "L_Calcaneocuboidea_Plantare_R",
        "L_Calcaneofibular_Ligament_L",
        "L_Calcaneofibular_Ligament_R",
        "L_Cervical_Ligament_L",
        "L_Cervical_Ligament_R",
        "L_Collateral_Interphalangial_Ligaments_L",
        "L_Collateral_Interphalangial_Ligaments_R",
        "L_Cruciform_Ligaments_L1_L",
        "L_Cruciform_Ligaments_R2_R",
        "L_Cuneo_Cuboideum_Plantare_Ligament_L",
        "L_Cuneo_Cuboideum_Plantare_Ligament_R",
        "L_Deep_Posterior_Sacrococcogeal_Ligament",
        "L_Deep_Transverse_Metatarsal_Ligaments_L",
        "L_Deep_Transverse_Metatarsal_Ligaments_R",
        "L_Dorsal_Calcaneocuboid_Ligament_L",
        "L_Dorsal_Calcaneocuboid_Ligament_R",
        "L_Dorsal_Cuboideonavicular_Ligament_L",
        "L_Dorsal_Cuboideonavicular_Ligament_R",
        "L_Dorsal_Cuneocuboid_Ligament_L",
        "L_Dorsal_Cuneocuboid_Ligament_R",
        "L_Dorsal_Cuneonavicular_Ligament_L",
        "L_Dorsal_Cuneonavicular_Ligament_R",
        "L_Dorsal_Metatarsal_Ligaments_L",
        "L_Dorsal_Metatarsal_Ligaments_R",
        "L_Dorsal_Talonavicular_Ligament_L",
        "L_Dorsal_Talonavicular_Ligament_R",
        "L_Dorsal_Tarsal_Ligament_R",
        "L_Dorsal_Tarsal_Ligament_L",
        "L_Dorsal_Tarsometatarsal_Ligament_L",
        "L_Dorsal_Tarsometatarsal_Ligament_R",
        "L_Extensor_Hoods_L",
        "L_Extensor_Hoods_R",
        "L_Fibularis_Brevis_L",
        "L_Fibularis_Brevis_R",
        "L_Flexor_Digitorum_Longus_Tendon_L",
        "L_Flexor_Digitorum_Longus_Tendon_R",
        "L_Flexor_Retinaculum_L",
        "L_Flexor_Retinaculum_R",
        "L_Iliofemoral_Ligament_L",
        "L_Iliofemoral_Ligament_R",
        "L_Iliopectineal_Line_L",
        "L_Iliopectineal_Line_R",
        "L_Inferior_Extensor_Retinaculum_L",
        "L_Inferior_Extensor_Retinaculum_R",
        "L_Inferior_Fibular_Retinaculumac_L",
        "L_Inferior_Fibular_Retinaculumac_R",
        "L_Inguinal_Ligament_L",
        "L_Inguinal_Ligament_R",
        "L_Inguinal_Ring_L",
        "L_Inguinal_Ring_R",
        "L_Intercrural_Fibers_L",
        "L_Intercrural_Fibers_R",
        "L_Intermetatarsal_ligament_L",
        "L_Intermetatarsal_ligament_R",
        "L_Interosseous_talocalcaneal_ligament_L",
        "L_Interosseous_talocalcaneal_ligament_R",
        "L_Interosseus_Membrane_L",
        "L_Interosseus_Membrane_R",
        "L_Ischifemoral_Ligament_L",
        "L_Ischifemoral_Ligament_R",
        "L_Joining_Capsules_L",
        "L_Joining_Capsules_R",
        "L_Lacunar_Ligament_L",
        "L_Lacunar_Ligament_R",
        "L_Lateral_Collateral_Ligament_L",
        "L_Lateral_Collateral_Ligament_R",
        "L_Lateral_Patellar_Retinaculum_L",
        "L_Lateral_Patellar_Retinaculum_R",
        "L_Lateral_Patellofemoral_Ligament_L",
        "L_Lateral_Patellofemoral_Ligament_R",
        "L_Lateral_Patellotibial_Ligament_L",
        "L_Lateral_Patellotibial_Ligament_R",
        "L_Lateral_Talocalcaneal_Ligament_L",
        "L_Lateral_Talocalcaneal_Ligament_R",
        "L_Ligament_of_the_Head_of_Femur_L",
        "L_Ligament_of_the_Head_of_Femur_R",
        "L_Lisfranc_Ligament_L",
        "L_Lisfranc_Ligament_R",
        "L_Medial_Collateral_Ligament_L",
        "L_Medial_Collateral_Ligament_R",
        "L_Medial_Patellar_Retinaculum_L",
        "L_Medial_Patellar_Retinaculum_R",
        "L_Medial_Patellofemoral_Ligament_L",
        "L_Medial_Patellofemoral_Ligament_R",
        "L_Medial_Patellotibial_Ligament_L",
        "L_Medial_Patellotibial_Ligament_R",
        "L_Medial_Talocalcaneal_Ligament_L",
        "L_Medial_Talocalcaneal_Ligament_R",
        "L_Oblique_Popliteal_Ligament_L",
        "L_Oblique_Popliteal_Ligament_R",
        "L_Patellar_Ligament_L",
        "L_Patellar_Ligament_R",
        "L_Pedis_Sheaths_L",
        "L_Pedis_Sheaths_R",
        "L_Plantar_Calcaneonavicular_Ligament_L",
        "L_Plantar_Calcaneonavicular_Ligament_R",
        "L_Plantar_Calcaneonavicular_Spring_Ligament_L",
        "L_Plantar_Calcaneonavicular_Spring_Ligament_R",
        "L_Plantar_Cuboideonavicular_Ligament_L",
        "L_Plantar_Cuboideonavicular_Ligament_R",
        "L_Plantar_Intercuneiform_Ligament_L",
        "L_Plantar_Intercuneiform_Ligament_R",
        "L_Plantar_Ligaments_L",
        "L_Plantar_Ligaments_R",
        "L_Plantar_Metatarsal_Ligament_L",
        "L_Plantar_Metatarsal_Ligament_R",
        "L_Plantar_Tarsometatarsal_Ligament_L",
        "L_Plantar_Tarsometatarsal_Ligament_R",
        "L_Posterior_Cruciate_Ligament_L",
        "L_Posterior_Cruciate_Ligament_R",
        "L_Posterior_Fibular_Head_Ligament_L",
        "L_Posterior_Fibular_Head_Ligament_R",
        "L_Posterior_Meniscofemoral_Ligament_L",
        "L_Posterior_Meniscofemoral_Ligament_R",
        "L_Posterior_Sacroiliac_Ligament_L",
        "L_Posterior_Sacroiliac_Ligament_R",
        "L_Posterior_Talocalcaneal_Ligament_L",
        "L_Posterior_Talocalcaneal_Ligament_R",
        "L_Posterior_Talofibular_Ligament_L",
        "L_Posterior_Talofibular_Ligament_R",
        "L_Posterior_Tibiofibular_Ligament_L",
        "L_Posterior_Tibiofibular_Ligament_R",
        "L_Posterior_Tibiotalal_Part_of_Medial_Ligament_L",
        "L_Posterior_Tibiotalal_Part_of_Medial_Ligament_R",
        "L_Pubic_Symphysis",
        "L_Pubofemoral_Ligament_L",
        "L_Pubofemoral_Ligament_R",
        "L_Sacrospinous_Ligament_L",
        "L_Sacrospinous_Ligament_R",
        "L_Sacrotuberous_ligament_L",
        "L_Sacrotuberous_ligament_R",
        "L_Shearh_of_Extensor_Hallucis_Longus_L",
        "L_Shearh_of_Extensor_Hallucis_Longus_R",
        "L_Sheath_of_Flexor_Digitorum_Longus_L",
        "L_Sheath_of_Flexor_Digitorum_Longus_R",
        "L_Sheath_of_Flexor_Hallucis_Longus_L",
        "L_Sheath_of_Flexor_Hallucis_Longus_R",
        "L_Sheath_of_Tibialis_Anterior_L",
        "L_Sheath_of_Tibialis_Anterior_R",
        "L_Sheath_of_Tibialis_Posterior_L",
        "L_Sheath_of_Tibialis_Posterior_R",
        "L_Superficial_Transverse_Metacarpall_L",
        "L_Superficial_Transverse_Metacarpall_R",
        "L_Superior_Extensor_Retinaculum_L",
        "L_Superior_Extensor_Retinaculum_R",
        "L_Superior_Fibular_Retinaculum_L",
        "L_Superior_Fibular_Retinaculum_R",
        "L_Tendon_sheath_L",
        "L_Tendon_sheath_R",
        "L_Tibiocalcaneal_part_of_medial_ligament_L",
        "L_Tibiocalcaneal_part_of_medial_ligament_R",
        "L_Tibionavicular_part_of_medial_ligament_L",
        "L_Tibionavicular_part_of_medial_ligament_R",
        "L_Transverse_Acetabular_Ligament_L",
        "L_Transverse_Acetabular_Ligament_R",
        "L_Transverse_Ligament_of_Knee_L",
        "L_Transverse_Ligament_of_Knee_R",
        "L_lliolumbar_Ligament_L",
        "L_lliolumbar_Ligament_R",
        "Lateral_Cutaneous_Nerve_of_Thigh_R",
        "Lateral_Marginal_Vein_R",
        "Lesser_Saphenous_Vein_R",
        "M_Abductor_Digiti_Minimi_L",
        "M_Abductor_Digiti_Minimi_R",
        "M_Abductor_Hallucis_L",
        "M_Abductor_Hallucis_R",
        "M_Achilles_Tendon_L",
        "M_Achilles_Tendon_R",
        "M_Adductor_Brevis_L",
        "M_Adductor_Brevis_R",
        "M_Adductor_Hallucis_Oblique_Head_L",
        "M_Adductor_Hallucis_Oblique_Head_R",
        "M_Adductor_Hallucis_Transverse_Head_L",
        "M_Adductor_Hallucis_Transverse_Head_R",
        "M_Adductor_Longus_L",
        "M_Adductor_Longus_R",
        "M_Adductor_Magnus_L",
        "M_Adductor_Magnus_R",
        "M_Aductor_Digit_Minimi_L",
        "M_Aductor_Digit_Minimi_R",
        "M_Articularis_Genus_L",
        "M_Articularis_Genus_R",
        "M_Biceps_Femoris_Long_Head_L",
        "M_Biceps_Femoris_Long_Head_R",
        "M_Biceps_Femoris_Short_Head_L",
        "M_Biceps_Femoris_Short_Head_R",
        "M_Dorsal_Interossei_L",
        "M_Dorsal_Interossei_R",
        "M_Extensor_Digitorum_Brevis_L",
        "M_Extensor_Digitorum_Brevis_R",
        "M_Extensor_Digitorum_Longus_L",
        "M_Extensor_Digitorum_Longus_R",
        "M_Extensor_Hallucis_Brevis_L",
        "M_Extensor_Hallucis_Brevis_R",
        "M_Extensor_Hallucis_Longus_L",
        "M_Extensor_Hallucis_Longus_R",
        "M_External_Anal_Sphincter",
        "M_Fibularis_Brevis_L",
        "M_Fibularis_Brevis_R",
        "M_Fibularis_Longubs_L",
        "M_Fibularis_Longubs_R",
        "M_Fibularis_Tertius_L",
        "M_Fibularis_Tertius_R",
        "M_Flexor_Digiti_Minimi_Brevis_L",
        "M_Flexor_Digiti_Minimi_Brevis_R",
        "M_Flexor_Digitorum_Brevis_L",
        "M_Flexor_Digitorum_Brevis_R",
        "M_Flexor_Digitorum_Longus_R",
        "M_Flexor_Digitorum_Longus_L",
        "M_Flexor_Hallucis_Brevis_L",
        "M_Flexor_Hallucis_Brevis_R",
        "M_Flexor_Hallucis_Longus_L",
        "M_Flexor_Hallucis_Longus_R",
        "M_Gastrocnemius_Lateral_L",
        "M_Gastrocnemius_Lateral_R",
        "M_Gastrocnemius_Medial_L",
        "M_Gastrocnemius_Medial_R",
        "M_Gemellus_Interior_L",
        "M_Gemellus_Interior_R",
        "M_Gemellus_Superior_L",
        "M_Gemellus_Superior_R",
        "M_Gluteus_Maximus_L",
        "M_Gluteus_Maximus_R",
        "M_Gluteus_Medius_L",
        "M_Gluteus_Medius_R",
        "M_Gluteus_Minimus_L",
        "M_Gluteus_Minimus_R",
        "M_Gracilis_L",
        "M_Gracilis_R",
        "M_Iliacus_L",
        "M_Iliacus_R",
        "M_Iliococcygeus_L",
        "M_Iliococcygeus_R",
        "M_Iliotibial_Tract_L",
        "M_Iliotibial_Tract_R",
        "M_Internal_anal_Sphincter",
        "M_Interossei_Plantar_L",
        "M_Interossei_Plantar_R",
        "M_Lumbricals_L",
        "M_Lumbricals_R",
        "M_Obturator_Externus_L",
        "M_Obturator_Externus_R",
        "M_Obturator_Internus_L",
        "M_Obturator_Internus_R",
        "M_Opponens_Digiti_Minimi_L",
        "M_Opponens_Digiti_Minimi_R",
        "M_Pantar_Aponeurosis_L",
        "M_Pantar_Aponeurosis_R",
        "M_Pectineus_L",
        "M_Pectineus_R",
        "M_Perineal_Membrane",
        "M_Piriformis_L",
        "M_Piriformis_R",
        "M_Plantar_Aponeurosis_Lateral_Band_L",
        "M_Plantar_Aponeurosis_Lateral_Band_R",
        "M_Plantaris_L",
        "M_Plantaris_R",
        "M_Popliteus_L",
        "M_Popliteus_R",
        "M_Psoas_Major_L",
        "M_Psoas_Major_R",
        "M_Psoas_Minor_L",
        "M_Psoas_Minor_R",
        "M_Pubococcygeus",
        "M_Puborectalis",
        "M_Quadratus_Femoris_L",
        "M_Quadratus_Femoris_R",
        "M_Quadratus_Plantae_L",
        "M_Quadratus_Plantae_R",
        "M_Rectus_Femoris_L",
        "M_Rectus_Femoris_R",
        "M_Sartorius_L",
        "M_Sartorius_R",
        "M_Semimembranosus_L",
        "M_Semimembranosus_R",
        "M_Semitendinosus_L",
        "M_Semitendinosus_R",
        "M_Soleus_L",
        "M_Soleus_R",
        "M_Tendinous_Arch_of_Levator_Ani_L",
        "M_Tendinous_Arch_of_Levator_Ani_R",
        "M_Tibialis_Anterior_L",
        "M_Tibialis_Anterior_R",
        "M_Tibialis_Posterior_L",
        "M_Tibialis_Posterior_R",
        "M_Transverse_Perineal",
        "M_Vastus_Intermedius_L",
        "M_Vastus_Intermedius_R",
        "M_Vastus_Lateralis_L",
        "M_Vastus_Lateralis_R",
        "M_Vastus_Medialis_L",
        "M_Vastus_Medialis_R",
        "Medial_Marginal_Vein_R",
        "Middle_Cluneal_Nerves_R",
        "Plantar_Metatarsal_Arteries_R",
        "Posterior_Femoral_Cutaneous_Nerve_R",
        "M_Plantaris_R",
        "M_Plantaris_L",
        "Saphenous_Nerve_R",
        "Superficial_Epigastric_Artery_R",
        "Superficial_Epigastric_Vein_R",
        "Superficial_Iliac_Circumflex_Artery_R",
        "Superficial_Iliac_Circumflex_Vein_R",
        "Superficial_Peroneal_Nerve_R",
        "Superior_Cluneal_Nerves_R",
        "Sural_Nerve_R",
        "Tibial_Nerve_R"
      ],
      "easyMeshNames": ["Deep_Peroneal_Nerve_R"]
    },
    "prometheus-320-a": {
      "sideMode": "Unmodified",
      "initialCameraPosition": {
        "x": 0.01834007689067299,
        "y": 1.3277217722081511,
        "z": -1.4538258106907676
      },
      "visibleMeshNames": [
        "B_Femur_R",
        "B_Hipbone_R",
        "B_Lumbar_Disc_L4",
        "B_Lumbar_Disc_L5",
        "B_Lumbar_Vertebra_L4",
        "B_Lumbar_Vertebra_L5",
        "B_Sacrum",
        "C_Acetabulum_Lunate_Surface_R",
        "C_Femur_Head_R",
        "L_Posterior_Sacroiliac_Ligament_R",
        "L_Sacrospinous_Ligament_R",
        "L_Sacrotuberous_ligament_R",
        "L_lliolumbar_Ligament_R",
        "M_Piriformis_R"
      ],
      "easyMeshNames": [
        "B_Hipbone_R",
        "L_Sacrospinous_Ligament_R",
        "L_Sacrotuberous_ligament_R",
        "M_Piriformis_R"
      ]
    },
    "prometheus-321-a": {
      "sideMode": "Unmodified",
      "initialCameraPosition": {
        "x": 0.01834007689067299,
        "y": 1.3277217722081511,
        "z": -1.4538258106907676
      },
      "visibleMeshNames": [
        "B_Coccyx",
        "B_Femur_R",
        "B_Fibula_R",
        "B_Hipbone_R",
        "B_Lumbar_Disc_L1",
        "B_Lumbar_Disc_L2",
        "B_Lumbar_Disc_L3",
        "B_Lumbar_Disc_L4",
        "B_Lumbar_Disc_L5",
        "B_Lumbar_Vertebra_L1",
        "B_Lumbar_Vertebra_L2",
        "B_Lumbar_Vertebra_L3",
        "B_Lumbar_Vertebra_L4",
        "B_Lumbar_Vertebra_L5",
        "B_Patella_R",
        "B_Sacrum",
        "B_Thoracic_Disc_T12",
        "B_Thoracic_Vertebra_T12",
        "B_Tibia_R",
        "C_Acetabulum_Lunate_Surface_R",
        "C_Articular_Surface_Distal_Part_Femur_R",
        "C_Articular_Surface_Fibular_Tibia_R",
        "C_Articular_Surface_Head_Fibula_R",
        "C_Articular_Surface_Patella_R",
        "C_Articular_Surface_Proximal_Tibia_R",
        "C_Femur_Head_R",
        "C_Lateral_meniscus_R",
        "C_Medial_meniscus_L",
        "Common_Iliac_Artery_R",
        "Deep_Circumflex_Iliac_Artery_R",
        "Deep_External_Pudendal_Artery_R",
        "Deep_Femoral_Artery_R",
        "External_Iliac_Artery_R",
        "External_Iliac_Vein_R",
        "F_Auricular_Fascia_R",
        "Femoral_Artery_R",
        "Femoral_Nerve_R",
        "Femoral_Vein_R",
        "Internal_Iliac_Artery_R",
        "L2_Anterior_Root_R",
        "L2_Posterior_Root_Ganglion_R",
        "L2_Posterior_Root_R",
        "L2_Spinal_Nerve_R",
        "L3_Anterior_Root_R",
        "L3_Posterior_Root_Ganglion_R",
        "L3_Posterior_Root_R",
        "L3_Spinal_Nerve_R",
        "L4_Anterior_Root_R",
        "L4_Posterior_Root_Ganglion_R",
        "L4_Posterior_Root_R",
        "L4_Spinal_Nerve_R",
        "L5_Anterior_Root_R",
        "L5_Posterior_Root_Ganglion_R",
        "L5_Posterior_Root_R",
        "L5_Spinal_Nerve_R",
        "L_Anterior_Cruciate_Ligament_R",
        "L_Anterior_Fibular_Head_Ligament_R",
        "L_Anterior_Meniscofemoral_Ligament_R",
        "L_Anterior_Sacroiliac_Ligament_R",
        "L_Arcuate_Popliteal_Ligament_R",
        "L_Articular_Joint_Capsule_R",
        "L_Deep_Posterior_Sacrococcogeal_Ligament",
        "L_Iliofemoral_Ligament_R",
        "L_Inguinal_Ligament_R",
        "L_Ischifemoral_Ligament_R",
        "L_Lacunar_Ligament_R",
        "L_Lateral_Collateral_Ligament_R",
        "L_Lateral_Patellar_Retinaculum_R",
        "L_Lateral_Patellofemoral_Ligament_R",
        "L_Lateral_Patellotibial_Ligament_R",
        "L_Ligament_of_the_Head_of_Femur_R",
        "L_Medial_Collateral_Ligament_R",
        "L_Medial_Patellar_Retinaculum_R",
        "L_Medial_Patellofemoral_Ligament_R",
        "L_Medial_Patellotibial_Ligament_R",
        "L_Oblique_Popliteal_Ligament_R",
        "L_Patellar_Ligament_R",
        "L_Posterior_Cruciate_Ligament_R",
        "L_Posterior_Fibular_Head_Ligament_R",
        "L_Posterior_Meniscofemoral_Ligament_R",
        "L_Posterior_Sacroiliac_Ligament_R",
        "L_Pubofemoral_Ligament_R",
        "L_Sacrospinous_Ligament_R",
        "L_Sacrotuberous_ligament_R",
        "L_Transverse_Acetabular_Ligament_R",
        "L_Transverse_Ligament_of_Knee_R",
        "L_lliolumbar_Ligament_R",
        "Lateral_Cutaneous_Nerve_of_Thigh_R",
        "Lateral_Femoral_Circumflex_Ascending_R",
        "Lateral_Femoral_Circumflex_Descending_R",
        "Lateral_Femoral_Circumflex_R",
        "M_Achilles_Tendon_R",
        "M_Adductor_Brevis_R",
        "M_Adductor_Magnus_R",
        "M_Articularis_Genus_R",
        "M_Biceps_Femoris_Long_Head_R",
        "M_Biceps_Femoris_Short_Head_R",
        "M_Coccigeus_Muscle_R",
        "M_Extensor_Digitorum_Longus_R",
        "M_Fibularis_Brevis_R",
        "M_Fibularis_Longubs_R",
        "M_Fibularis_Tertius_R",
        "M_Gastrocnemius_Lateral_R",
        "M_Gastrocnemius_Medial_R",
        "M_Gemellus_Interior_R",
        "M_Gemellus_Superior_R",
        "M_Gluteus_Maximus_R",
        "M_Gluteus_Medius_R",
        "M_Gluteus_Minimus_R",
        "M_Gracilis_R",
        "M_Iliacus_R",
        "M_Iliotibial_Tract_R",
        "M_Obturator_Externus_R",
        "M_Obturator_Internus_R",
        "M_Pectineus_R",
        "M_Piriformis_R",
        "M_Popliteus_R",
        "M_Psoas_Major_R",
        "M_Quadratus_Femoris_R",
        "M_Rectus_Femoris_R",
        "M_Sartorius_R",
        "M_Semimembranosus_R",
        "M_Semitendinosus_R",
        "M_Soleus_R",
        "M_Superior_Auricular_Muscle_R",
        "M_Tibialis_Anterior_R",
        "M_Vastus_Intermedius_R",
        "M_Vastus_Lateralis_R",
        "M_Vastus_Medialis_R",
        "Medial_circumflex_femoral_Artery_R",
        "Obturator_Nerve_R",
        "Pudendal_Artery_R",
        "S1_Anterior_Root_R",
        "S1_Posterior_Root_Ganglion_R",
        "S1_Posterior_Root_R",
        "S1_Spinal_Nerve_R",
        "S2_Anterior_Root_R",
        "S2_Posterior_Root_Ganglion_R",
        "S2_Posterior_Root_R",
        "S2_Spinal_Nerve_R",
        "S3_Anterior_Root_R",
        "S3_Posterior_Root_Ganglion_R",
        "S3_Posterior_Root_R",
        "S3_Spinal_Nerve_R",
        "S4_Anterior_Root_R",
        "S4_Posterior_Root_Ganglion_R",
        "S4_Posterior_Root_R",
        "S4_Spinal_Nerve_R",
        "S5_Anterior_Root_R",
        "S5_Posterior_Root_Ganglion_R",
        "S5_Posterior_Root_R",
        "S5_Spinal_Nerve_R",
        "S5_Ventral_Ramus_R",
        "Saphenous_Nerve_R",
        "Sciatic_Nerve_R",
        "Superficial_Iliac_Circumflex_Artery_R",
        "Ventral_Ramus_of_S5_Nerve_R"
      ],
      "easyMeshNames": [
        "Femoral_Artery_R",
        "Femoral_Nerve_R",
        "Femoral_Vein_R",
        "Lateral_Cutaneous_Nerve_of_Thigh_R",
        "Obturator_Nerve_R",
        "Sciatic_Nerve_R"
      ]
    },
    "prometheus-321-b": {
      "sideMode": "Unmodified",
      "initialCameraPosition": {
        "x": 0.01834007689067299,
        "y": 1.3277217722081511,
        "z": -1.4538258106907676
      },
      "visibleMeshNames": [
        "B_Coccyx",
        "B_Femur_R",
        "B_Fibula_R",
        "B_Hipbone_R",
        "B_Lumbar_Disc_L1",
        "B_Lumbar_Disc_L2",
        "B_Lumbar_Disc_L3",
        "B_Lumbar_Disc_L4",
        "B_Lumbar_Disc_L5",
        "B_Lumbar_Vertebra_L1",
        "B_Lumbar_Vertebra_L2",
        "B_Lumbar_Vertebra_L3",
        "B_Lumbar_Vertebra_L4",
        "B_Lumbar_Vertebra_L5",
        "B_Patella_R",
        "B_Sacrum",
        "B_Thoracic_Disc_T12",
        "B_Thoracic_Vertebra_T12",
        "B_Tibia_R",
        "C_Acetabulum_Lunate_Surface_R",
        "C_Articular_Surface_Distal_Part_Femur_R",
        "C_Articular_Surface_Fibular_Tibia_R",
        "C_Articular_Surface_Head_Fibula_R",
        "C_Articular_Surface_Patella_R",
        "C_Articular_Surface_Proximal_Tibia_R",
        "C_Femur_Head_R",
        "C_Lateral_meniscus_R",
        "C_Medial_meniscus_L",
        "Common_Iliac_Artery_R",
        "Deep_Circumflex_Iliac_Artery_R",
        "Deep_External_Pudendal_Artery_R",
        "Deep_Femoral_Artery_R",
        "External_Iliac_Artery_R",
        "External_Iliac_Vein_R",
        "F_Auricular_Fascia_R",
        "Femoral_Artery_R",
        "Femoral_Nerve_R",
        "Femoral_Vein_R",
        "Internal_Iliac_Artery_R",
        "L2_Anterior_Root_R",
        "L2_Posterior_Root_Ganglion_R",
        "L2_Posterior_Root_R",
        "L2_Spinal_Nerve_R",
        "L3_Anterior_Root_R",
        "L3_Posterior_Root_Ganglion_R",
        "L3_Posterior_Root_R",
        "L3_Spinal_Nerve_R",
        "L4_Anterior_Root_R",
        "L4_Posterior_Root_Ganglion_R",
        "L4_Posterior_Root_R",
        "L4_Spinal_Nerve_R",
        "L5_Anterior_Root_R",
        "L5_Posterior_Root_Ganglion_R",
        "L5_Posterior_Root_R",
        "L5_Spinal_Nerve_R",
        "L_Anterior_Cruciate_Ligament_R",
        "L_Anterior_Fibular_Head_Ligament_R",
        "L_Anterior_Meniscofemoral_Ligament_R",
        "L_Anterior_Sacroiliac_Ligament_R",
        "L_Arcuate_Popliteal_Ligament_R",
        "L_Articular_Joint_Capsule_R",
        "L_Deep_Posterior_Sacrococcogeal_Ligament",
        "L_Iliofemoral_Ligament_R",
        "L_Inguinal_Ligament_R",
        "L_Ischifemoral_Ligament_R",
        "L_Lacunar_Ligament_R",
        "L_Lateral_Collateral_Ligament_R",
        "L_Lateral_Patellar_Retinaculum_R",
        "L_Lateral_Patellofemoral_Ligament_R",
        "L_Lateral_Patellotibial_Ligament_R",
        "L_Ligament_of_the_Head_of_Femur_R",
        "L_Medial_Collateral_Ligament_R",
        "L_Medial_Patellar_Retinaculum_R",
        "L_Medial_Patellofemoral_Ligament_R",
        "L_Medial_Patellotibial_Ligament_R",
        "L_Oblique_Popliteal_Ligament_R",
        "L_Patellar_Ligament_R",
        "L_Posterior_Cruciate_Ligament_R",
        "L_Posterior_Fibular_Head_Ligament_R",
        "L_Posterior_Meniscofemoral_Ligament_R",
        "L_Posterior_Sacroiliac_Ligament_R",
        "L_Pubofemoral_Ligament_R",
        "L_Sacrospinous_Ligament_R",
        "L_Sacrotuberous_ligament_R",
        "L_Transverse_Acetabular_Ligament_R",
        "L_Transverse_Ligament_of_Knee_R",
        "L_lliolumbar_Ligament_R",
        "Lateral_Cutaneous_Nerve_of_Thigh_R",
        "Lateral_Femoral_Circumflex_Ascending_R",
        "Lateral_Femoral_Circumflex_Descending_R",
        "Lateral_Femoral_Circumflex_R",
        "M_Achilles_Tendon_R",
        "M_Adductor_Brevis_R",
        "M_Adductor_Magnus_R",
        "M_Articularis_Genus_R",
        "M_Biceps_Femoris_Long_Head_R",
        "M_Biceps_Femoris_Short_Head_R",
        "M_Coccigeus_Muscle_R",
        "M_Extensor_Digitorum_Longus_R",
        "M_Fibularis_Brevis_R",
        "M_Fibularis_Longubs_R",
        "M_Fibularis_Tertius_R",
        "M_Gastrocnemius_Lateral_R",
        "M_Gastrocnemius_Medial_R",
        "M_Gemellus_Interior_R",
        "M_Gemellus_Superior_R",
        "M_Gluteus_Maximus_R",
        "M_Gluteus_Medius_R",
        "M_Gluteus_Minimus_R",
        "M_Gracilis_R",
        "M_Iliacus_R",
        "M_Iliotibial_Tract_R",
        "M_Obturator_Externus_R",
        "M_Obturator_Internus_R",
        "M_Piriformis_R",
        "M_Popliteus_R",
        "M_Psoas_Major_R",
        "M_Quadratus_Femoris_R",
        "M_Semimembranosus_R",
        "M_Semitendinosus_R",
        "M_Soleus_R",
        "M_Superior_Auricular_Muscle_R",
        "M_Tibialis_Anterior_R",
        "M_Vastus_Intermedius_R",
        "M_Vastus_Lateralis_R",
        "M_Vastus_Medialis_R",
        "Medial_circumflex_femoral_Artery_R",
        "Obturator_Nerve_R",
        "Pudendal_Artery_R",
        "S1_Anterior_Root_R",
        "S1_Posterior_Root_Ganglion_R",
        "S1_Posterior_Root_R",
        "S1_Spinal_Nerve_R",
        "S2_Anterior_Root_R",
        "S2_Posterior_Root_Ganglion_R",
        "S2_Posterior_Root_R",
        "S2_Spinal_Nerve_R",
        "S3_Anterior_Root_R",
        "S3_Posterior_Root_Ganglion_R",
        "S3_Posterior_Root_R",
        "S3_Spinal_Nerve_R",
        "S4_Anterior_Root_R",
        "S4_Posterior_Root_Ganglion_R",
        "S4_Posterior_Root_R",
        "S4_Spinal_Nerve_R",
        "S5_Anterior_Root_R",
        "S5_Posterior_Root_Ganglion_R",
        "S5_Posterior_Root_R",
        "S5_Spinal_Nerve_R",
        "S5_Ventral_Ramus_R",
        "Saphenous_Nerve_R",
        "Sciatic_Nerve_R",
        "Superficial_Iliac_Circumflex_Artery_R",
        "Ventral_Ramus_of_S5_Nerve_R"
      ],
      "easyMeshNames": [
        "Deep_Femoral_Artery_R",
        "Femoral_Artery_R",
        "Femoral_Vein_R",
        "Lateral_Femoral_Circumflex_Ascending_R",
        "Lateral_Femoral_Circumflex_R",
        "Medial_circumflex_femoral_Artery_R",
        "Saphenous_Nerve_R"
      ]
    },
    "prometheus-322-a": {
      "sideMode": "Unmodified",
      "initialCameraPosition": {
        "x": 0.01834007689067299,
        "y": 1.3277217722081511,
        "z": -1.4538258106907676
      },
      "visibleMeshNames": [
        "B_Coccyx",
        "B_Femur_R",
        "B_Fibula_R",
        "B_Hipbone_R",
        "B_Lumbar_Disc_L1",
        "B_Lumbar_Disc_L2",
        "B_Lumbar_Disc_L3",
        "B_Lumbar_Disc_L4",
        "B_Lumbar_Disc_L5",
        "B_Lumbar_Vertebra_L1",
        "B_Lumbar_Vertebra_L2",
        "B_Lumbar_Vertebra_L3",
        "B_Lumbar_Vertebra_L4",
        "B_Lumbar_Vertebra_L5",
        "B_Patella_R",
        "B_Sacrum",
        "B_Thoracic_Disc_T12",
        "B_Thoracic_Vertebra_T12",
        "B_Tibia_R",
        "C_Acetabulum_Lunate_Surface_R",
        "C_Articular_Surface_Distal_Part_Femur_R",
        "C_Articular_Surface_Fibular_Tibia_R",
        "C_Articular_Surface_Head_Fibula_R",
        "C_Articular_Surface_Patella_R",
        "C_Articular_Surface_Proximal_Tibia_R",
        "C_Femur_Head_R",
        "C_Lateral_meniscus_R",
        "C_Medial_meniscus_L",
        "Common_Iliac_Artery_R",
        "Common_Iliac_Vein_R",
        "Common_Peroneal_Nerve_R",
        "Deep_Femoral_Artery_R",
        "External_Iliac_Artery_R",
        "External_Iliac_Vein_R",
        "F_Auricular_Fascia_R",
        "Femoral_Artery_R",
        "Femoral_Vein_R",
        "Inferior_Gluteal_Artery_R",
        "Inferior_Gluteal_Nerve_R",
        "Inferior_Gluteal_Vein_R_03",
        "Internal_Iliac_Artery_R",
        "Internal_Iliac_Vein_R",
        "L2_Anterior_Root_R",
        "L2_Posterior_Root_Ganglion_R",
        "L2_Posterior_Root_R",
        "L2_Spinal_Nerve_R",
        "L3_Anterior_Root_R",
        "L3_Posterior_Root_Ganglion_R",
        "L3_Posterior_Root_R",
        "L3_Spinal_Nerve_R",
        "L4_Anterior_Root_R",
        "L4_Posterior_Root_Ganglion_R",
        "L4_Posterior_Root_R",
        "L4_Spinal_Nerve_R",
        "L5_Anterior_Root_R",
        "L5_Posterior_Root_Ganglion_R",
        "L5_Posterior_Root_R",
        "L5_Spinal_Nerve_R",
        "L_Anterior_Cruciate_Ligament_R",
        "L_Anterior_Fibular_Head_Ligament_R",
        "L_Anterior_Meniscofemoral_Ligament_R",
        "L_Anterior_Sacroiliac_Ligament_R",
        "L_Arcuate_Popliteal_Ligament_R",
        "L_Articular_Joint_Capsule_R",
        "L_Deep_Posterior_Sacrococcogeal_Ligament",
        "L_Iliofemoral_Ligament_R",
        "L_Ischifemoral_Ligament_R",
        "L_Lateral_Collateral_Ligament_R",
        "L_Lateral_Patellar_Retinaculum_R",
        "L_Lateral_Patellofemoral_Ligament_R",
        "L_Lateral_Patellotibial_Ligament_R",
        "L_Ligament_of_the_Head_of_Femur_R",
        "L_Medial_Collateral_Ligament_R",
        "L_Medial_Patellar_Retinaculum_R",
        "L_Medial_Patellofemoral_Ligament_R",
        "L_Medial_Patellotibial_Ligament_R",
        "L_Oblique_Popliteal_Ligament_R",
        "L_Patellar_Ligament_R",
        "L_Posterior_Cruciate_Ligament_R",
        "L_Posterior_Fibular_Head_Ligament_R",
        "L_Posterior_Meniscofemoral_Ligament_R",
        "L_Posterior_Sacroiliac_Ligament_R",
        "L_Pubofemoral_Ligament_R",
        "L_Sacrospinous_Ligament_R",
        "L_Sacrotuberous_ligament_R",
        "L_Transverse_Acetabular_Ligament_R",
        "L_Transverse_Ligament_of_Knee_R",
        "L_lliolumbar_Ligament_R",
        "Lateral_Femoral_Circumflex_R",
        "M_Achilles_Tendon_R",
        "M_Adductor_Brevis_R",
        "M_Adductor_Magnus_R",
        "M_Articularis_Genus_R",
        "M_Biceps_Femoris_Short_Head_R",
        "M_Coccigeus_Muscle_R",
        "M_Extensor_Digitorum_Longus_R",
        "M_Fibularis_Brevis_R",
        "M_Fibularis_Longubs_R",
        "M_Fibularis_Tertius_R",
        "M_Gastrocnemius_Lateral_R",
        "M_Gastrocnemius_Medial_R",
        "M_Gemellus_Interior_R",
        "M_Gemellus_Superior_R",
        "M_Gluteus_Minimus_R",
        "M_Gracilis_R",
        "M_Iliacus_R",
        "M_Iliotibial_Tract_R",
        "M_Obturator_Externus_R",
        "M_Obturator_Internus_R",
        "M_Pectineus_R",
        "M_Piriformis_R",
        "M_Popliteus_R",
        "M_Psoas_Major_R",
        "M_Quadratus_Femoris_R",
        "M_Rectus_Femoris_R",
        "M_Sartorius_R",
        "M_Semimembranosus_R",
        "M_Semitendinosus_R",
        "M_Soleus_R",
        "M_Superior_Auricular_Muscle_R",
        "M_Tibialis_Anterior_R",
        "M_Vastus_Intermedius_R",
        "M_Vastus_Lateralis_R",
        "M_Vastus_Medialis_R",
        "Medial_circumflex_femoral_Artery_R",
        "Popliteal_Artery_R",
        "Popliteal_Vein_R",
        "Posterior_Femoral_Cutaneous_Nerve_R",
        "Pudendal_Artery_R",
        "Pudendal_Nerve_R",
        "S1_Anterior_Root_R",
        "S1_Posterior_Root_Ganglion_R",
        "S1_Posterior_Root_R",
        "S1_Spinal_Nerve_R",
        "S2_Anterior_Root_R",
        "S2_Posterior_Root_Ganglion_R",
        "S2_Posterior_Root_R",
        "S2_Spinal_Nerve_R",
        "S3_Anterior_Root_R",
        "S3_Posterior_Root_Ganglion_R",
        "S3_Posterior_Root_R",
        "S3_Spinal_Nerve_R",
        "S4_Anterior_Root_R",
        "S4_Posterior_Root_Ganglion_R",
        "S4_Posterior_Root_R",
        "S4_Spinal_Nerve_R",
        "S5_Anterior_Root_R",
        "S5_Posterior_Root_Ganglion_R",
        "S5_Posterior_Root_R",
        "S5_Spinal_Nerve_R",
        "S5_Ventral_Ramus_R",
        "Saphenous_Nerve_R",
        "Sciatic_Nerve_R",
        "Superior_Gluteal_Artery_R",
        "Superior_Gluteal_Nerve_R",
        "Superior_Gluteal_Vein_R",
        "Tibial_Nerve_R",
        "Ventral_Ramus_of_S5_Nerve_R"
      ],
      "easyMeshNames": [
        "Lateral_Femoral_Circumflex_R",
        "M_Gluteus_Minimus_R",
        "Popliteal_Artery_R",
        "Popliteal_Vein_R",
        "Posterior_Femoral_Cutaneous_Nerve_R",
        "Pudendal_Nerve_R",
        "Sciatic_Nerve_R",
        "Superior_Gluteal_Artery_R",
        "Superior_Gluteal_Nerve_R",
        "Superior_Gluteal_Vein_R"
      ]
    },
    "prometheus-331-a": {
      "sideMode": "Unmodified",
      "initialCameraPosition": {
        "x": 0.01834007689067299,
        "y": 1.3277217722081511,
        "z": -1.4538258106907676
      },
      "visibleMeshNames": [
        "B_Calcaneus_R",
        "B_Coccyx",
        "B_Cuboid_R",
        "B_Distal_Phalanx_Foot_1_R",
        "B_Distal_Phalanx_Foot_2_R",
        "B_Distal_Phalanx_Foot_3_R",
        "B_Distal_Phalanx_Foot_4_R",
        "B_Distal_Phalanx_Foot_5_R",
        "B_Femur_R",
        "B_Fibula_R",
        "B_Hipbone_R",
        "B_Intermediate_Cuneiform_R",
        "B_Lateral_Cuneiform_R",
        "B_Lumbar_Disc_L4",
        "B_Lumbar_Disc_L5",
        "B_Lumbar_Vertebra_L5",
        "B_Medial_Cuneiform_Bone_R",
        "B_Metatarsal_1_R",
        "B_Metatarsal_2_R",
        "B_Metatarsal_3_R",
        "B_Metatarsal_4_R",
        "B_Metatarsal_5_R",
        "B_Middle_Phalanx_Foot_2_R",
        "B_Middle_Phalanx_Foot_3_R",
        "B_Middle_Phalanx_Foot_4_R",
        "B_Middle_Phalanx_Foot_5_R",
        "B_Navicular_Bone_R",
        "B_Patella_R",
        "B_Proximal_Phalanx_Foot_1_R",
        "B_Proximal_Phalanx_Foot_2_R",
        "B_Proximal_Phalanx_Foot_3_R",
        "B_Proximal_Phalanx_Foot_4_R",
        "B_Proximal_Phalanx_Foot_5_R",
        "B_Sacrum",
        "B_Sesamoid_Foot_R",
        "B_Talus_R",
        "B_Tibia_R",
        "C_Acetabulum_Lunate_Surface_R",
        "C_Articular_Surface_Cuboid_Calcaneus_R",
        "C_Articular_Surface_Cuboid_of_Lateral_Cuneiform_R",
        "C_Articular_Surface_Cuboid_of_Navicular_R",
        "C_Articular_Surface_Distal_Middle_Phalanx_Fourth_Toe_R",
        "C_Articular_Surface_Distal_Middle_Phalanx_Second_Toe_R",
        "C_Articular_Surface_Distal_Middle_Phalanx_Third_Toe_R",
        "C_Articular_Surface_Distal_Part_Femur_R",
        "C_Articular_Surface_Distal_Phalanx_Fourth_Toe_R",
        "C_Articular_Surface_Distal_Phalanx_Great_Toe_R",
        "C_Articular_Surface_Distal_Phalanx_Little_Toe_R",
        "C_Articular_Surface_Distal_Phalanx_Second_Toe_R",
        "C_Articular_Surface_Distal_Phalanx_Third_Toe_R",
        "C_Articular_Surface_Distal_Talus_R",
        "C_Articular_Surface_Distal_Tibia_R",
        "C_Articular_Surface_Distal_of_Cuboid_R",
        "C_Articular_Surface_Distal_of_Fifth_Metatarsal_R",
        "C_Articular_Surface_Distal_of_Fourth_Metatarsal_R",
        "C_Articular_Surface_Distal_of_Lateral_Cuneiform_R",
        "C_Articular_Surface_Distal_of_Middle_Phalanx_Little_Toe_R",
        "C_Articular_Surface_Distal_of_Navicular_R",
        "C_Articular_Surface_Distal_of_Proximal_Phalanx_Fourth_Toe_R",
        "C_Articular_Surface_Distal_of_Proximal_Phalanx_Great_Toe_R",
        "C_Articular_Surface_Distal_of_Proximal_Phalanx_Little_Toe_R",
        "C_Articular_Surface_Distal_of_Proximal_Phalanx_Second_Toe_R",
        "C_Articular_Surface_Distal_of_Proximal_Phalanx_Third_Toe_R",
        "C_Articular_Surface_Distal_of_Second_Metatarsal_R",
        "C_Articular_Surface_Distal_of_Third_Metatarsal_R",
        "C_Articular_Surface_Fibular_Tibia_R",
        "C_Articular_Surface_Head_Fibula_R",
        "C_Articular_Surface_Head_of_First_Metatarsal_R",
        "C_Articular_Surface_Lateral_Cuneiform_of_Cuboid_R",
        "C_Articular_Surface_Lateral_Malleolus_R",
        "C_Articular_Surface_Middle_Calcaneal_R",
        "C_Articular_Surface_Middle_Talar_R",
        "C_Articular_Surface_Navicular_of_Cuboid_R",
        "C_Articular_Surface_Patella_R",
        "C_Articular_Surface_Posterior_Calcaneal_R",
        "C_Articular_Surface_Posterior_Talar_R",
        "C_Articular_Surface_Proximal_Cuboid_R",
        "C_Articular_Surface_Proximal_Talus_R",
        "C_Articular_Surface_Proximal_Tibia_R",
        "C_Articular_Surface_Proximal_of_Fifth_Metatarsal_R",
        "C_Articular_Surface_Proximal_of_Fourth_Metatarsal_R",
        "C_Articular_Surface_Proximal_of_Lateral_Cuneiform_R",
        "C_Articular_Surface_Proximal_of_Navicular_R",
        "C_Articular_Surface_Proximal_of_Proximal_Phalanx_Fourth_Toe_R",
        "C_Articular_Surface_Proximal_of_Proximal_Phalanx_Great_Toe_R",
        "C_Articular_Surface_Proximal_of_Proximal_Phalanx_Little_Toe_R",
        "C_Articular_Surface_Proximal_of_Proximal_Phalanx_Second_Toe_R",
        "C_Articular_Surface_Proximal_of_Proximal_Phalanx_Third_Toe_R",
        "C_Articular_Surface_Proximal_of_Third_Metatarsal_R",
        "C_Articular_Surface_Proximall_of_First_Metatarsal_R",
        "C_Articular_Surface_Proximall_of_Middle_Phalanx_Fourth_Toe_R",
        "C_Articular_Surface_Proximall_of_Middle_Phalanx_Little_Toe_R",
        "C_Articular_Surface_Proximall_of_Middle_Phalanx_Second_Toe_R",
        "C_Articular_Surface_Proximall_of_Middle_Phalanx_Third_Toe_R",
        "C_Articular_Surface_Proximall_of_Second_Metatarsal_R",
        "C_Articular_Surface_Proximolateral_Distal_of_Medial_Cuneiform_R",
        "C_Articular_Surface_of_Lateral_Cuneiform_R",
        "C_Femur_Head_R",
        "C_Lateral_meniscus_R",
        "C_Medial_meniscus_L",
        "F_Auricular_Fascia_R",
        "F_Deep_Posterior_Compartment_R",
        "F_Lateral_Compartment_R",
        "L_Anterior_Cruciate_Ligament_R",
        "L_Anterior_Fibular_Head_Ligament_R",
        "L_Anterior_Meniscofemoral_Ligament_R",
        "L_Anterior_Sacroiliac_Ligament_R",
        "L_Anterior_Talofibular_Ligament_R",
        "L_Anterior_Tibiofibular_Ligament_R",
        "L_Anterior_Tibiotalar_Part_R",
        "L_Arcuate_Popliteal_Ligament_R",
        "L_Articular_Joint_Capsule_R",
        "L_Calcaneocuboidea_Plantare_R",
        "L_Calcaneofibular_Ligament_R",
        "L_Cervical_Ligament_R",
        "L_Collateral_Interphalangial_Ligaments_R",
        "L_Cruciform_Ligaments_R2_R",
        "L_Cuneo_Cuboideum_Plantare_Ligament_R",
        "L_Deep_Posterior_Sacrococcogeal_Ligament",
        "L_Deep_Transverse_Metatarsal_Ligaments_R",
        "L_Dorsal_Calcaneocuboid_Ligament_R",
        "L_Dorsal_Cuboideonavicular_Ligament_R",
        "L_Dorsal_Cuneocuboid_Ligament_R",
        "L_Dorsal_Cuneonavicular_Ligament_R",
        "L_Dorsal_Metatarsal_Ligaments_R",
        "L_Dorsal_Talonavicular_Ligament_R",
        "L_Dorsal_Tarsal_Ligament_R",
        "L_Dorsal_Tarsometatarsal_Ligament_R",
        "L_Extensor_Hoods_R",
        "L_Fibularis_Brevis_R",
        "L_Flexor_Digitorum_Longus_Tendon_R",
        "L_Flexor_Retinaculum_R",
        "L_Iliofemoral_Ligament_R",
        "L_Inferior_Extensor_Retinaculum_R",
        "L_Inferior_Fibular_Retinaculumac_R",
        "L_Inguinal_Ligament_R",
        "L_Intermetatarsal_ligament_R",
        "L_Interosseous_talocalcaneal_ligament_R",
        "L_Interosseus_Membrane_R",
        "L_Ischifemoral_Ligament_R",
        "L_Joining_Capsules_R",
        "L_Lacunar_Ligament_R",
        "L_Lateral_Collateral_Ligament_R",
        "L_Lateral_Patellar_Retinaculum_R",
        "L_Lateral_Patellofemoral_Ligament_R",
        "L_Lateral_Patellotibial_Ligament_R",
        "L_Lateral_Talocalcaneal_Ligament_R",
        "L_Ligament_of_the_Head_of_Femur_R",
        "L_Lisfranc_Ligament_R",
        "L_Medial_Collateral_Ligament_R",
        "L_Medial_Patellar_Retinaculum_R",
        "L_Medial_Patellofemoral_Ligament_R",
        "L_Medial_Patellotibial_Ligament_R",
        "L_Medial_Talocalcaneal_Ligament_R",
        "L_Oblique_Popliteal_Ligament_R",
        "L_Patellar_Ligament_R",
        "L_Pedis_Sheaths_R",
        "L_Plantar_Calcaneonavicular_Ligament_R",
        "L_Plantar_Calcaneonavicular_Spring_Ligament_R",
        "L_Plantar_Cuboideonavicular_Ligament_R",
        "L_Plantar_Intercuneiform_Ligament_R",
        "L_Plantar_Ligaments_R",
        "L_Plantar_Metatarsal_Ligament_R",
        "L_Plantar_Tarsometatarsal_Ligament_R",
        "L_Posterior_Cruciate_Ligament_R",
        "L_Posterior_Fibular_Head_Ligament_R",
        "L_Posterior_Meniscofemoral_Ligament_R",
        "L_Posterior_Sacroiliac_Ligament_R",
        "L_Posterior_Talocalcaneal_Ligament_R",
        "L_Posterior_Talofibular_Ligament_R",
        "L_Posterior_Tibiofibular_Ligament_R",
        "L_Posterior_Tibiotalal_Part_of_Medial_Ligament_R",
        "L_Pubofemoral_Ligament_R",
        "L_Sacrospinous_Ligament_R",
        "L_Sacrotuberous_ligament_R",
        "L_Shearh_of_Extensor_Hallucis_Longus_R",
        "L_Sheath_of_Flexor_Digitorum_Longus_R",
        "L_Sheath_of_Flexor_Hallucis_Longus_R",
        "L_Sheath_of_Tibialis_Anterior_R",
        "L_Sheath_of_Tibialis_Posterior_R",
        "L_Superficial_Transverse_Metacarpall_R",
        "L_Superior_Extensor_Retinaculum_R",
        "L_Superior_Fibular_Retinaculum_R",
        "L_Tendon_sheath_R",
        "L_Tibiocalcaneal_part_of_medial_ligament_R",
        "L_Tibionavicular_part_of_medial_ligament_R",
        "L_Transverse_Acetabular_Ligament_R",
        "L_Transverse_Ligament_of_Knee_R",
        "L_lliolumbar_Ligament_R",
        "M_Abductor_Digiti_Minimi_R",
        "M_Abductor_Hallucis_R",
        "M_Achilles_Tendon_R",
        "M_Adductor_Brevis_R",
        "M_Adductor_Hallucis_Oblique_Head_R",
        "M_Adductor_Hallucis_Transverse_Head_R",
        "M_Adductor_Longus_R",
        "M_Adductor_Magnus_R",
        "M_Aductor_Digit_Minimi_R",
        "M_Articularis_Genus_R",
        "M_Biceps_Femoris_Long_Head_R",
        "M_Biceps_Femoris_Short_Head_R",
        "M_Coccigeus_Muscle_R",
        "M_Dorsal_Interossei_R",
        "M_Extensor_Digitorum_Brevis_R",
        "M_Extensor_Digitorum_Longus_R",
        "M_Extensor_Hallucis_Brevis_R",
        "M_Extensor_Hallucis_Longus_R",
        "M_Fibularis_Brevis_R",
        "M_Fibularis_Longubs_R",
        "M_Fibularis_Tertius_R",
        "M_Flexor_Digiti_Minimi_Brevis_R",
        "M_Flexor_Digitorum_Brevis_R",
        "M_Flexor_Digitorum_Longus_R",
        "M_Flexor_Hallucis_Brevis_R",
        "M_Flexor_Hallucis_Longus_R",
        "M_Gastrocnemius_Lateral_R",
        "M_Gastrocnemius_Medial_R",
        "M_Gemellus_Interior_R",
        "M_Gemellus_Superior_R",
        "M_Gluteus_Maximus_R",
        "M_Gluteus_Medius_R",
        "M_Gluteus_Minimus_R",
        "M_Gracilis_R",
        "M_Iliacus_R",
        "M_Iliotibial_Tract_R",
        "M_Interossei_Plantar_R",
        "M_Lumbricals_R",
        "M_Obturator_Externus_R",
        "M_Obturator_Internus_R",
        "M_Opponens_Digiti_Minimi_R",
        "M_Pantar_Aponeurosis_R",
        "M_Pectineus_R",
        "M_Piriformis_R",
        "M_Plantar_Aponeurosis_Lateral_Band_R",
        "M_Plantaris_R",
        "M_Popliteus_R",
        "M_Quadratus_Femoris_R",
        "M_Quadratus_Plantae_R",
        "M_Rectus_Femoris_R",
        "M_Sartorius_R",
        "M_Semimembranosus_R",
        "M_Semitendinosus_R",
        "M_Soleus_R",
        "M_Superior_Auricular_Muscle_R",
        "M_Tibialis_Anterior_R",
        "M_Tibialis_Posterior_R",
        "M_Vastus_Intermedius_R",
        "M_Vastus_Lateralis_R",
        "M_Vastus_Medialis_R",
        "M_Plantaris_R"
      ],
      "easyMeshNames": [
        "B_Tibia_R",
        "M_Adductor_Longus_R",
        "M_Extensor_Hallucis_Longus_R",
        "M_Gastrocnemius_Lateral_R",
        "M_Gastrocnemius_Medial_R",
        "M_Iliotibial_Tract_R",
        "M_Rectus_Femoris_R",
        "M_Tibialis_Anterior_R",
        "M_Vastus_Lateralis_R"
      ]
    },
    "prometheus-96-a": {
      "sideMode": "Unmodified",
      "initialCameraPosition": {
        "x": 0.01834007689067299,
        "y": 1.3277217722081511,
        "z": -1.4538258106907676
      },
      "visibleMeshNames": ["B_Hipbone_R"],
      "easyMeshNames": ["B_Hipbone_R"]
    },
    "prometheus-97-a": {
      "sideMode": "Unmodified",
      "initialCameraPosition": {
        "x": 0.01834007689067299,
        "y": 1.3277217722081511,
        "z": -1.4538258106907676
      },
      "visibleMeshNames": [
        "B_Coccyx",
        "B_Hipbone_L",
        "B_Hipbone_R",
        "B_Sacrum",
        "L_Pubic_Symphysis"
      ],
      "easyMeshNames": ["B_Hipbone_R", "B_Sacrum"]
    },
    "prometheus-98-a": {
      "sideMode": "Unmodified",
      "initialCameraPosition": {
        "x": 0.01834007689067299,
        "y": 1.3277217722081511,
        "z": -1.4538258106907676
      },
      "visibleMeshNames": [
        "B_Coccyx",
        "B_Hipbone_L",
        "B_Hipbone_R",
        "B_Lumbar_Disc_L4",
        "B_Lumbar_Disc_L5",
        "B_Lumbar_Vertebra_L4",
        "B_Lumbar_Vertebra_L5",
        "B_Sacrum",
        "C_Acetabulum_Lunate_Surface_L",
        "C_Acetabulum_Lunate_Surface_R",
        "L_Anterior_Longituinal_Ligament",
        "L_Anterior_Sacroiliac_Ligament_L",
        "L_Anterior_Sacroiliac_Ligament_R",
        "L_Iliopectineal_Line_L",
        "L_Iliopectineal_Line_R",
        "L_Inguinal_Ligament_L",
        "L_Inguinal_Ligament_R",
        "L_Lacunar_Ligament_L",
        "L_Lacunar_Ligament_R",
        "L_Posterior_Sacroiliac_Ligament_L",
        "L_Posterior_Sacroiliac_Ligament_R",
        "L_Pubic_Symphysis",
        "L_Sacrospinous_Ligament_L",
        "L_Sacrospinous_Ligament_R",
        "L_Sacrotuberous_ligament_L",
        "L_Sacrotuberous_ligament_R",
        "L_lliolumbar_Ligament_L",
        "L_lliolumbar_Ligament_R"
      ],
      "easyMeshNames": [
        "B_Hipbone_R",
        "L_Anterior_Longituinal_Ligament",
        "L_Anterior_Sacroiliac_Ligament_R",
        "L_Inguinal_Ligament_R",
        "L_Pubic_Symphysis",
        "L_Sacrospinous_Ligament_R",
        "L_lliolumbar_Ligament_R"
      ]
    }
  }
}
