/* eslint react/display-name: off */
import { quizConnectQuery, quizPb } from "@augmedi/proto-gen";
import { assertDefined } from "@augmedi/type-utils";
import type { PlainMessage } from "@bufbuild/protobuf";
import { useSuspenseQuery } from "@connectrpc/connect-query";
import assert from "assert-ts";
import { forwardRef, useMemo } from "react";
import { useFrozenModelAssetUrls } from "../logic/asset.js";
import { allOverlayMaterialCombinedChannelKeys } from "../logic/overlay-material.js";
import { retryExceptConnectNotFound } from "../query.js";
import {
  RawModelPreview,
  type Props as RawModelPreviewProps,
} from "./RawModelPreview.js";
import {
  SharedModelPreviewStuff,
  type Props as SharedModelPreviewStuffProps,
  type SharedModelPreviewStuffRef,
} from "./SharedModelPreviewStuff.js";

interface Props
  extends Pick<
      RawModelPreviewProps,
      "visibleLabelIdsPerChannel" | "settingsByChannel"
    >,
    Pick<SharedModelPreviewStuffProps, "backgroundColor"> {
  frozenModelId: string;
  visibleMeshIds?: string[];
  initialCameraPosition?: number[];
  desiredLabelIdsPerChannel?: { CombinedR?: string[] | undefined };
  onClick?: (
    selectedLabelIds: string[], // only selected labels
    labelsById: Map<string, PlainMessage<quizPb.FrozenModel_Label>>, // all labels, including unselected
  ) => void;
}

type VisibleLabelIds = RawModelPreviewProps["visibleLabelIdsPerChannel"];

function filterVisibleLabelIds(
  visibleLabelIds: VisibleLabelIds,
  cb: (labelId: string) => boolean,
): VisibleLabelIds {
  if (!visibleLabelIds) {
    return undefined;
  }
  const newVisibleLabelIds: VisibleLabelIds = {};
  for (const channel of allOverlayMaterialCombinedChannelKeys) {
    newVisibleLabelIds[channel] = visibleLabelIds[channel]?.filter(cb);
  }
  return newVisibleLabelIds;
}

export const FrozenModelPreview = forwardRef<SharedModelPreviewStuffRef, Props>(
  (
    {
      frozenModelId,
      visibleMeshIds,
      visibleLabelIdsPerChannel,
      initialCameraPosition,
      backgroundColor,
      desiredLabelIdsPerChannel,
      onClick,
      ...restProps
    }: Props,
    ref,
  ) => {
    const getFrozenModelQuery = useSuspenseQuery(
      quizConnectQuery.getFrozenModel,
      {
        id: frozenModelId,
      },
      {
        retry: retryExceptConnectNotFound(),
      },
    );

    const { frozenMeshIdsByMeshName, labelsById, meshNamesByWholeMeshLabelId } =
      useMemo(() => {
        const meshes = getFrozenModelQuery.data.manifest?.meshes ?? [];
        const frozenMeshIdsByMeshName = new Map(
          meshes.map((m) => [m.gltfMeshName, m.id]),
        );

        const labels = getFrozenModelQuery.data.manifest?.labels ?? [];
        const labelsById = new Map(labels.map((l) => [l.id, l]));
        assert(labelsById.size === labels.length);

        const meshNamesByMeshId = new Map(
          meshes.map((m) => [m.id, m.gltfMeshName]),
        );
        const meshNamesByWholeMeshLabelId = new Map(
          labels
            .filter((l) => l.isWholeMeshLabel)
            .map((l) => [l.id, assertDefined(meshNamesByMeshId.get(l.meshId))]),
        );

        return {
          frozenMeshIdsByMeshName,
          labelsById,
          meshNamesByWholeMeshLabelId,
        };
      }, [getFrozenModelQuery.data]);

    const { relevantPartitionIds, visibleGltfMeshNames } = useMemo(() => {
      const visibleMeshIdsSet = visibleMeshIds && new Set(visibleMeshIds);

      const meshes = getFrozenModelQuery.data.manifest?.meshes ?? [];

      const relevantPartitionIds = [
        ...new Set(
          meshes
            .filter((m) => !visibleMeshIdsSet || visibleMeshIdsSet.has(m.id))
            .map((m) => m.partitionId),
        ),
      ].sort();

      const visibleGltfMeshNames = new Set(
        meshes
          .filter((m) => !visibleMeshIdsSet || visibleMeshIdsSet.has(m.id))
          .map((m) => m.gltfMeshName),
      );

      return { relevantPartitionIds, visibleGltfMeshNames };
    }, [visibleMeshIds, getFrozenModelQuery.data]);

    const modelAssetUrls = useFrozenModelAssetUrls(
      frozenModelId,
      relevantPartitionIds,
    );

    const visibleLabelIdsPerPartition = useMemo((): VisibleLabelIds[] => {
      const meshes = getFrozenModelQuery.data.manifest?.meshes ?? [];
      const labels = getFrozenModelQuery.data.manifest?.labels ?? [];
      return relevantPartitionIds.map((partitionId) => {
        const meshIdsThisPartition = new Set(
          meshes.filter((m) => m.partitionId === partitionId).map((m) => m.id),
        );
        const labelIdsThisPartition = new Set(
          labels
            .filter((l) => meshIdsThisPartition.has(l.meshId))
            .map((l) => l.id),
        );
        return filterVisibleLabelIds(visibleLabelIdsPerChannel, (labelId) =>
          labelIdsThisPartition.has(labelId),
        );
      });
    }, [
      visibleLabelIdsPerChannel,
      relevantPartitionIds,
      getFrozenModelQuery.data,
    ]);

    return (
      <>
        <SharedModelPreviewStuff
          ref={ref}
          backgroundColor={backgroundColor}
          initialCameraPosition={initialCameraPosition}
        />
        {modelAssetUrls.map((url, i) => (
          <RawModelPreview
            key={url}
            {...restProps}
            gltfUrl={url}
            meshNamesByWholeMeshLabelId={meshNamesByWholeMeshLabelId}
            visibleGltfMeshNames={visibleGltfMeshNames}
            visibleLabelIdsPerChannel={visibleLabelIdsPerPartition[i]}
            desiredLabelIdsPerChannel={desiredLabelIdsPerChannel}
            onClick={(selectedLabelIds: string[]) =>
              onClick?.(selectedLabelIds, labelsById)
            }
            frozenMeshIdsByMeshName={frozenMeshIdsByMeshName}
          />
        ))}
      </>
    );
  },
);
