/* eslint @typescript-eslint/no-unused-vars: off */
import { quizConnectQuery } from "@augmedi/proto-gen";
import { useQuery } from "@connectrpc/connect-query";
import {
  Alert,
  Container,
  Loader,
  Stack,
  Tabs,
  Text,
  Title,
} from "@mantine/core";
import { useLocation, useParams } from "wouter";
import { ModelPageDraftTab } from "./ModelPageDraftTab.js";
import { ModelPageFrozenTab } from "./ModelPageFrozenTab.js";

export const ModelPage = () => {
  const { modelId, modelPageTab } = useParams<{
    modelId: string;
    modelPageTab?: string;
  }>();

  const [_location, setLocation] = useLocation();

  const getModelQuery = useQuery(quizConnectQuery.getModel, { id: modelId });

  return (
    <Container py="md">
      <Stack>
        <Title order={1}>{getModelQuery.data?.name ?? "Model"}</Title>
        {getModelQuery.isLoading && <Loader />}
        {getModelQuery.isError && (
          <Alert title="Error" color="red">
            {getModelQuery.error + ""}
          </Alert>
        )}

        <Tabs
          keepMounted={false}
          value={modelPageTab}
          onChange={(newTab) =>
            setLocation(`/models/${modelId}/${newTab ?? "draft"}`)
          }
        >
          <Tabs.List>
            <Tabs.Tab value="draft">Draft version</Tabs.Tab>
            <Tabs.Tab value="frozen">Frozen version</Tabs.Tab>
          </Tabs.List>

          <Tabs.Panel value="draft" pt="xs">
            <ModelPageDraftTab modelId={modelId} />
          </Tabs.Panel>

          <Tabs.Panel value="frozen" pt="xs">
            {getModelQuery.data &&
              (getModelQuery.data.latestFrozenModelId ? (
                <ModelPageFrozenTab
                  frozenModelId={getModelQuery.data.latestFrozenModelId}
                />
              ) : (
                <Text>This model has no frozen version yet</Text>
              ))}
          </Tabs.Panel>
        </Tabs>
      </Stack>
    </Container>
  );
};
