/* eslint @tanstack/query/exhaustive-deps: off */
import { quizConnectQuery, quizPb } from "@augmedi/proto-gen";
import { callUnaryMethod, useTransport } from "@connectrpc/connect-query";
import { useSuspenseQueries as tsUseSuspenseQueries } from "@tanstack/react-query";

export function useModelAssetUrls(
  modelId: string,
  partitionIds: string[],
): string[] {
  const transport = useTransport();

  const getModelAssetQueries = tsUseSuspenseQueries({
    queries: partitionIds.map((partitionId) => ({
      queryFn: () =>
        callUnaryMethod(
          quizConnectQuery.getModelAsset,
          {
            id: modelId,
            partitionId,
            type: quizPb.ModelAssetType.GLTF,
          },
          { transport },
        ),
      queryKey: ["useModelAssetUrls", "getModelAsset", modelId, partitionId],
    })),
  });

  return getModelAssetQueries.map((query) => query.data.downloadUrl);
}

export function useFrozenModelAssetUrls(
  frozenModelId: string,
  partitionIds: string[],
): string[] {
  const transport = useTransport();

  const getFrozenModelAssetQueries = tsUseSuspenseQueries({
    queries: partitionIds.map((partitionId) => ({
      queryFn: () =>
        callUnaryMethod(
          quizConnectQuery.getFrozenModelAsset,
          {
            id: frozenModelId,
            partitionId,
            type: quizPb.FrozenModelAssetType.GLTF_WEB_V2,
          },
          { transport },
        ),
      queryKey: [
        "useFrozenModelAssetUrls",
        "getFrozenModelAsset",
        frozenModelId,
        partitionId,
      ],
    })),
  });

  return getFrozenModelAssetQueries.map((query) => query.data.downloadUrl);
}
