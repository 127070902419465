import { atlasConnectQuery } from "@augmedi/proto-gen";
import { useSuspenseQuery } from "@connectrpc/connect-query";
import { Blockquote, Stack, Text, Title } from "@mantine/core";

interface Props {
  structureId: string;
}

export const AtlasStructurePanel = ({ structureId }: Props) => {
  const getStructureMetadataQuery = useSuspenseQuery(
    atlasConnectQuery.getStructureMetadata,
    {
      id: structureId,
    },
  );
  const baseMetadata = getStructureMetadataQuery.data.base;
  if (!baseMetadata) {
    throw new Error("Structure not found");
  }

  return (
    <Stack>
      <Stack gap={0}>
        <Title order={2}>{baseMetadata.name}</Title>
        {baseMetadata.alternativeNames.length > 0 && (
          <Text fs="italic">
            {baseMetadata.alternativeNames.map(({ name }) => name).join(" · ")}
          </Text>
        )}
      </Stack>
      {baseMetadata.description && (
        <Blockquote>{baseMetadata.description}</Blockquote>
      )}
    </Stack>
  );
};
