// defaults
let domain: string = "dev-augmedi.eu.auth0.com";
let clientId: string = "e8Yurcr6gnyvP5ZajKTPatY8LhrF5Spp";
let audience: string = "https://dev-augmedi.eu.auth0.com/api/v2/";
let stripeURL: string = "https://buy.stripe.com/test_eVa02i30S8Gib4Y288";
let auth0UserURL: string =
  "https://manage.auth0.com/dashboard/eu/dev-augmedi/users/";
let auth0State: string = "db64jZDB";
let sentryEnvironment: string = "local";

if (window.location.hostname === "app.dev.augmedi.com") {
  sentryEnvironment = "development";
  auth0State = "z3bJG20";
}

const prodHosts = ["app.prod.augmedi.com", "augmedi.app"];
if (prodHosts.includes(window.location.hostname)) {
  domain = "prod-augmedi.eu.auth0.com";
  clientId = "fgqobXh3cMo4q5EhnPGYKsQBdWFwAyf8";
  audience = "https://prod-augmedi.eu.auth0.com/api/v2/";
  stripeURL = "https://buy.stripe.com/fZe5ng19Y0GDcXSfYY";
  auth0UserURL = "https://manage.auth0.com/dashboard/eu/prod-augmedi/users/";
  sentryEnvironment = "production";
}

if (window.location.hostname === "augmedi.app") {
  auth0State = "d6L7ddb46D";
}

if (window.location.hostname === "app.prod.augmedi.com") {
  auth0State = "7uBhA3d";
}

export const config = {
  ASSET_URL_LIFETIME_MILLIS: 60 * 60 * 1000,
  POPOVER_CLOSE_DELAY: 200,
  domain,
  clientId,
  audience,
  stripeURL,
  auth0UserURL,
  auth0State,
  sentryEnvironment,
} as const;
