import type { MantineColor } from "@mantine/core";

export const colorConstants = {
  selectedRightColor: "#00ff0099",
  selectedRightColorMantine: "green" satisfies MantineColor,
  selectedWrongColor: "#ff000099",
  selectedWrongColorMantine: "red" satisfies MantineColor,
  selectedUnknownCorrectnessColor: "#0024ff99",
  secondaryColor: "#ffff0099", // yellow
  ambientHighlightColor: "#0091e399",
  introductionGroupSelectedColor: "#00ff0099",
  introductionGroupSelectedColorMantine: "green" satisfies MantineColor,
  introductionGroupSeenColor: "#0033ff55",
  introductionGroupSeenColorMantine: "blue" satisfies MantineColor,
  introductionGroupRemainingColor: "var(--mantine-color-gray-5)",
  systemShownRightColor: "#00ff0099",
} as const;
