import { useMantineTheme, type DefaultMantineColor } from "@mantine/core";
import * as classes from "./PercentPill.module.css";

interface Props {
  ratio?: number;
  labelSuffix?: string;
  colorName?: DefaultMantineColor;
  border?: boolean;
}

export const PercentPill = ({
  ratio,
  labelSuffix,
  colorName,
  border,
}: Props) => {
  const theme = useMantineTheme();
  const backgroundColor = colorName ? theme.colors[colorName][0] : undefined;
  const textColor = colorName ? theme.colors[colorName][9] : undefined;
  const borderColor =
    colorName && border ? theme.colors[colorName][7] : undefined;
  const barColor = colorName ? theme.colors[colorName][7] : undefined;

  if (ratio === undefined) {
    return (
      <div
        className={classes.wrapper}
        style={{ backgroundColor, borderColor, color: textColor }}
      >
        <div className={classes.text}>{labelSuffix && ` ${labelSuffix}`}</div>
      </div>
    );
  }
  return (
    <div
      className={classes.wrapper}
      style={{ backgroundColor, borderColor, color: textColor }}
    >
      <div className={classes.text}>
        {Math.round(100 * ratio) + " %"}
        {labelSuffix && ` ${labelSuffix}`}
      </div>
      <div className={classes.barWrapper}>
        <div
          className={classes.bar}
          style={{
            backgroundColor: barColor,
            width: `${Math.round(100 * ratio)}%`,
          }}
        />
      </div>
    </div>
  );
};
