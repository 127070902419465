.item {
  display: flex;
  align-items: stretch;
  border-radius: var(--mantine-radius-md);
  border: 1px solid var(--mantine-color-gray-2);
  background-color: var(--mantine-color-white);
  margin-bottom: var(--mantine-spacing-sm);
}

.itemDragging {
  box-shadow: var(--mantine-shadow-sm);
}

.itemContent {
  display: block;
  padding: var(--mantine-spacing-sm);
  padding-left: var(--mantine-spacing-xs);
  flex-grow: 1;
  text-decoration: none;
  color: inherit;
}

.itemContentClickable {
  cursor: pointer;
}

.itemContentClickable:hover {
  text-decoration: underline;
}

.itemContentTruncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.dragHandle {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--mantine-color-gray-6);
  padding-left: var(--mantine-spacing-lg);
  padding-right: var(--mantine-spacing-md);
  cursor: grab;
}
