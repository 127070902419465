import { quizConnectQuery } from "@augmedi/proto-gen";
import { useSuspenseQuery } from "@connectrpc/connect-query";
import { AreaChart } from "@mantine/charts";
import { Group, Paper, Stack, Text } from "@mantine/core";

export const ProgressPanel = () => {
  const res = useSuspenseQuery(quizConnectQuery.getQuizDailyRepetitionStats, {
    utcOffset: Math.round(new Date().getTimezoneOffset() / 60),
  });
  const chartEntries = res.data.dailyProgressByDate.map((stat) => ({
    date: new Date(stat.date).toLocaleDateString(),
    learned: Math.round(stat.learnedRatio * 100),
    needsReview: Math.round(stat.needsReviewRatio * 100),
  }));

  return (
    <Paper withBorder p="md" radius="md">
      <Stack>
        <Group justify="space-between">
          <Text fz="xl" fw={700}>
            Progress
          </Text>
        </Group>
        <AreaChart
          h={300}
          series={[
            { name: "learned", color: "blue", label: "Learned" },
            { name: "needsReview", color: "yellow", label: "Needs review" },
          ]}
          data={chartEntries}
          dataKey={"date"}
          curveType="linear"
          unit="%"
        />
      </Stack>
    </Paper>
  );
};
