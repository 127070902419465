// https://github.com/grpc/grpc/blob/master/doc/health-checking.md

// @generated by protoc-gen-connect-query v1.4.2 with parameter "target=ts"
// @generated from file proto/health.proto (package grpc.health.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { MethodKind } from "@bufbuild/protobuf";
import { HealthCheckRequest, HealthCheckResponse } from "./health_pb.js";

/**
 * @generated from rpc grpc.health.v1.Health.Check
 */
export const check = {
  localName: "check",
  name: "Check",
  kind: MethodKind.Unary,
  I: HealthCheckRequest,
  O: HealthCheckResponse,
  service: {
    typeName: "grpc.health.v1.Health"
  }
} as const;
