/* eslint react/display-name: off, react/prop-types: off */
import { useMantineTheme } from "@mantine/core";
import { forwardRef } from "react";
import * as classes from "./MiniPie.module.css";

interface Props {
  size: number;
  // Slices will be drawn on top of each other. Their ratios will _not_ be
  // summed. The last slice is drawn on top.
  slices: { ratio: number; colorName: string; colorLevel?: number }[];
}

export const MiniPie = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const theme = useMantineTheme();
  const { size, slices } = props;
  const r = size / 2;

  return (
    <div ref={ref} {...props}>
      <svg width={size} height={size}>
        {slices.map(({ ratio: _ratio, colorName, colorLevel }, index) => {
          const ratio = Math.min(Math.max(_ratio, 0), 0.9999); // HACK Ratio 1 breaks the arc calculations. I _could_ fix them properly, but this works.
          const startAngle = -90;
          const endAngle = startAngle + ratio * 360;
          const color = theme.colors[colorName][colorLevel ?? 6];

          const startX = r + r * Math.cos((startAngle * Math.PI) / 180);
          const startY = r + r * Math.sin((startAngle * Math.PI) / 180);
          const endX = r + r * Math.cos((endAngle * Math.PI) / 180);
          const endY = r + r * Math.sin((endAngle * Math.PI) / 180);

          const largeArcFlag = endAngle - startAngle <= 180 ? 0 : 1;

          return (
            <path
              id={`slice-${index}`}
              key={index}
              className={classes.miniPie}
              d={`M ${r} ${r} L ${startX} ${startY} A ${r} ${r} 0 ${largeArcFlag} 1 ${endX} ${endY} Z`}
              fill={color}
            />
          );
        })}
      </svg>
    </div>
  );
});
