import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import { rem } from "@mantine/core";
import { IconGripVertical } from "@tabler/icons-react";
import { Link } from "wouter";
import * as classes from "./DraggableList.module.css";

interface Item {
  id: string;
  label: string;
  linkTo?: string;
  onClick?: () => void;
}

interface Props {
  items: Item[];
  onReorder: (idsInNewOrder: string[]) => void;
  truncate?: boolean;
}

export const DraggableList = ({ items, onReorder, truncate }: Props) => {
  return (
    <DragDropContext
      onDragEnd={({ destination, source }) => {
        if (!destination) {
          return;
        }
        const idsInNewOrder = [...items.map((v) => v.id)];
        const [movedId] = idsInNewOrder.splice(source.index, 1);
        idsInNewOrder.splice(destination.index ?? 0, 0, movedId);
        onReorder(idsInNewOrder);
      }}
    >
      <Droppable droppableId="dnd-list" direction="vertical">
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {items.map((item, index) => (
              <Draggable key={item.id} index={index} draggableId={item.id}>
                {(provided, snapshot) => (
                  <div
                    className={[
                      classes.item,
                      snapshot.isDragging && classes.itemDragging,
                    ]
                      .filter((v) => v)
                      .join(" ")}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                  >
                    <div
                      {...provided.dragHandleProps}
                      className={classes.dragHandle}
                    >
                      <IconGripVertical
                        style={{ width: rem(18), height: rem(18) }}
                        stroke={1.5}
                      />
                    </div>
                    {item.linkTo === undefined ? (
                      <div
                        className={[
                          classes.itemContent,
                          item.onClick && classes.itemContentClickable,
                          truncate && classes.itemContentTruncate,
                        ]
                          .filter((v) => v)
                          .join(" ")}
                        onClick={item.onClick}
                      >
                        {item.label}
                      </div>
                    ) : (
                      <Link
                        className={[
                          classes.itemContent,
                          classes.itemContentClickable,
                          truncate && classes.itemContentTruncate,
                        ]
                          .filter((v) => v)
                          .join(" ")}
                        to={item.linkTo}
                      >
                        {item.label}
                      </Link>
                    )}
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};
