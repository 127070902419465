.wrapper {
  display: inline-block;
  border-radius: var(--mantine-radius-sm);
  overflow: hidden;
  width: 200px;
  border: 1px solid transparent;
}

.text {
  padding: 2px var(--mantine-spacing-xs) 1px var(--mantine-spacing-xs);
  text-align: center;
}

.barWrapper {
  width: 100%;
}

.bar {
  height: 3px;
}
